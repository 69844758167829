import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CashHandoverRequest } from 'src/app/models/cash-handover/cash-handover-request-model';
import { Config } from 'src/config/config';
import { RestRequestService } from '../rest-request.service';

@Injectable({
  providedIn: 'root'
})
export class CashHandoverService {

  private readonly CashHandoverJobsUrl: string;

  constructor(private rest: RestRequestService, private restService: RestRequestService, @Inject("recoveryConfig") private recoveryConfig = Config.recoveryConfig) { 

    this.CashHandoverJobsUrl = this.getCashHandoverJobsUrl(recoveryConfig);

  }

  private getCashHandoverJobsUrl(recoveryConfig: any = {}): string {
    let callDeskUrl = '';

    if (recoveryConfig.env === 'local') {
      callDeskUrl = `${recoveryConfig.appServerUrl}`;
    } else {
      callDeskUrl = `${recoveryConfig.recoveryServiceUrl}`;
    }
    return callDeskUrl;
  }

  GetCashHandOverDet(user_name: string): Observable<CashHandoverRequest[]> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');

    // call list with filters uri
    let jobURL: string = this.CashHandoverJobsUrl + `/vGetCashHandOverDet/${user_name}`;
    return this.restService.get<CashHandoverRequest[]>(
      jobURL,
      {
        headers: headers,
      }
    );
  }  

  getBranchList(): Observable<any[]> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');

    // call list with filters uri
    let jobURL: string = this.CashHandoverJobsUrl + `/vGetBranchList`;
    return this.restService.get<any[]>(
      jobURL,
      {
        headers: headers,
      }
    );
  }

  CashHOSOut(payload: any): any {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');
    let url = `${this.CashHandoverJobsUrl}/vCashHandOverSignOff`;
    return this.rest.post<any>(url, payload, {
      headers: headers
    });
  }

}
