import { Component, Input, OnInit } from '@angular/core';
import { MapsAPILoader } from "@agm/core";
import { MatSnackBar } from '@angular/material/snack-bar';
import { DurationUtil } from 'src/app/utils/DurationUtil';

@Component({
  selector: 'app-visit-route-data-view',
  templateUrl: './visit-route-data-view.component.html',
  styleUrls: ['./visit-route-data-view.component.scss']
})
export class VisitRouteDataViewComponent implements OnInit {

  selectedOriginType: string;
  selectedDestinationType: string;
  originLatLng: {lat: Number, lng: Number};
  originAddress: string = "";
  destinationLatLng: {lat: Number, lng: Number};
  destinationAddress: string = "";

  directionService: any;

  @Input() currentLat:any;
  @Input() currentLng:any;

  @Input() waypoints:any[] = [];

  totalDistance: any;

  constructor(private mapsAPILoader: MapsAPILoader, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.selectedOriginType = "current";
    this.selectedDestinationType = "current";
    if (this.currentLat == undefined || this.currentLng == undefined || this.currentLat == null || this.currentLng == null) {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.currentLat = position.coords.latitude;
          this.currentLng = position.coords.longitude;
        });
      } else {
        this._snackBar.open('Failed to get current location', 'Dismiss', {
          duration: DurationUtil.TWO_SEC,
          horizontalPosition: "right",
          verticalPosition: "top",
        });
      }
    } else {

    }

    this.mapsAPILoader.load().then(() => {
      this.directionService = new google.maps.DirectionsService;
    })
    .catch(e => {
      this._snackBar.open('Failed to load map resources', 'Dismiss', {
        duration: DurationUtil.TWO_SEC,
        horizontalPosition: "right",
        verticalPosition: "top",
      });
    });
  }

  getRouteDistance = async (origin: string | google.maps.LatLng, waypoints: any[], destination: any, optimize: boolean): Promise<string> => {

    let returnValue: any = null;
    await this.directionService
      .route({
        origin: origin,
        waypoints: waypoints,
        destination: destination,
        optimizeWaypoints: optimize,
        travelMode: google.maps.TravelMode.DRIVING
      }, (result: google.maps.DirectionsResult, status: google.maps.DirectionsStatus) => {
        if (status == "OK") {
          returnValue = result;
        } else {
          returnValue = null;
        }
      });
    return returnValue;
  }

  clickHandler() {
    let origin: string | google.maps.LatLng;
    let destination: string | google.maps.LatLng;
    let wayptsToSend: Array<any> = [];
    let itrRange = this.waypoints.length;
    if (this.selectedOriginType == "address") {
      origin = this.originAddress;
    } else {
      origin = new google.maps.LatLng({lat: this.currentLat, lng: this.currentLng})
    }

    if (this.selectedDestinationType == "address") {
      destination = this.destinationAddress;
    } else if (this.selectedDestinationType == "last") {
      destination = this.waypoints[-1].customerAddress;
      itrRange = itrRange - 1;
    } else {
      destination = new google.maps.LatLng({lat: this.currentLat, lng: this.currentLng})
    }
    
    if (itrRange > 0 ) {
      for (let i = 0; i < itrRange; i++) {
        wayptsToSend.push({
          location: this.waypoints[i].customerAddress,
          stopover: true
        })
      }
    }

    let temp = new google.maps.LatLng({lat: 6.9270786, lng: 79.861243})

    console.log(origin, wayptsToSend, destination)

    this.getRouteDistance(temp, wayptsToSend, temp, true).then(result => {
      console.log(result);
    });
  }

}
