<div class="component-wrapper">
  <div class="form-wrapper">
    <div>
      <mat-form-field appearance="fill" class="form-field w-100 pt-3 pb-1 px-4">
        <mat-label>Route name</mat-label>
        <input matInput type="text" [(ngModel)]="name" />
      </mat-form-field>
    </div>

    <div>
      <mat-form-field appearance="fill" class="form-field w-100 pt-1 px-4">
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="postal code or city" #input>
      </mat-form-field>
    </div>
    <div class= "buttonSet" py-3 d-flex>
      <div class="w-10 button-wrapper" *ngIf="(selectedList.length < 1 ? false : true) && (name.length < 1 ? false : true)">
        <button mat-raised-button color="primary" class="mx-2" (click)="onPreviewHandler()">View Map</button>
        <button mat-raised-button color="primary" class="mx-2" (click)="onSubmitHandler()">Submit</button>
      </div>
      <!-- <div class="w-25 button-wrapper" *ngIf="(selectedList.length < 1 ? false : true) && (name.length < 1 ? false : true)">
        <button mat-raised-button color="primary" class="mx-2" (click)="onSubmitHandler()">Submit</button>
      </div> -->
    </div>
    <div class="table-container">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 w-100">
        <ng-container matColumnDef="Selected">
          <th class="px-2" mat-header-cell *matHeaderCellDef> Selected </th>
          <td mat-cell *matCellDef="let element; let i = index;"><input type="checkbox"
              [checked]="selectedList.includes(element.code) ? true : false"
              (change)="onChangeHandler(element.code, $event)"></td>
        </ng-container>
        <ng-container matColumnDef="Postal Code">
          <th class="px-2" mat-header-cell *matHeaderCellDef> Postal Code </th>
          <td mat-cell *matCellDef="let element"> {{element.code}} </td>
        </ng-container>
        <ng-container matColumnDef="Name">
          <th class="px-2" mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>
        <ng-container matColumnDef="Latitude">
          <th class="px-2" mat-header-cell *matHeaderCellDef> Latitude </th>
          <td mat-cell *matCellDef="let element"> {{element.lat}} </td>
        </ng-container>
        <ng-container matColumnDef="Longtitude">
          <th class="px-2" mat-header-cell *matHeaderCellDef> Longtitude </th>
          <td mat-cell *matCellDef="let element"> {{element.long}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index" class="table-row"
          [ngClass]="selectedList.includes(row.code) ? 'selected-row' : ''"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[10, 25, 100]" aria-label="Select page of users"></mat-paginator>
    </div>
  </div>
</div>
