<app-dialog-header [title]="title" (closeDialogEvent)="closeDialog()"></app-dialog-header>

<div class="container-fluid py-4">
  <div class="w-100 card-container">
    <div class="card-header w-100 p-2">
      <span>3rd Party</span>
    </div>
    <div class="card-content p-2 w-100">
      <div class="row card-content-row w-100 px-5 px-md-4 py-2 py-md-0">
        <div class="col-6 py-1 py-md-2 px-2 d-flex justify-content-start">
          <button mat-raised-button color="primary" (click)="openNotePadEntry()">Open Note Pad</button>
        </div>
        <div class="col-12 py-1 py-md-2">
          <div class="col-12 card-content-leading">Name</div>
          <div class="col-12 card-content-description">
            <mat-form-field class="w-100">
              <input [disabled]="disableUpdate" matInput [(ngModel)]="thirdPartyName">
            </mat-form-field>
          </div>
        </div>
        <div class="col-12 py-1 py-md-2">
          <div class="col-12 card-content-leading">Address</div>
          <div class="col-12 card-content-description">
            <mat-form-field class="w-100">
              <input matInput [disabled]="disableUpdate" [(ngModel)]="thirdPartyAddress">
            </mat-form-field>
          </div>
        </div>
        <div class="col-12 py-1 py-md-2">
          <div class="col-12 card-content-leading">Contact Number</div>
          <div class="col-12 card-content-description">
            <mat-form-field class="w-100">
              <input matInput [disabled]="disableUpdate" [(ngModel)]="contactNo">
            </mat-form-field>
          </div>
        </div>
        <div class="col-12 py-1 py-md-2">
          <div class="col-12 card-content-leading">Other Flags</div>
          <div class="col-12 card-content-description">
            <mat-form-field class="w-100">
              <mat-select [(ngModel)]="otherFlag" (selectionChange)="handleFlagChange($event)">
                <mat-option value="NA">--Select--</mat-option>
                <mat-option *ngFor="let flag of flags" [value]="flag">{{flag}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row p-2">
    <div class="col-6 p-1">
      <button mat-raised-button color="primary" (click)="updateFollowUpDetails()">Update Flags</button>
    </div>
    <div class="col-6 p-1">
      <button mat-raised-button color="warn" (click)="closeDialog()">Close</button>
    </div>
  </div>
</div>