import { Inject, Injectable } from "@angular/core";
import { RestRequestService } from "../rest-request.service";
import { Config } from "../../../config/config";
import { HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { TodaysCallList } from "src/app/models/call-desk/todays-calls-response";
import { CallDeskUpdateRequestModel } from "src/app/models/call-desk/call-desk-update-request-model";
import { ClientDetailResponseModel } from "../../models/call-desk/ClientDetailResponseModel";
import { FacilityDetailsResponseModel } from "../../models/call-desk/FacilityDetailsResponseModel";
import { FlaggingdetailsResponseModel } from "../../models/call-desk/flaggingdetails-response-model";
import { SecurityDetailsResponseModel } from "../../models/call-desk/security-details-response-model";
import { CallListContract } from "src/app/models/call-desk/todays-call-list-response";
import { CallProgress } from "src/app/models/call-desk/call-progress";
import { CallListContractInfo } from "src/app/models/call-desk/todays-call-list-single-record-model";
import { DetailViewResponseModel } from "src/app/models/call-desk/detail-view-info-model";
import { NotePadEntryUpdateModel } from "src/app/models/call-desk/note-pad-entry-update.model";

@Injectable({
  providedIn: 'root'
})
/**
 * Services implementation releated to  call desk
 * @author Malindu Panchala
 */
export class TodaysCallDeskService {

  private readonly callDeskJobsUrl: string;

  constructor(private restService: RestRequestService, @Inject("recoveryConfig") private recoveryConfig = Config.recoveryConfig) {

    this.callDeskJobsUrl = this.getCallDeskJobsUrl(recoveryConfig);
  }

  /**
   * Get CallDesk job service Url from CallDesk injectable object.
   * @param recoveryConfig
   * @returns callDeskUrl:string
   */
  private getCallDeskJobsUrl(recoveryConfig: any = {}): string {
    let callDeskUrl = '';

    if (recoveryConfig.env === 'local') {
      callDeskUrl = `${recoveryConfig.appServerUrl}`;
    } else {
      callDeskUrl = `${recoveryConfig.recoveryServiceUrl}`;
    }
    return callDeskUrl;
  }

  /**
   * Returns the All the Call desk details.
   * {CallDeskDataRequestModel} requestData
   * Returns {Observable<CallDeskDataResponseModel>}
   */
  getTodaysCallList(id: string, date: string): Observable<TodaysCallList> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');

    const type = "CALL";

    let jobURL: string = this.callDeskJobsUrl + `/vTodayContractListRequest/CIG00000676/CALL/14-SEP-22`;
    return this.restService.get<TodaysCallList>(
      jobURL,
      {
        headers: headers
      }
    );
  }

  getContractDetailsById(id: string, cType: string): Observable<DetailViewResponseModel> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');
    console.log("cType : " + cType);

    let jobURL: string = this.callDeskJobsUrl + `/vGetSingleContractListRequestFilter/${id}/${cType}`;
    return this.restService.get<DetailViewResponseModel>(
      jobURL,
      {
        headers: headers
      }
    );
  }

  getOriginTypes(): Observable<{
    OTYPE: string,
    ODESC: string
  }[]> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');

    let jobURL: string = this.callDeskJobsUrl + `/vGetOrigineDet`;
    return this.restService.get<{
      OTYPE: string,
      ODESC: string
    }[]>(
      jobURL,
      {
        headers: headers
      }
    );
  }

  updateCallStatus(payload: CallDeskUpdateRequestModel) {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');

    let jobURL: string = this.callDeskJobsUrl + `/vUpdCallDesk`;
    return this.restService.post<any>(
      jobURL,
      payload,
      {
        headers: headers
      }
    );
  }

  updateNotePadEntry(payload: NotePadEntryUpdateModel): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');

    let jobURL: string = this.callDeskJobsUrl + `/vUpdNpVisit`;
    return this.restService.post<any>(
      jobURL,
      payload,
      {
        headers: headers
      }
    );
  }

  getNpEntry(contractNo: string) {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');

    let jobURL: string = this.callDeskJobsUrl + `/vGetNPEntry/${contractNo}`;
    return this.restService.get<any>(
      jobURL,
      {
        headers: headers
      }
    );
  }



  getClientDetail(contractNo: string, cType: string): Observable<ClientDetailResponseModel> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');
    let jobURL: string = this.callDeskJobsUrl + `/vGetClientDetails/` + contractNo + '/' + cType;
    return this.restService.get<ClientDetailResponseModel>(
      jobURL,
      {
        headers: headers
      }
    );
  }

  getFacilityDetail(contractNo: string, cType: string): Observable<FacilityDetailsResponseModel> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');

    let jobURL: string = this.callDeskJobsUrl + `/vGetFacilityDetails/` + contractNo + `/` + cType;
    return this.restService.get<FacilityDetailsResponseModel>(
      jobURL,
      {
        headers: headers
      }
    );
  }

  getFlaggingDetail(contractNo: string, cType: string): Observable<FlaggingdetailsResponseModel> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');

    let jobURL: string = this.callDeskJobsUrl + `/vGetFollowUpDetails/` + contractNo + `/` + cType;
    return this.restService.get<FlaggingdetailsResponseModel>(
      jobURL,
      {
        headers: headers
      }
    );
  }

  getSecurityDetail(contractNo: string, cType: string): Observable<SecurityDetailsResponseModel[]> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');

    let jobURL: string = this.callDeskJobsUrl + `/vGetSecurityDetails/` + contractNo + `/` + cType;
    return this.restService.get<SecurityDetailsResponseModel[]>(
      jobURL,
      {
        headers: headers
      }
    );
  }

  updateClientDetails(payload: any) {

    const headers = new HttpHeaders().set('Content-Type', 'text/plain');

    let jobURL: string = this.callDeskJobsUrl + `/vUpdateClientDetails`;
    return this.restService.post<any>(
      jobURL,
      payload,
      {
        headers: headers
      }
    );
  }

  updateSecurityDetails(payload: any) {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');

    let jobURL: string = this.callDeskJobsUrl + `/vUpdateSecurityDetails`;
    return this.restService.post<any>(
      jobURL,
      payload,
      {
        headers: headers
      }
    );
  }

  // getCallListWithFilters(invg: string, type: string, npl: string, dpd: string, notPayed: string, payed: string, notPayedOption: string, date: string, cType: string): Observable<Array<CallListContract>> {
    getCallListWithFilters(invg: string, type: string, npl: string, dpd: string, notPayed: string, notPayedOption: string,td: string, date: string, cType: string): Observable<Array<CallListContract>> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');

    let jobURL: string = this.callDeskJobsUrl + `/vGetTodaysCallList/${invg}/${type}/${dpd}/${npl}/${notPayed}/${notPayedOption}/${td}/${date}/${cType}`;
    return this.restService.get<Array<CallListContract>>(
      jobURL,
      {
        headers: headers,
      }
    );
  }

  searchCallListWithFilters(invg: string, keyword: string, type: string, mode: string, npl: string, dpd: string, notPayed: string,td: string, date: string, cType: string): Observable<Array<CallListContract>> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');

    // call list with filters uri
    // let jobURL: string = this.callDeskJobsUrl + `/vSearchCallList/${invg}/${keyword}/${type}/${mode}/${dpd}/${npl}/${notPayed}/${td}/${date}/${cType}`;
    let jobURL: string = this.callDeskJobsUrl + `/vSearchCallList/${invg}/${keyword}/${type}/${mode}/${dpd}/${npl}/${notPayed}/${date}/${cType}`;
    return this.restService.get<Array<CallListContract>>(
      jobURL,
      {
        headers: headers,
      }
    );
  }

  testAuth(): Observable<any> {
    let authdata = btoa('bbapplive' + ':' + 'BB@#applive');
    // const headers = new HttpHeaders().set('Content-Type', 'text/plain').set("Access-Control-Allow-Origin", "*")
    //   .set("Authorization", `Basic ${authdata}`);

    const headers = new HttpHeaders().set('Content-Type', 'text/plain');
    // let authdata = btoa('bbapplive' + ':' + 'BB@#applive');
    // headers.append("Access-Control-Allow-Origin", "*");

    // call list with filters uri
    let jobURL: string = 'https://cfmobile.lk/BB_APP_LIVE/CFBBService.svc/vGetDetialViewSnap/2233059705/CR';



    return this.restService.get<any>(
      jobURL,
      {
        headers: headers,
      }
    );
  }





  updateFollowUpDetails(payload: any) {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');

    let jobURL: string = this.callDeskJobsUrl + `/vUpdateTPDetails`;
    return this.restService.post<any>(
      jobURL,
      payload,
      {
        headers: headers
      }
    );
  }


  callProgress(invg: string, cType: string): Observable<CallProgress[]> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');

    // call list with filters uri
    let jobURL: string = this.callDeskJobsUrl + `/vGetCallProgress/${invg}/${cType}`;
    return this.restService.get<CallProgress[]>(
      jobURL,
      {
        headers: headers,
      }
    );
  }


  getClientExposure(nic: string) {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');
    // vGetClientExposure/600783319V/ACTIVE

    let jobURL: string = this.callDeskJobsUrl + `/vGetClientExposure/${nic}/ACTIVE`;
    return this.restService.get<any>(
      jobURL,
      {
        headers: headers
      }
    );
  }

  getTranactionSummary(conractNo: string) {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');
    let jobURL: string = this.callDeskJobsUrl + `/vGetTransDet/${conractNo}/31-DEC-2022`;
    return this.restService.get<any>(
      jobURL,
      {
        headers: headers
      }
    );
  }

  getDetailViewSnapData(contractNo: string, cType: string) {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');
    // vGetClientExposure/600783319V/ACTIVE

    let jobURL: string = this.callDeskJobsUrl + `/vGetDetialViewSnap/${contractNo}/${cType}`;
    return this.restService.get<any>(
      jobURL,
      {
        headers: headers
      }
    );
  }

  pinClientLocation(payload: any) {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');

    let jobURL: string = this.callDeskJobsUrl + `/vUpdCllocation`;
    return this.restService.post<any>(
      jobURL,
      payload,
      {
        headers: headers
      }
    );
  }

  sendCEFTSms(payload: {
    contractno: string,
    contactno: string
  }): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');

    let jobURL: string = this.callDeskJobsUrl + `/vSndCEFTsms`;
    return this.restService.post<any>(
      jobURL,
      payload,
      {
        headers: headers
      }
    );
  }

}
