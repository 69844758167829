import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-request-dialog',
  templateUrl: './request-dialog.component.html',
  styleUrls: ['./request-dialog.component.scss']
})
export class RequestDialogComponent implements OnInit {

  constructor(public dialogRf: MatDialogRef<RequestDialogComponent>) { }

  ngOnInit(): void {
  }

  onCloseHandler() {
    this.dialogRf.close()
  } 

}
