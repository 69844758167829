import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { TodaysCallDeskService } from "../../services/call-desk/call-desk.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ClientDetailResponseModel } from "../../models/call-desk/ClientDetailResponseModel";

@Component({
  selector: 'app-client-detail-dialog',
  templateUrl: './client-detail-dialog.component.html',
  styleUrls: ['./client-detail-dialog.component.scss']
})
export class ClientDetailDialogComponent implements OnInit {

  title = "Client Details"
  contractNo = '123456';
  selectedClientDetail: ClientDetailResponseModel;

  constructor(private dialogRef: MatDialogRef<ClientDetailDialogComponent>, public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, private service: TodaysCallDeskService,
    private _snackBar: MatSnackBar) {
    this.selectedClientDetail = data.selectedClientDetail[0];
    this.contractNo = this.selectedClientDetail.contractNo;
  }

  ngOnInit(): void {
    console.log(this.selectedClientDetail)
  }

  updateClientDetails() {
    let payload: any = {
      CONTRACTNO: this.selectedClientDetail.contractNo,
      CONTACTNO: this.selectedClientDetail.contactNo,
      ADDRESS: this.selectedClientDetail.address,
      OCCUPTION: this.selectedClientDetail.occupation,
      THIRD_PARTY_NAME: this.selectedClientDetail.thirdPartyName,


      system: this.selectedClientDetail.cType

    
    }
    ;

    this.service.updateClientDetails(payload).subscribe({
      next: (data) => {
        if (data?.content[0]?.result) {

        }
        alert("Client Details Updated Successfully!");
        console.log(JSON.stringify(this.selectedClientDetail));
      },
      error: (err) => {
        console.log(err);
        alert("An error occured while connecting to the server!");
      }
    });
  }

  closeDialog(): any {
    this.dialogRef.close();
  }
}
