/**
 * ReceiptRequestModel
 * @author Achala M. Rathnathilaka
 */
export class ReceiptRequestModel{

  brncode= "";
  usrid= "";
  paymode= "";
  contractno= "";
  amount= "";
  chqtype= "";
  chqno= "";
  chqamt= "";
  accno= "";
  date= "";
  SYSTEM= "";

}
