import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { RestRequestService } from '../rest-request.service';
import { Observable } from 'rxjs';
import { Config } from "../../../config/config";

@Injectable({
  providedIn: 'root'
})
export class TrackingSummaryReportService {

  private readonly callDeskJobsUrl: string;
  private readonly apiUrl: string;

  constructor(private restService: RestRequestService,
    @Inject("recoveryConfig") private recoveryConfig = Config.recoveryConfig,
    private http: HttpClient) {


      this.callDeskJobsUrl = this.getCallDeskJobsUrl(recoveryConfig);

      // Set apiUrl based on recoveryConfig
      if (recoveryConfig.env === 'local') {
        this.apiUrl = recoveryConfig.appServerUrl;
      } else {
        this.apiUrl = recoveryConfig.recoveryServiceUrl;
      }
     }


  private getCallDeskJobsUrl(recoveryConfig: any = {}): string {
    if (recoveryConfig.env === 'local') {
      return recoveryConfig.appServerUrl;
    } else {
      return recoveryConfig.recoveryServiceUrl;
    }
  }


  // POST Update Real Time Tracing Distance
  updateRealTimeTracingDistance(selectedRows: any[]): Observable<any> {
    const url = `${this.apiUrl}/vUpdateRealTimeTracingDistance`;
    const requestBody = JSON.stringify(selectedRows);
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');
    return this.http.post<any>(
      url,
      requestBody,
      { headers }
    );
  }
}
