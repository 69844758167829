import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { ClientDetailDialogComponent } from "../client-detail-dialog/client-detail-dialog.component";
import { TodaysCallDeskService } from "../../services/call-desk/call-desk.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NotePadEntryViewComponent } from "../note-pad-entry-view/note-pad-entry-view.component";
import { SettlementCalculationComponent } from "../settlement-calculation/settlement-calculation.component";
import { FacilityDetailsResponseModel } from "../../models/call-desk/FacilityDetailsResponseModel";
import { TransactionSummaryComponent } from "../transaction-summary/transaction-summary.component";
import { DateUtil } from "../../utils/DateUtil";
import { UserAuthenticationService } from 'src/app/services/user-authentication/user-authentication.service';

@Component({
  selector: 'app-facility-detail-dialog',
  templateUrl: './facility-detail-dialog.component.html',
  styleUrls: ['./facility-detail-dialog.component.scss']
})
export class FacilityDetailDialogComponent implements OnInit {

  title = "Facility Details"
  selectedFacilityDetail: FacilityDetailsResponseModel;
  expiryDate = '';
  activateDate = '';
  lastPaymentDate = '';

  nic: string = null

  constructor(private auth: UserAuthenticationService, private dialogRef: MatDialogRef<ClientDetailDialogComponent>, public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, private service: TodaysCallDeskService, private _snackBar: MatSnackBar) {
    this.selectedFacilityDetail = data.selectedFacilityDetail[0];

    console.log(this.selectedFacilityDetail)
    this.expiryDate = DateUtil.getDateString(new Date(this.selectedFacilityDetail.expireDate));
    this.activateDate = DateUtil.getDateString(new Date(this.selectedFacilityDetail.activatedDate));
    this.lastPaymentDate = DateUtil.getDateString(new Date(this.selectedFacilityDetail.lastpmtDate));
  }

  ngOnInit(): void {

  }

  closeDialog(): any {
    this.dialogRef.close();
  }

  openClientExposure() {
    // const dialogConfig = new MatDialogConfig();
    // dialogConfig.disableClose = true;
    // dialogConfig.closeOnNavigation = true;
    // dialogConfig.autoFocus = true;
    // dialogConfig.height = '500px';
    // dialogConfig.width = '900px';
    // dialogConfig.data = {
    //   contractNo: this.selectedFacilityDetail.contractNo,
    //   nic: this.selectedFacilityDetail.nic

    // }
    // const dialogRef = this.dialog.open(ClientExposureComponent, dialogConfig);
    // dialogRef.afterClosed().subscribe(
    //   data => {
    //     console.log(`NP entry opened`);
    //   }
    // );
    // if (this.selectedFacilityDetail.nic) {
    //   window.open(`https://www.cfmobile.lk/crmesb/ESBInfo/CRMClientExposure/ClientExposure?IDNO=${this.selectedFacilityDetail.nic}`, '_blank')
    // } else {
    //   alert("NIC number not available")
    // }

    let IDNO: any = `${this.selectedFacilityDetail.nic}`

    this.auth.ClientExposure(IDNO);
  }

  openTransactionSummaryDialog() {
    // const dialogConfig = new MatDialogConfig();
    // dialogConfig.disableClose = true;
    // dialogConfig.closeOnNavigation = true;
    // dialogConfig.autoFocus = true;
    // dialogConfig.height = '800px';
    // dialogConfig.width = '1200px';
    // dialogConfig.data = {
    //   contractNo: this.selectedFacilityDetail.contractNo
    // }
    // const dialogRef = this.dialog.open(TransactionSummaryComponent, dialogConfig);
    // dialogRef.afterClosed().subscribe(
    //   data => {
    //     console.log(`Transaction Summary opened`);
    //   }
    // );

    // if (this.selectedFacilityDetail.nic) {
    //   window.open(`https://cfmobile.lk/crmesbtest/ESBInfotest/CRMTransactionSummery/CRMTransactionSummery?OPPORTUNITYID=${this.selectedFacilityDetail.contractNo}`, '_blank')
    // } else {
    //   alert("Contract number not available")
    // }

    let OPPORTUNITYID = `${this.selectedFacilityDetail.contractNo}`

    this.auth.CRMTransactionSummery(OPPORTUNITYID);

  }

  openNotePadEntry() {
    // const dialogConfig = new MatDialogConfig();
    // dialogConfig.disableClose = true;
    // dialogConfig.closeOnNavigation = true;
    // dialogConfig.autoFocus = true;
    // dialogConfig.height = '800px';
    // dialogConfig.width = '1200px';
    // dialogConfig.data = {
    //   // contractNo: this.selectedCallDeskTransaction.contractNo

    //   contractNo: this.selectedFacilityDetail.contractNo
    // }
    // const dialogRef = this.dialog.open(NotePadEntryViewComponent, dialogConfig);
    // dialogRef.afterClosed().subscribe(
    //   data => {
    //     console.log(`NP entry opened`);
    //   }
    // );

    // if (this.selectedFacilityDetail.nic) {
    //   window.open(`https://cfmobile.lk/crmesbtest/ESBInfotest/CRMNotePad/CRMNotePadview?FACNO=${this.selectedFacilityDetail.contractNo}`, '_blank')
    // } else {
    //   alert("NIC number not available")
    // }

    let FACNO = `${this.selectedFacilityDetail.contractNo}`

    this.auth.openNotepadService(FACNO);

  }

  settlementCalculation() {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '100vh';
    dialogConfig.width = '100vw';
    dialogConfig.maxWidth = '100vw';
    dialogConfig.data = {
      contractNo: this.selectedFacilityDetail.contractNo,
      cType:this.selectedFacilityDetail.cType
    }
    const dialogRef = this.dialog.open(SettlementCalculationComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        console.log(`Settlement cal opened`);
      }
    );
  }
}
