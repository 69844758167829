<div class="container-fluid">
  <div class="flex-wrap">
    <div class="row " style="padding-top: 1rem">
      <div class="col-12 text-center">
        <h2>Create User Role</h2>
      </div>

    </div>


    <div class="" style="margin: 1rem 2rem 2rem 2rem">

      <div style="padding-top: 1rem">
        <form>

          <!-- Role name input -->
          <div class="form-group">
            <div class="form-outline ">
              <label class="form-label" for="roleName">Role Name </label>
              <input type="text" id="roleName" formControlName="roleName" class="form-control"
                     required/>

            </div>
          </div>

          <!-- branch input -->
          <div class="form-group">
            <div class="form-outline mb-4" style="padding-top: 2rem">
              <label class="form-label" for="branch">Branch</label>
              <input type="text" id="branch" formControlName="branch" class="form-control"
                     required/>

            </div>
          </div>

          <!-- role input -->
          <div class="form-group">
            <div class="form-outline mb-4" style="">


              <label style="padding-left: 0.5rem; ">Role Status :</label> <br>
              <select #userStatus style="width: 100%; border-color: #ced4da; background-color: white; height: 2.5rem">
                <option value="select">-Select-</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>


            </div>
          </div>


          <div class="row mb-4">


          </div>

          <!-- Submit button -->
          <div class="row text-center" style="">
            <div class="col text-center">

              <button type="submit" class="btn  btn-primary btn-block" style="width: 80%">Create Role</button>

            </div>
          </div>

        </form>
      </div>
    </div>
  </div>
</div>
