<app-dialog-header [title]="title" (closeDialogEvent)="closeDialog()"></app-dialog-header>

<div>
    <div class="row pb-2">
        <h3 class="p-2">Update allocated details</h3>
    </div>
    <div class="row">
        <div class="col p-2">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Proposed Date</mat-label>
                <input matInput [matDatepicker]="suggestedDate" disabled placeholder="disable typing" readonly (click)="suggestedDate.open()">
                <mat-hint>DD/MM/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="suggestedDate"></mat-datepicker-toggle>
                <mat-datepicker touchUi #suggestedDate></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col p-2">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Visit date</mat-label>
                <input matInput [matDatepicker]="visitDatePicker" [(ngModel)]="selectedDate" placeholder="disable typing" readonly (click)="visitDatePicker.open()">
                <mat-hint>dd/mm/yyyy</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="visitDatePicker"></mat-datepicker-toggle>
                <mat-datepicker touchUi #visitDatePicker></mat-datepicker>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col p-2">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Select a route</mat-label>
                <mat-select [(ngModel)]="selectedRouteId" name="route">
                    <mat-option value=" ">Not selected</mat-option>
                    <mat-option *ngFor="let itr of routes" [value]="itr.routeId">
                        {{itr.name}}
                    </mat-option>
                </mat-select>
                <mat-hint align="start">Select a route</mat-hint>
            </mat-form-field>
        </div>
    </div>
    <div>
        <button class="me-2" mat-raised-button color="primary" (click)="updateRouteAllocation()">Update</button>
        <button class="me-2" mat-raised-button color="warn" (click)="closeDialog()">Close</button>
    </div>
</div>