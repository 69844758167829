import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { CallDeskDataResponseModel } from "../../models/call-desk-data-response-model";
import { VisitDeskDialogComponent } from "../visit-desk-dialog/visit-desk-dialog.component";
import { RequestDialogComponent } from "../request-dialog/request-dialog.component";
import { AngularGridInstance, Column, Formatters, GridOption } from "angular-slickgrid";
import { VisitDeskDataRequestModel } from "../../models/visit-desk-data-request-model";
import { VisitDeskDataResponseModel } from "../../models/visit-desk-data-response-model";
import { VisitDeskDataResponseModelInfo } from "../../models/visit-desk-data-response-model-info";
import { MapsAPILoader } from "@agm/core";
import { VisitUpdateDialogComponent } from "../visit-update-dialog/visit-update-dialog.component";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { VisitDeskService } from "../../services/visit-desk.service";
import { TranslaterService } from 'angular-slickgrid/app/modules/angular-slickgrid/services';
import { VisitSelectionDeskService } from "../../services/visit-selection-desk-service";
import { VisitDeskGeoLocationModel } from "../../models/visit-desk/visit-desk-geo-location-model";
import { VisitDeskGeoLocationInfoModel } from "../../models/visit-desk/visit-desk-geo-location-info-model";
import { OptimizedVisitPostRequestObjectModel } from "../../models/visit-desk/optimized-visit-post-request-object-model";
import { GeoCodingLocationResponseModel } from "../../models/visit-desk/geo_coding_location_response_model";
import { VisitDeskUpdateCheckinRequestModel } from "../../models/visit-desk/visit-desk-update-checkin-request-model";
import { HttpErrorResponse } from "@angular/common/http";
import { UserAuthenticationService } from 'src/app/services/user-authentication/user-authentication.service';
import { DateUtil } from 'src/app/utils/DateUtil';
import {ManualCallDialogComponent} from "../manual-call-dialog/manual-call-dialog.component";
import {DurationUtil} from "../../utils/DurationUtil";
import { rightAllignment } from 'src/app/utils/testAlign';
import { log } from 'console';
import { LocationAutomationService } from 'src/app/services/locationAutomationService/location-automation.service';

@Component({
  selector: 'app-visit-selection-update-desk-dialog',
  templateUrl: './visit-selection-update-desk-dialog.component.html',
  styleUrls: ['./visit-selection-update-desk-dialog.component.scss']
})
export class VisitSelectionUpdateDeskDialogComponent implements OnInit, OnDestroy {

  readonly title: string = "Visit selection update desk"

  latitude: number = 6.922973;
  longitude: number = 79.85734;

  latestLatitude: number = 6.922973;
  latestLongitude: number = 79.85734;
  zoom: number = 0;
  address: string = "";
  startEndTrackLable: string = "Start Tracking";
  private geoCoder: any;
  allVisitColumnDefinitions: Column[] = [];
  selectedVisitColumnDefinitions: Column[] = [];

  gridOptions: GridOption = {};
  visitDataset: any[] = [];
  gridOptionsSelectedVisit: GridOption = {};
  // selectedVisitDataset: any [] = [];

  allVisitAngularGrid!: AngularGridInstance;
  selectedVisitangularGridObj!: any;

  angularGrid!: AngularGridInstance;
  allVisitAngularGridObj!: any;
  selectedTableRow!: any;
  selectedAllVisitDeskTransactions: VisitDeskDataResponseModelInfo[] = [];
  visitDeskDataResponseResult: VisitDeskDataResponseModel = new VisitDeskDataResponseModel();
  visitOriginalDataset: any[] = [];

  selectedOptimizeVisitTransaction: VisitDeskDataResponseModelInfo;
  geoLocationList: VisitDeskGeoLocationInfoModel[] = [];
  selectedOptimizeVisitTableRow!: any;
  visitDeskGeoLocationList: VisitDeskGeoLocationModel = new VisitDeskGeoLocationModel();


  vistiDeskDataRequestModel = new VisitDeskDataRequestModel();

  /**
   * Tracking related variables - experimental
   */
  watchMode: boolean = false;
  watchId: number = null
  watchOptions: any = {
    enableHighAccuracy: false,
    timeout: 5000,
    maximumAge: 1000
  }
  cType='';

  constructor(private dialogRef: MatDialogRef<VisitDeskDialogComponent>, public dialog: MatDialog,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone, protected visitDeskService: VisitSelectionDeskService, private auth: UserAuthenticationService, private locationAutomation: LocationAutomationService) {

    this.selectedVisitColumnDefinitions = [
      { id: 'contractNo', name: 'Contract No', field: 'contractNo', minWidth: 100, sortable: true },
      { id: "type", name: "Type", field: "cType", minWidth: 50},
      { id: 'customerName', name: 'Name', field: 'customerName', minWidth: 200, sortable: true },
      { id: 'amountDue', name: 'Amount Due', field: 'amountDue', minWidth: 100, sortable: true, formatter: rightAllignment, params: { minDecimal: 2, maxDecimal: 2 } },
      { id: 'dueDate', name: 'Due Date', field: 'dueDate', minWidth: 100, sortable: true, formatter: Formatters.dateIso },
      { id: 'customerAddress', name: 'Address', field: 'customerAddress', minWidth: 300, sortable: true },
    ];;

    this.gridOptionsSelectedVisit = {

      enableAutoResize: true,
      enableSorting: true,
      enableExport: true,
      enablePagination: true,
      enableRowSelection: true,
      enableExcelExport: true,
      enableFiltering: true,
      enableCheckboxSelector: true,
      enableCellNavigation: true,
      checkboxSelector: {
        hideSelectAllCheckbox: true,
      },
      multiSelect: false,
      rowSelectionOptions: {
        selectActiveRow: true,
      }
    };
  }

  ngOnInit(): void {

    this.mapsAPILoader.load().then(() => {
      // removed because this api request is useless
      // this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;
    });
    this.initiateTableData();
    if(this.locationAutomation.interval){
      this.startEndTrackLable = "End Tracking"
    }
  }

  initiateTableData(): any {
    this.visitDataset[0] = {
      id: 0,
      contractNo: 'N/A',
      cType:'N/A',
      customerName: 'N/A',
      amountDue: 'N/A',
      dueDate: 'N/A',
      customerAddress: 'N/A'

    };
  }

  initiateAngulargridGrid(event: Event) {
    this.angularGrid = (event as CustomEvent).detail as AngularGridInstance;
    this.selectedVisitangularGridObj = this.angularGrid.slickGrid;
    this.angularGrid.slickGrid.onSelectedRowsChanged.subscribe((e, args) => {
      this.handleOptimizeVisitTableRowSelection(e, args)
    });
  }
  resizeGrid2() {
    this.angularGrid.resizerService.resizeGrid();
  }

  /**Handle All visit desk table row selection*/
  handleAllVisitTableRowSelection(event: Event, args: any) {
    this.selectedAllVisitDeskTransactions = null;
    if (Array.isArray(args.rows) && this.allVisitAngularGridObj) {

      this.selectedTableRow = args.rows.map((idx: number) => {
        const selectedRowData = this.allVisitAngularGridObj.getDataItem(idx);
        if (selectedRowData) {
        }
        return selectedRowData || '';
      });

      this.selectedAllVisitDeskTransactions = this.selectedTableRow;
    }
  }

  /**Handle Selected visit table row selection*/
  handleOptimizeVisitTableRowSelection(event: Event, args: any) {
    this.selectedOptimizeVisitTransaction = null;
    if (Array.isArray(args.rows) && this.selectedVisitangularGridObj) {

      this.selectedOptimizeVisitTableRow = args.rows.map((idx: number) => {
        const selectedRowData = this.selectedVisitangularGridObj.getDataItem(idx);
        if (selectedRowData) {
        }
        return selectedRowData || '';
      });

      this.selectedOptimizeVisitTransaction = this.selectedOptimizeVisitTableRow[0];
    }
  }

  // private setCurrentLocation() {
  //   if ('geolocation' in navigator) {
  //     navigator.geolocation.getCurrentPosition((position) => {
  //       this.latitude = position.coords.latitude;
  //       this.longitude = position.coords.longitude;
  //       console.log("GEO LOCATION" + this.latitude + this.longitude);

  //       this.zoom = 8;
  //       this.getAddress(this.latitude, this.longitude);
  //     });
  //   }
  // }

  getAddress(latitude: any, longitude: any) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results: any, status: any) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }


  loadVisit() {
    this.visitDataset = []
    let user = this.auth.currentUserValue.code
    let date = DateUtil.getDateString(new Date())
    this.visitDeskService.getOptimizeVisitData(user, this.vistiDeskDataRequestModel, date).subscribe({
      next: (responseResult: VisitDeskDataResponseModel) => {
        this.visitDataset = []
        this.visitDeskDataResponseResult = responseResult;
        this.visitOriginalDataset = this.visitDeskDataResponseResult.Table;
        Array.prototype.push.apply(this.visitDataset, this.visitOriginalDataset);

        this.visitDataset = this.visitDeskDataResponseResult.Table;

        if (this.visitDataset.length > 0) {
          for (let i = 0; i < this.visitDataset.length; i++) {
            this.visitDataset[i] = {
              id: i,
              assignDate: this.visitDataset[i].ASSIGNDATE,
              contractNo: this.visitDataset[i].CONTRACTNO,
              cType: this.visitDataset[i].cType,
              customerAddress: this.visitDataset[i].CUSTOMERADDRESS,
              customerName: this.visitDataset[i].CUSTOMERNAME,
              defaultAreass: this.visitDataset[i].DEFAULTARREARS,
              amountDue: this.visitDataset[i].TOTALARREARS,
              dueDate: this.visitDataset[i].DUEDATE,
              equipment: this.visitDataset[i].EQUIPMENT,
              grossRental: this.visitDataset[i].GROSSRENTAL,
              insuranArrears: this.visitDataset[i].INSURANCEARREARS,
              investigationCode: this.visitDataset[i].INVESTIGATORCODE,
              lastPayDate: this.visitDataset[i].LASTPAYDATE,
              make: this.visitDataset[i].MAKE,
              nic: this.visitDataset[i].NIC,
              noOfAreas: this.visitDataset[i].NOOFARREARS,
              otherAreassAmount: this.visitDataset[i].OTHERARREARSAMOUNT,
              registrationNo: this.visitDataset[i].REGISTRATIONNO,
              totalAreas: this.visitDataset[i].TOTALARREARS,
              totalAreas1: this.visitDataset[i].TOTALARREARS1,
              contact_no: this.visitDataset[i].CONTACT_NO,
              secondary_contact_no: this.visitDataset[i].SECONDARY_CONTACT_NO,
              guranter_contact_no: this.visitDataset[i].GURANTER_CONTACT_NO,
              last_payment_amount: this.visitDataset[i].LAST_PAYMENT_AMOUNT,
              lastPayDate1: this.visitDataset[i].LASTPAYDATE1,
              guranter_name: this.visitDataset[i].GURANTER_NAME,
              postalCode: this.visitDataset[i].POSTALCODE,
              assignSerial: this.visitDataset[i].ASSIGNSERIAL,
            };

            let geoLocationContract = new VisitDeskGeoLocationInfoModel();
            geoLocationContract.contractId = this.visitDataset[i].contractNo;
            geoLocationContract.cType = this.visitDataset[i].cType;
            geoLocationContract.contactNumber = this.visitDataset[i].contact_no;
            geoLocationContract.customerAdress = this.visitDataset[i].customerAddress;
            geoLocationContract.customerName = this.visitDataset[i].customerName;
            this.updateLonLat(geoLocationContract);
            this.geoLocationList.push(geoLocationContract);
          }
          this.visitDeskGeoLocationList.geoLocationList = this.geoLocationList;
        } else {
          this.visitDataset = [];
        }

        this.resizeGrid2();
        this.allVisitAngularGrid.dataView.refresh();
      }
    });
    this.resizeGrid2();
    this.allVisitAngularGrid.dataView.refresh();

  }

  updateLonLat(contract: VisitDeskGeoLocationInfoModel) {

    this.geoCoder.geocode({ 'address': contract.customerAdress }, (results: any, status: any) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          let location: GeoCodingLocationResponseModel = results[0].geometry.location;
          let locationLat: string = JSON.stringify(results[0].geometry.location.lat());
          let locationLng: string = JSON.stringify(results[0].geometry.location.lng());

          contract.lat = locationLat;
          contract.lng = locationLng;
          console.log('results found ' + locationLat + " " + locationLng);
        } else {

        }
      } else {

      }
    });
  }

  openRequestDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '800px';
    dialogConfig.width = '900px';

    const dialogRef = this.dialog.open(RequestDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(

    );
  }

  clear() {
    this.visitDataset = [];
  }

  closeDialog() {
    this.dialogRef.close();
  }

  openVisitDesk() {
    this.setupVisitData();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '100vh';
    dialogConfig.width = '99.5vw';
    dialogConfig.maxWidth = '99.5vw';
    dialogConfig.data = { visitDeskGeoLocationList: this.visitDeskGeoLocationList };

    const dialogRef = this.dialog.open(VisitDeskDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      if (data.completed) {
        this.loadVisit();
      }
    });
  }

  startLocationWatch() {
    if (navigator.geolocation) {
      this.watchMode = true;
      this.watchId = navigator.geolocation.watchPosition(this.locationWatchSuccess, this.locationWatchFail, this.watchOptions)
    } else {
      alert("Geolocation services are disabled")
    }
  }

  locationWatchSuccess(position: any) {
    var latitude = position.coords.latitude;
    var longitude = position.coords.longitude;

    console.log(latitude, longitude)
  }

  locationWatchFail(error: any) {
    console.log(error)
  }

  stopLocationWatching() {
    let watch = navigator.geolocation.clearWatch(this.watchId);
    this.watchId = null;
    this.watchMode = false
  }

  trackMe() {
    this.trackerFunc()
  }


  private getCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latestLatitude = position.coords.latitude;
        this.latestLongitude = position.coords.longitude;
        console.log("GEO LOCATION" + this.latitude + "  " + this.longitude);
        this.zoom = 8;

      });
    }
  }

  //Tracking on/off trigger function 😊
  startEndTracking(){

    console.debug("Tracker Button Status: "+this.startEndTrackLable);
    this.startEndTrackLable =  (this.startEndTrackLable=="Start Tracking" )? "End Tracking":"Start Tracking";

    // this if line is tricky: Change if you really know what you doing 😏
    if(this.startEndTrackLable=="End Tracking"){
      this.locationAutomation.startSendingCoordinates(this.auth.currentUserValue.username);
      console.debug("tracking on ")
    }else
    if(!this.locationAutomation.interval){
      this.locationAutomation.startSendingCoordinates(this.auth.currentUserValue.username);
    }else{
      this.locationAutomation.stopSendingCoordinates(this.auth.currentUserValue.username);
    }
  }

  trackerFunc() {
    this.getCurrLocation((lat: number, lng: number) => {
      let date = new Date;
      let currentDate = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
      let payload: any = {
        userid: this.auth.currentUserValue.code,
        date: currentDate,
        routeid: "1165",
        lat: lat,
        // lat: 7.674672,
        lng: lng
        // lng: 80.058057
        // 7.674672, 80.058057 testing
      };

      console.debug(JSON.stringify(payload));
      this.visitDeskService.trackMe(payload).subscribe({
        next: (data) => {
          window.alert("Tracking Data saved successfully");
        },
        complete: () => {
          console.debug("Saved Successfully");
        },
        error: (error: HttpErrorResponse) => {
          window.alert("Error");
        },
      });
    })
  }

  getCurrLocation(callback: (lat: number, lng: number) => void) {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        callback(position.coords.latitude, position.coords.longitude)
        // alert("DEVWORK - getCurrLocation function triggered")
      })
    }
  }

  setupVisitData() {
  }

  updateVisit() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '800px';
    dialogConfig.width = '1200px';
    dialogConfig.data = {
      selectedCallDeskTransaction: this.selectedOptimizeVisitTransaction,
      dialogType: "VISIT"
    };

    const dialogRef = this.dialog.open(ManualCallDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      if (data.completed) {
        this.loadVisit()
      }
    });
  }

  ngOnDestroy() {
    navigator.geolocation.clearWatch(this.watchId);
  }

  openContractDetail() {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = false;
      dialogConfig.closeOnNavigation = true;
      dialogConfig.autoFocus = true;
      dialogConfig.height = '100vh';
      dialogConfig.width = '99.5vw';
      dialogConfig.maxWidth = '99.5vw';
      dialogConfig.data = {
        selectedCallDeskTransaction: {
          contractNo: this.selectedOptimizeVisitTransaction.contractNo,
          cType: this.selectedOptimizeVisitTransaction.cType

        },
        dialogType: "VISIT"
      };

      const dialogRef = this.dialog.open(ManualCallDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(
        data => {
          // this.processFiltersAndReloadData()
          // this.getProgress()
          if (data.completed) {
            this.loadVisit()
          }
        }
      );

  }

  // trackingStatus feature
  public getTrackingStatus(userId: string): void {
    // Your logic here to process the empId
    console.log('Processing employee with empId:', userId);
    // You can add any other logic or functionality based on the empId parameter.
  }
}
