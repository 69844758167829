import { MapsAPILoader } from '@agm/core';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularGridInstance, Column, FieldType, Formatters, GridOption } from 'angular-slickgrid';
import { TrackingSummaryReportService } from 'src/app/services/tracking-summary-report/tracking-summary-report.service';
import { UserAuthenticationService } from 'src/app/services/user-authentication/user-authentication.service';
import { VisitSummaryService } from 'src/app/services/visit-services/visit-summary.service';
import { DateUtil } from 'src/app/utils/DateUtil';
import { DurationUtil } from 'src/app/utils/DurationUtil';
import { rightAllignment } from 'src/app/utils/testAlign';

@Component({
  selector: 'app-visit-summary-view',
  templateUrl: './visit-summary-view.component.html',
  styleUrls: ['./visit-summary-view.component.scss']
})
export class VisitSummaryViewComponent implements OnInit {

  dataset: any[] = [];
  columnDefinitions: Column[] = [];
  gridOptions: GridOption = {};//CallDeskDataResponseRestModelInfo
  ngGrid: AngularGridInstance;

  public allwaypoints: any[] = [];
  public numberOfSlots: number;
  public arrayNo: number;
  private listModule: number;
  private slotswaypoints: any[] = [];
  private start: any;
  private end: any;
  public realDistance: number = 0;
 // public distance: number = null;

  isButtonDisabled = false;

  startDate: Date = null;
  endDate: Date = null;
  selectedInvg: string = ""

  userInfo: {
    INVG_CODE: string,
    INVG_NAME: string
  }

  investigators: {
    INVG_CODE: string,
    INVG_NAME: string
  }[] = null

  directionService: google.maps.DirectionsService = null
  geoCodeService: google.maps.Geocoder
  pendingUpdates: any[];

  constructor(private trackingSummaryReportService: TrackingSummaryReportService,private dialogRef: MatDialogRef<VisitSummaryViewComponent>, private service: VisitSummaryService, private mapsAPILoader: MapsAPILoader, private _snackBar: MatSnackBar, private auth: UserAuthenticationService) { }

  ngOnInit(): void {

    this.columnDefinitions = [
      {
        id: "username",
        name: "User Name",
        field: "username",
        minWidth: 200,
        type: FieldType.string,
        filterable: true,
      }, {
        id: "userLogin",
        name: "User login",
        field: "userLogin",
        minWidth: 100,
        type: FieldType.string,
        filterable: true,
      }, {
        id: "totalDistance",
        name: "Total Distance (KMs)",
        field: "totalDistance",
        minWidth: 150,
        type: FieldType.number,
        formatter: rightAllignment,
        params: { minDecimal: 2, maxDecimal: 2 },
      }, {
        id: "totalCheckIns",
        name: "Total Check Ins",
        field: "totalCheckIns",
        minWidth: 150,
        formatter: rightAllignment,
        type: FieldType.string
      },
      //  {
      //   id: "startingPoint",
      //   name: "Starting Point",
      //   field: "startingPoint",
      //   minWidth: 300,
      //   type: FieldType.string
      // },
      {
        id: "visitStartTime",
        name: "Visit Start time",
        field: "visitStartTime",
        minWidth: 175,
        type: FieldType.string,
      }, {
        id: "visitEndTime",
        name: "Visit End Time",
        field: "visitEndTime",
        minWidth: 175,
        type: FieldType.string,
      }, {
        id: "date",
        name: "Date",
        field: "date",
        minWidth: 100,
        type: FieldType.string,
      }
    ];

    this.gridOptions = {
      formatterOptions: {
        minDecimal: 2,
        maxDecimal: 2,
        dateSeparator: "-",
        displayNegativeNumberWithParentheses: true
      },
      enableAutoResize: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      enableCheckboxSelector: true,
      enableFiltering: true,
      checkboxSelector: {
        hideSelectAllCheckbox: true,
      },
      multiSelect: false,
      rowSelectionOptions: {
        selectActiveRow: true,
      },
      columnPicker: {
        hideForceFitButton: true
      },
      gridMenu: {
        hideForceFitButton: true
      },
      enablePagination: false,
    };

    this.mapsAPILoader.load().then(() => {
      this.directionService = new google.maps.DirectionsService;
      this.geoCodeService = new google.maps.Geocoder;
    })
      .catch(e => {
        this._snackBar.open('Failed to load map resources', 'Dismiss', {
          duration: DurationUtil.TWO_SEC,
          horizontalPosition: "right",
          verticalPosition: "top",
        });
      });

    this.userInfo = {
      INVG_CODE: this.auth.currentUserValue.code,
      INVG_NAME: this.auth.currentUserValue.user_id
    }

    let branchId = this.auth.currentUserValue.branch
    this.service.getInvestigators(branchId).subscribe({
      next: value => {
        console.log(value)
        this.investigators = value
      },
      error: err => {
        console.log(err)
      }
    })
  }


  angularGridReady(event: any) {
    this.ngGrid = (event as CustomEvent).detail as AngularGridInstance;
  }



  getVisitSummary() {
    this.isButtonDisabled = true;

    let firstDate = DateUtil.getDateString(this.startDate)
    let lastDate = DateUtil.getDateString(this.endDate)
    this.service.getVisitSummary(firstDate, lastDate, this.selectedInvg).subscribe({
      next: (value) => {
        this.dataset = []
        this.pendingUpdates = [];


        if ("response" in value) {
          this._snackBar.open('No data found', 'Dismiss', {
            duration: DurationUtil.TWO_SEC,
            horizontalPosition: "right",
            verticalPosition: "top",
          });
        } else {
          value.forEach(({ visitDate, visits_ }, personkey) => {
            console.log(personkey, visits_)
            //this.realDistance= 0;
            visits_.forEach(async (item, key) => {

              // let totalDistance;
              // this.calculateDistanceCallback(key, item, visitDate, startingPoint, (key, item, itemsDate, distance) => {
              //   totalDistance = this.realDistance;

              const { username, userLogin, locations, visitEndTime, visitStartTime } = item
              this.getStartingPoints(personkey, item, visitDate, locations, (key: number, obj: any, itemsDate: string, totalDistance: number, totalCheckins: number, startingPoint: string) => {
                console.log(startingPoint);
                console.log("ACHALA totalDistance"+ totalDistance);
                console.log("ACHALA  this.realDistance"+this.realDistance);


                
                const distanceTrackingData = {
                  investigatorCode: userLogin,
                  visitDate: visitDate,
                  distanceVisited: this.realDistance,
                  UpdatedDate: this.getDateFormatted(),
              };

              this.pendingUpdates.push(distanceTrackingData);

                // const distanceTrackingData = [{
                //   investigatorCode: userLogin,
                //   visitDate: visitDate,
                //   distanceVisited: totalDistance,
                //   UpdatedDate: this.getDateFormatted(),
                // }];

                // this.trackingSummaryReportService.updateRealTimeTracingDistance(distanceTrackingData)
                // .subscribe(response => {
                //   console.log('POST Response:', response);

                // });

                this.dataset.push({
                  id: personkey,
                  username: obj.username,
                  userLogin: obj.userLogin,
                  startingPoint: startingPoint,
                  visitStartTime: obj.visitStartTime,
                  visitEndTime: obj.visitEndTime,
                  date: visitDate,
                  // totalDistance: totalDistance,
                  totalDistance: this.realDistance,
                  totalCheckIns: totalCheckins
                })

                this.ngGrid?.dataView?.refresh()
              })

              this.ngGrid?.dataView?.refresh()
            })
          })
        }
      },
      error: err => {
        this._snackBar.open('Error occured while fetching data', 'Dismiss', {
          duration: DurationUtil.TWO_SEC,
          horizontalPosition: "right",
          verticalPosition: "top",
        });
      }
    })

  }

  // Approved button
  savePendingUpdates() {
    if (this.pendingUpdates.length > 0) {
        this.pendingUpdates.forEach(updateData => {
            this.trackingSummaryReportService.updateRealTimeTracingDistance([updateData])
                .subscribe(response => {
                    console.log('POST Response:', response);
                    //clear the pending updates array if desired
                    this.pendingUpdates = [];
                }, error => {
                    console.log('Error on updating distance:', error);
                });
        });
    } else {
        console.log("No updates to save.");
    }
  }

  // Current Date
  getDateFormatted() {
    const date = new Date();
    const day = date.getDate();
    const monthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear().toString().substr(-2); // Get last two digits of the year
    return `${day.toString().padStart(2, '0')}-${month}-${year}`;
  }

  async calculateDistanceCallback(key: number, item: any, itemsDate: string, startingPoint: string, callback: (key: number, item: any, itemsDate: string, totalDistance: number, totalCheckins: number, startingPoint: string) => void) {
    let checkings = item.locations.length;

    let processedRoutes = 0;
    this.numberOfSlots = Math.floor((checkings - 1) / 25);
    if ((Math.floor(checkings - 1) % 25) != 0) {
      this.listModule = (checkings - 1) % 25;
      this.numberOfSlots = this.numberOfSlots + 1;
      console.log("listSize : " + checkings);
      console.log("numOfSlots : " + this.numberOfSlots);
      console.log("listMiodule : " + this.listModule);
    }

    if (checkings > 1) {
      let dup_locations = [...item.locations]
      this.allwaypoints = [];
      // let start = dup_locations.shift()
      // let end = dup_locations.pop()
      // let waypoints: google.maps.DirectionsWaypoint[] = dup_locations.map(item => {
      //   return {
      //     location: new google.maps.LatLng(parseFloat(item["lat"]), parseFloat(item["lng"])),
      //     stopover: true
      //   }
      // });

      dup_locations.map(item => {

        let wayPointObject = {
          location: { lat: Number(item["lat"]), lng: Number(item["lng"]) }
        };
        // this.routeURL = this.routeURL + '/' + Number(this.geoLocationList[24*((this.arrayNo))+i].lat) + ',' + Number(this.geoLocationList[24*((this.arrayNo))+i].lng);
        this.allwaypoints.push(wayPointObject);

        // return {
        //   location: new google.maps.LatLng(parseFloat(item["lat"]), parseFloat(item["lng"])),
        //   stopover: true
        // }
      });
       console.log("way points: " + JSON.stringify(this.allwaypoints));
      for (this.arrayNo = 0; this.arrayNo <= (this.numberOfSlots - 1); this.arrayNo++) {

        this.slotswaypoints = [];
        this.start=null;
        this.end=null;
        // this.start = dup_locations.shift();
        // this.end = dup_locations.pop();
        this.start = this.allwaypoints[25 * (this.arrayNo)].location;
        if ((this.numberOfSlots - 1) != this.arrayNo) {
          this.end = this.allwaypoints[25 * (this.arrayNo) + 24].location;
        }
        else {
          this.end = this.allwaypoints[25 * (this.arrayNo) + this.listModule].location;
        }





        if ((this.numberOfSlots == 1) || ((this.numberOfSlots - 1) == this.arrayNo)) {
          for (let i = 1; i < this.listModule; i++) {
            if (this.allwaypoints[24 * ((this.arrayNo)) + i].location.lat) {
              // if (this.allwaypoints[1].location.lat) {

              let wayPointObject = {
                location: { lat: parseFloat(this.allwaypoints[24 * ((this.arrayNo)) + i].location.lat), lng: parseFloat(this.allwaypoints[24 * ((this.arrayNo)) + i].location.lng) }
              };
              //this.routeURL = this.routeURL + '/' + Number(this.geoLocationList[24*((this.arrayNo))+i].lat) + ',' + Number(this.geoLocationList[24*((this.arrayNo))+i].lng);
              this.slotswaypoints.push(wayPointObject);
            }


          }

        }
        else {
          for (let i = 1; i < 24; i++) {
            if (this.allwaypoints[24 * ((this.arrayNo)) + i].location.lat) {
              //    if (this.allwaypoints[1].location.lat) {
              let wayPointObject = {
                location: { lat: parseFloat(this.allwaypoints[24 * ((this.arrayNo)) + i].location.lat), lng: parseFloat(this.allwaypoints[24 * ((this.arrayNo)) + i].location.lng) }
              };
              //  this.routeURL = this.routeURL + '/' + Number(this.allwaypoints[24*((this.arrayNo))+i].lat) + ',' + Number(this.allwaypoints[24*((this.arrayNo))+i].lng);
              this.slotswaypoints.push(wayPointObject);
            }
          }
        }
        console.log("ArrayNo : " + this.arrayNo);
        console.log("start : " + JSON.stringify(this.start));
        console.log("end : " + JSON.stringify(this.end));
        console.log(" slots way points: " + JSON.stringify(this.slotswaypoints));



        let request: google.maps.DirectionsRequest = {
          // origin: new google.maps.LatLng(parseFloat(this.start["lat"]), parseFloat(this.start["lng"])),
          origin: this.start,
          waypoints: this.slotswaypoints,
          // destination: new google.maps.LatLng(parseFloat(this.end["lat"]), parseFloat(this.end["lng"])),
          destination: this.end,
          optimizeWaypoints: true,
          travelMode: google.maps.TravelMode.DRIVING
        }

        this.directionService.route(request, (result, status) => {

           let distance = 0
          if (status == "OK") {
            result.routes[0].legs.map(leg => {
              distance += leg.distance.value;
              //console.log("distance : " + this.arrayNo + " = " + this.distance);


            })

            console.log("distance : " + this.arrayNo + " = " + distance);
            this.realDistance = distance + this.realDistance;

            console.log("real distance 1: " + this.realDistance);

            processedRoutes++;

        if (processedRoutes === this.numberOfSlots) {
          this.realDistance = this.realDistance/1000;
          console.log("All routes processed.");
          console.log("callback :" + key + " : " + item + " : " + this.realDistance + " : " + checkings + " : " + startingPoint);
          callback(key, item, itemsDate, this.realDistance, checkings, startingPoint);
        }


          }

        })

      // console.log("callback :"+key +" : "+ item +" : "+ this.realDistance +" : "+ checkings +" : "+ startingPoint);
      // callback(key, item, itemsDate, this.realDistance, checkings, startingPoint);

      }
      // console.log("callback :"+key +" : "+ item +" : "+ this.realDistance +" : "+ checkings +" : "+ startingPoint);
      // callback(key, item, itemsDate, this.realDistance, checkings, startingPoint);



    } else {
      console.log("real distance 2: " + this.realDistance);
      console.log("callbackesle :"+ key +" : "+ item +" : "+ this.realDistance +" : "+ checkings +" : "+ startingPoint);
      callback(key, item, itemsDate, this.realDistance, checkings, startingPoint);
    }

  }

  getStartingPoints(key: number, item: any, itemsDate: string, locations: { lat: string, lng: string }[], callback: (key: number, item: any, itemsDate: string, totalDistance: number, totalCheckins: number, startingPoint: string) => void) {
    // // get address
    // // for success scenario, call the callback
    // // for errors, call callback with custom values
    // if (locations.length > 1) {
    //   let request: google.maps.GeocoderRequest = {
    //     location: {
    //       lat: parseFloat(locations[0].lat),
    //       lng: parseFloat(locations[0].lng)
    //     }
    //   }

    //   this.geoCodeService.geocode(request, (result, status) => {
    //     if (status != "OK") {

    //       let address = "---"
    //       console.log("status gone wrong", status)
    //      this.calculateDistanceCallback(key, item, itemsDate, address, callback)
    //     }
    //     else {
    //       let address = result?.map(i => {
    //         return i?.address_components.map(j => {
    //           return j?.short_name
    //         }).join(",")
    //       }).join(",")
    //       this.calculateDistanceCallback(key, item, itemsDate, address, callback)
    //       console.log("status gone correct", status)
    //     }
    //   })
    // } else {
    //   console.log("els")
    //   this.calculateDistanceCallback(key, item, itemsDate, "---", callback)
    // }
    console.log("els")
      this.calculateDistanceCallback(key, item, itemsDate, "---", callback)
  }


  updateToTable(key: number, item: any, itemsDate: string, totalDistance: number, totalCheckins: number, startingPoint: string) {
    this.dataset.push({
      id: key,
      username: item.username,
      userLogin: item.userLogin,
      startingPoint: startingPoint,
      visitStartTime: item.visitStartTime,
      visitEndTime: item.visitEndTime,
      date: itemsDate,
     //totalDistance: totalDistance,
      totalDistance: this.realDistance,
      totalCheckIns: totalCheckins
    })
  }

  // async reverseGeocode(lat: number, lng: number): Promise<string> {
  //   let request: google.maps.GeocoderRequest = {
  //     location: {
  //       lat: lat,
  //       lng: lng
  //     }
  //   }

  //   let address = ""
  //   await this.geoCodeService.geocode(request, (result, status) => {
  //     if (status != "OK") {
  //       address = "Could not determine"
  //     }
  //     else {
  //       address = result?.map(i => {
  //         return i?.address_components.map(j => {
  //           return j?.short_name
  //         }).join(",")
  //       }).join(",")
  //     }
  //   })
  //   return address
  // }

  // async calculateDistance(locations: any[]): Promise<number> {
  //   let dup_locations = [...locations]
  //   let start = dup_locations.shift()
  //   let end = dup_locations.pop()
  //   let waypoints: google.maps.DirectionsWaypoint[] = dup_locations.map(item => {
  //     return {
  //       location: new google.maps.LatLng(item["lat"], item["lng"]),
  //       stopover: true
  //     }
  //   });

  //   let request: google.maps.DirectionsRequest = {
  //     origin: new google.maps.LatLng(start["lat"], start["lng"]),
  //     waypoints: waypoints,
  //     destination: new google.maps.LatLng(end["lat"], end["lng"]),
  //     optimizeWaypoints: false,
  //     travelMode: google.maps.TravelMode.DRIVING
  //   }

  //   let distance = 0
  //   await this.directionService.route(request, (result, status) => {
  //     if (status == "OK") {
  //       result.routes[0].legs.map(leg => {
  //         distance += leg.distance.value;
  //       })
  //     }

  //   })
  //   return distance
  // }

  processDate(date: Date) {
    let monthIdx: number = date.getMonth()
    let month = monthIdx < 9 ? `0${monthIdx + 1}` : `${monthIdx + 1}`
    return `${date.getDate()}-${month}-${date.getFullYear()}`
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
