import { Component, Inject, OnInit } from '@angular/core';
import { TodaysCallDeskService } from "../../services/call-desk/call-desk.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { FlaggingDetailsResponseModel } from "../../models/call-desk/flagging-details-response-model";
import { FacilityDetailsService } from 'src/app/services/facility-details/facility-details.service';
import { MatSelectChange } from '@angular/material/select';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { concat } from 'rxjs';
import { DurationUtil } from 'src/app/utils/DurationUtil';
import {UserAuthenticationService} from "../../services/user-authentication/user-authentication.service";
import { ClientDetailResponseModel } from 'src/app/models/call-desk/ClientDetailResponseModel';

@Component({
  selector: 'app-followup-detail-dialog',
  templateUrl: './followup-detail-dialog.component.html',
  styleUrls: ['./followup-detail-dialog.component.scss']
})
export class FollowupDetailDialogComponent implements OnInit {

  title: string = "Follow-up details"
  selectedFlaggingDetail: FlaggingDetailsResponseModel;
  thirdPartyAddress = '';
  thirdPartyName = '';
  otherFlag = '';
  contractNo: string = null;
  cType: string = null;

  contactNo: string = null;

  flags: string[] = null
  disableUpdate: boolean = false;

  thirdPartyFormGroup: FormGroup = null

  selectedCallDeskTransaction: any;
  selectedClientDetail: ClientDetailResponseModel;


  constructor(private dialogRef: MatDialogRef<FollowupDetailDialogComponent>,private auth: UserAuthenticationService, public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, private service: TodaysCallDeskService, private _snackBar: MatSnackBar, private facilityService: FacilityDetailsService, private formBuilder: FormBuilder) {
    
    this.selectedCallDeskTransaction = data.selectedCallDeskTransaction;
    this.contractNo = data.contractNo;
    this.cType = data.cType;
    
    console.log("check value test: " + data.cType);
    if (data.selectedFlaggingDetail && Array.isArray(data.selectedFlaggingDetail) && (data.selectedFlaggingDetail.length > 0)) {
      this.selectedFlaggingDetail = data.selectedFlaggingDetail[0];
      console.log(data.selectedFlaggingDetail[0]);
      this.thirdPartyAddress = this.selectedFlaggingDetail.THIRD_PARTY_ADDRESS;
      this.thirdPartyName = this.selectedFlaggingDetail.THIRD_PARTY_NAME;
      this.otherFlag = this.selectedFlaggingDetail.OTHER_FLAGS;
      this.contactNo = this.selectedFlaggingDetail.THIRD_PARTY_CONTACT_NO;

      this.selectedFlaggingDetail = data.selectedFlaggingDetail[0];

      if (this.selectedFlaggingDetail.THIRD_PARTY_ADDRESS || this.selectedFlaggingDetail.THIRD_PARTY_CONTACT_NO || this.selectedFlaggingDetail.THIRD_PARTY_NAME || this.selectedFlaggingDetail.OTHER_FLAGS) {
        this.disableUpdate = false
      }
    } else {
      this.disableUpdate = true
    }
  }

  ngOnInit(): void {
    this.getFlagsList();

    this.thirdPartyFormGroup = this.formBuilder.group({
      name: ["", Validators.required],
      address: ["", Validators.required],
      contactNo: ["", Validators.required],
    })
  }

  getFlagsList() {
    this.facilityService.getFlagsList().subscribe({
      next: (value) => {
        this.flags = value.map(item => item.FLAG)
      },
      error: (err) => {
        alert("Could not retrieve flag list")
      }
    })
  }

  handleFlagChange(event: MatSelectChange) {
    if (event.value == "Vehicle with a 3rd party") {
      this.disableUpdate = false
    } else {
      this.disableUpdate = true
    }
  }

  closeDialog(): any {
    this.dialogRef.close();
  }

  updateFollowUpDetails() {
    
   let userid=  this.auth.currentUserValue.epf;
    let payload: any = {
      CONTRACTNO: this.contractNo,
      OTHER_FLAGS: this.otherFlag,
      USER: userid,
      system:this.cType
    };

    console.log(JSON.stringify(payload));

    if (this.otherFlag == "Vehicle with a 3rd party") {
      let username = JSON.parse(localStorage.getItem("branchless-user"))?.username;
      
      let thirdPartyPayload = {
        contractno: this.contractNo,
        name: this.thirdPartyName,
        address: this.thirdPartyAddress,
        contactno: this.contactNo,
        user: userid,
        system: this.cType
      }

      console.log(thirdPartyPayload)

      this.facilityService.updateThirdParty(thirdPartyPayload).subscribe({
        next: (value) => {
          this._snackBar.open("Third party details updated", "Dismiss", {
            duration: DurationUtil.TWO_SEC,
            horizontalPosition: "right",
            verticalPosition: "top",
          })
        },
        error: (err) => {
          alert("An error Third party Detail update!")
        }
      })
    }

    this.service.updateFollowUpDetails(payload).subscribe({
      next: (data) => {

        alert("Followup Details Updated Successfully!");
        // this.selectedClientDetail = data;
        console.log(JSON.stringify(this.selectedFlaggingDetail));

      },
      error: (err) => {
        console.log(err);
        alert("An Error occured while connecting to the server!");
      }
    });
  }

  openNotePadEntry() {
    // const dialogConfig = new MatDialogConfig();
    // dialogConfig.disableClose = true;
    // dialogConfig.closeOnNavigation = true;
    // dialogConfig.autoFocus = true;
    // dialogConfig.height = '800px';
    // dialogConfig.width = '1200px';
    // dialogConfig.data = {
    //   contractNo: this.selectedFlaggingDetail.CONTRACTNO
    // }
    // const dialogRef = this.dialog.open(NotePadEntryViewComponent, dialogConfig);
    // dialogRef.afterClosed().subscribe(
    //   data => {
    //     console.log(`NP entry opened`);
    //   }
    // );

    // if (this.data.contractNo) {
    //   window.open(`https://cfmobile.lk/crmesbtest/ESBInfotest/CRMNotePad/CRMNotePadview?FACNO=${this.data.contractNo}`, '_blank')
    // } else {
    //   alert("Contract number not available")
    // }

    let FACNO = `${this.data.contractNo}`

    this.auth.openNotepadService(FACNO);
  }
}
