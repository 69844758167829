import { Component, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IdleService } from './idle.service';
import { environment } from 'src/environments/environment';
import { FormGroup } from '@angular/forms';
import { SocialUser } from 'angularx-social-login';
import { UserAuthenticationService } from './services/user-authentication/user-authentication.service';
import { FieldOfficerService } from './services/field-officer/field-officer-service';
import { LocationAutomationService } from './services/locationAutomationService/location-automation.service';
import { SessionService } from './services/session-service';


@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-root',
  templateUrl: './app-recovery.component.html',
  styleUrls: ['./app-recovery.component.scss']
})
export class AppRecoveryComponent {
  currentUserSubject: any;
  loginForm!: FormGroup;
  errorMsg?: boolean = true;
  user?: SocialUser;
  loggedIn?: boolean;
  loginFormFromAD: FormGroup = null
  logID: string = null

  constructor(private router: Router, private idleService: IdleService, private auth: UserAuthenticationService, private fieldOfficerService: FieldOfficerService, private locationAutomationService: LocationAutomationService,private sessionService: SessionService) {
  }
  //TODO checking the tracking status 😊
  ngOnInit(): void {
   this.checkSessionTimeOut();
   this.sesionTimeAutoCheck();
    this.sesionTimeAutoCheck();
    this.initialIdleSettings();
    if (this.checkLoggedInState()) {
      this.fieldOfficerService.getTrackingState(this.auth.currentUserValue.username)
        .then((response) => {
          if (response.content[0].TRACK_STAT == "ON") {
            this.locationAutomationService.startSendingCoordinates(this.auth.currentUserValue.username)
          }
        })
    }
  }

  checkLoggedInState(): boolean {
    console.log(this.auth.currentUserValue?.code);
    if (this.auth.currentUserValue?.code) {
      console.debug("User is logged in")
      return true;

    }

    return false;
  }

  private initialIdleSettings() {
    const idleTimeoutInSeconds: number = environment.recoveryConfig.idleTimeInMinutes * 60;
    console.debug(environment.recoveryConfig.idleTimeInMinutes);
    //const idleTimeoutInSeconds: number = 60 * 10;
    this.idleService.startWatching(idleTimeoutInSeconds).subscribe((isTimeOut: boolean) => {
      if (isTimeOut && this.checkLoggedInState()) {
        alert("Session timeout. It will redirect to login page.");
        this.auth.logout(this.router);

      }

    });
  }


//DulsaraMannakkara - session time out 2023.10.11
  private sesionTimeAutoCheck() {
    // Define the interval in milliseconds (4.59 mins)
    const checkInterval = (5* 60 * 1000)-1000;

    // Create an interval timer using setInterval
    setInterval(() => {

      if (this.checkLoggedInState()) {
        this.checkSessionTimeOut();
      }
    }, checkInterval);
  }


  checkSessionTimeOut(){
    const user_name = this.auth.currentUserValue.username;

    this.sessionService.GetSessionService(user_name).subscribe({
      next: (result: { time: any; }[]) => {
        console.log(result);
        const value = result[0].time;
        const sessionTimeout: number = environment.recoveryConfig.sessionTimeOutinHours * 60*60*1000;
        //const sessionTimeout : number = 4 * 60 * 60 * 1000;// 4 hours in milliseconds
        const LastLoginTime  = value;
        const LastLogin = Date.parse(LastLoginTime);

        const remenderInMin = 15*60*1000;// 5 mins in milliseconds
        console.log("d&T : "+LastLogin);

        const currentTime : number = Date.now();

        console.log("currentTime : "+currentTime);
        if(currentTime > (LastLogin + sessionTimeout)){
          this.auth.logout(this.router);
          console.log("end session guard");
          alert("Automatically session expired. Please log in again...!!!");
        }
        else if(currentTime > (LastLogin + (sessionTimeout-remenderInMin))){
          alert("Your session will expire Automatically in 15 minits ...!!!");
          console.log("session time out remainder 15 mins");
        }




      }
    })

  }

}
