import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/internal/operators/catchError";

@Injectable()
export class TokenInjector implements HttpInterceptor {

    constructor(private router: Router) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = localStorage.getItem('branchless-token');
       // let authdata = btoa('bbapplive' + ':' + 'BB@#applive');

      // let authdata = btoa('BB_USER' + ':' + 'bb_log_68$');

       // let authdata = 'bbapplive' + ':' + 'BB@#applive';
      let authdata = null;

      // console.log("AUTH DATA",authdata  );
        if (authdata) {

          //without Auth
          // const clonedReq  = req.clone( {
          //   // setHeaders: { Authorization: `Bearer ${token}` , Access-Control-Allow-Origin: `*`};
          //   // headers: req.headers.set("Authorization", `Basic ${authdata}`).set("Access-Control-Allow-Origin", `*`)
          //   headers: req.headers.set("Access-Control-Allow-Origin", `*`)
          //     .set("Access-Control-Allow-Methods", "GET , PUT , POST ,OPTIONS, DELETE")
          //     .set("Access-Control-Allow-Headers", "*")
          // });

          //with Auth
          const clonedReq  = req.clone( {
            headers: req.headers.set("Authorization", `Basic ${authdata}`).set("Access-Control-Allow-Origin", `*`)
              .set("Access-Control-Allow-Methods", "GET , PUT , POST ,OPTIONS, DELETE")
              .set("Access-Control-Allow-Headers", "*")
          });
          return next.handle(clonedReq);
        }else{
                return next.handle(req);

        }
    }
}
