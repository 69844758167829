<div class="dialog-container">
  <div class="cancel-area">
    <button class="cancel-button" mat-icon-button aria-label="Example icon button with a vertical three dot icon" (click)="closeDialog()">
      <mat-icon class="close-icon" appearance="fill" color="warn">close</mat-icon>
    </button>
  </div>

  <div class="row border-1" style="padding-top: 1rem">
    <div class="col-8">
      <h2>Client Exposure</h2>
    </div>
  </div>
  <div class="dialog-content w-100 p-3">
    <div *ngIf="noContent" class="no-content">
      No data available
    </div>
    <div class="container w-75 entry-tile px-4 py-1 my-2" *ngFor="let item of entries">
      <div class="row w-100 py-1" >
        <div class="col-6 tile-leading">Number of Contracts</div>
        <div class="col-6 tile-content">{{item.numberofcontracts || "0"}}</div>
      </div>
      <div class="row w-100 py-1">
        <div class="col-6 tile-leading">Total Amount Finance</div>
        <div class="col-6 tile-content">{{item.totalamountfinanced || "0"}}</div>
      </div>
      <div class="row w-100 py-1">
        <div class="col-6 tile-leading">Monthly Commitment</div>
        <div class="col-6 tile-content">{{item.monthlycomitment || "0"}}</div>
      </div>
      <div class="row w-100 py-1">
        <div class="col-6 tile-leading">Stock Balance</div>
        <div class="col-6 tile-content">{{item.stockbalance || "0"}}</div>
      </div>
      <div class="row w-100 py-1">
        <div class="col-6 tile-leading">Total Areas</div>
        <div class="col-6 tile-content">{{item.totalarrears || "0"}}</div>
      </div>
      <div class="row w-100 py-1">
        <div class="col-6 tile-leading">Rental Areas</div>
        <div class="col-6 tile-content">{{item.rentalarrears || "0"}}</div>
      </div>
      <div class="row w-100 py-1">
        <div class="col-6 tile-leading">Default Areas</div>
        <div class="col-6 tile-content">{{item.defaultarrears || "0"}}</div>
      </div>
      <div class="row w-100 py-1">
        <div class="col-6 tile-leading">Insurance Areas</div>
        <div class="col-6 tile-content">{{item.insurancearrears || "0"}}</div>
      </div>
      <div class="row w-100 py-1">
        <div class="col-6 tile-leading">Areas less than 3</div>
        <div class="col-6 tile-content">{{item.arrearsless_3 || "0"}}</div>
      </div>
      <div class="row w-100 py-1">
        <div class="col-6 tile-leading">Areas between 3-6</div>
        <div class="col-6 tile-content">{{item.arrearsbetween3_6 || "0"}}</div>
      </div>
      <div class="row w-100 py-1">
        <div class="col-6 tile-leading">Areas more than 6</div>
        <div class="col-6 tile-content">{{item.arrearsmorethan_6 || "0"}}</div>
      </div>


    </div>
  </div>
</div>
