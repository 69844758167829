<div class="container p-3">
    <div class="px-2 py2">
        <mat-tab-group mat-stretch-tabs="true">
            <mat-tab label="Create Route">
                <app-create-route-form></app-create-route-form>
            </mat-tab>
            <mat-tab label="View My Routes">
                <view-routes></view-routes>
            </mat-tab>
            <mat-tab label="View Branch's route">
                <view-branch-routes></view-branch-routes>
            </mat-tab>
          </mat-tab-group>
    </div>
</div>