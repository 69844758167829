import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inquiry-desk-dialog',
  templateUrl: './inquiry-desk-dialog.component.html',
  styleUrls: ['./inquiry-desk-dialog.component.scss']
})
export class InquiryDeskDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
