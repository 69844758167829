<div class="container-fluid">
  <div class="row border-1" style="padding-top: 1rem">
    <div class="col-8">
      <h2>TODAY CALLS</h2>
    </div>
  </div>

  <div class="row" style="padding-top: 2rem">
    <div class="col-4">
      <button view class="btn btn-success float-end" style="width: 80%; align:center "
        (click)="getTodaysCallList()">Search</button>
    </div>
    <div class="col-4">
      <button view class="btn btn-success float-end" style="width: 80%; align:center " (click)="manualCall()">Detail
        View</button>
    </div>
    <div class="col-4 ">
      <button view class="btn btn-primary float-none" style="width: 80%; align:center "
        (click)="closeDialog()">Close</button>
    </div>
  </div>

  <div class="row pt-5 px-3">
    Showing {{todayCallsDataset.length}} records
  </div>

  <div class="row" style="padding-top: 0.5rem">
    <div class="col-12" style="padding-top: 10px; z-index: 0">

      <angular-slickgrid gridId="allVisit" [columnDefinitions]="todayCallsColumnDefinitions"
        [gridOptions]="todayCallsgridOptions" [dataset]="todayCallsDataset"
        (onAngularGridCreated)="initiateTodayCallsAngulargridGrid($event)"></angular-slickgrid>
    </div>
  </div>
</div>