<app-dialog-header
  [title]="title"
  (closeDialogEvent)="closeDialog()"
></app-dialog-header>
<div class="container-fluid">
  <div class="row p-2 gx-2">
    <div class="col-12 col-md-4 px-1 py-2">
      <div class="w-100 h-100 card-container my-2">
        <div class="card-header w-100 p-2">
          <span>Contract Details</span>
        </div>
        <div class="card-content p-2 w-100">
          <div class="row card-content-row w-100 px-5 px-md-4 py-2">
            <div class="col-12 py-2">
              <div class="col-12 card-content-leading">Contract number</div>
              <div class="col-12 card-content-description">
                {{ this.selectedFacilityDetail.contractNo | whitespace }}
              </div>
            </div>
            <div class="col-12 py-2">
              <div class="col-12 card-content-leading">CEFT ID</div>
              <div class="col-12 card-content-description">
                {{ this.selectedFacilityDetail.CEFTID | whitespace }}
              </div>
            </div>
            <div class="col-12 py-2">
              <div class="col-12 card-content-leading">Facility amount</div>
              <div class="col-12 card-content-description">
                {{
                  this.selectedFacilityDetail.facilityAmount
                    | whitespace
                    | thousandSeparator
                }}
              </div>
            </div>
            <div class="col-12 py-2">
              <div class="col-12 card-content-leading">Activated Date</div>
              <div class="col-12 card-content-description">
                {{ this.activateDate | whitespace }}
              </div>
            </div>
            <div class="col-12 py-2">
              <div class="col-12 card-content-leading">Expire Date</div>
              <div class="col-12 card-content-description">
                {{ this.expiryDate | whitespace }}
              </div>
            </div>
            <div class="col-12 py-2">
              <div class="col-12 card-content-leading">Rental amount</div>
              <div class="col-12 card-content-description">
                {{
                  this.selectedFacilityDetail.rentalAmount
                    | whitespace
                    | thousandSeparator
                }}
              </div>
            </div>
            <div class="col-12 py-2">
              <div class="col-12 card-content-leading">Period / Debited</div>
              <div class="col-12 card-content-description">
                {{ this.selectedFacilityDetail.periodDebited | whitespace }}
              </div>
            </div>
            <div class="col-12 py-2">
              <div class="col-12 card-content-leading">Related contracts</div>
              <div class="col-12 card-content-description">
                {{ this.selectedFacilityDetail.relatedContarcts | whitespace }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-4 px-1 py-2">
      <div class="w-100 h-100 card-container my-2">
        <div class="card-header w-100 p-2">
          <span>Arrears Details</span>
        </div>
        <div class="card-content p-2 w-100">
          <div class="row card-content-row w-100 px-5 px-md-4 py-2">
            <div class="col-12 py-2">
              <div class="col-12 card-content-leading">Arrears</div>
              <div class="col-12 card-content-description">
                {{
                  this.selectedFacilityDetail.arrears
                    | whitespace
                    | thousandSeparator
                }}
              </div>
            </div>
            <div class="col-12 py-2">
              <div class="col-12 card-content-leading">Rental in Arrears</div>
              <div class="col-12 card-content-description">
                {{
                  this.selectedFacilityDetail.rnt
                    | whitespace
                    | thousandSeparator
                }}
              </div>
            </div>
            <div class="col-12 py-2">
              <div class="col-12 card-content-leading">RNO</div>
              <div class="col-12 card-content-description">
                {{
                  this.selectedFacilityDetail.rno
                    | whitespace
                    | thousandSeparator
                }}
              </div>
            </div>
            <div class="col-12 py-2">
              <div class="col-12 card-content-leading">Insurance Arrears</div>
              <div class="col-12 card-content-description">
                {{
                  this.selectedFacilityDetail.rnt1
                    | whitespace
                    | thousandSeparator
                }}
              </div>
            </div>
            <div class="col-12 py-2">
              <div class="col-12 card-content-leading">INO</div>
              <div class="col-12 card-content-description">
                {{
                  this.selectedFacilityDetail.ino
                    | whitespace
                    | thousandSeparator
                }}
              </div>
            </div>
            <div class="col-12 py-2">
              <div class="col-12 card-content-leading">DCF</div>
              <div class="col-12 card-content-description">
                {{
                  this.selectedFacilityDetail.dcf
                    | whitespace
                    | thousandSeparator
                }}
              </div>
            </div>
            <div class="col-12 py-2">
              <div class="col-12 card-content-leading">Other</div>
              <div class="col-12 card-content-description">
                {{
                  this.selectedFacilityDetail.oth
                    | whitespace
                    | thousandSeparator
                }}
              </div>
            </div>

            <div class="col-12 py-2">
              <div class="col-12 card-content-leading">RIA</div>
              <div class="col-12 card-content-description">
                {{ this.selectedFacilityDetail.ria | whitespace }}
              </div>
            </div>
            <div class="col-12 py-2">
              <div class="col-12 card-content-leading">Current DPD</div>
              <div class="col-12 card-content-description">
                {{ this.selectedFacilityDetail.currentDPD | whitespace }}
              </div>
            </div>
            <div class="col-12 py-2">
              <div class="col-12 card-content-leading">B.W period</div>
              <div class="col-12 card-content-description">
                {{ this.selectedFacilityDetail.BWPeriod }}
              </div>
            </div>
            <div class="col-12 py-2">
              <div class="col-12 card-content-leading">
                Minimum amount to be collected
              </div>
              <div class="col-12 card-content-description">
                {{
                  this.selectedFacilityDetail.minimumAmountToBeCollected
                    | whitespace
                    | thousandSeparator
                }}
              </div>
            </div>
            <div class="col-12 py-2">
              <div class="col-12 card-content-leading">Last pmt date</div>
              <div class="col-12 card-content-description">
                {{ this.lastPaymentDate | whitespace }}
              </div>
            </div>
            <div class="col-12 py-2">
              <div class="col-12 card-content-leading">Last paid amount</div>
              <div class="col-12 card-content-description">
                {{
                  this.selectedFacilityDetail.lastPaidAmount | thousandSeparator
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-4 px-1 py-2">
      <div class="w-100 h-100 card-container my-2">
        <div class="card-header w-100 p-2">
          <span>Break Up</span>
        </div>
        <div class="card-content p-2 w-100">
          <div class="row card-content-row w-100 px-5 px-md-4 py-2">
            <div class="col-12 py-2">
              <div class="col-12 card-content-leading">Collection balance</div>
              <div class="col-12 card-content-description">
                {{
                  this.selectedFacilityDetail.collectionBalance
                    | whitespace
                    | thousandSeparator
                }}
              </div>
            </div>
            <div class="col-12 py-2">
              <div class="col-12 card-content-leading">Stock Out on hire</div>
              <div class="col-12 card-content-description">
                {{
                  this.selectedFacilityDetail.stockOutOnHire | thousandSeparator
                }}
              </div>
            </div>
            <div class="col-12 py-2">
              <div class="col-12 card-content-leading">Total default</div>
              <div class="col-12 card-content-description">
                {{
                  this.selectedFacilityDetail.totalDefault
                    | whitespace
                    | thousandSeparator
                }}
              </div>
            </div>
            <div class="col-12 py-2">
              <div class="col-12 card-content-leading">Stock balance</div>
              <div class="col-12 card-content-description">
                {{
                  this.selectedFacilityDetail.stockBalance
                    | whitespace
                    | thousandSeparator
                }}
              </div>
            </div>
            <div class="col-12 py-2">
              <div class="col-12 card-content-leading">Full balance</div>
              <div class="col-12 card-content-description">
                {{
                  this.selectedFacilityDetail.FULLBALANCE
                    | whitespace
                    | thousandSeparator
                }}
              </div>
            </div>

            <div class="col-12 py-2">
              <div class="col-12 card-content-leading">GSO</div>
              <div class="col-12 card-content-description">
                {{
                  this.selectedFacilityDetail.gso
                    | whitespace
                    | thousandSeparator
                }}
              </div>
            </div>
            <div class="col-12 py-2">
              <div class="col-12 card-content-leading">CHR</div>
              <div class="col-12 card-content-description">
                {{
                  this.selectedFacilityDetail.chr
                    | whitespace
                    | thousandSeparator
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row p-2 gx-2 w-100">
    <div class="col-6 col-md-3 p-1">
      <button
        mat-raised-button
        color="primary"
        class="w-100"
        (click)="openClientExposure()"
      >
        Client exposure
      </button>
    </div>
    <div class="col-6 col-md-3 p-1">
      <button
        mat-raised-button
        color="primary"
        class="w-100"
        (click)="openTransactionSummaryDialog()"
      >
        Transaction Summary
      </button>
    </div>
    <div class="col-6 col-md-3 p-1">
      <button
        mat-raised-button
        color="primary"
        class="w-100"
        (click)="settlementCalculation()"
      >
        Settlement cal
      </button>
    </div>
    <div class="col-6 col-md-3 p-1">
      <button
        mat-raised-button
        color="warn"
        class="w-100"
        (click)="closeDialog()"
      >
        Close
      </button>
    </div>
  </div>
</div>
