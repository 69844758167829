import {Inject , Injectable} from "@angular/core";
import {RestRequestService} from "../rest-request.service";
import {Config} from "../../../config/config";
import {HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs/internal/Observable";
import {ReceiptResponseModel} from "../../models/receipt/receipt-response-model";

@Injectable({
  providedIn: 'root'
})
/**
 * Service implementation releated to Receipt funtionalities
 * @author Achala M. Rathnathilaka
 */
export  class ReceiptService{

  private   readonly cashReceiptJobsUrl: string;
  private readonly receiptConfirmationUrl: string;
  private readonly pdChequeSaveUrl: string;
  constructor(private restService: RestRequestService,@Inject("recoveryConfig") private recoveryConfig = Config.recoveryConfig) {

    this.cashReceiptJobsUrl = this.getCashReceiptobsUrl(recoveryConfig);
    this.receiptConfirmationUrl = this.getCashReciptJobUrl(recoveryConfig);
    this.pdChequeSaveUrl = this.getPdChequeSaveUrl(recoveryConfig);

  }

  /**
   * Get cash receipt job service Url from  injectable object.
   * @param recoveryConfig
   * @returns url:string
   */
  private getCashReceiptobsUrl(recoveryConfig: any = {}): string {
    let url = '';

    if (recoveryConfig.env === 'local') {
      url = `${recoveryConfig.appServerUrl}/vCashReceipt`;
    } else {
      url = `${recoveryConfig.recoveryServiceUrl}/vCashReceipt`;
    }
    return url;
  }


  private getCashReciptJobUrl(recoveryConfig: any = {}): string{
    let url = '';

    if (recoveryConfig.env === 'local') {
      url = `${recoveryConfig.appServerUrl}/vCashReceiptConfirm`;
    } else {
      url = `${recoveryConfig.recoveryServiceUrl}/vCashReceiptConfirm`;
    }
    return url;

  }

  private getPdChequeSaveUrl(recoveryConfig: any = {}): string{
    let url = '';

    if (recoveryConfig.env === 'local') {
      url = `${recoveryConfig.appServerUrl}/vSavePDChq`;
    } else {
      url = `${recoveryConfig.recoveryServiceUrl}/vSavePDChq`;
    }
    return url;

  }


  /**
   * Save Optimized Visit
   */
  getCashReceipt ( payload: any ): Observable<ReceiptResponseModel> {

    const httpOptions = {headers: new HttpHeaders ( {'Content-Type': 'text/plain'} )};

    return this.restService.post<ReceiptResponseModel> (  this.cashReceiptJobsUrl , payload , httpOptions );
  }

  /**
   *
   * @param payload
   * @returns {Observable<any>}
   */
  confirmReceipt(payload:any):Observable<any>{

    const httpOPtions = {headers: new HttpHeaders({'Content-Type': 'text/plain'} )};

    return this.restService.post<any>( this.receiptConfirmationUrl ,payload, httpOPtions);

  }


  /**
   *
   * @param payload
   * @returns {Observable<any>}
   */
  savePdCheque(payload:any):Observable<any>{

    const httpOPtions = {headers: new HttpHeaders({'Content-Type': 'text/plain'} )};

    return this.restService.post<any>( this.pdChequeSaveUrl ,payload, httpOPtions);

  }

}
