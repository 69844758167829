import { Component, Inject, OnInit } from '@angular/core';
import { Column, GridOption } from 'angular-slickgrid';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CallDeskDialogComponent } from "../call-desk-dialog/call-desk-dialog.component";

import { SettlementCalculatorService } from "../../services/settlement-calculator/settlement-calculator.service"
import { Contract } from "../../models/contract-model";
import { ContractRequestComponent } from './contract-request/contract-request.component';
import { LetterViewComponent } from './letter-view/letter-view/letter-view.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { resourceUsage } from 'process';
import { UserAuthenticationService } from 'src/app/services/user-authentication/user-authentication.service';
import { DateUtil } from 'src/app/utils/DateUtil';
import { DurationUtil } from 'src/app/utils/DurationUtil';
import { any } from 'codelyzer/util/function';

import { Validators } from '@angular/forms';

@Component({
  selector: 'app-settlement-calculation',
  templateUrl: './settlement-calculation.component.html',
  styleUrls: ['./settlement-calculation.component.scss']
})
export class SettlementCalculationComponent implements OnInit {
  dataset: {
    "BNKCODE": string,
    "BNKNAME": string
  }[];

  numberOnly(event: { which: any; keyCode: any; }): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  readonly title: string = "Concession requests and calculations"

  todayCallsColumnDefinitions: Column[] = [];
  todayCallsgridOptions: GridOption = {};
  todayCallsDataset: any[] = [];
  

  dataObj: {
    contract_id: string,
    date: string,
    cType: string
  } = {
      contract_id: "",
      date: "",
      cType: ""
    };

  selectedSendTo = "1";

  // material card view data

  hasData: boolean = false;
  tableData: Array<any> = [];

  currentElement: any = {};

  contractno = "0174788666";
  relType = "Cheque";
  institute: string = "";
  add1: string = "";
  add2: string = "";
  add3: string = "";
  add4: string = "";
  expdate: Date = null;
  ipaddress: string = "";
  user: string = "";
  reqletter: string | ArrayBuffer = null;
  cfletter: string | ArrayBuffer = null;

  uploadProgress: number;

  // uploadRequestLetter: File | null = null;
  // uploadCFLetter: File | null = null;

  dif_criteria = "RATE";
  facilityNo: string = "";
  till_date: Date = null;
  tc_rate: number = null;
  inq_type: string = "";
  settlement_type = "";
  settlement_reason = "";
  waive_off: boolean = false;
  only_to_close: boolean = false;
  ofs: boolean = false;
  special_contract: boolean = false;
  rno = {
    amount: "0",
    rate: "0"
  }
  gso = {
    amount: "0",
    rate: "0"
  }
  ino = {
    amount: "0",
    rate: "0"
  }
  chr = {
    amount: "0",
    rate: "0"
  }
  dcf = "0";
  approval_send_to = "";
  comment = ""

  clicked = false;

  isCalculated: boolean = false;
  isSaved: boolean = false;
  calculatedResult: any = [];

  // date limitations
  today: Date = null
  minDate: Date = null
  maxDate: Date = null

  constructor(private dialogRef: MatDialogRef<SettlementCalculationComponent>, public dialog: MatDialog, private settlementService: SettlementCalculatorService, private snackBar: MatSnackBar, private postUploadRequestLetter: SettlementCalculatorService, private postUploadCFLetter: SettlementCalculatorService, private _snackBar: MatSnackBar, @Inject(MAT_DIALOG_DATA) public params: any, private auth: UserAuthenticationService) {
    this.today = new Date();
    this.minDate = this.today
    this.maxDate = new Date()
    this.maxDate.setDate(this.minDate.getDate() + 7);

    number: ['', [Validators.required, Validators.pattern("^[0-9]*$")]]

  }

  ngOnInit(): void {
    if (this.params?.contractNo) {
      this.dataObj.contract_id = this.params.contractNo;
      this.dataObj.cType = this.params.cType;
    } else {
      this.dataObj.contract_id = "";
      this.dataObj.cType = "";
    }



    this.settlementService.initCalculation(this.dataObj.contract_id, this.dataObj.cType)
      .subscribe({
        next: (value: any) => {
          let result = JSON.parse(value);

          this.tc_rate = result.content[0]?.tc_rate;
        },
        error: (err: any) => {
          console.log(err);
        }
      })

    this.settlementService.getIPAddress().subscribe({
      next: (value: any) => {
        this.ipaddress = value?.ip || null
      },
      error: (err: any) => {
        console.log(err);
      }
    })

    this.bankList()



  }

  inputHandlerCFLetter() {
    console.log("Hello")
  }

  handleFileInputRequestLetter(event: any) {
    // this.reqletter = files.item(0);

    console.log("Request letter")

    let fileList = event.target.files as FileList
    const reader = new FileReader();
    reader.onloadend = () => {
      console.log(reader.result);
      // Logs data:<type>;base64,wL2dvYWwgbW9yZ...
      this.reqletter = reader.result
    };
    reader.readAsDataURL(fileList[0]);
  }

  handleFileInputCFLetter(event: any) {

    // this.reqletter = files.item(0);

    let fileList = event.target.files as FileList
    const reader = new FileReader();
    reader.onloadend = () => {
      console.log(reader.result);
      // Logs data:<type>;base64,wL2dvYWwgbW9yZ...
      this.cfletter = reader.result
    };
    reader.readAsDataURL(fileList[0]);
  }

  // handleFileInputCFLetter(event: any) {
  //   alert("done")
  //   let fileList = event.target.files as FileList
  //   const reader = new FileReader();
  //   reader.onloadend = () => {
  //     // console.log(reader.result);
  //     // Logs data:<type>;base64,wL2dvYWwgbW9yZ...
  //     this.cfletter = reader.result
  //   };
  //   reader.readAsDataURL(fileList[0])
  // }

  uploadRequestLetterFileToActivity() {
    this.postUploadRequestLetter.postUploadRequestLetter(this.reqletter).subscribe(data => {
      // do something, if upload success
    }, error => {
      console.log(error);
      this.handleError("Request Letter failed to upload")
    });
  }

  uploadCFLetterFileToActivity() {
    this.postUploadCFLetter.postUploadCFLetter(this.cfletter).subscribe(data => {
      // do something, if upload success
    }, error => {
      console.log(error);
      this.handleError("CF Letter failed to upload")
    });
  }

  handleError(msg: string = "An error occured. Cannot complete the task") {
    this.snackBar.open(msg, 'Dismiss', {
      duration: DurationUtil.TWO_SEC,
      horizontalPosition: "right",
      verticalPosition: "top",
      panelClass: ['mat-toolbar', 'mat-primary']
    });
  }

  onCalculate(): any {
    let payload = {
      contractno: this.dataObj.contract_id,
      system: this.dataObj.cType,
      date: DateUtil.getDateString(this.till_date),
      tc_rate: this.tc_rate,
      inquiry_type: this.inq_type,
      settlement_type: this.settlement_type,
      waive_off: this.waive_off == true ? "Y" : "N",
      only_to_close: this.only_to_close == true ? "Y" : "N",
      ofs_balance: this.ofs == true ? "Y" : "N",
      special_contract: this.special_contract == true ? "Y" : "N",
      type: this.dif_criteria,
      rno: this.dif_criteria == "AMOUNT" ? this.rno.amount : this.dif_criteria == "RATE" ? this.rno.rate : null,
      gso: this.dif_criteria == "AMOUNT" ? this.gso.amount : this.dif_criteria == "RATE" ? this.gso.rate : null,
      ino: this.dif_criteria == "AMOUNT" ? this.ino.amount : this.dif_criteria == "RATE" ? this.ino.rate : null,
      chr: this.dif_criteria == "AMOUNT" ? this.chr.amount : this.dif_criteria == "RATE" ? this.chr.rate : null,
      dcf: this.dcf,
      approval_send_to: this.approval_send_to,
      comment: this.comment
    }

    this.settlementService.calculateSettlement(payload).subscribe({
      next: (value: string) => {
        if (value.includes("content")) {
          let result = JSON.parse(value);

          if (":B1" in result.content) {
            this._snackBar.open(`Facility number is not valid`, 'Dismiss', {
              duration: DurationUtil.TWO_SEC,
              horizontalPosition: "right",
              verticalPosition: "top",
            });
          } else if ("result" in result.content) {
            let formatted = String(result.content["result"]).replace("ORA-20010: ", "");
            this._snackBar.open(`${formatted}`, 'Dismiss', {
              duration: DurationUtil.TWO_SEC,
              horizontalPosition: "right",
              verticalPosition: "top",
            });
          } else {
            this.isCalculated = true;
            this.calculatedResult = result.content;
            let el = document.getElementById("calculator-result");
            console.log(result);
          }
        } else {
          this._snackBar.open(`Unable to perform calculation`, 'Dismiss', {
            duration: DurationUtil.TWO_SEC,
            horizontalPosition: "right",
            verticalPosition: "top",
          });
        }
      },
      error: (err) => {
        console.log(err)
        alert("Error");
      }
    });
  }

  onSubmitOFS(): any {

    let dataUrlReq: string = null, dataUrlCf: string = null 

    dataUrlReq = this.reqletter.toString().replace(/^data:image\/?[A-z]*;base64,/, "");

    dataUrlCf = this.reqletter.toString().replace(/^data:image\/?[A-z]*;base64,/, "");

    // console.log(dataUrlReq)
    // console.log(dataUrlCf)

    let payload = {
      contractno: this.dataObj.contract_id,
      system: this.dataObj.cType,
      user: this.auth.currentUserValue.code,
      relType: this.relType,
      institute: this.institute,
      add1: this.add1,
      add2: this.add2,
      add3: this.add3,
      add4: this.add4,
      expdate: DateUtil.getDateString(this.expdate),
      ipaddress: this.ipaddress,
      reqletter: dataUrlReq,
      cfletter: dataUrlCf,
    }

    this.settlementService.ofsSave(payload).subscribe({
      next: (value: any) => {

        this._snackBar.open(`Successfully saved`, 'Dismiss', {
          duration: DurationUtil.TWO_SEC,
          horizontalPosition: "right",
          verticalPosition: "top",
        });

        // console.log(value)

        // if(value[0] == "SUCCESS"){
        //   this._snackBar.open(`Successfully saved`, 'Dismiss', {
        //     duration: DurationUtil.TWO_SEC,
        //     horizontalPosition: "right",
        //     verticalPosition: "top",
        //   });

        //   // this.closeCalculationDialog();
        //   // alert("Successfully saved");

        // } else {
        //   // console.log(value);
        //   this._snackBar.open(`Unable to save`, 'Dismiss', {
        //     duration: DurationUtil.TWO_SEC,
        //     horizontalPosition: "right",
        //     verticalPosition: "top",
        //   });
        // }
      },
      error: (err: any) => {
        console.log(err)
        alert("Error");
      }
    });
  }

  bankList() {
    this.settlementService.getBankList(this.user)
      .subscribe({
        next: data => {
          this.dataset = [...data];
        },
        error: error => {
          console.log(error);
        }
      })
  }

  saveCalculation(): any {

    this.onCalculateHandler();
    let payload = {
      contractno: this.dataObj.contract_id,
      date: DateUtil.getDateString(this.till_date), // date format should be dd/mm/yyyy
      type: this.dif_criteria,
      term_Fee: 2,
      def_Rno: this.dif_criteria == "AMOUNT" ? this.rno.amount : this.dif_criteria == "RATE" ? this.rno.rate : null,
      def_Gso: this.dif_criteria == "AMOUNT" ? this.gso.amount : this.dif_criteria == "RATE" ? this.gso.rate : null,
      def_Ino: this.dif_criteria == "AMOUNT" ? this.ino.amount : this.dif_criteria == "RATE" ? this.ino.rate : null,
      def_Chr: this.dif_criteria == "AMOUNT" ? this.chr.amount : this.dif_criteria == "RATE" ? this.chr.rate : null,
      flags: "p",
      user: this.auth.currentUserValue.code,
      comment: this.comment,
      alert: this.approval_send_to,
      subamt: 0,
      finalamt: 0,
      closeonly: this.only_to_close == true ? "Y" : "N",
      wave: this.waive_off == true ? "Y" : "N",
      settype: "N",
      inqtype: this.inq_type,
      setrsn: this.settlement_reason,
      bnkcode: "",
      spcer: "N",
      reltype: "",
      expdte: "",
      dcf: "",
      appoff: "",
      dcfexc: this.dcf,

    }

    this.settlementService.saveSettlement(payload).subscribe({
      next: (value: any) => {
        if (Array.isArray(value.content) && Object.prototype.hasOwnProperty.call(value.content[0], "SUCCESS")) {
          this._snackBar.open(`Successfully saved`, 'Dismiss', {
            duration: DurationUtil.TWO_SEC,
            horizontalPosition: "right",
            verticalPosition: "top",
          });
          this.isSaved = true;


          // this.closeCalculationDialog();
        } else {
          console.log(value);
          this._snackBar.open(`Unable to save`, 'Dismiss', {
            duration: DurationUtil.TWO_SEC,
            horizontalPosition: "right",
            verticalPosition: "top",
          });
        }
      },
      error: (err: any) => {
        console.log(err)
        alert("Error");
      }
    });
  }

  onCalculateHandler() {
    let payload = {
      contractno: this.dataObj.contract_id,
      date: DateUtil.getDateString(this.till_date),
      tc_rate: this.tc_rate,
      rno: this.dif_criteria == "AMOUNT" ? this.rno.amount : this.dif_criteria == "RATE" ? this.rno.rate : null,
      gso: this.dif_criteria == "AMOUNT" ? this.gso.amount : this.dif_criteria == "RATE" ? this.gso.rate : null,
      ino: this.dif_criteria == "AMOUNT" ? this.ino.amount : this.dif_criteria == "RATE" ? this.ino.rate : null,
      chr: this.dif_criteria == "AMOUNT" ? this.chr.amount : this.dif_criteria == "RATE" ? this.chr.rate : null,
      settlement_type: this.settlement_type,
      type: this.dif_criteria,
      ofs_balance: this.ofs ? "Y" : "N",
      dcf: this.dcf
    }

    this.settlementService.calculateSettlement(payload).subscribe({
      next: (value: string) => {
        if (value.includes("content")) {
          let result = JSON.parse(value);

          if (":B1" in result.content) {
            this._snackBar.open(`Facility number is not valid`, 'Dismiss', {
              duration: DurationUtil.TWO_SEC,
              horizontalPosition: "right",
              verticalPosition: "top",
            });
          } else if ("result" in result.content) {
            let formatted = String(result.content["result"]).replace("ORA-20010: ", "");
            this._snackBar.open(`${formatted}`, 'Dismiss', {
              duration: DurationUtil.TWO_SEC,
              horizontalPosition: "right",
              verticalPosition: "top",
            });
          } else if (result?.content[0]?.result == "ERROR") {
            window.alert("Error occured: " + result?.content[0]?.MSG);
          } else {
            this.isCalculated = true;
            this.calculatedResult = result.content;
            let el = document.getElementById("calculator-result");
            console.log(result);
          }
        } else {
          this._snackBar.open(`Unable to perform calculation`, 'Dismiss', {
            duration: 800,
            horizontalPosition: "right",
            verticalPosition: "top",
          });
        }
      },
      error: (err) => {
        console.log(err)
        alert("Error");
      }
    })
  }

  changeHandler(event: any) {
    this.dataObj.contract_id = event.target.value;
  }

  datePickerChangeHandler(event: any) {
    console.log(this.till_date)
    let dateNew = new Date(event.value);
    this.dataObj.date = `${dateNew.getDate()}/${dateNew.getMonth()}/${dateNew.getFullYear()}`;
  }

  testButton() {
    this.settlementService.vGetSingleContractListRequest(this.dataObj.contract_id, {})
      .subscribe({
        next: data => {
          try {
            let temp = Contract.fromJson(data);
            let dataFromContract = temp.requestEntries();
            let listForDataset: any[] = [];

            dataFromContract.forEach((i: any, key) => {
              listForDataset.push({
                name: i.name,
                value: i.value
              });
            })

            this.tableData = listForDataset;
            this.hasData = true;
          } catch (e) {
            console.log(e);
            alert("Error occured during the operation");
          }
        },
        error: error => {
          console.log(error);
        }
      })
  }

  letterViewHandler(type: string) {
    if (this.dataObj.contract_id) {
      if (type == "CF") {
        this.settlementService.viewCFletter(this.dataObj.contract_id).subscribe({
          next: (value) => {
            if (value == "Letter is not available for this Contract") {
              this._snackBar.open(`${value}`, 'Dismiss', {
                duration: DurationUtil.TWO_SEC,
                horizontalPosition: "right",
                verticalPosition: "top",
              });
            } else {
              try {
                const data = JSON.parse(value);

                const imageUrl = this.getDataUrl(data["Letter"]);

                this.dialog.open(LetterViewComponent, {
                  disableClose: true,
                  data: {
                    image: imageUrl
                  },
                  height: '800px',
                  width: '1200px'
                });
              } catch (error) {
                this._snackBar.open('Unable to show the letter', 'Dismiss', {
                  duration: DurationUtil.TWO_SEC,
                  horizontalPosition: "right",
                  verticalPosition: "top",
                });
              }
            }
          },
          error: (err) => {
            console.log(err);
          }
        })
      }
      else if (type = "REQ") {
        this.settlementService.viewRequestLetter(this.dataObj.contract_id).subscribe({
          next: (value) => {
            if (value == "Letter is not available for this Contract") {
              this._snackBar.open(`${value}`, 'Dismiss', {
                duration: DurationUtil.TWO_SEC,
                horizontalPosition: "right",
                verticalPosition: "top",
              });
            } else {
              try {
                const data = JSON.parse(value);

                const imageUrl = this.getDataUrl(data["Letter"]);

                this.dialog.open(LetterViewComponent, {
                  disableClose: true,
                  data: {
                    image: imageUrl
                  },
                  height: '800px',
                  width: '1200px'
                });
              } catch (error) {
                this._snackBar.open('Unable to show the letter', 'Dismiss', {
                  duration: DurationUtil.TWO_SEC,
                  horizontalPosition: "right",
                  verticalPosition: "top",
                });
              }
            }
          },
          error: (err) => {
            console.log(err);
          }
        })
      } else {
        this._snackBar.open('Unable to request the letter', 'Dismiss', {
          duration: DurationUtil.TWO_SEC,
          horizontalPosition: "right",
          verticalPosition: "top",
        });
      }
    } else {
      this._snackBar.open('Contract number is not entered!', 'Dismiss', {
        duration: DurationUtil.TWO_SEC,
        horizontalPosition: "right",
        verticalPosition: "top",
      });
    }
  }

  clearHandler() {
    this.dataObj = {
      contract_id: "0110484281",
      date: "01/10/2021",
      cType: "CR"
      
    }

    this.hasData = false;
    this.tableData = [];
  }

  getDataUrl(data: string) {
    let contentPrefix = "data:image/png;base64,";

    return contentPrefix + data;
  }

  closeCalculationDialog(): any {
    this.dialogRef.close();
  }

  selectToFormFromContract(element: any) {
    this.currentElement = element;
    this.dataObj = {
      ...this.dataObj,
      contract_id: this.currentElement["contract_No"],
    }

    let temp: Contract = element;
    let dataFromContract = temp.requestEntries();
    let listForDataset: any[] = [];

    dataFromContract.forEach((i: any, key) => {
      listForDataset.push({
        name: i.name,
        value: i.value
      });
    })

    this.tableData = listForDataset;
    this.hasData = true;
  }
}
