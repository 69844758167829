import {Component , Inject , OnInit} from '@angular/core';
import {FollowupDetailDialogComponent} from "../followup-detail-dialog/followup-detail-dialog.component";
import {TodaysCallDeskService} from "../../services/call-desk/call-desk.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MAT_DIALOG_DATA , MatDialog , MatDialogConfig , MatDialogRef} from "@angular/material/dialog";
import {SecurityDetailsResponseModel} from "../../models/call-desk/security-details-response-model";
import {ClientLocationViewDialogComponent} from "../client-location-view-dialog/client-location-view-dialog.component";
import { ClientDetailResponseModel } from 'src/app/models/call-desk/ClientDetailResponseModel';

@Component({
  selector: 'app-security-detail-dialog',
  templateUrl: './security-detail-dialog.component.html',
  styleUrls: ['./security-detail-dialog.component.scss']
})
export class SecurityDetailDialogComponent implements OnInit {
  selectedClientDetail: ClientDetailResponseModel;
  title = "Security Details"
  selectedSecurityDetail: SecurityDetailsResponseModel;



  constructor ( private dialogRef: MatDialogRef<SecurityDetailDialogComponent> , public dialog: MatDialog , @Inject ( MAT_DIALOG_DATA ) public data: any , private service: TodaysCallDeskService , private _snackBar: MatSnackBar ) {
    this.selectedSecurityDetail = data.selectedSecurityDetail[0];
  }

  ngOnInit (): void {

  }


  closeDialog (): any {
    this.dialogRef.close ();
  }

  updateSecurityDetails(){
    let payload: any = {
      CONTRACTNO: this.selectedSecurityDetail.contractno,
      CONTACTNO_GUR01: this.selectedSecurityDetail.guranter01ContactNo,
      CONTACTNO_GUR02: this.selectedSecurityDetail.guranter02ContactNo,
      system: this.selectedClientDetail.cType 
    };

    console.log(JSON.stringify(payload));
    this.service.updateSecurityDetails(payload).subscribe({
      next: (data) => {

        alert("Security Details Updated Successfully!");
        // this.selectedClientDetail = data;
        console.log(JSON.stringify(this.selectedSecurityDetail));

      },
      error: (err) => {
        console.log(err);
        alert("An Error occured while connecting to the server!");
      }
    });

  }

  openLocationMap() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '800px';
    dialogConfig.width = '1200px';
    dialogConfig.data = {
      contractNo: this.selectedSecurityDetail.contractno
    }
    const dialogRef = this.dialog.open(ClientLocationViewDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        console.log(`NP entry opened`);
      }
    );
  }

}
