import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RouteServicesService } from 'src/app/services/route-management/route-services';
import { UserAuthenticationService } from 'src/app/services/user-authentication/user-authentication.service';
import { MapPreviewDialogComponent } from '../map-preview-dialog/map-preview-dialog.component';

@Component({
  selector: 'view-branch-routes',
  templateUrl: './view-branch-routes.component.html',
  styleUrls: ['./view-branch-routes.component.scss']
})
export class ViewBranchRoutesComponent implements OnInit {

  constructor(private service: RouteServicesService, private dialog: MatDialog, private auth: UserAuthenticationService) { }

  routeList: Array<any> = [];

  noContent: boolean = false;

  branch: string = null;

  ngOnInit(): void {
    this.branch = this.auth.currentUserValue.branch
    this.fetchData();
  }

  fetchData() {
    this.service.getRoutesByBranchId(this.branch).subscribe(
      (value: any) => {
        this.routeList = value;
      },
      (error: any) => {
        this.noContent = true;
        console.log(error);
      }
    );
  }

  handlePreview(id: number) {
    let temp: any[] = [];
    this.routeList[id].postal_code__.forEach((element: any) => {
      temp.push({
        postal_code: element.postal_code_,
        name: element.name_,
        lat: element.lat,
        lng: element.lng
      })
    });
    const dialogRef = this.dialog.open(MapPreviewDialogComponent, {
      data: temp
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });

    
  }

  displayedColumns: string[] = ['ID','Name',"User", "actions"];

}
