<div>
    <form>
        <label for="name">Name</label>
        <input [value]="name" id="name" autocomplete="off" (input)="onInputHandler($event)"/>
    </form>
    <div>
    <mat-form-field appearance="fill" class="form-field w-100 pt-3 pb-1 px-4">
        <mat-label>Route name</mat-label>
        <input matInput type="text" [(ngModel)]="name"/>
    </mat-form-field>
    </div>

    <div>
        <mat-form-field appearance="fill" class="form-field w-100 pt-1 pb-2 px-4">
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
        </mat-form-field>
    </div>

    <table mat-table [dataSource]="dataSource" class="w-100">
        <ng-container matColumnDef="Selected">
            <th mat-header-cell *matHeaderCellDef> Selected </th>
            <td mat-cell *matCellDef="let element; let i = index"><input type="checkbox"
                    [checked]="selectedList.includes(element.code)" (change)="onChangeHandler(i,$event)" /></td>
        </ng-container>
        <ng-container matColumnDef="Postal Code">
            <th mat-header-cell *matHeaderCellDef> Postal Code </th>
            <td mat-cell *matCellDef="let element"> {{element.code}} </td>
        </ng-container>
        <ng-container matColumnDef="Name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>
        <ng-container matColumnDef="Latitude">
            <th mat-header-cell *matHeaderCellDef> Latitude </th>
            <td mat-cell *matCellDef="let element"> {{element.lat}} </td>
        </ng-container>
        <ng-container matColumnDef="Longtitude">
            <th mat-header-cell *matHeaderCellDef> Longtitude </th>
            <td mat-cell *matCellDef="let element"> {{element.long}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 25, 100]" aria-label="Select page of users"></mat-paginator>


    <div class="py-3 d-flex">
        <button mat-raised-button color="primary" style="margin-right: 1rem;"
            (click)="onPreviewHandler()">View Map</button>
        <button mat-raised-button color="primary" style="margin-right: 1rem;"
            (click)="onSubmitHandler()">Submit</button>
        <button mat-raised-button color="warn" style="margin-right: 1rem;"
            (click)="onCancelHandler()">Cancel</button>
    </div>

    <!-- old version
    <button (click)="onPreviewHandler()">View Map</button>
    <button (click)="onSubmitHandler()">Submit</button>
    <button (click)="onCancelHandler()">Cancel</button> -->
</div>