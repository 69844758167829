<div class="container">
    <div class="p-3">
        <div class="p-1">
            <h5>View summary report</h5>
        </div>
        <div class="row">
            <div class="col">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>investigator</mat-label>
                    <mat-select [(ngModel)]="selectedInvg">
                        <mat-option [value]="userInfo.INVG_CODE">My Information</mat-option>
                        <mat-optgroup label="Branch investigators">
                            <mat-option *ngFor="let i of investigators" [value]="i.INVG_CODE">{{i.INVG_NAME}}</mat-option>
                        </mat-optgroup>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field class="w-100" appearance="fill">
                    <mat-label>From</mat-label>
                    <input matInput [matDatepicker]="fromPicker" placeholder="disable typing" readonly (click)="fromPicker.open()" [(ngModel)]="startDate">
                    <mat-hint>DD/MM/YYYY</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
                    <mat-datepicker touchUi #fromPicker></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field class="w-100" appearance="fill">
                    <mat-label>To</mat-label>
                    <input matInput [matDatepicker]="toPicker" placeholder="disable typing" readonly (click)="toPicker.open()" [(ngModel)]="endDate">
                    <mat-hint>DD/MM/YYYY</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
                    <mat-datepicker touchUi #toPicker></mat-datepicker>
                </mat-form-field>
            </div>
            <div *ngIf="startDate != null && endDate != null && selectedInvg!= undefined && selectedInvg!= null"
                class="col">
                <button [disabled]="isButtonDisabled" mat-raised-button color="primary" class="form-control" (click)="getVisitSummary()">Search</button>

                <button  mat-raised-button color="primary" class="form-control button-spacing" (click)="savePendingUpdates()" [disabled]="pendingUpdates.length === 0">Approved</button>

                <!-- <button  mat-raised-button color="primary" class="form-control" (click)="getVisitSummary()">Search</button> -->

            </div>
        </div>
    </div>
    <div>Now showing: {{dataset.length}} records</div>
    <div class="p-3">
        <angular-slickgrid *ngIf="dataset.length > 0" gridId="todaysCallList" [columnDefinitions]="columnDefinitions"
            [gridOptions]="gridOptions" [dataset]="dataset"
            (onAngularGridCreated)="angularGridReady($event)"></angular-slickgrid>
    </div>
    <style>
      .button-spacing {
          margin-top: 10px;
      }
  </style>
  <div class="d-flex flex-row-reverse">
      <button class="btn btn-primary float-left" (click)="closeDialog()">Close</button>
  </div>


</div>
