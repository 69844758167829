<app-dialog-header [title]="title" (closeDialogEvent)="closeDialog()"></app-dialog-header>

<div class="container-fluid">
  <div class="row p-2 pt-4">
    <div class="w-100 card-container">
      <div class="card-header w-100 p-2">
        <span>Security Details</span>
      </div>
      <div class="card-content p-2 w-100">

        <!-- section 1 -->

        <div class="row card-content-header py-2 px-4">
          Security equipment
        </div>
        <div class="row card-content-row w-100 px-5 px-md-4 py-2">
          <div class="col-6 py-2">
            <div class="col-12 card-content-leading">Type</div>
            <div class="col-12 card-content-description">
              <mat-form-field class="w-100">
                <input matInput [disabled]=true [(ngModel)]="selectedSecurityDetail.securityEquipType"
                  style="color: black;">
              </mat-form-field>
            </div>
          </div>
          <div class="col-12 py-2">
            <div class="col-12 card-content-leading">Make</div>
            <div class="col-12 card-content-description">
              <mat-form-field class="w-100">
                <input matInput [disabled]=true [(ngModel)]="selectedSecurityDetail.securityEquipMake"
                  style="color: black;">
              </mat-form-field>
            </div>
          </div>
          <div class="col-12 py-2">
            <div class="col-12 card-content-leading">Registration No</div>
            <div class="col-12 card-content-description">
              <mat-form-field class="w-100">

                <input matInput [disabled]=true [(ngModel)]="selectedSecurityDetail.securityEquipRegNo"
                  style="color: black;">


              </mat-form-field>
            </div>
          </div>
          <div class="col-12 py-2">
            <div class="col-12 card-content-leading">Value</div>
            <div class="col-12 card-content-description">
              <mat-form-field class="w-100">

                <input [disabled]=true matInput [(ngModel)]="selectedSecurityDetail.securityEquipValue"
                  style="color: black;">


              </mat-form-field>
            </div>
          </div>
        </div>

        <!-- Section 2 -->
        <div class="row card-content-header py-2 px-4">
          Gurantor 01
        </div>
        <div class="row card-content-row w-100 px-5 px-md-4 py-2">
          <div class="row d-flex align-items-center">
            <div class="">
              <!-- col-6 py-2 -->
              <div class="col-12 card-content-leading">Name</div>
              <div class="col-12 card-content-description">
                <mat-form-field class="w-100">

                  <input matInput [disabled]=true [(ngModel)]="selectedSecurityDetail.guranter01Name"
                    style="color: black;">


                </mat-form-field>
              </div>
            </div>
            <div class="col-6 py-2">
              <button mat-raised-button color="primary" class="w-100" (click)="openLocationMap()">Location</button>
            </div>
          </div>
          <div class="col-12 py-2">
            <div class="col-12 card-content-leading">Contact</div>
            <div class="col-12 card-content-description">
              <mat-form-field class="w-100">
                <input matInput [(ngModel)]="selectedSecurityDetail.guranter01ContactNo" style="color: black;">
              </mat-form-field>
            </div>
          </div>
          <div class="col-12 py-2">
            <div class="col-12 card-content-leading">Address</div>
            <div class="col-12 card-content-description">
              <mat-form-field class="w-100">
                <input [disabled]=true matInput [(ngModel)]="selectedSecurityDetail.guranter01Adress"
                  style="color: black;">
              </mat-form-field>
            </div>
          </div>
          <div class="col-12 py-2">
            <div class="col-12 card-content-leading">Occupation</div>
            <div class="col-12 card-content-description">
              <mat-form-field class="w-100">
                <input matInput [disabled]=true [(ngModel)]="selectedSecurityDetail.guranter01Occupation"
                  style="color: black;">
              </mat-form-field>
            </div>
          </div>
        </div>

        <!-- Section 3 -->
        <div class="row card-content-header py-2 px-4">
          Gurantor 02
        </div>
        <div class="row card-content-row w-100 px-5 px-md-4 py-2">
          <div class="row d-flex align-items-center">
            <div class="">
              <!-- col-6 py-2 -->
              <div class="col-12 card-content-leading">Name</div>
              <div class="col-12 card-content-description">
                <mat-form-field class="w-100">
                  <input matInput [disabled]=true [(ngModel)]="selectedSecurityDetail.guranter02Name"
                    style="color: black;">
                </mat-form-field>
              </div>
            </div>
            <div class="col-6 py-2">
              <button mat-raised-button color="primary" class="w-100" (click)="openLocationMap()">Location</button>
            </div>
          </div>
          <div class="col-12 py-2">
            <div class="col-12 card-content-leading">Contact</div>
            <div class="col-12 card-content-description">
              <mat-form-field class="w-100">
                <input matInput [(ngModel)]="selectedSecurityDetail.guranter02ContactNo" style="color: black;">
              </mat-form-field>
            </div>
          </div>
          <div class="col-12 py-2">
            <div class="col-12 card-content-leading">Address</div>
            <div class="col-12 card-content-description">
              <mat-form-field class="w-100">
                <input [disabled]=true matInput [(ngModel)]="selectedSecurityDetail.guranter02Adress"
                  style="color: black;">
              </mat-form-field>
            </div>
          </div>
          <div class="col-12 py-2">
            <div class="col-12 card-content-leading">Occupation</div>
            <div class="col-12 card-content-description">
              <mat-form-field class="w-100">
                <input matInput [disabled]=true [(ngModel)]="selectedSecurityDetail.guranter02Occupation"
                  style="color: black;">
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row p-2">
    <div class="col-6 p-1">
      <button mat-raised-button class=" p-1 w-100 text-wrap" color="primary" (click)="updateSecurityDetails()">Tel num
        changes updation option</button>
    </div>
    <div class="col-6 p-1">
      <button mat-raised-button class="p-1 w-100" color="warn" (click)="closeDialog()">Close</button>
    </div>
  </div>
</div>