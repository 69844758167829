import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RestRequestService } from '../rest-request.service';
import { Config } from "../../../config/config";

@Injectable({
  providedIn: 'root'
})
export class NotepadEntryService {

  private readonly callDeskJobsUrl: string;
  private readonly apiUrl: string; 

  constructor(
    private restService: RestRequestService,
    @Inject("recoveryConfig") private recoveryConfig = Config.recoveryConfig,
    private http: HttpClient
  ) {
    this.callDeskJobsUrl = this.getCallDeskJobsUrl(recoveryConfig);

    // Set apiUrl based on recoveryConfig
    if (recoveryConfig.env === 'local') {
      this.apiUrl = recoveryConfig.appServerUrl;
    } else {
      this.apiUrl = recoveryConfig.recoveryServiceUrl;
    }
  }

  private getCallDeskJobsUrl(recoveryConfig: any = {}): string {
    if (recoveryConfig.env === 'local') {
      return recoveryConfig.appServerUrl;
    } else {
      return recoveryConfig.recoveryServiceUrl;
    }
  }

  getNotepadHistory(contractNo: string, cType: string): Observable<any> {
    const url = `${this.apiUrl}/vGetNotepadHistory/${contractNo}/${cType}`;
    return this.http.get<any>(url);
  }
}
