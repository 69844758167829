import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, NgForm, Validators } from "@angular/forms";
import { GoogleLoginProvider, SocialAuthService, SocialUser } from "angularx-social-login";
import { UserAuthenticationService } from 'src/app/services/user-authentication/user-authentication.service';
import { first } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DurationUtil } from 'src/app/utils/DurationUtil';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.scss'],
  animations: [
  trigger('fadeInOut', [
    transition(':enter', [
      style({opacity:0}),
      animate(500, style({opacity:1}))
    ]),
    transition(':leave', [
      animate(300, style({opacity:0}))
    ])
  ])
]
})
export class UserLoginComponent implements OnInit {

  loginForm!: FormGroup;
  errorMsg?: boolean = true;
  user?: SocialUser;
  loggedIn?: boolean;

  loginFormFromAD: FormGroup = null

  otp: FormGroup = null

  logID: string = null

  constructor(private router: Router, private formBuilder: FormBuilder, private authService: SocialAuthService, private auth: UserAuthenticationService, private _snackBar: MatSnackBar) {

  }

  ngOnInit(): void {
    this.otp = this.formBuilder.group({
      first: [null, Validators.required],
      second: [null, Validators.required],
      third: [null, Validators.required],
      forth: [null, Validators.required],
      fifth: [null, Validators.required],
      sixth: [null, Validators.required]
    })

    this.loginFormFromAD = this.formBuilder.group({
      username: ["", Validators.required],
      password: ["", Validators.required]
    })

    this.loginForm = this.formBuilder.group({
      userName: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.authService.authState.subscribe((user) => {
      this.checkLoggedInState(user)
    });

    this.checkLoggedInState(this.auth.currentUserValue)
  }

  checkLoggedInState(user: any) {
    this.user = user;
      this.loggedIn = (user != null);
      console.debug("Logged in as "+this.user);
      if (this.loggedIn) {
        this.router.navigateByUrl('/home');
      }
  }

  changeModel(): void {
    this.errorMsg = true;
  }

  get loginParams() {
    return this.loginForm.controls;
  }

  submitHandler(e: Event) {
    e.preventDefault();
    if (this.loginFormFromAD.valid) {
      let payload = this.loginFormFromAD.value
      this.auth.userLoginVerificationInit(payload.username, payload.password).subscribe(
        {
          next: value => {
            this.logID = value?.content[0]?.LOGID
            console.log(this.logID);
          },
          error: err => {
            this._snackBar.open("Failed to sign in", "Dismiss", {
              duration: DurationUtil.TWO_SEC,
              horizontalPosition: "right",
              verticalPosition: "top",
            })
          }
        }
      )
    }
  }

  focusNextElement(e: Event, elementRef: HTMLInputElement) {
    elementRef.focus()
  }

  verifyOTP(e: Event) {
    e.preventDefault();

    if (this.otp.valid && this.logID && this.loginFormFromAD.valid) {
      let formValue = this.otp.controls
      let otpCode = Object.entries(formValue).map(item => item[1].value).join("")
      this.auth.userVerifyOTP(this.loginFormFromAD.value.username, otpCode, this.logID).pipe(first()).subscribe({
        next: value => {
          console.log(value)
          if (value.isLoggedIn) {
            this.router.navigateByUrl('/home');
          } else {
            this._snackBar.open(`Verification failed`, 'Dismiss', {
              duration: DurationUtil.TWO_SEC,
              horizontalPosition: "right",
              verticalPosition: "top",
            });
          }
        },
        error: err => {
          console.log(err)
          this._snackBar.open(`Verification failed`, 'Dismiss', {
            duration: DurationUtil.TWO_SEC,
            horizontalPosition: "right",
            verticalPosition: "top",
          });
        }
      })
    } else {
      this._snackBar.open(`Please complete the form before verification`, 'Dismiss', {
        duration: DurationUtil.TWO_SEC,
        horizontalPosition: "right",
        verticalPosition: "top",
      });
    }
  }

  cancelHandler() {
    this.logID = null

    this.otp.reset()
  }

  onSubmit(): any {
    if(this.loginForm.valid) {
      this.auth.userLogin(this.loginForm.get("userName").value, "")
      .pipe(first())
      .subscribe({
        next: value => {
          if (value) {
            this.router.navigateByUrl('/home');
          } else {
            this._snackBar.open(`Could not log in to the system`, 'Dismiss', {
              duration: DurationUtil.TWO_SEC,
              horizontalPosition: "right",
              verticalPosition: "top",
            });
          }
        },
        error: err => {
          console.log(err);
          this._snackBar.open(`Could not log in to the system`, 'Dismiss', {
            duration: DurationUtil.TWO_SEC,
            horizontalPosition: "right",
            verticalPosition: "top",
          });
        }
      })
    } else {
      this._snackBar.open(`Please fill all fields`, 'Dismiss', {
        duration: DurationUtil.TWO_SEC,
        horizontalPosition: "right",
        verticalPosition: "top",
      });
    }
  }


  clickLogin(): any {
    alert('clicked');
    this.router.navigateByUrl('/home');
  }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  signOut(): void {
    this.authService.signOut();
  }
}
