/**
 * UI component related to add contract list request service implementation
 * @author Malindu Panchala
 */

import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddContractListResponseBody } from 'src/app/models/add-contract-list-request-models';
import { SettlementCalculatorService } from 'src/app/services/settlement-calculator/settlement-calculator.service';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatInput } from '@angular/material/input';
import { Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-contract-request',
  templateUrl: './contract-request.component.html',
  styleUrls: ['./contract-request.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ContractRequestComponent implements OnInit {

  constructor(public settlementService: SettlementCalculatorService, public dialog: MatDialog) { }

  @ViewChild(MatPaginator) paginator !:MatPaginator;

  columns: Array<string> = ["Name", "NIC","Contract Number", "Total arrears"];
  columnLabels: Array<string> = ["customerName", "nic", "contract_No", "totalArrears"];
  columnsWithExpand: Array<string> = ["open", ...this.columns,"expand"];
  expandedElement: any | null;
  

  dataset: Array<any> = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();

  dataLoaded: boolean = false;

  // two way binding for selected item and parent component
  @Output() selectToFormEvent = new EventEmitter<any>();

  ngOnInit(): void {
    this.settlementService.vAddContractListRequest("","",{})
    .subscribe({
      next: (data) => {
        
        let reqList = AddContractListResponseBody.fromJson(data);
        this.dataset = [];

        this.processTableFromArray(reqList);
        this.dataLoaded = true;
        this.dataSource.data = this.dataset;
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  processTableFromArray(object: AddContractListResponseBody) {
    object.table.forEach(i => {
      this.dataset.push(i);
    });

    this.dataSource = new MatTableDataSource(this.dataset);
    this.dataSource.paginator = this.paginator;
  }

  openIntoForm(element: any) {
    this.selectToFormEvent.emit(element);
  }

}
