import { Component, Inject, OnInit } from '@angular/core';
import { ManualCallDialogComponent } from "../manual-call-dialog/manual-call-dialog.component";
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { VisitDeskDataResponseModelInfo } from "../../models/visit-desk-data-response-model-info";
import { HttpErrorResponse } from "@angular/common/http";
import { VisitDeskService } from "../../services/visit-desk.service";
import { MapsAPILoader } from "@agm/core";
import { GeoCodingLocationResponseModel } from "../../models/visit-desk/geo_coding_location_response_model";
import { VisitUpdateRequestModel } from "../../models/visit-desk/VisitUpdateRequestModel";
import { PdChequeDialogComponent } from "../pd-cheque-dialog/pd-cheque-dialog.component";
import { ReceiptDialogComponent } from "../receipt-dialog/receipt-dialog.component";
import { DateUtil } from 'src/app/utils/DateUtil';
import { UserAuthenticationService } from 'src/app/services/user-authentication/user-authentication.service';
import { VisitSelectionDeskService } from 'src/app/services/visit-selection-desk-service';

@Component({
  selector: 'app-visit-update-dialog',
  templateUrl: './visit-update-dialog.component.html',
  styleUrls: ['./visit-update-dialog.component.scss']
})
export class VisitUpdateDialogComponent implements OnInit {
  
  readonly title: string  = "Visit Update" 
  
  isPromisedToPayDisabled = true;
  visitStatus = '';
  callStatus = '';
  isStatusHidden = false;

  assignDate?: string;
  contractNo?: string;
  customerAddress?: string;
  customerName?: string;
  defaultAreass?: string;
  dueDate?: string;
  dueDate1?: string;
  equipment?: string;
  grossRental?: string;
  insuranArrears?: string;
  investigationCode?: string;
  lastPayDate?: string;
  make?: string;
  nic?: string;
  noOfAreas?: string;
  otherAreassAmount?: string;
  registrationNo?: string;
  totalAreas?: string;
  totalAreas1?: string;
  contact_no?: string;
  secondary_contact_no?: string;
  threep_name?: string;
  threep_contactNo?: string;
  guranter_name?: string;
  lastPayDate1?: string;
  last_payment_amount?: string;
  postalCode?: string;
  address: string = "";

  nextFollowUpType = '';
  promisedAmount = '';
  promisedDate = '';
  directionNature = '';
  comment = '';
  flag = '';
  followUpDate = '';
  assignSerial = '';
  private geoCoder: any;
  zoom: number = 0;
  latitude: number = 6.922973;
  longitude: number = 79.85734;

  isCompleted: boolean = true;

  selectedOptimizeVisitTransaction: VisitDeskDataResponseModelInfo;

  // date limitations
  minDate: Date = null
  maxDate: Date = null

  constructor(private dialogRef: MatDialogRef<ManualCallDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    protected visitDeskService: VisitDeskService, private mapsAPILoader: MapsAPILoader, public dialog: MatDialog, private auth: UserAuthenticationService, private visitUpdateService: VisitSelectionDeskService) {

    this.selectedOptimizeVisitTransaction = data.selectedOptimizeVisitTransaction;

    this.minDate = new Date();
  }

  ngOnInit(): void {
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;
    });
    this.assignDate = this.selectedOptimizeVisitTransaction.assignDate;
    this.assignSerial = this.selectedOptimizeVisitTransaction.assignSerial;
    this.contractNo = this.selectedOptimizeVisitTransaction.contractNo;
    this.customerAddress = this.selectedOptimizeVisitTransaction.customerAddress;
    this.customerName = this.selectedOptimizeVisitTransaction.customerName;
    this.defaultAreass = this.selectedOptimizeVisitTransaction.defaultAreass;
    this.dueDate = this.getFormattedDate(this.selectedOptimizeVisitTransaction.dueDate);
    this.dueDate1 = this.getFormattedDate(this.selectedOptimizeVisitTransaction.dueDate1);
    this.equipment = this.selectedOptimizeVisitTransaction.equipment;
    this.grossRental = this.selectedOptimizeVisitTransaction.grossRental;
    this.insuranArrears = this.selectedOptimizeVisitTransaction.insurancfArrears;
    this.investigationCode = this.selectedOptimizeVisitTransaction.investigationCode;
    this.lastPayDate = this.getFormattedDate(this.selectedOptimizeVisitTransaction.lastPayDate);
    this.make = this.selectedOptimizeVisitTransaction.make;
    this.nic = this.selectedOptimizeVisitTransaction.nic;
    this.noOfAreas = this.selectedOptimizeVisitTransaction.noOfArreas;
    this.otherAreassAmount = this.selectedOptimizeVisitTransaction.otherArreassAmount;
    this.registrationNo = this.selectedOptimizeVisitTransaction.registrationNo;
    this.totalAreas = this.selectedOptimizeVisitTransaction.totalArrears;
    this.contact_no = this.selectedOptimizeVisitTransaction.contact_no;
    this.secondary_contact_no = this.selectedOptimizeVisitTransaction.secondary_contact_no;
    this.threep_name = this.selectedOptimizeVisitTransaction.threep_name;
    this.threep_contactNo = this.selectedOptimizeVisitTransaction.threep_contactNo;
    this.guranter_name = this.selectedOptimizeVisitTransaction.guranter_name;
    this.lastPayDate1 = this.getFormattedDate(this.selectedOptimizeVisitTransaction.lastPayDate1);
    this.last_payment_amount = this.selectedOptimizeVisitTransaction.last_payment_amount;
    this.postalCode = this.selectedOptimizeVisitTransaction.postalCode;
  }

  getFormattedDate(date: string) {
    let formatted = "N/A";

    if (date) {
      formatted = date.slice(0, 10);
    }

    return formatted;
  }

  onCallStatusChange(value: any) {
    this.callStatus = value;
    if (this.callStatus == 'answered') {
      this.isStatusHidden = false;
    } else {
      this.isStatusHidden = true;
    }
    if (this.callStatus != 'answered' && this.isPromisedToPayDisabled == false) {
      this.isPromisedToPayDisabled = true;
    }
    if (this.callStatus == 'answered' && this.visitStatus == 'promisedToPay') {
      this.isPromisedToPayDisabled = false;
    }
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        console.log("GEO LOCATION" + this.latitude + "  " + this.longitude);

        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  getAddress(latitude: any, longitude: any) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results: any, status: any) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  getLonLat(adress: string) {
    this.geoCoder.geocode({ 'address': adress }, (results: any, status: any) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          let location: GeoCodingLocationResponseModel = results[0].geometry.location;
          let locationLat: string = JSON.stringify(results[0].geometry.location.lat());
          let locationLng: string = JSON.stringify(results[0].geometry.location.lng());
          console.log('results found ' + locationLat + " " + locationLng);
          window.alert('results found ' + locationLat + " " + locationLng);
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  onVisitStatusChange(value: any) {
    this.visitStatus = value;
    if (this.visitStatus == 'promisedToPay') {
      this.isPromisedToPayDisabled = false;
    } else {
      this.isPromisedToPayDisabled = true;
    }
  }

  closeDialog(): any {
    this.dialogRef.close();
  }

  openInquiry() {
    window.open('https://www.cfmobile.lk/crmesb/ESBInfo/CRMCallCenter/CRMCallCenter?OPPORTUNITYID=300000053159026', '_blank')
  }

  checking() {
    let date = new Date;
    let currentDate = DateUtil.getDateString(date);
    let currentPosition: {
      lat: number,
      lng: number
    } = null
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        currentPosition = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }

        let payload = {
          userid: this.auth.currentUserValue.code,
          date: currentDate,
          routeid:"265",
          ...currentPosition
        }
  
        this.visitUpdateService.trackMe(payload).subscribe({
          next: (data) => {
            window.alert("Tracking Data saved successfully");
          },
          complete: () => {
            console.log("Save SUCCESS" );
          },
          error: (error: HttpErrorResponse) => {
            window.alert("Error");
          },
        });
      });
    } else {
      window.alert("Navigation services are unavailable! Cannot track the location")
    }
  }

  updateVisitStatus() {
    let followUpDateProcess = new Date(this.followUpDate);
    let promisedDate = new Date(this.promisedDate);
    let payload: any = {
      CONTRACTNO: this.contractNo,
      ASSIGNSERIAL: this.assignSerial,
      TYPE: this.nextFollowUpType,
      CLINET_RESPONSE: this.callStatus,
      FOLLWOUP_DATE: DateUtil.getDateString(followUpDateProcess),
      DIRECTION_NATURE: this.directionNature,
      FLAG: this.flag,
      VISIT_STATUS: this.visitStatus,
      PROMISED_AMT: this.promisedAmount,
      PROMISED_DATE: DateUtil.getDateString(promisedDate),
      COMMENT: this.comment,
      VISIT_FLAG: this.isCompleted ? "COMPLETED" : "NOT_COMPLETED",
      ORIGINETYPE: "00"
    
    }

    this.visitDeskService.updateVisitStatus(payload).subscribe({
      next: (data) => {
        window.alert("Visit data saved successfully");
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
        window.alert("An error occured while connecting to the server!");
      }
    });
  }

  selectFollowupDate(e: any) {
    this.followUpDate = e.value;
  }

  selectPaymentDate(e: any) {
    this.promisedDate = e.value;
  }

  openPdCheque() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = false;
    dialogConfig.height = '100vh';
    dialogConfig.width = '100vw';
    dialogConfig.maxWidth = '100vw';
    dialogConfig.data = { selectedOptimizeVisitTransaction: this.selectedOptimizeVisitTransaction };
    const dialogRef = this.dialog.open(PdChequeDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        console.log(`Pd cheque dialog opened`);
      }
    );
  }

  openReceipt() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '100vh';
    dialogConfig.width = '100vw';
    dialogConfig.maxWidth = '100vw';
    dialogConfig.data = { selectedOptimizeVisitTransaction: this.selectedOptimizeVisitTransaction };

    const dialogRef = this.dialog.open(ReceiptDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        console.log(`Dialog result: WORKS`);
      }
    );
  }
}
