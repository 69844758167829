import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
/**
 * Rest Service implementation
 * @author Achala M. Rathnathilaka
 */
export class RestRequestService {

  constructor(private httpClient: HttpClient) {
  }

  post<T>(url: string, body: any, httpOptions?: {}) {
    return this.httpClient.post<T>(url, body, httpOptions);
  }

  put<T>(url: string, body: any, httpOptions?: {}) {
    if (body) {
      return this.httpClient.put<T>(url, body, httpOptions);
    }
    return this.httpClient.put<T>(url, httpOptions);
  }

  delete<T>(url: string, httpOptions?: {}) {
    return this.httpClient.delete<T>(url, httpOptions);
  }

  get<T>(url: string, httpOptions?: {}) {
    return this.httpClient.get<T>(url, httpOptions);
  }

}
