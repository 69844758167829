<app-dialog-header [title]="title" (closeDialogEvent)="closeCashHandover()"></app-dialog-header>

<br>

<div class="container-fluid dialog-wrapper">
    <div class="flex-wrap">
        <div class="row">
            <div class="d-flex flex-row">
                <div class="container">
                    <div class="row card-view my-2 pb-2">
                        <div class="">
                            <mat-form-field appearance="outline" class="m-1 w-100">
                                <mat-label>User</mat-label>
                                <input matInput readonly [(ngModel)] = "user_name"/>
                            </mat-form-field>
                        </div>
                        <div class="">
                            <mat-form-field appearance="outline" class="m-1 w-100">
                                <mat-label>Cash Amount</mat-label>
                                <input matInput readonly [(ngModel)] = "cash_amt"/>
                            </mat-form-field>
                        </div>
                        <div class="">
                            <mat-form-field appearance="outline" class="m-1 w-100">
                                <mat-label>Cheque Amount</mat-label>
                                <input matInput readonly [(ngModel)] = "chq_amt"/>
                            </mat-form-field>
                        </div>
                        <!-- <div class="">
                            <mat-form-field appearance="outline" class="m-1 w-100">
                                <mat-label>Sign Out Branch</mat-label>
                                <mat-select [(ngModel)]="descr" [disabled]="" autocomplete="off"
                                aria-selected="true" [value]="branch_name">
                                <mat-option *ngFor="let inst of dataset" [value]="inst.brncode">{{inst.descr}}
                                </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div> -->
                        <div class="">
                            <mat-form-field appearance="outline" class="m-1 w-100">
                                <mat-label>SignOut Branch</mat-label>
                                <mat-select [(ngModel)]="branchcode" autocomplete="off"
                                    aria-selected="true">
                                    <mat-option *ngFor="let inst of dataset" [value]="inst.brncode">{{inst.descr}}</mat-option> 
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="">
                            <mat-form-field appearance="outline" class="m-1 w-100">
                                <mat-label>SignOut Status</mat-label>
                                <input matInput readonly [(ngModel)] = "massage"/>
                            </mat-form-field>
                        </div>
                        <div class="" align="center">
                            <label (click)="CashHOSignOut()">Sign Out</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>