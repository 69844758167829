import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { VisitSummary } from 'src/app/models/visit-desk/visit-summary-model';
import { Config } from 'src/config/config';
import { RestRequestService } from '../rest-request.service';

@Injectable({
  providedIn: 'root'
})

/**
 * Services implementation releated to  Visit summary
 * @author Malindu Panchala
 */
export class VisitSummaryService {

  private readonly visitSummaryJobsUrl: string;

  constructor(private restService: RestRequestService, @Inject("recoveryConfig") private recoveryConfig = Config.recoveryConfig) {
    this.visitSummaryJobsUrl = this.getVisitSummaryJobsUrl(recoveryConfig);
  }

  getVisitSummaryJobsUrl(recoveryConfig: any = {}): string {
    let visitSummaryUrl = '';

    if (this.recoveryConfig.env === 'local') {
      visitSummaryUrl = `${recoveryConfig.appServerUrl}`;
    } else {
      visitSummaryUrl = `${recoveryConfig.recoveryServiceUrl}`;
    }
    return visitSummaryUrl;
  }

  getVisitSummary(start: string, end: string, invg: string): Observable<VisitSummary[]> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');

    let jobURL: string = this.visitSummaryJobsUrl + `/vGetVisitsWithin/${invg}/${start}/${end}`;

    console.log(jobURL)
    return this.restService.get<VisitSummary[]>(
      jobURL,
      {
        headers: headers
      }
    );
  }

  getInvestigators(branch: string): Observable<{
    INVG_CODE: string,
    INVG_NAME: string
  }[]> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');

    let jobURL: string = this.visitSummaryJobsUrl + `/vGetIntrbyBranch/${branch}`;

    console.log(jobURL)
    return this.restService.get<{
      INVG_CODE: string,
      INVG_NAME: string
    }[]>(
      jobURL,
      {
        headers: headers
      }
    );
  }
}
