import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-route-management-dialog',
  templateUrl: './route-management-dialog.component.html',
  styleUrls: ['./route-management-dialog.component.scss']
})
export class RouteManagementDialogComponent implements OnInit {

  readonly title: string = "Route management"

  constructor(public dialogRef: MatDialogRef<RouteManagementDialogComponent>) { }

  ngOnInit(): void {
  }

  closeCalculationDialog(): any {
    this.dialogRef.close();
  }

}
