<div class="container-fluid">
  <div class="row border-1" style="padding-top: 1rem">
    <div class="col-9">
      <h2>Request Details</h2>
    </div>

  </div>

      <div class="row">
        <div class="col-10" style="padding-top: 1rem" >
          <label style="color: rgba(0,0,0,.6); padding-left: 0.5rem;">Status :</label><br>
          <select #status style="width: 90%" >
            <option value="select">-Select-</option>
            <option value="promisedToPay">Requests a special balance</option>
            <option value="pdc">Requests a deferment</option>
            <option value="leftMsg">Requests a Waive off</option>
            <option value="visitBranch">Requests a charge  Reversal</option>
            <option value="visitBranch">Requests to release a block</option>
            <option value="visitBranch">Requests to Flag</option>
            <option value="visitBranch">Requests next renewal</option>
            <option value="visitBranch">Block the next reminder</option>
            <option value="visitBranch">Allocation removal request</option>
            <option value="visitBranch">Enhancement of cash transit limit</option>
            <option value="visitBranch">Requests CR copy</option>
            <option value="visitBranch">Requests No objection letter</option>
            <option value="visitBranch">Request  status confirmation letter</option>
            <option value="visitBranch">Request higher management attention</option>
          </select>
        </div>
        <div class="col-2 ">
          <button view class="btn btn-primary float-none" style="width: 80%; margin: 0px auto 10px auto;" >Request</button>
          <button view class="btn btn-primary float-none" style="width: 80%" (click)="onCloseHandler()">Close</button>
        </div>
      </div>

</div>
