<app-dialog-header [title]="title" (closeDialogEvent)="closeDialog()"></app-dialog-header>
<!-- loadingView - kalanarathnayake-->
<div id="backColor" *ngIf="isLoading">
    <div id="loadingView">
      <img class="loadingGif" src="../../../../assets/loading.gif" alt="loadingLogo">
    </div>
  </div>
  <!-- loadingView -->
<div class="m-3">
    <div class="d-flex flex-row">
        <div class="p-2">
            <button [disabled]="mode == 'ALL' || !didAutoProRun" mat-raised-button color="primary"
                (click)="changeMode('ALL')">All visits</button>
        </div>
        <div class="p-2">
            <button [disabled]="mode == 'NONROUTED' || !didAutoProRun" mat-raised-button color="primary"
                (click)="changeMode('NONROUTED')">Non-routed</button>
        </div>
        <div class="p-2">
            <button [disabled]="mode == 'BALANCE' || !didAutoProRun" mat-raised-button color="primary"
                (click)="changeMode('BALANCE')">Balance</button>
        </div>
        <div class="p-2">
            <button mat-raised-button color="accent" (click)="autoProximityAdjust()">Auto Pro Suggest</button>
        </div>
    </div>

    <div>
        <section>
            <mat-checkbox [(ngModel)]="enableSearch" (change)="handleSearchEnable($event)">Enable search</mat-checkbox>
        </section>
    </div>

    <br>
    <div class="row">
        <section>
          <mat-radio-group aria-label="Select an option" [(ngModel)]="selectOptionMode" (change)="changeType($event)" [disabled]="!enableSearch">
            <div class="d-flex">
              <div class = "col-2">
                <mat-radio-button color="primary" value="CR">
                  <span style="font-size: 14px; text-overflow:ellipsis">CR</span>
                </mat-radio-button>
              </div>
              <div class = "col-2">
                <mat-radio-button color="primary" value="WO">
                  <span style="font-size: 14px; text-overflow:ellipsis">WO</span>
                </mat-radio-button>
              </div>
              <div class = "col-2">
                <mat-radio-button color="primary" value="PL">
                  <span style="font-size: 14px; text-overflow:ellipsis">PL</span>
                </mat-radio-button>
              </div>
              <div class = "col-2">
                <mat-radio-button color="primary" value="PW">
                  <span style="font-size: 14px; text-overflow:ellipsis">PLWO</span>
                </mat-radio-button>
              </div>
            </div>
          </mat-radio-group>
        </section>
    </div>
    <br>

    <div>
        <div class="row d-flex align-items-baseline">
            <div class="col-9">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Search</mat-label>
                    <mat-placeholder>Search reports</mat-placeholder>
                    <input matInput type="text" [(ngModel)]="search" [disabled]="!enableSearch">
                    <mat-select matSuffix [(ngModel)]="searchType">
                        <mat-option value="Contract">Contract</mat-option>
                        <mat-option value="Name">Name</mat-option>
                        <mat-option value="NIC">NIC</mat-option>
                        <mat-option value="Chassi">Chassi</mat-option>
                        <mat-option value="Vehicle">Vehicle</mat-option>
                    </mat-select>
                    <mat-hint align="start"><strong>Select a search type from the dropdown icon {{search}}</strong>
                    </mat-hint>
                    <mat-hint align="end">Search by: {{searchType}}</mat-hint>
                </mat-form-field>
            </div>
            <div class="col-3">
                <button class="w-100" [disabled]="search?.length < 1" mat-raised-button color="accent"
                    (click)="searchAllocations()">Search</button>
            </div>
        </div>
    </div>



    <div class="py-2 overflow-container" style="min-height:400px;">
        <div>Showing {{dataset.length}} records</div>
        <angular-slickgrid gridId="RouteAllocationTable1" [columnDefinitions]="columnDefinitions"
            [gridOptions]="gridOptions" [dataset]="dataset" (onAngularGridCreated)="angularGridReady($event)"
            (onGridStateChanged)="gridStateChanged($event)">
        </angular-slickgrid>

        <!-- <angular-slickgrid gridId="RouteAllocationTable1" [columnDefinitions]="columnDefinitions"
            [gridOptions]="gridOptions" [dataset]="dataset" (onAngularGridCreated)="angularGridReady($event)"
            (onGridStateChanged)="gridStateChanged($event.detail)"
            (onSelectedRowsChanged)="handleSelectedRowsChanged1($event.detail.eventData, $event.detail.args)"></angular-slickgrid> -->
    </div>

    <div class="d-flex flex-row-reverse">
        <!-- <div class="p-2">
            <button mat-raised-button color="primary" (click)="autoProximityAdjust()">AUTO PROXIMITY ADJUST </button>
        </div> -->
        <!-- TODO add the functionality to the remove -->
        <div class="p-2">
            <button [disabled]="this.selectedTitle?.length < 1" mat-raised-button color="warn"
                style="margin-right: 1rem;"  (click)="removeVisit()">REMOVE VISIT</button>
        </div>

        <div class="p-2">
            <button [disabled]="this.selectedTitle?.length < 1" mat-raised-button color="primary"
                style="margin-right: 1rem;" (click)="showLocation()">VIEW MAP</button>
        </div>
        <div class="p-2">
            <button [disabled]="this.selectedTitle?.length < 1" mat-raised-button color="primary"
                style="margin-right: 1rem;" (click)="handleUpdateDialog()">UPDATE</button>
        </div>
    </div>

    <!-- <table>
        <tr>
            <th>Route Summary</th>
            <th>Schedulled visits</th>
            <th>Actual visits</th>
        </tr>
    </table> -->

    <div class="py-2 overflow-container" style="min-height:400px;">
        <angular-slickgrid gridId="RouteAllocationTable2" [columnDefinitions]="columnDefinitions1"
            [gridOptions]="gridOptions1" [dataset]="dataset1"
            (onAngularGridCreated)="angularGridReady1($event)"></angular-slickgrid>
    </div>

    <!-- <div class="d-flex flex-row-reverse">
        <div class="p-2">
            <button mat-raised-button color="primary">Save</button>
        </div>
    </div> -->
    <div>
        <h2>Total Summary</h2>
    </div>
    <div class="col-12 col-lg-4 py-3">
        <div class="row text-center table-div py-2" style="background-color: rgb(175, 175, 216);">
            <div class="totalVisits">Scheduled Visits</div>
        </div>
        <div class="row text-center table-div py-1">
            <div></div>
        </div>
        <div class="row">
            <div class="col-6 table-div">Total Contracts</div>
            <div class="col-6 table-div">{{totalContracts}}</div>
        </div>
        <div class="row">
          <div class="col-6 table-div"> Removed Visits</div>
          <div class="col-6 table-div">{{totalRemovalVisits}}</div>
      </div>
        <div class="row">
            <div class="col-6 table-div">Total Visits</div>
            <div class="col-6 table-div">{{totalVisits}}</div>
        </div>
        <!-- <div class="row">
            <div class="col-6 table-div">Distance(KM)</div>
            <div class="col-6 table-div">{{totalDistance}}</div>
        </div> -->
    </div>

    <div class="col-12 col-lg-4 py-3">
        <div class="row text-center table-div py-2" style="background-color: rgb(139, 241, 139);">
            <div>Actual Visits</div>
        </div>
        <div class="row text-center table-div py-1">
            <div></div>
        </div>
        <div class="row">
            <div class="col-6 table-div">Actual Visits</div>
            <div class="col-6 table-div">{{totalActualVisits}}</div>
        </div>
        <div class="row">
            <div class="col-6 table-div"> Removal Visits</div>
            <div class="col-6 table-div">{{totalRemovalVisits}}</div>
        </div>
        <div class="row">
            <div class="col-6 table-div">Balance Visits</div>
            <div class="col-6 table-div">{{totalBalanceVisits}}</div>
        </div>
    </div>

</div>
