import {Inject , Injectable} from "@angular/core";
import {RestRequestService} from "./rest-request.service";
import {Config} from "../../config/config";
import {HttpHeaders , HttpParams} from "@angular/common/http";
import {Observable} from "rxjs/internal/Observable";
import {CallDeskDataResponseModel} from "../models/call-desk-data-response-model";
import {CallDeskDataRequestModel} from "../models/call-desk-data-request-model";
import {VisitDeskDataRequestModel} from "../models/visit-desk-data-request-model";
import {VisitDeskDataResponseModel} from "../models/visit-desk-data-response-model";

@Injectable({
  providedIn: 'root'
})
/**
 * Service implementation releated to visit desk funtionalities
 * @author Achala M. Rathnathilaka
 */
export class VisitDeskService {

  private   readonly visitDeskJobsUrl: string;
  private readonly  optimizedVisitSaveUrl: string;
  private readonly  checkingUrl: string;
  private readonly  updateVisitUrl: string;
  private readonly baseUrl: string;

  constructor(private restService: RestRequestService,@Inject("recoveryConfig") private recoveryConfig = Config.recoveryConfig) {

    this.visitDeskJobsUrl = this.getCallDeskJobsUrl(recoveryConfig);
    this.optimizedVisitSaveUrl = this.getOptimizeVisitSaveJobsUrl(recoveryConfig);
    this.checkingUrl = this.getCheckingJobsUrl(recoveryConfig);
    this.updateVisitUrl = this.getUpdateVisitJobsUrl(recoveryConfig);
    this.baseUrl = this.getBaseUrl(recoveryConfig);
  }

  /**
   * Get base job service Url from visitDesk injectable object.
   * @param recoveryConfig
   * @returns baseUrl
   */
   getBaseUrl(recoveryConfig: { env: string; basePath: string; appServerUrl: string; recoveryServiceUrl: string; }): string {
    let url = '';

    if (recoveryConfig.env === 'local') {
      url = `${recoveryConfig.appServerUrl}`;
    } else {
      url = `${recoveryConfig.recoveryServiceUrl}`;
    }
    return url;
  }

  /**
   * Get Visit desk job service Url from visitDesk injectable object.
   * @param recoveryConfig
   * @returns visitDeskUrl:string
   */
  private getCallDeskJobsUrl(recoveryConfig: any = {}): string {
    let visitDeskUrl = '';

    if (recoveryConfig.env === 'local') {
      visitDeskUrl = `${recoveryConfig.appServerUrl}/vTodayContractListRequest`;
    } else {
      visitDeskUrl = `${recoveryConfig.recoveryServiceUrl}/vTodayContractListRequest`;
    }
    return visitDeskUrl;
  }

  private getOptimizeVisitSaveJobsUrl(recoveryConfig: any = {}): string {
    let optimizedViewSaveUrl = '';

    if (recoveryConfig.env === 'local') {
      optimizedViewSaveUrl = `${recoveryConfig.appServerUrl}/vAddAsOptimize`;
    } else {
      optimizedViewSaveUrl = `${recoveryConfig.recoveryServiceUrl}/vAddAsOptimize`;
    }
    return optimizedViewSaveUrl;
  }

  private getCheckingJobsUrl(recoveryConfig: any = {}): string {
    let url = '';

    if (recoveryConfig.env === 'local') {
      url = `${recoveryConfig.appServerUrl}/vAddCheckin`;
    } else {
      url = `${recoveryConfig.recoveryServiceUrl}/vAddCheckin`;
    }
    return url;
  }

  private getUpdateVisitJobsUrl(recoveryConfig: any = {}): string {
    let url = '';

    if (recoveryConfig.env === 'local') {
      url = `${recoveryConfig.appServerUrl}/vUpdVisitDesk`;
    } else {
      url = `${recoveryConfig.recoveryServiceUrl}/vUpdVisitDesk`;
    }
    return url;
  }

  /**
   * Returns the All the Call desk details.
   * {CallDeskDataRequestModel} requestData
   * Returns {Observable<VisitDeskDataResponseModel>}
   */
  getVisitDeskData (user: string, requestData: VisitDeskDataRequestModel, routeid: string ): Observable<VisitDeskDataResponseModel> {
    const headers = new HttpHeaders ().set ( 'Content-Type' , 'text/plain' );

    let jobURL: string  =  this.visitDeskJobsUrl + `/${user}/VISIT/` + routeid;
    // console.log ( "URL   " + jobURL );
    return this.restService.get<VisitDeskDataResponseModel> (
      jobURL ,
      {
        headers: headers
      }
    );
  }

  /**
   * Save Optimized Visit
   */
  saveOptimizedVisit ( selectedOptimizedVisits: any ): Observable<any> {

    const httpOptions = {headers: new HttpHeaders ( {'Content-Type': 'text/plain'} )};

    return this.restService.post<any> (  this.optimizedVisitSaveUrl , selectedOptimizedVisits , httpOptions );
  }

  checking ( checkingDetail: any ): Observable<any> {
    const httpOptions = {headers: new HttpHeaders ( {'Content-Type': 'text/plain'} )};
    return this.restService.post<any> (  this.checkingUrl , checkingDetail , httpOptions );
  }

  updateVisitStatus ( updateVisitPayload: any ): Observable<any> {
    const httpOptions = {headers: new HttpHeaders ( {'Content-Type': 'text/plain'} )};
    return this.restService.post<any> (  this.updateVisitUrl , updateVisitPayload , httpOptions );
  }

  updateVisitDistanceData(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');
    let jobURL: string = this.baseUrl + "/api";
    return this.restService.post<any>(jobURL, payload,
    {
      headers: headers
    });
  }

}
