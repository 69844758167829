import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-auto-call-dialog',
  templateUrl: './auto-call-dialog.component.html',
  styleUrls: ['./auto-call-dialog.component.scss']
})
export class AutoCallDialogComponent implements OnInit {
  isPromisedToPayDisabled = true;
  visitStatus = '';

  constructor(private dialogRef: MatDialogRef<AutoCallDialogComponent>) { }

  ngOnInit(): void {
  }

  onVisitStatusChange(value: any) {
    this.visitStatus = value;
    if (this.visitStatus == 'promisedToPay') {
      this.isPromisedToPayDisabled = false;
    } else {
      this.isPromisedToPayDisabled = true;
    }
  }


  closeDialog(): any {
    this.dialogRef.close ();
  }

}
