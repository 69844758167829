import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appTwoDigitDecimalNumber]'
})
export class TwoDigitDecimalNumberDirective {
  // Allow decimal numbers and negative values
  private regex: RegExp = new RegExp(/^\d{1,3}(,\d{3})*.\d{2}$/);
  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

  constructor(private el: ElementRef) {
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // // console.log(this.el.nativeElement.value);
    // // Allow Backspace, tab, end, and home keys
    // if (this.specialKeys.indexOf(event.key) !== -1) {
    //   return;
    // }
    // let current: string = this.el.nativeElement.value;
    // const position = this.el.nativeElement.selectionStart;
    // const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    // if (next && !String(next).match(this.regex)) {
    //   // event.preventDefault();
    //   console.log(next)

    let amount: string = this.el.nativeElement.value;

    const splitted = amount.split(".");

    if (splitted.length > 1) {
      const decimals = splitted[1];
      if (decimals.length >= 2) {
        // event.preventDefault
        amount = splitted[0] + "." + decimals.substring(0, 1)
      }
    }
    this.el.nativeElement.value = amount;
  }
}