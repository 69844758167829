<div class="deep-container">
  <app-navigation-bar></app-navigation-bar>
  <!-- <div class="main-container">
        <div class="container-bg">
            <div class="title">
                <h3>Welcome back, {{user}}</h3>
            </div>
        </div>
    </div> -->
  <div class="main-container">
    <div class="container-bg">
      <div class="row">
        <div class="col-12 col-md-6 d-flex justify-content-center card-cl">
          <div class="card-view" (click)="openTodaysCallList()">
            <div class="card-view-inner-div">
              <div class="card-content">
                <div class="icon-container icon-container-bg-primary">
                  <mat-icon class="card-icon" appearance="fill"
                    >phone_in_talk</mat-icon
                  >
                </div>
                <div class="card-text">
                  <span class="card-text-sub-div">Call desk</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 d-flex justify-content-center card-cl">
          <div class="card-view" (click)="openRouteManagement()">
            <div class="card-view-inner-div">
              <div class="card-content">
                <div class="icon-container icon-container-bg-warning">
                  <mat-icon class="card-icon" appearance="fill"
                    >pin_drop</mat-icon
                  >
                </div>
                <div class="card-text">
                  <span class="card-text-sub-div">Route Management</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 d-flex justify-content-center card-cl">
          <div class="card-view" (click)="openRouteAllocation()">
            <div class="card-view-inner-div">
              <div class="card-content">
                <div class="icon-container icon-container-bg-info">
                  <mat-icon class="card-icon" appearance="fill"
                    >person_pin_circle</mat-icon
                  >
                </div>
                <div class="card-text">
                  <span class="card-text-sub-div">Route allocation</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 d-flex justify-content-center card-cl">
          <div class="card-view" (click)="openTodaysVisitList()">
            <div class="card-view-inner-div">
              <div class="card-content">
                <div class="icon-container icon-container-bg-success">
                  <mat-icon class="card-icon" appearance="fill"
                    >emoji_transportation</mat-icon
                  >
                </div>
                <div class="card-text">
                  <span class="card-text-sub-div">Visit desk</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 d-flex justify-content-center card-cl">
          <div class="card-view" (click)="openNavigator()">
            <div class="card-view-inner-div">
              <div class="card-content">
                <div class="icon-container icon-container-bg-danger">
                  <mat-icon class="card-icon" appearance="fill"
                    >travel_explore</mat-icon
                  >
                </div>
                <div class="card-text">
                  <span class="card-text-sub-div">Navigator</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 d-flex justify-content-center card-cl">
          <div class="card-view" (click)="openVisitListReport()">
            <div class="card-view-inner-div">
              <div class="card-content">
                <div class="icon-container icon-container-bg-primary">
                  <mat-icon class="card-icon" appearance="fill"
                    >receipt_long</mat-icon
                  >
                </div>
                <div class="card-text">
                  <span class="card-text-sub-div"
                    >Todays visit list report</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 d-flex justify-content-center card-cl">
          <div class="card-view" (click)="openCashHandover()">
            <div class="card-view-inner-div">
              <div class="card-content">
                <div class="icon-container icon-container-bg-warning">
                  <mat-icon class="card-icon" appearance="fill"
                    >account_balance</mat-icon
                  >
                </div>
                <div class="card-text">
                  <span class="card-text-sub-div">Cash Handover</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 d-flex justify-content-center card-cl">
          <!--<div class="card-view" (click)="openCalculator()">-->
          <div class="card-view" (click)="openCollectorPerformance()">
            <div class="card-view-inner-div">
              <div class="card-content">
                <div class="icon-container icon-container-bg-dark">
                  <mat-icon class="card-icon" appearance="fill"
                    >calculate</mat-icon
                  >
                </div>
                <!-- <div class="card-text">
                        <span  class="card-text-sub-div">Concession requests and Calculations</span>
                    </div> -->
                <div class="card-text">
                  <span class="card-text-sub-div">Collector Performance</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 d-flex justify-content-center card-cl">
          <div class="card-view" (click)="openInquiry()">
            <div class="card-view-inner-div">
              <div class="card-content">
                <div class="icon-container icon-container-bg-success">
                  <mat-icon class="card-icon" appearance="fill">info</mat-icon>
                </div>
                <div class="card-text">
                  <span class="card-text-sub-div">Inquiry</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 d-flex justify-content-center card-cl">
          <div class="card-view" (click)="openDistanceTracker()">
            <div class="card-view-inner-div">
              <div class="card-content">
                <div class="icon-container icon-container-bg-danger">
                  <mat-icon class="card-icon" appearance="fill">route</mat-icon>
                </div>
                <div class="card-text">
                  <span class="card-text-sub-div">Distance tracker</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 d-flex justify-content-center card-cl">
          <div class="card-view" (click)="adminPortel()">
            <div class="card-view-inner-div">
              <div class="card-content">
                <div class="icon-container icon-container-bg-warning">
                  <mat-icon class="card-icon" appearance="fill"
                    >admin_panel_settings</mat-icon
                  >
                </div>
                <div class="card-text">
                  <span class="card-text-sub-div">Admin Portal</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
