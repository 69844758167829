<app-dialog-header
  [title]="title"
  (closeDialogEvent)="closeDialog()"
></app-dialog-header>
<br />
<div class="beautiful-border">
  <mat-tab-group>
    <mat-tab label="All">
      <br />
      <mat-form-field appearance="standard">
        <mat-label>Search</mat-label>
        <input
          matInput
          (keyup)="applyFilter($event)"
          placeholder="Ex. User Name, INV ID or NIC"
          #input
        />
      </mat-form-field>
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              hidden
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
              [aria-label]="checkboxLabel()"
            ></mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-radio-button          
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
              [aria-label]="checkboxLabel(row)"
            ></mat-radio-button>
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>STATUS</th>
          <td mat-cell *matCellDef="let element">
            <span [ngClass]="getStatusStyle(element.INV_ACCESS_ROUTE_DETAILS)">
              <span
                [ngClass]="getStatusColor(element.INV_ACCESS_ROUTE_DETAILS)"
              ></span>
              {{ getStatusText(element.INV_ACCESS_ROUTE_DETAILS) }}
            </span>
          </td>
        </ng-container>

        <!-- USER_ID Column -->
        <ng-container matColumnDef="USER_ID">
          <th mat-header-cell *matHeaderCellDef>USER ID</th>
          <td mat-cell *matCellDef="let element">{{ element.USER_ID }}</td>
        </ng-container>

        <!-- INV_NAME Column -->
        <ng-container matColumnDef="INV_NAME">
          <th mat-header-cell *matHeaderCellDef>INV NAME</th>
          <td mat-cell *matCellDef="let element">{{ element.INV_NAME }}</td>
        </ng-container>

        <!-- INV_ID Column -->
        <ng-container matColumnDef="INV_ID">
          <th mat-header-cell *matHeaderCellDef>INV ID</th>
          <td mat-cell *matCellDef="let element">{{ element.INV_ID }}</td>
        </ng-container>

        <!-- NIC Column -->
        <ng-container matColumnDef="NIC">
          <th mat-header-cell *matHeaderCellDef>NIC</th>
          <td mat-cell *matCellDef="let element">{{ element.NIC }}</td>
        </ng-container>

        <!-- INV_BRANCH Column -->
        <ng-container matColumnDef="INV_BRANCH">
          <th mat-header-cell *matHeaderCellDef>INV BRANCH</th>
          <td mat-cell *matCellDef="let element">{{ element.INV_BRANCH }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          (click)="selection.toggle(row)"
        ></tr>
      </table>

      <mat-paginator
        [pageSizeOptions]="[10, 20, 30, 40]"
        showFirstLastButtons
        aria-label="Select page of periodic elements"
      >
      </mat-paginator>

      <br />
      <button mat-button class="custom-button" (click)="toggleActivation()">
        {{ activationActionText }}
      </button>
    </mat-tab>
  </mat-tab-group>

  <mat-dialog-actions align="end">
    <div class="py-3 d-flex">
      <button
        mat-raised-button
        mat-dialog-close
        color="warn"
        style="margin-right: 1rem"
      >
        Close
      </button>
    </div>
  </mat-dialog-actions>
</div>
