<h1>Visit Desk</h1>
<div  class="col-12" >
  <!-- this creates a google map on the page with the given lat/lng from -->
  <!-- the component as the initial center of the map: -->
  <agm-map [latitude]="lat" [longitude]="lng">
    <agm-direction
      [origin]="origin"
      [destination]="destination"
      [waypoints]="waypoints"
    >
      <!--[renderOptions]="renderOptions"-->
      <!--[markerOptions]="markerOptions">-->
    </agm-direction>
  </agm-map>
</div>

<div  style=" display:flex; justify-content:flex-end; width:100%; padding-top: 20px; padding-right: 20px;" >
  <table bordet="0">
    <tr>
      <td style="color:green; padding-top:20px; padding-left: 20px">
        <button view class="btn btn-primary float-left" (click)="navigateToGoogleApp()">Start Trip</button>
      </td>
      <!--<td style="color:green; padding-top:20px; padding-left: 20px">-->
        <!--<button view class="btn btn-primary float-left" (click)="checkIn()">Check In</button>-->
      <!--</td>-->
      <td style="color:green; padding-top:20px; padding-left: 20px">
        <button view class="btn btn-primary float-left" (click)="closeDialog()">Close</button>
      </td>
    </tr>
  </table>
</div>
