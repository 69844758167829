import { Component, Inject, OnInit } from '@angular/core';
import { ManualCallDialogComponent } from "../manual-call-dialog/manual-call-dialog.component";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MapsAPILoader } from "@agm/core";
import { VisitDeskService } from "../../services/visit-desk.service";
import { HttpErrorResponse } from "@angular/common/http";
import { ReceiptService } from "../../services/receipt/receipt.service";
import { ReceiptResponseModelInfo } from "../../models/receipt/receipt-response-model-info";
import { ReceiptRequestModel } from "../../models/receipt/receipt-request-model";
import { ReceiptResponseModel } from "../../models/receipt/receipt-response-model";
import { DateAdapter } from "@angular/material/core";
import { VisitDeskDataResponseModelInfo } from "../../models/visit-desk-data-response-model-info";
import { UserAuthenticationService } from 'src/app/services/user-authentication/user-authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DurationUtil } from 'src/app/utils/DurationUtil';
import { DateUtil } from 'src/app/utils/DateUtil';

@Component({
  selector: 'app-receipt-dialog',
  templateUrl: './receipt-dialog.component.html',
  styleUrls: ['./receipt-dialog.component.scss']
})
export class ReceiptDialogComponent implements OnInit {


  title: string = 'Receipt';
  
  currentDate: Date = new Date();
  isDisabled: boolean = true;

  Receipt = 'Cash';
  contractNo = '';
  chequeDate = '';
  receiptNo = '';
  cashAmount = '';
  chequeAmount = '';
  chequeNo = '';
  chequeAC = '';
  isConfirmButtonDisable = true;
  isGetButtonDisable = false;
  isReceiptFieldDisable = true;
  SYSTEM = '';


  receiptPayload: ReceiptRequestModel = new ReceiptRequestModel();
  receiptConfirmPayload: any;
  receiptResponse: ReceiptResponseModel = new ReceiptResponseModel();
  selectedOptimizeVisitTransaction: VisitDeskDataResponseModelInfo;
  // receiptResponse:ReceiptResponseModelInfo = new ReceiptResponseModelInfo();
  constructor(private snackbar: MatSnackBar, private receiptService: ReceiptService, private dialogRef: MatDialogRef<ManualCallDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private auth: UserAuthenticationService) {

    this.selectedOptimizeVisitTransaction = data.selectedOptimizeVisitTransaction;
    this.contractNo = this.selectedOptimizeVisitTransaction.contractNo;
    this.SYSTEM = this.selectedOptimizeVisitTransaction.cType;
  }

  ngOnInit(): void {
  }

  numberOnly(event: { which: any; keyCode: any; }): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  closeDialog(): any {
    this.dialogRef.close();
  }


  confirmReceipt() {

    this.receiptConfirmPayload = {
      "contractno": this.contractNo,
      "receiptno": this.receiptNo
    };

    console.log(JSON.stringify(this.receiptConfirmPayload));
    this.receiptService.confirmReceipt(this.receiptConfirmPayload).subscribe({
      next: (data: any) => {
        console.log("Cash Receipt" + data.content[0].STATUS);


        window.alert("Cash Receipt Save success.");
        this.isConfirmButtonDisable = true;
      },
      complete: () => {
        console.log("Cash Receipt Save success");
      },
      error: (error: HttpErrorResponse) => {
        window.alert("Cash Receipt Save was unsuccessful");
      },
    });
  }

  //this function will convert string numbers into comma less float type string value - kalanaRathnayake
  getChequeAmountFiltered(amount:any) {
    const amountWithCommas = amount; // input: "100,000.12"
    const cleanedAmount = amountWithCommas.replace(/,/g, "");
    // uncomment below line if you wanna get the value as a string
    // const amountAsString = cleanedAmount.toString();

    // DEV-USE-Don'tUncomment
    // console.log(amountAsString);
    // Output: "100000.12"
    return cleanedAmount;
  }

    //this function will convert string numbers into comma less float type string value - kalanaRathnayake
    getCashAmountFiltered(amount:any) {
      const amountWithCommas = amount; // input: "100,000.12"
      const cleanedAmount = amountWithCommas.replace(/,/g, "");
      // uncomment below line if you wanna get the value as a string
      // const amountAsString = cleanedAmount.toString();

      // DEV-USE-Don'tUncomment
      // console.log(amountAsString);
      // Output: "100000.12"
      return cleanedAmount;
    }


  getReceipt() {
//ToDo- set chequeDate to payload by backend -DulsaraMannakkara 2023/08/31
    let promiseDateProcess = this.currentDate ? DateUtil.getDateString(new Date(this.currentDate)) : null;


    const ad_username = this.auth.currentUserValue.username
    this.receiptPayload.amount =this.getCashAmountFiltered(this.cashAmount);
    this.receiptPayload.chqamt = this.getChequeAmountFiltered(this.chequeAmount);
    this.receiptPayload.chqno = this.chequeNo;
    this.receiptPayload.accno = this.chequeAC;
    this.receiptPayload.date = this.chequeDate;
    //this.receiptPayload.date = promiseDateProcess;

    this.receiptPayload.contractno = this.contractNo;
    this.receiptPayload.brncode = this.auth.currentUserValue.branch;
    this.receiptPayload.usrid = this.auth.currentUserValue.username;
    this.receiptPayload.paymode = this.Receipt == "Cash" ? "01" : "05";
    this.receiptPayload.chqtype = this.Receipt == "Cheque" ? "03" : "";
    this.receiptPayload.SYSTEM = this.SYSTEM;

    console.log(JSON.stringify(this.receiptPayload));

    this.isGetButtonDisable = true;
    this.receiptService.getCashReceipt(this.receiptPayload).subscribe({
      next: (data: ReceiptResponseModel) => {
        console.log(data);

        if (this.receiptNo = data.content[0].RECEIPTNO) {
          window.alert("Receipt Saved");
        } else {
          this.snackbar.open(data.content[0]?.MSG, 'Dismiss', {
            duration: DurationUtil.TWO_SEC,
            horizontalPosition: "right",
            verticalPosition: "top",
            panelClass: ['mat-toolbar', 'mat-primary']
          });
        }
        this.isConfirmButtonDisable = false;
      },
      complete: () => {
        console.log("Cash Receipt Save success");
      },
      error: (error: HttpErrorResponse) => {
        this.isGetButtonDisable = false;
        window.alert("Cash Receipt Save was unsuccessful");

      },
    });
  }

  selectchequeDate(e: any) {
    this.chequeDate = e.value;
    let date = new Date(e.value);
    this.chequeDate = `${date.getDate()}/${date.toLocaleString('en-us', { month: 'short' })}/${date.getFullYear()}`;

    return this.chequeDate;
  }
}
