import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { RouteServicesService } from 'src/app/services/route-management/route-services';
import { UserAuthenticationService } from 'src/app/services/user-authentication/user-authentication.service';
import { DurationUtil } from 'src/app/utils/DurationUtil';
import { MapPreviewDialogComponent } from '../map-preview-dialog/map-preview-dialog.component';

@Component({
  selector: 'app-update-route-form',
  templateUrl: './update-route-form.component.html',
  styleUrls: ['./update-route-form.component.scss']
})
export class UpdateRouteFormComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource: MatTableDataSource<{
    code: string;
    name: string;
    lat: string;
    long: string;
    selected: boolean;
}> = null;

data: {
  code: string;
  name: string;
  lat: string;
  long: string;
  selected: boolean;
}[] = []

  name: "";

  constructor(private auth: UserAuthenticationService,public dialogRef: MatDialogRef<UpdateRouteFormComponent>, @Inject(MAT_DIALOG_DATA) public params: any, public dialog: MatDialog, private _snackBar: MatSnackBar, private service: RouteServicesService,) { }

  displayedColumns: string[] = ['Selected', 'Postal Code', 'Name', 'Latitude', 'Longtitude'];

  selectedList: Array<string> = [];

  tableData: any = RouteServicesService.GLOBAL.branch.postalCodes;

  branchName: string = RouteServicesService.GLOBAL.branch.id;

  user: string = RouteServicesService.GLOBAL.user;

  view: boolean = false;

  branch: string = null;


  ngOnInit(): void {

    this.user = this.auth.currentUserValue.code
    this.branch = this.auth.currentUserValue.branch
    this.name = this.params.name
    this.selectedList = this.params.coords.map((item:any)=> item.postal_code_)
    this.getPostalCodes();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getPostalCodes() {
    this.service.getPostalCodes(this.user, this.branch).subscribe({
      next: (value) => {
        console.log("Postal codes list" + value.content.length);
        value.content.forEach(element => {
          if(this.selectedList.includes(element.POSTAL_CODE)){
            this.data.unshift({
              code: element.POSTAL_CODE,
              name: element.TOWN,
              lat: element.LAT,
              long: element.LNG,
              selected: false
            })
          }
          else {          
            this.data.push({
            code: element.POSTAL_CODE,
            name: element.TOWN,
            lat: element.LAT,
            long: element.LNG,
            selected: false
          })}


          this.dataSource = new MatTableDataSource(this.data)
          // console.log(this.dataSource.data.length)
          this.dataSource.paginator = this.paginator;
        });
      }, 
      error: (err) => {
          console.log(err);
          this._snackBar.open("Postal codes failes to load", "Dismiss", {
            duration: DurationUtil.TWO_SEC,
            horizontalPosition: "right",
            verticalPosition: "top"
          });
      },
    })
  }

  onInputHandler(event: any) {
    this.name = event.target.value;
  }

  onChangeHandler(value: any, event: any): void {
    if (event.target.checked) {
      if (!this.selectedList.includes(value)) {
        this.selectedList.push(value);
      } else {
        return;
      }
    } else {
      if (this.selectedList.includes(value)) {
        let i = this.selectedList.indexOf(value);
        this.selectedList.splice(i, 1);
      } else {
        return;
      }
    }

    // this.selectedList.sort((a, b) => a - b);
  }

  onPreviewHandler() {
    let temp: any[] = [];
    this.selectedList.forEach(item => {
      temp.push({
        postal_code: this.tableData[item].code,
        name: this.tableData[item].name,
        lat: this.tableData[item].lat,
        lng: this.tableData[item].long
      });
    });

    console.log(temp);

    const dialogRef = this.dialog.open(MapPreviewDialogComponent, {
      data: temp
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  onSubmitHandler(): void {
    if (this.name == "") {
      this._snackBar.open("Please fill name", "Dismiss", {
        duration: DurationUtil.TWO_SEC,
        horizontalPosition: "right",
        verticalPosition: "top"
      });
      return;
    }

    if (this.selectedList.length < 1) {
      this._snackBar.open("Please Select One or more postal codes", "Dismiss", {
        duration: DurationUtil.TWO_SEC,
        horizontalPosition: "right",
        verticalPosition: "top"
      });
      return;
    }

    let postalCodeList: any[] = [];

    this.selectedList.forEach(i => {
      postalCodeList.push({
        postal_code: this.tableData[i].code,
        name_: this.tableData[i].name,
        lat: this.tableData[i].lat,
        lng: this.tableData[i].long,
        "date": "10/10/2022"
      })
    })

    let payload = {
      routeid: this.params.route,
      // user_id: "testuser0001",
      "user_id": "CIG00000679",
      branch_id: "01",
      name: this.name,
      date: "10/10/2022",
      postal_codesup: postalCodeList
    }

    this.service.updateRoute(payload).subscribe(
      value=> {
        if (value == "SUCCESSFULLY UPDATED") {
          this._snackBar.open("Route updated", "Dismiss", {
            duration: DurationUtil.TWO_SEC,
            horizontalPosition: "right",
            verticalPosition: "top"
          });
          this.onCancelHandler();
        } else {
          this._snackBar.open("Route update failed", "Dismiss", {
            duration: DurationUtil.TWO_SEC,
            horizontalPosition: "right",
            verticalPosition: "top"
          });
        }
        
      },
      err => {
        if (err.error.text == "SUCCESSFULLY UPDATED") {
          this._snackBar.open("Route updated", "Dismiss", {
            duration: DurationUtil.TWO_SEC,
            horizontalPosition: "right",
            verticalPosition: "top"
          });
        } else {
          console.log(err.error);
        alert("Connection issue");
        }
        this.onCancelHandler();
      }
    );
  }

  onCancelHandler(): void {
    this.dialogRef.close("exit");
  }



}
