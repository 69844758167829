import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { SettlementCalculationComponent } from "../settlement-calculation/settlement-calculation.component";
import { CallDeskUpdateRequestModel } from 'src/app/models/call-desk/call-desk-update-request-model';
import { TodaysCallDeskService } from 'src/app/services/call-desk/call-desk.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotePadEntryViewComponent } from '../note-pad-entry-view/note-pad-entry-view.component';
import { ClientDetailDialogComponent } from "../client-detail-dialog/client-detail-dialog.component";
import { FacilityDetailDialogComponent } from "../facility-detail-dialog/facility-detail-dialog.component";
import { SecurityDetailDialogComponent } from "../security-detail-dialog/security-detail-dialog.component";
import { FollowupDetailDialogComponent } from "../followup-detail-dialog/followup-detail-dialog.component";
import { ClientDetailResponseModel } from "../../models/call-desk/ClientDetailResponseModel";
import { FacilityDetailsResponseModel } from "../../models/call-desk/FacilityDetailsResponseModel";
import { SecurityDetailsResponseModel } from "../../models/call-desk/security-details-response-model";
import { FlaggingdetailsResponseModel } from 'src/app/models/call-desk/flaggingdetails-response-model';
import { ClientDetailSnapDialogComponent } from "../client-detail-snap-dialog/client-detail-snap-dialog.component";
import { ClientLocationViewDialogComponent } from "../client-location-view-dialog/client-location-view-dialog.component";
import { MapsAPILoader } from "@agm/core";
import { HttpErrorResponse } from "@angular/common/http";
import { DateUtil } from 'src/app/utils/DateUtil';
import { DurationUtil } from 'src/app/utils/DurationUtil';
import { PdChequeDialogComponent } from '../pd-cheque-dialog/pd-cheque-dialog.component';
import { ReceiptDialogComponent } from '../receipt-dialog/receipt-dialog.component';
import { VisitUpdateRequestModel } from 'src/app/models/visit-desk/VisitUpdateRequestModel';
import { VisitDeskService } from 'src/app/services/visit-desk.service';
import { VisitSelectionDeskService } from "../../services/visit-selection-desk-service";
import { UserAuthenticationService } from "../../services/user-authentication/user-authentication.service";
import { RelatedContractsComponent } from '../related-contracts/related-contracts.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { PopUpContactDetailsComponent } from 'src/app/pop-up-contact-details/pop-up-contact-details.component';
import { WhitespacePipe } from 'src/app/pipes/whitespace.pipe';
import { CashHandoverService } from 'src/app/services/cash-handover/cash-handover.service';
import { CashHandoverComponent } from '../v2/cash-handover/cash-handover.component';
import { DetailViewResponseModel } from 'src/app/models/call-desk/detail-view-info-model';
import { CallListContract } from 'src/app/models/call-desk/todays-call-list-response';

import { PopUpOpenNotepadComponent } from '../pop-up-open-notepad/pop-up-open-notepad.component';
import { LongTextEditor } from 'angular-slickgrid';
import { FieldOfficerService } from 'src/app/services/field-officer/field-officer-service';


//bilesh

@Component({
  selector: 'app-manual-call-dialog',
  templateUrl: './manual-call-dialog.component.html',
  styleUrls: ['./manual-call-dialog.component.scss']
})
export class ManualCallDialogComponent implements OnInit {
  promiseDateProcess: string;

  contractId: string = undefined


  // userForm = new FormGroup({
  //   callStatus: new FormControl(100, Validators.required),
  //   originType: new FormControl(100, Validators.required),
  //   promisedDate: new FormControl(100, Validators.required),
  //   promisedAmount: new FormControl(100, Validators.required),
  //   likelyHood: new FormControl(100, Validators.required),
  //   diarisedDate: new FormControl(100, Validators.required),
  //   nextFollowUpType: new FormControl(100, Validators.required),
  //   comment: new FormControl(100, Validators.required)
  // });

  // onFormSubmit(): void {
  //   console.log('Call Status:' + this.userForm.get('callStatus').value);
  //   console.log('Origin Types:' + this.userForm.get('originType').value);
  //   console.log('Promise Date:' + this.userForm.get('promisedDate').value);
  //   console.log('Promised Amount:' + this.userForm.get('promisedAmount').value);
  //   console.log('Likelihood:' + this.userForm.get('likelyHood').value);
  //   console.log('Diarized Date:' + this.userForm.get('diarisedDate').value);
  //   console.log('Follow-up Type:' + this.userForm.get('nextFollowUpType').value);
  //   console.log('Comment:' + this.userForm.get('comment').value);
  // }

  // numberOnly(event: { which: any; keyCode: any; })  {
  //   const charCode = (event.which) ? event.which : event.keyCode;
  //   // if (charCode > 31 && (charCode < 48 || charCode > 57)) {
  //   //   return false;
  //   var t = event.which.value;
  //   event.which.value =
  //   t.indexof('.') >= 0
  //   ? t.substr(0, t.indexof(".")) + t.substr(t.indexof("."), 3)
  //    : t;t.indexof('.') >= 0
  //       ? t.substr(0, t.indexof(".")) + t.substr(t.indexof("."), 3)
  //        : t;
  //  // }
  //   return;
  // }
  // validate(event: { target: any; keyCode: any; }):boolean {
  //   var t = event.target.value;
  //   event.target.value =
  //   t.indexof('.') >= 0
  //   ? t.substr(0, t.indexof(".")) + t.substr(t.indexof("."), 3)
  //    : t;t.indexof('.') >= 0
  //       ? t.substr(0, t.indexof(".")) + t.substr(t.indexof("."), 3)
  //        : t;

  //   return true;
  // }

  readonly title = "Contract Details"


  callStatus = '';
  callResponse = '';

  visitStatus = '';
  visitResponse = ''

  originType = '';
  likelyHood = '';


  nextFollowUpType = '';
  selectedContact = '';
  followUpDate: Date = null;
  directionNature = '';
  promisedAmount = '';
  promisedDate: any;
  comment = '';
  flag = '';
  contractNo = '';
  cType = '';
  followUpDatenew = ''


  otherFlag = '';
  clientLat = '';
  clientLong = '';
  user = '';
  diarisedDate: any;
  selectedCallDeskTransaction: any;
  selectedClientDetail: ClientDetailResponseModel;
  selectedFacilityDetail: FacilityDetailsResponseModel;
  selectedFlaggingDetail: FlaggingdetailsResponseModel;
  selectedSecurityDetail: SecurityDetailsResponseModel[];

  assignSerial: string = null
  contactNumber: string = null;
  customerName: string = null;
  secondaryContactNumber: string = null;
  guaranterContactNo: string = null;
  threepContactNo: string = null;

  // formatting variables
  dueDateFormatted: string = "";
  lastPayDateFormatted: string = "";
  minDate: Date = null;

  // date limitations

  latestLatitude: number = 0;
  latestLongitude: number = 0;
  private geoCoder: any;
  zoom: number = 0;
  dialogType: string = "";

  // snap view variables
  snapData: {
    RentalArrears: number,
    arrears: number,
    DPD: null,
    direction_nature: null;
    VehicleNo: string,
    lastPaidDate: Date,
    lastPaidAmount: number,
    minToReverseNPL: number,
    clientLocationLat: number,
    clientLocationLng: number,
    flag: string,
    trigeringNPL: null,
    cType: string,
    fullname: string,
    eom_dpd: string
  } = {
      RentalArrears: null,
      arrears: null,
      DPD: null,
      direction_nature: null,
      VehicleNo: null,
      lastPaidDate: null,
      lastPaidAmount: null,
      minToReverseNPL: null,
      clientLocationLat: null,
      clientLocationLng: null,
      flag: null,
      trigeringNPL: null,
      cType: null,
      fullname: null,
      eom_dpd: null
    }

  originTypeList: any[] = null

  ceft: string = null
  mode: string = null
  GUARNTRCONTACTS: string = null
  RESIDENCE1: string = null
  RESIDENCE2: string = null
  MOBILE2: string = null
  enableSave: boolean = false

  notePadEntry: boolean = false
  notePadEntryMode: string = "CALL"
  notePadEntryVisible: boolean = false

  formControl: {
    callStatus: string,
    visitStatus: string,
    originType: string,
    clientResponse: string,
    promisedDate: Date,
    promisedAmount: string,
    likelyhood: string,
    followUpDate: string,
    followUpType: string,
    comment: string
  } = {
      callStatus: null,
      visitStatus: null,
      originType: null,
      clientResponse: null,
      promisedDate: null,
      promisedAmount: null,
      likelyhood: null,
      followUpDate: null,
      followUpType: null,
      comment: null
    }

  sections = {
    callStatus: {
      view: true,
      disabled: false
    },
    visitStats: {
      view: false,
      disabled: true
    },
    promisedDetailsDisabled: false,
    followUpDisabled: false
  }

  isCompatible: boolean = false;


  constructor(private CashHService: CashHandoverService, private dialogRef: MatDialogRef<ManualCallDialogComponent>, public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any,
    private service: TodaysCallDeskService, private _snackBar: MatSnackBar, private mapsAPILoader: MapsAPILoader, private visitDeskService: VisitDeskService,
    protected visitSelectionDeskService: VisitSelectionDeskService, private auth: UserAuthenticationService, private whitespace: WhitespacePipe,     private fieldOfficerService: FieldOfficerService
) {
    this.selectedCallDeskTransaction = data.selectedCallDeskTransaction;
    this.dialogType = data.dialogType;

    this.mode = data.mode;

    this.contractNo = this.selectedCallDeskTransaction.contractNo;
    this.cType = this.selectedCallDeskTransaction.cType;
    this.minDate = new Date();
    console.log("check value test: " + this.cType)
  }

  ngOnInit(): void {
    this.setVisibility();

    if(this.dialogType == 'CALL'){
      this.notePadEntryVisible = true
    }

    if ((this.dialogType == 'CALL' && this.mode == "BALANCE") || this.dialogType == 'VISIT') {
      this.enableSave = true
    }
    if (this.dialogType == 'CALL') {
      this.notePadEntryMode = 'CALL';
    }
    else {
      this.notePadEntryMode = 'VISIT';
    }
    this.mapsAPILoader.load().then(() => {
      this.getCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;
    });

    this.getSingleContractData();
    this.getSnapViewData();
    this.getOriginTypes();
    //2023.07.26-ADD clear the refresh promise data methods: DulsaraMannakkara
    this.clearBaseOnCallStatus(event);
    this.clearBaseOnCallResponse(event);
    this.clearBaseOnVisitStatus(event);
    this.clearBaseOnVisitResponse(event);
  }
  //2023.07.21-Clear the promise data in get method. --DulsaraMannakkara
  getSingleContractData() {
    this.service.getContractDetailsById(this.contractNo, this.cType).subscribe({

      next: value => {
        // console.debug(value)
        // console.log("Value:", value);

        console.log("Contract data loaded in manual-call")
        let data = value.content
        if (data.length > 0) {
          let record = data[0]

          this.ceft = record.CEFTNO
          this.assignSerial = record.ASSIGN_SERIAL
          this.contactNumber = record.CONTACTNO
          this.GUARNTRCONTACTS = record.GUARNTRCONTACTS
          this.RESIDENCE1 = record.RESIDENCE1
          this.RESIDENCE2 = record.RESIDENCE2

          this.MOBILE2 = record.MOBILE2
          this.customerName = record.CUSTOMERNAME

          this.visitStatus = record.CLIENT_RESPONSE;
          this.callStatus = record.CALL_STATUS;
          this.nextFollowUpType = record.TYPE_ == "NA" ? null : record.TYPE_;
          this.followUpDate = new Date(record.FOLLWOUP_DATE);
          let promiseAmount = this.whitespace.transform(record.PROMISED_AMT);
          // this.promisedAmount = promiseAmount == "---" ? "" : promiseAmount;
          this.promisedAmount = '';
          // this.promisedDate = new Date(record.PROMISED_DTE);
          this.promisedDate = '';
          this.comment = record.COMMENT;
          this.flag = record.FLAG;
          // this.likelyHood = record.likelyhood;
          this.likelyHood = '';

          this.diarisedDate = new Date(record.diarized_date);
          this.clientLat = record.LNG;
          this.clientLong = record.LAT;
          this.user = record.USER;
          this.cType = record.cType;
          this.followUpDatenew = record.TYPE_;
          // this.originType = record.OR_TYPE == " " ? "00" : record.OR_TYPE
          this.originType;

          this._snackBar.open("Contract data loaded successfully", 'Dismiss', {
            duration: DurationUtil.TWO_SEC,
            horizontalPosition: "right",
            verticalPosition: "top",
            panelClass: ['mat-toolbar', 'mat-primary']
          });
          this.handlerClear()
        }
      },
      error: err => {
        this._snackBar.open("Data failed to load", 'Dismiss', {
          duration: DurationUtil.TWO_SEC,
          horizontalPosition: "right",
          verticalPosition: "top",
          panelClass: ['mat-toolbar', 'mat-primary']
        });
      }
    })
  }

  setVisibility() {
    if (this.notePadEntry) {
      if (this.notePadEntryMode == "CALL") {
        this.sections = {
          callStatus: {
            view: true,
            disabled: false
          },
          visitStats: {
            view: false,
            disabled: true
          },
          promisedDetailsDisabled: false,
          followUpDisabled: false
        }
      } else {
        this.sections = {
          callStatus: {
            view: false,
            disabled: true
          },
          visitStats: {
            view: true,
            disabled: false
          },
          promisedDetailsDisabled: false,
          followUpDisabled: false
        }
      }

    } else {
      this.sections = {
        callStatus: {
          view: true,
          disabled: this.dialogType == 'CALL' && this.mode == "BALANCE" ? false : true
        },
        visitStats: {
          view: this.dialogType == "VISIT" ? true : false,
          disabled: this.dialogType == "VISIT" ? false : true
        },
        promisedDetailsDisabled: this.mode == "BALANCE" || this.dialogType == "VISIT" ? false : true,
        followUpDisabled: this.mode == "BALANCE" || this.dialogType == "VISIT" ? false : true
      }
    }
  }

//2023.07.26-ADD clear the refresh promise data: DulsaraMannakkara
  clearBaseOnCallStatus(event : any){
  //alert("you are try to change call status");
    this.clearOtherInputFieldsCallStatus();
  }

  clearOtherInputFieldsCallStatus() {
    this.promisedAmount = '';
    this.promisedDate = '';
    this.likelyHood = '';
    this.callResponse = '';
    this.originType = '00';
  }
  clearBaseOnCallResponse(event : any){
    //alert("you are try to change call Response");
    this.clearOtherInputFieldsCallResponse();

  }
  clearOtherInputFieldsCallResponse() {
    this.promisedAmount = '';
    this.promisedDate = '';
    this.originType = '00';
    this.likelyHood = '';

  }

  clearBaseOnVisitStatus(event: any){
   // alert("you are try to change Visit status");
    this.clearOtherInputFieldsVisitStatus();

  }
  clearOtherInputFieldsVisitStatus() {
    this.visitStatus = '';
    this.promisedAmount = '';
    this.promisedDate = '';
    this.originType = '00';
    this.likelyHood = '';

  }
  clearBaseOnVisitResponse(event: any){
    //alert("you are try to change Visit Response");
    this.clearOtherInputFieldsVisitResponse();

  }
  clearOtherInputFieldsVisitResponse() {
    this.promisedAmount = '';
    this.promisedDate = '';
    this.originType = '00';
    this.likelyHood = '';

  }
  //end:2023.07.26-ADD clear the refresh promise data: DulsaraMannakkara

  changeNotePadEntry(event: any) {
    this.setVisibility()
    if (this.notePadEntry) {
      this.isCompatible = true
    } else {
      this.isCompatible = false
    }
  }

  isLoading: boolean = false;
  getSnapViewData() {
    //loadingView bool - KalanaRathnayake
    this.isLoading = true;

    this.service.getDetailViewSnapData(this.contractNo, this.cType).subscribe({
      next: (value) => {
        if (value && value.length > 0) {
          this.snapData = {
            RentalArrears: value[0].RentalArrears,
            arrears: value[0].arrears,
            DPD: value[0].DPD,
            direction_nature: value[0].direction_nature,
            VehicleNo: value[0].VehicleNo,
            lastPaidDate: new Date(value[0].lastPaidDate),
            lastPaidAmount: value[0].lastPaidAmount,
            minToReverseNPL: value[0].minToReverseNPL,
            clientLocationLat: parseFloat(value[0].clientLocationLat),
            clientLocationLng: parseFloat(value[0].clientLocationLng),
            flag: value[0].flag,
            trigeringNPL: value[0].trigeringNPL,
            cType: value[0].cType,
            fullname: value[0].fullname,
            eom_dpd: value[0].eom_dpd

          }

          // console.log(this.snapData);
        }
        //when data is loaded the loading view will turn off by bool value 0 - KalanaRathnayake
        //forProduction
        this.isLoading = false;

        // forDebugging
        // this.isLoading = true;
      },
      error: (err) => {
        alert("Error occured while retrieving data")
        console.log(err);
        //loading will keep going when err trigger if you want to change make it false - KalanaRathnayake
        this.isLoading = true;
      }
    })
  }
  sendContactDetails() {

    this.dialog.open(PopUpContactDetailsComponent, {
      data: { primary: this.contactNumber, secondary: this.MOBILE2, thirdParty: this.RESIDENCE1,resident2: this.RESIDENCE2, gaurante: this.GUARNTRCONTACTS }
    });
  }
  sendCEFT() {
    if (this.contactNumber) {
      this.service.sendCEFTSms({
        contractno: this.contractNo,
        contactno: this.contractNo
      }).subscribe({
        next: (value) => {
          if (Array.isArray(value) && value.length > 0 && value[0]?.content == "SUCCESSFULLYSEND") {
            this._snackBar.open('CEFT message sent to the contact number', 'Dismiss', {
              duration: DurationUtil.TWO_SEC,
              horizontalPosition: "right",
              verticalPosition: "top",
              panelClass: ['mat-toolbar', 'mat-primary']
            });
          } else {
            this._snackBar.open('CEFT message was failed to send', 'Dismiss', {
              duration: DurationUtil.TWO_SEC,
              horizontalPosition: "right",
              verticalPosition: "top",
            });
          }
        },
        error: (err) => {
          alert("Error occured while connecting to server")
        }
      })
    } else {
      this._snackBar.open('Contact number is not available', 'Dismiss', {
        duration: DurationUtil.TWO_SEC,
        horizontalPosition: "right",
        verticalPosition: "top",
      });
    }
  }

  setContactNumbers(value: any): any {
    if (value == null || value == "NA" || value == undefined || value == " ") {
      return null;
    } else {
      return value;
    }
  }

  getOriginTypes() {
    this.service.getOriginTypes().subscribe({
      next: value => {
        if (value?.length > 0) {
          this.originTypeList = value

        } else {
          this.originTypeList = []
        }
      },
      error: err => { }
    })
  }

  onCallStatusChange(value: any) {
    this.callStatus = value;

  }

  onVisitStatusChange(value: any) {
    this.visitStatus = value;
  }

  onOriginStatusChange(value: any) {
    this.originType = value;
  }

  onNextFollowUpChange(value: any) {
    this.nextFollowUpType = value;
  }

  onLikelyHoodChange(value: any) {
    this.likelyHood = value;
  }

  closeDialog(): any {
    this.dialogRef.close();
  }

  processMonth(date: any): string {
    let temp = date.getMonth() + 1;
    if (temp < 10) {
      return "0" + temp;
    } else {
      return temp;
    }
  }

  updateVisitStatus() {
    let tracklocation: boolean = window.confirm("Do you want to Track your location?");
    if (tracklocation) {
      this.trackerFunc();
    }
    let dateProcess = this.followUpDate ? DateUtil.getDateString(new Date(this.followUpDate)) : null;
    let promiseDateProcess = this.promisedDate ? DateUtil.getDateString(new Date(this.promisedDate)) : null;
    let diarizedDateProcess = this.diarisedDate ? DateUtil.getDateString(new Date(this.diarisedDate)) : null;


    //TODO visit update model
    let payload: VisitUpdateRequestModel = {
      CONTRACTNO: this.contractNo,
      ASSIGNSERIAL: this.assignSerial,
      TYPE: this.visitStatus == "VisitCancelled" ? "REMOVED" : this.nextFollowUpType,
      CLINET_RESPONSE: ((this.visitStatus == "metTheClient") || (this.visitStatus == "metTheGuarantor") || (this.visitStatus == "metTheThirdParty") || (this.visitStatus == "metOther")) ? this.visitResponse : "",
      FOLLWOUP_DATE: dateProcess,
      DIRECTION_NATURE: this.directionNature,
      FLAG: this.flag,
      VISIT_STATUS: this.visitStatus == "VisitCancelled" ? "REMOVED" : this.visitStatus,
      PROMISED_AMT: this.visitResponse == "promisedToPay" ? this.getNumberFiltered() : "",
      PROMISED_DATE: (this.visitResponse == "promisedToPay") ? (promiseDateProcess == "NaN-NaN-NaN" ? "" : promiseDateProcess) : "",
      //PROMISED_DATE: promiseDateProcess == "NaN-NaN-NaN" ? "" : promiseDateProcess,
      COMMENT: this.comment,
      // VISIT_FLAG: this.nextFollowUpType == "COMPLETED" ? "COMPLETED" : this.nextFollowUpType.includes("call") ? "COMPLETED" : "VISIT",
      // VISIT_FLAG: "COMPLETED",
      VISIT_FLAG: this.visitStatus == "VisitCancelled" ? "REMOVED" : "COMPLETED",
      ORIGINETYPE: this.originType,
      DIARIZED_DATE: dateProcess,
      LIKELYHOOD: this.likelyHood,
      // ADD_UserName_to PayLoad: 2023.07.25
      USER_NAME: this.auth.currentUserValue.username,

      SYSTEM: this.cType
    }

    this.visitDeskService.updateVisitStatus(payload).subscribe({
      next: (data: any) => {
        if (this.originTypeHandler()) {
          // window.alert("Visit data saved successfully");
          this._snackBar.open('Visit Data Saved Successfully', 'Dismiss', {
            duration: DurationUtil.FIVE_SEC,
            horizontalPosition: "right",
            verticalPosition: "top",
          });
          this.dialogRef.close({
            completed: true
          })
        }
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
        window.alert("An error occured while connecting to the server!");
      }
    });
  }

  updateCallStatus() {
    // function trigger check | DEV-USE
    // alert("UpdateCallStatus function trigged");

    if (this.callStatusHandler() && this.originTypeHandler()) {
      let dateProcess = this.followUpDate ? DateUtil.getDateString(new Date(this.followUpDate)) : null;
      let promiseDateProcess = this.promisedDate ? DateUtil.getDateString(new Date(this.promisedDate)) : null;
      let diarizedDateProcess = this.diarisedDate ? DateUtil.getDateString(new Date(this.diarisedDate)) : null;
      let payload: CallDeskUpdateRequestModel = {
        CONTRACTNO: this.contractNo,
        ASSIGNSERIAL: this.assignSerial,
        TYPE: this.nextFollowUpType,
        CALL_STATUS: this.callStatus,
        FOLLWOUP_DATE: dateProcess,
        DIRECTION_NATURE: this.directionNature,
        FLAG: "NA",
        CLINET_RESPONSE: this.callResponse,
        PROMISED_AMT: this.callResponse == "promisedToPay" ? this.getNumberFiltered() : "",
        //PROMISED_AMT: this.promisedAmount,
        PROMISED_DATE: (this.callResponse == "promisedToPay") ? (promiseDateProcess == "NaN-NaN-NaN" ? "" : promiseDateProcess) : "",
        COMMENT: this.comment,
        OTHER_FLAGS: this.otherFlag,
        CL_LAT: this.clientLat,
        CL_LNG: this.clientLong,
        CL_USER: this.user,
        LIKELYHOOD: this.likelyHood,
        DIARIZED_DATE: dateProcess,
        ORIGINETYPE: this.originType,
        //ADD_UserName_to PayLoad: 2023.07.25
        USER_NAME: this.auth.currentUserValue.username,


        SYSTEM: this.cType
      }

      this.service.updateCallStatus(payload).subscribe({
        next: (data) => {
          if (data.content[0]["msg"] == "SUCCESSFULLY ADDED") {
            // alert("Data saved successfully");
            this._snackBar.open('Data Saved Successfully', 'Dismiss', {
              duration: DurationUtil.FIVE_SEC,
              horizontalPosition: "right",
              verticalPosition: "top",
            });
            this.closeDialog();
          } else {
            // alert("Data saving was unsuccessful");
            this._snackBar.open('Data Saving Was Unsuccessful', 'Dismiss', {
              duration: DurationUtil.FIVE_SEC,
              horizontalPosition: "right",
              verticalPosition: "top",
            });
          }
        },
        error: (err) => {
          console.log(err);
          alert("An error occurred while connecting to the server!");
          this._snackBar.open('An error occurred while connecting to the server!', 'Dismiss', {
            duration: DurationUtil.FIVE_SEC,
            horizontalPosition: "right",
            verticalPosition: "top",
          });
        }
      });
    }
  }

  //this function will convert string numbers into comma less float type string value - kalanaRathnayake
  getNumberFiltered() {
    const amountWithCommas = this.promisedAmount; // input: "100,000.12"
    const cleanedAmount = amountWithCommas.replace(/,/g, "");
    const amountAsString = cleanedAmount.toString();

    // DEV-USE-Don'tUncomment
    // console.log(amountAsString);
    // Output: "100000.12"
    return amountAsString;
  }

  updateNotepadEntry() {
    // function trigger check | DEV-USE
    // alert("UpdateNotepadEntry function trigged");

    if (this.originTypeHandler()) {
      let dateProcess = DateUtil.getDateString(new Date(this.followUpDate)) || null;
      let promiseDateProcess = this.promisedDate ? DateUtil.getDateString(new Date(this.promisedDate)) : null;
      let diarizedDateProcess = this.diarisedDate ? DateUtil.getDateString(new Date(this.diarisedDate)) : null;

      let payload = {
        VISIT_STATUS: this.notePadEntryMode == "VISIT" ? this.visitStatus : null,
        CONTRACTNO: this.contractNo,
        ASSIGNSERIAL: this.assignSerial,
        TYPE: this.nextFollowUpType,
        CALL_STATUS: this.notePadEntryMode == "CALL" ? this.callStatus : null,
        FOLLWOUP_DATE: dateProcess ? dateProcess : "",
        DIRECTION_NATURE: this.directionNature,
        FLAG: "NA",
        CLINET_RESPONSE: this.notePadEntryMode == "CALL" ? this.callResponse : this.visitResponse,
        PROMISED_AMT: this.callResponse || this.visitResponse == "promisedToPay" ? this.getNumberFiltered() : "",
        //PROMISED_AMT: this.promisedAmount,
        PROMISED_DATE: promiseDateProcess == "NaN-NaN-NaN" ? "" : promiseDateProcess,
        COMMENT: this.comment,
        OTHER_FLAGS: this.otherFlag,
        CL_LAT: this.clientLat,
        CL_LNG: this.clientLong,
        CL_USER: this.user,
        LIKELYHOOD: this.likelyHood,
        DIARIZED_DATE: dateProcess ? dateProcess : "",
        ORIGINETYPE: this.originType,
        DTYPE: this.nextFollowUpType == "COMPLETED" ? "COMPLETED" : this.notePadEntryMode,
        SYSTEM: this.cType,
        //ADD_UserName_to PayLoad: 2023.07.25
        USER_NAME: this.auth.currentUserValue.username
      }
      console.log(JSON.stringify(payload))
      this.service.updateNotePadEntry(payload).subscribe({
        next: (value => {
          // alert("Data updated successfully!");
          this._snackBar.open('Data Updated Successfully!', 'Dismiss', {
            duration: DurationUtil.FIVE_SEC,
            horizontalPosition: "right",
            verticalPosition: "top",
          });

          this.closeDialog();
        }),
        error: (err => {
          // alert("An error occurred while updating the record");
          this._snackBar.open("An Error Occurred While Updating The Record", 'Dismiss', {
            duration: DurationUtil.FIVE_SEC,
            horizontalPosition: "right",
            verticalPosition: "top",
          });

        })
      })
    }
  }

  handlerClear() {
    if (this.nextFollowUpType != null) {
      this.nextFollowUpType = '';
      this.followUpDate = null
      this.comment = "";
    }
  }

  submitHandler() {
    if (this.dialogType == "VISIT") {
      this.updateVisitStatus();
    } else {
      this.updateCallStatus();
    }
  }

  selectFollowupDate(e: any) {
    this.followUpDate = e.value;
  }

  selectDiarizedDate(e: any) {
    this.diarisedDate = e.value;
  }

  settlementCalculation() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '100vh';
    dialogConfig.width = '100vw';
    dialogConfig.maxWidth = '100vw';
    dialogConfig.data = {
      contractNo: this.selectedCallDeskTransaction.contractNo,
      cType: this.selectedCallDeskTransaction.cType
    }
    const dialogRef = this.dialog.open(SettlementCalculationComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        console.log(`Settlement cal opened`);
      }
    );
  }

  openClientDetails() {
    this.service.getClientDetail(this.selectedCallDeskTransaction.contractNo, this.selectedCallDeskTransaction.cType).subscribe({
      next: (data) => {
        this.selectedClientDetail = data;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.closeOnNavigation = true;
        dialogConfig.autoFocus = true;
        dialogConfig.height = '100vh';
        dialogConfig.width = '100vw';
        dialogConfig.maxWidth = '100vw';
        dialogConfig.data = {
          selectedClientDetail: this.selectedClientDetail
        }
        const dialogRef = this.dialog.open(ClientDetailDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
          data => {
            // console.log(``);
          }
        );
      },
      error: (err) => {
        console.log(err);
        alert("An error occured while connecting to the server!");
      }
    });
  }

  openFacilityDetail() {
    this.service.getFacilityDetail(this.contractNo, this.cType).subscribe({
      next: (data) => {
        this.selectedFacilityDetail = data;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.closeOnNavigation = true;
        dialogConfig.autoFocus = true;
        dialogConfig.height = '100vh';
        dialogConfig.width = '100vw';
        dialogConfig.maxWidth = '100vw';
        dialogConfig.data = {
          selectedFacilityDetail: this.selectedFacilityDetail
        }
        const dialogRef = this.dialog.open(FacilityDetailDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
          data => {
            console.log(`Settlement cal opened`);
          }
        );
      },
      error: (err) => {
        console.log(err);
        alert("An error occured while connecting to the server!");
      }
    });
  }

  openRelatedContract() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '100vh';
    dialogConfig.width = '100vw';
    dialogConfig.maxWidth = '100vw';
    dialogConfig.position = {
      'top': '0',
      right: '0'
    };
    dialogConfig.data = {
      contractNo: this.selectedCallDeskTransaction.contractNo
    }
    const dialogRef = this.dialog.open(ClientDetailSnapDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        console.log(`Settlement cal opened`);
      }
    );
  }

  openSecurityDetail() {
    this.service.getSecurityDetail(this.contractNo, this.cType).subscribe({
      next: (data) => {
        this.selectedSecurityDetail = data;
        if (this.selectedSecurityDetail.length > 0) {
          console.log(JSON.stringify(this.selectedSecurityDetail));
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.closeOnNavigation = true;
          dialogConfig.autoFocus = true;
          dialogConfig.height = '100vh';
          dialogConfig.width = '100vw';
          dialogConfig.maxWidth = '100vw';
          dialogConfig.data = {
            selectedSecurityDetail: this.selectedSecurityDetail
          }
          const dialogRef = this.dialog.open(SecurityDetailDialogComponent, dialogConfig);
          dialogRef.afterClosed().subscribe(
            data => {
              console.log(`Settlement cal opened`);
            }
          );
        } else {
          alert("Security Data Not Available!");
        }
      },
      error: (err) => {
        console.log(err);
        alert("An error occured while connecting to the server!");
      }
    });
  }

  openFollowUpDetail() {
    let payload: any = {
      contractno: this.selectedCallDeskTransaction.contractNo,
      cType: this.selectedCallDeskTransaction.cType
    }

    console.log(JSON.stringify(payload));

    // this.service.getFlaggingDetail(payload).subscribe({
    //   next: (data) => {
    //     this.selectedFlaggingDetail = data;
    //     // if (data.content[0][":B1"] == "SUCCESSFULLY ADDED") {
    //     //   alert("Data saved successfully");
    //     //   this.closeDialog();
    //     // } else {
    //     //   alert("Data saving was unsuccessful");
    //     // }
    //   },
    //   error: (err) => {
    //     console.log(err);
    //     alert("An error occured while connecting to the server!");
    //   }
    // });

    this.service.getFlaggingDetail(this.selectedCallDeskTransaction.contractNo, this.cType).subscribe({
      next: (data) => {
        this.selectedFlaggingDetail = data;
        console.log("selectedFacilityDetail" + JSON.stringify(this.selectedSecurityDetail));
        // if (this.selectedFlaggingDetail && Array.isArray(data) && (data.length > 0)) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.closeOnNavigation = true;
        dialogConfig.autoFocus = true;
        dialogConfig.height = '100vh';
        dialogConfig.width = '100vw';
        dialogConfig.maxWidth = '100vw';
        dialogConfig.data = {
          selectedFlaggingDetail: this.selectedFlaggingDetail,
          contractNo: this.selectedCallDeskTransaction.contractNo,
          cType: this.selectedCallDeskTransaction.cType
        }
        const dialogRef = this.dialog.open(FollowupDetailDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
          data => {
            console.log(`Settlement cal opened`);
          }
        );
        // } else {
        //
        //   alert("No Followup data available!");
        // }
      },
      error: (err) => {
        console.log(err);
        alert("An error occured while connecting to the server!");
      }
    });
  }
 callTheNumber() {
    if (!this.contactNumber) {
      this._snackBar.open('Select a contact number', 'Dismiss', {
        duration: DurationUtil.TWO_SEC,
        horizontalPosition: "right",
        verticalPosition: "top",
        panelClass: ['mat-toolbar', 'mat-primary']
      });
    } else {
      window.open(`tel:${this.contactNumber}`);
    }
  }

  openNotePadEntry() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '100vh';
    dialogConfig.width = '100vw';
    dialogConfig.maxWidth = '100vw';
    dialogConfig.data = {
      contractNo: this.selectedCallDeskTransaction.contractNo
    }
    const dialogRef = this.dialog.open(NotePadEntryViewComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        console.log(`NP entry opened`);
      }
    );
  }

  openInquiry() {
    window.open('https://www.cfmobile.lk/crmesb/ESBInfo/CRMCallCenter/CRMCallCenter?OPPORTUNITYID=300000053159026', '_blank')
  }

  // validationHandler() {
  //   // if (this.callStatus != "NA" && this.clientResonseHandler() && this.followupHendler()) {
  //   if (this.callStatus != "NA" && this.clientResonseHandler()) {
  //     return true;
  //   } else {
  //     if (this.callStatus == "NA") {
  //       this._snackBar.open('Please select call status', 'Dismiss', {
  //         duration: DurationUtil.TWO_SEC,
  //         horizontalPosition: "right",
  //         verticalPosition: "top",
  //         panelClass: ['mat-toolbar', 'mat-primary']
  //       });
  //     }
  //     return false;
  //   }
  // }

  // handles the followUpType validation in updateVisitStatus and updateCallStatus feature
  // followUpHandler() {
  //   if (this.nextFollowUpType.length < 1 || this.nextFollowUpType == '') {
  //     this._snackBar.open('Please Fill VALID Next Follow Up Type', 'Dismiss', {
  //       duration: DurationUtil.SEVEN_SEC,
  //       horizontalPosition: "right",
  //       verticalPosition: "top",
  //       panelClass: ['mat-toolbar', 'mat-primary']
  //     });
  //     return false;

  //   } else {
  //     return true;
  //   }
  // }

  callStatusHandler() {
    if (this.callStatus == "NA") {
      this._snackBar.open('Please select call status', 'Dismiss', {
        duration: DurationUtil.TWO_SEC,
        horizontalPosition: "right",
        verticalPosition: "top",
        panelClass: ['mat-toolbar', 'mat-primary']
      })
      return false;
    } else {
      return true;
    }
  }

  //done
  originTypeHandler() {
    if (this.originType == '') {
      this._snackBar.open('Please Select a VALID Origin Type', 'Dismiss', {
        duration: DurationUtil.SEVEN_SEC,
        horizontalPosition: "right",
        verticalPosition: "top",
        panelClass: ['mat-toolbar', 'mat-primary']
      });
      return false;
    } else {
      return true;
    }
  }

  clientResonseHandler() {
    if (this.callStatus == "answered" && this.visitStatus == "promisedToPay") {
      if ((this.getNumberFiltered() == null || this.getNumberFiltered() == "NA" || this.getNumberFiltered().length < 1) || (this.promisedDate == null || this.promisedDate == "NA" || this.promisedDate.length < 1)) {
        this._snackBar.open('Please fill client response first', 'Dismiss', {
          duration: DurationUtil.TWO_SEC,
          horizontalPosition: "right",
          verticalPosition: "top",
          panelClass: ['mat-toolbar', 'mat-primary']
        });
        return false;
      } else {
        return true;
      }
    } else {
      if (this.callStatus == "answered" && this.visitStatus == "NA") {
        this._snackBar.open('Please fill client response first', 'Dismiss', {
          duration: DurationUtil.TWO_SEC,
          horizontalPosition: "right",
          verticalPosition: "top",
          panelClass: ['mat-toolbar', 'mat-primary']
        });
      }
      // this.promisedAmount = null;
      // this.promisedDate = null;
      return true;
    }
  }


  followupHendler() {
    const isInstanceOfDate = this.followUpDate instanceof Date
    const isANumber = !isNaN(this.followUpDate.valueOf())
    if (!(isInstanceOfDate && isANumber) || this.nextFollowUpType == "NA") {
      this._snackBar.open('Please fill Next follow up details', 'Dismiss', {
        duration: DurationUtil.TWO_SEC,
        horizontalPosition: "right",
        verticalPosition: "top",
        panelClass: ['mat-toolbar', 'mat-primary']
      });
      return false;
    } else {
      return true;
    }
  }

  private getCurrentLocation(callback?: (lat: number, lng: number) => void) {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        callback(position.coords.latitude, position.coords.longitude)
        console.log("GEO LOCATION");
      });
    }
  }

  pinTheLocation() {
    this.getCurrentLocation((lat: number, lng: number) => {
      console.debug("PingLocationTriggered")
      let payload: any = {
        CONTRACTNO: this.selectedCallDeskTransaction.contractNo,
        LAT: lat.toString(),
        LNG: lng.toString(),
        USER: this.auth.currentUserValue.username,
        SYSTEM: this.cType
      };
      this.service.pinClientLocation(payload).subscribe({
        next: (data) => {
          window.alert("New Client location Successfully Updated");
          console.debug("current client location :",lat, lng)
        },
        complete: () => {
          console.log("SAVE SUCCESS CLIENT LOCATION");
        },
        error: (error: HttpErrorResponse) => {
          window.alert("Error");
        },
      });
    });


    // console.log((payload));

  }

 // TODO  OPENLOCATIONMAP
  openLocationMap() {
    // alert(this.selectedCallDeskTransaction.contractNo)
    this.getCurrentLocation(async (lat, lng) => {
      const response = await this.fieldOfficerService.getClientPingLocation(
        this.contractNo
      );

      if (
        response &&
        response.length > 0 &&
        response[0].lat &&
        response[0].lng
      ) {
        const location = response[0];
        const destination = { lat: location.lat, lng: location.lng };
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.closeOnNavigation = true;
        dialogConfig.autoFocus = true;
        dialogConfig.height = '100vh';
        dialogConfig.width = '100vw';
        dialogConfig.maxWidth = '100vw';
        dialogConfig.data = {
          selectedCallDeskContract: {
            contractNo: this.selectedCallDeskTransaction.contractNo,
          },
          startPosition: { lat: lat, lng: lng },
          endPosition: destination
        };
        const dialogRef = this.dialog.open(
          ClientLocationViewDialogComponent,
          dialogConfig
        );
        dialogRef.afterClosed().subscribe((data) => {
          console.log(`Contract details opened`);
        });
      }
    });
  }
  openReceipt() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '100vh';
    dialogConfig.width = '100vw';
    dialogConfig.maxWidth = '100vw';
    dialogConfig.data = { selectedOptimizeVisitTransaction: this.selectedCallDeskTransaction };

    const user_name = this.auth.currentUserValue.username


    this.CashHService.GetCashHandOverDet(user_name).subscribe(result => {
      if (result[0].res !== "00") {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.closeOnNavigation = true;
        dialogConfig.autoFocus = true;
        dialogConfig.height = '100vh';
        dialogConfig.width = '99.5vw';
        dialogConfig.maxWidth = '99.5vw';
        dialogConfig.data = {
          selectedOptimizeVisitTransaction: this.selectedCallDeskTransaction

        }
        const dialogRef = this.dialog.open(ReceiptDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(data => {

        })
      } else {
        this._snackBar.open(result[0]?.msg, 'Dismiss', {
          duration: DurationUtil.TWO_SEC,
          horizontalPosition: "right",
          verticalPosition: "top",
          panelClass: ['mat-toolbar', 'mat-primary']
        });
      }
    })

  }


  openPDCheque() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = false;
    dialogConfig.height = '100vh';
    dialogConfig.width = '100vw';
    dialogConfig.maxWidth = '100vw';
    dialogConfig.data = { selectedOptimizeVisitTransaction: this.selectedCallDeskTransaction };
    const dialogRef = this.dialog.open(PdChequeDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        console.log(`Pd cheque dialog opened`);
      }
    );
  }

  openRelatedContracts() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '100vh';
    dialogConfig.width = '100vw';
    dialogConfig.maxWidth = '100vw';

    let dialogRef = this.dialog.open(RelatedContractsComponent, {
      ...dialogConfig,
      data: {
        contractNo: this.contractNo,
        cType: this.cType
      }
    })

    dialogRef.afterClosed().subscribe({
      next: data => {
        console.log("Related contracts closed")
      }
    })
  }

  trackerFunc() {

    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        // callback(position.coords.latitude, position.coords.longitude)
        let date = new Date;
        let currentDate = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
        let payload: any = {
          userid: this.auth.currentUserValue.code,
          date: currentDate,
          routeid: "265",
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };

        console.log(JSON.stringify(payload));
        this.visitSelectionDeskService.trackMe(payload).subscribe({
          next: (data) => {
            window.alert("Tracking Data saved successfully");
          },
          complete: () => {
            console.log("Save SUCCESS");
          },
          error: (error: HttpErrorResponse) => {
            window.alert("Error: Tracking data Saving error.");
          },
        });


      });

    }
  }

  getCurrLocation(callback: (lat: number, lng: number) => void) {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        callback(position.coords.latitude, position.coords.longitude)
      })
    }
  }

  get showPromiseAmount(): boolean {
    if (this.notePadEntryMode == "CALL") {

      return (this.callStatus == 'answered') && (this.callResponse == 'promisedToPay');

    }
    else {

      return ((this.visitStatus == "metTheClient") || (this.visitStatus == "metTheGuarantor") || (this.visitStatus == "metTheThirdParty") || (this.visitStatus == "metOther")) && (this.visitResponse == 'promisedToPay');

    }
  }
  // else {
  //   return (this.visitStatus == 'metTheClient') && (this.visitResponse == 'promisedToPay');

  // }
  // }


    // 2023.08.02 Notepad Entry poup button - Bilesh
    openNotePadEntry_popup() {

      const data = {
        contractNo: this.selectedCallDeskTransaction.contractNo,
        cType: this.selectedCallDeskTransaction.cType,
      };

      const dialogRef = this.dialog.open(PopUpOpenNotepadComponent, {
        data: data,
      });
    }

}
