import {Component , Inject , OnInit} from '@angular/core';
import {MAT_DIALOG_DATA , MatDialogRef} from "@angular/material/dialog";
import {ManualCallDialogComponent} from "../manual-call-dialog/manual-call-dialog.component";
import {ReceiptService} from "../../services/receipt/receipt.service";
import {HttpErrorResponse} from "@angular/common/http";
import {VisitDeskDataResponseModelInfo} from "../../models/visit-desk-data-response-model-info";
import { DateUtil } from 'src/app/utils/DateUtil';

@Component ( {
  selector: 'app-pd-cheque-dialog' ,
  templateUrl: './pd-cheque-dialog.component.html' ,
  styleUrls: [ './pd-cheque-dialog.component.scss' ]
} )
export class PdChequeDialogComponent implements OnInit {
  // contractNo = '0113648803';
  contractNo = '';
  chequeDate = '';
  depositDate = '';
  receiptDate = '';
  receiptNo = '';
  cashAmount = '';
  chequeAmount = '';
  chequeNo = '';
  chequeAC = '';
  isConfirmButtonDisable = true;
  isGetButtonDisable = false;
  bankName  = '' ;
  branchName = '';
  depositAccount = '';
  savingAccount = '';
  fullAmount = '';
  exclude = 'N';
  include = 'Y';
  selectedInsuranceOption = '';

  isGroupChequeSelected: boolean = false;
  pDChequePayload: any;
  debtCollectorCode = '';
  bookNo = '';
  tempRecNo = '';
  selectedOptimizeVisitTransaction : VisitDeskDataResponseModelInfo;

  constructor ( private receiptService: ReceiptService , private dialogRef: MatDialogRef<PdChequeDialogComponent> , @Inject ( MAT_DIALOG_DATA ) public data: any ) {
    this.selectedOptimizeVisitTransaction = data.selectedOptimizeVisitTransaction;
    this.contractNo = this.selectedOptimizeVisitTransaction.contractNo;
  }

  ngOnInit (): void {
  }

  numberOnly(event: { which: any; keyCode: any; }): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  selectchequeDate ( e: any ) {
    this.chequeDate = e.value;
    let date = new Date ( e.value );
    this.chequeDate = DateUtil.getDateString(date);

  }

  selectDepositDate ( e: any ) {
    this.depositDate = e.value;
    let date = new Date ( e.value );
    this.depositDate = DateUtil.getDateString(date);

  }

  selectReceiptDate ( e: any ) {
    let date = new Date ( e.value );
    this.receiptDate = DateUtil.getDateString(date);

  }


  closeDialog (): any {
    this.dialogRef.close ();
  }

  savePDCheque () {

    this.pDChequePayload = {

      "contractno":this.contractNo,
      "chqno":this.chequeNo,
      "chq_date":this.chequeDate,
      "dep_date": this.depositDate,
      "chq_amt": this.chequeAmount,
      "deb_bank":this.bankName,
      "db_accno":this.depositAccount,
      "brncode":this.branchName,
      "usrid":"",
      "ins":this.selectedInsuranceOption,
      "deb_col_code":this.debtCollectorCode,
      "book_det":this.bookNo,
      "man_rcpt": this.tempRecNo,
      "man_rcpt_date":this.receiptDate,
      "sacno":this.savingAccount,
      "full_amt":this.fullAmount,
      "man_rct_stat": this.receiptDate
    };

    console.log(JSON.stringify(this.pDChequePayload));
    this.receiptService.savePdCheque( this.pDChequePayload ).subscribe({
      next: (data : any) => {
        console.log("PD Cheque"+ data.content[0].STATUS );


        window.alert("PD Cheque Save success.");
        this.isConfirmButtonDisable = false;
      },
      complete: () => {
        console.log("PD Cheque Save success" );
      },
      error: (error: HttpErrorResponse) => {
        window.alert("PD Cheque Save was unsuccessful");
      },
    });
  }
}
