import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SettlementCalculatorService } from 'src/app/services/settlement-calculator/settlement-calculator.service';

@Component({
  selector: 'app-letter-view',
  templateUrl: './letter-view.component.html',
  styleUrls: ['./letter-view.component.scss']
})
export class LetterViewComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<LetterViewComponent>, @Inject(MAT_DIALOG_DATA) public params: any, private service: SettlementCalculatorService) { }
  
  imageBase64: string = null;
  imageUrl: string = null;
  
  ngOnInit(): void {
    this.imageUrl = this.params.image;
    console.log(this.imageUrl);
  }

  closeLetterViewDialog(): any {
    this.dialogRef.close();
  } 
}
