import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Config } from "../../config/config";
import { CallListContract } from '../models/call-desk/todays-call-list-response';
import { RestRequestService } from './rest-request.service';
import { ClientDetailResponseModel } from 'src/app/models/call-desk/ClientDetailResponseModel';


@Injectable({
  providedIn: 'root'
})
export class RelatedContractsService {
  private readonly relatedContJobURL: string;

  constructor(private restService: RestRequestService, @Inject("recoveryConfig") private recoveryConfig = Config.recoveryConfig) {

    this.relatedContJobURL = this.getRelatedContractsJobsUrl(recoveryConfig);
  }

  /**
   * Get CallDesk job service Url from CallDesk injectable object.
   * @param recoveryConfig
   * @returns callDeskUrl:string
   */
  private getRelatedContractsJobsUrl(recoveryConfig: any = {}): string {
    let jobURL = '';

    if (recoveryConfig.env === 'local') {
      jobURL = `${recoveryConfig.appServerUrl}`;
    } else {
      jobURL = `${recoveryConfig.recoveryServiceUrl}`;
    }
    return jobURL;
  }

  getRelatedContracts(contractId: string, cType: string): Observable<any[]> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');

    let jobURL = this.relatedContJobURL + `/vGetRelatedContracts/${contractId}/${cType}`

    return this.restService.get<any[]>(jobURL, {
      headers: headers
    })
  }

}
