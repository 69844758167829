import {CallDeskDataResponseModelInfo} from "./call-desk-data-response-model-info";

/**
 * Call desk data response model
 * @author Achala M. Rathnathilaka
 */
export class CallDeskDataResponseModel {

   Table: CallDeskDataResponseModelInfo[] =[];
}
