import { Inject, Injectable } from '@angular/core';
import { Config } from "../../../config/config";
import { RestRequestService } from "../rest-request.service";
import { Contract } from "../../models/contract-model";
import { Observable } from 'rxjs';
import { url } from 'inspector';
import { HttpHeaders } from '@angular/common/http';
import { settlementCalcResponseModelInfo } from 'src/app/models/settlement-calculation/settlement-calc-response-model-info';
import { settlementCalcResponseModel } from 'src/app/models/settlement-calculation/settlement-calculation-response-model';


@Injectable({
  providedIn: 'root'
})

/**
 * Service implementation releated to settlement calculator
 * @author Malindu Panchala
 */

export class SettlementCalculatorService {

  private readonly settlementCalJobsUrl: string;
  httpClient: any;

  constructor(private restService: RestRequestService, private rest: RestRequestService, @Inject("recoveryConfig") private recoveryConfig = Config.recoveryConfig) {
    this.settlementCalJobsUrl = this.getSettlementCalJobsUrl(recoveryConfig);
  }

  /**
     * Get Settlement calculator job service Url from Settlement Calculator injectable object.
     * @param recoveryConfig
     * @returns settlementCalUrl:string
     */
  private getSettlementCalJobsUrl(recoveryConfig: any = {}): string {
    let settlementCalUrl = '';

    if (recoveryConfig.env === 'local') {
      settlementCalUrl = `${recoveryConfig.appServerUrl}`;
    } else {
      settlementCalUrl = `${recoveryConfig.recoveryServiceUrl}`;
    }
    return settlementCalUrl;
  }

  vAddContractListRequest(id: string, date: string, payload: any): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');
    let url = `${this.settlementCalJobsUrl}/vAddContractListRequest/${id}/01-Oct-2021`;
    return this.rest.get<any>(url, {
      headers: headers,
    });
  }

  vGetSingleContractListRequest(id: string, payload: any): Observable<any> {
    console.log("Service recieved " + id);
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');
    let url = `${this.settlementCalJobsUrl}/vGetSingleContractListRequest/${id}`;
    return this.rest.get<any>(url, {
      headers: headers,
    });
  }

  viewRequestLetter(id: string): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');
    let url = `${this.settlementCalJobsUrl}/vGetCRletter/${id}`;
    return this.rest.get<any>(url, {
      headers: headers,
      responseType: 'text' as const
    });
  }

  viewCFletter(id: string): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');
    let url = `${this.settlementCalJobsUrl}/vGetCFletter/${id}`;
    return this.rest.get<any>(url, {
      headers: headers,
      responseType: 'text' as const
    });
  }

  calculateSettlement(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');
    let url = `${this.settlementCalJobsUrl}/vSetCal`;
    return this.rest.post<any>(url, payload, {
      headers: headers,
      responseType: 'text'
    });
  }

  saveSettlement(payload: any): any {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');
    let url = `${this.settlementCalJobsUrl}/vSaveSetCal`;
    return this.rest.post<any>(url, payload, {
      headers: headers
    });
  }

  saveOfsFileUpload(payload: any): any {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');
    const formData: FormData = new FormData();
    let url = `${this.settlementCalJobsUrl}/vOfsFileUpload`;
    return this.rest.post<any>(url, payload, {
      headers: headers
    });
  }

  postUploadRequestLetter(reqletter: string | ArrayBuffer): Observable<boolean> {
    const endpoint = '/vOfsFileUpload';
    const formData: FormData = new FormData();
    // formData.append('fileKey', reqletter, reqletter.name);
    return this.httpClient
      .post(endpoint, formData, { headers: Headers })
      .map(() => { return true; })
      .catch((e: any) => this.handleError(e));
  }

  postUploadCFLetter(cfletter: string | ArrayBuffer): Observable<boolean> {
    const endpoint = '/vOfsFileUpload';
    const formData: FormData = new FormData();
    // formData.append('fileKey', cfletter);
    return this.httpClient
      .post(endpoint, formData, { headers: Headers })
      .map(() => { return true; })
      .catch((e: any) => this.handleError(e));
  }

  getIPAddress(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');

    let jobURL: string = `https://api64.ipify.org?format=json`;
    return this.restService.get<any>(
      jobURL,
      {
        headers: headers,

      }
    );
  }

  getBankList(invg: string): Observable<any[]> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');

    // call list with filters uri
    let jobURL: string = this.settlementCalJobsUrl + `/vGetBankDet`;
    return this.restService.get<any[]>(
      jobURL,
      {
        headers: headers,
      }
    );
  }

  // ofsSave(requestData: settlementCalcResponseModelInfo) : Observable<settlementCalcResponseModelInfo> {
  //   const headers = new HttpHeaders().set('Content-Type', 'text/plain');

  //   let jobURL: string = this.settlementCalJobsUrl + `/vOfsFileUpload`;
  //   return this.restService.post<any>(
  //     jobURL,
  //     {
  //       headers: headers
  //     }
  //   );
  // }

  ofsSave(payload: any): any {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');
    let url = `${this.settlementCalJobsUrl}/vOfsFileUpload`;
    return this.rest.post<any>(url, payload, {
      headers: headers,
      responseType: 'text' as const
    });
  }

  handleError(e: any) {
    throw new Error('Method not implemented.');
  }

  initCalculation(contract_id: any, cType: any): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');
    let url = `${this.settlementCalJobsUrl}/vGETRentMat/${contract_id}/${cType}`;
    return this.rest.get<any>(url, {
      headers: headers,
      responseType: 'text' as const
    });
  }
}
