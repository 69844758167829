<app-dialog-header [title]="title" (closeDialogEvent)="closeDialog()"></app-dialog-header>
<div>
    <div class="p-2">
        <h3>Sub contracts</h3>
    </div>
    <div class="p3-2 pb-1">
        Showing {{dataset.length}} records
    </div>
    <div class="py-2 overflow-container">
        <angular-slickgrid gridId="relatedContractList" [columnDefinitions]="columnDefinitions" [gridOptions]="gridOptions"
            [dataset]="dataset" (onAngularGridCreated)="angularGridReady($event)"></angular-slickgrid>
    </div>
    <div class="d-flex justify-content-end">
        <button [disabled]="!contractSelected" color="primary" mat-raised-button (click)="openContractDetails()">Details</button>
    </div>
</div>

