/**
 * Model implementation releated to add contract list request
 * @author Malindu Panchala
 */

import { Container } from "@angular/compiler/src/i18n/i18n_ast";
import { Contract } from "./contract-model";

export class AddContractListRequestBody {
    constructor(
        public body: Contract
    ) { }
}

export class AddContractListResponseBody {
    public get table(): Array<Contract> {
        return this._table;
    }
    public set table(value: Array<Contract>) {
        this._table = value;
    }
    constructor(private _table: Array<Contract>) { }

    static fromJson(object: any): AddContractListResponseBody {
        let arrayOfContracts: Array<any> = object["Table"];
        let output: Array<Contract> = [];
        arrayOfContracts.forEach(i => {
            let tempContract = new Contract(
                i["CONTRACTNO"],
                i["NIC"],
                i["INVESTIGATORCODE"],
                i["ASSIGNDATE"],
                i["CUSTOMERNAME"],
                i["CUSTOMERADDRESS"],
                i["TOTALARREARS"],
                i["DEFAULTARREARS"],
                i["OTHERARREARSAMOUNT"],
                i["INSURANCEARREARS"],
                i["TOTALARREARS1"],
                i["NOOFARREARS"],
                i["DUEDATE"],
                i["LASTPAYDATE"],
                i["GROSSRENTAL"],
                i["DUEDATE1"],
                i["REGISTRATIONNO"],
                i["EQUIPMENT"],
                i["MAKE"]
            );
            output.push(tempContract);
        })

        let returnObj = new AddContractListResponseBody(output);
        return returnObj;
    }
}