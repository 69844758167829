<app-dialog-header [title]="title" (closeDialogEvent)="closeDialog()"></app-dialog-header>
<div class="py-2"></div>
<div class="col-12 py-1">
  <agm-map [latitude]="lat" [longitude]="lng">
    <agm-direction [origin]="origin" [destination]="destination" [waypoints]="waypoints">

    </agm-direction>
  </agm-map>
</div>

<div class="row">
  <div class="col-6">
    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Visit Date:</mat-label>
      <input matInput [matDatepicker]="visitDatePicker" placeholder="disable typing" readonly (click)="visitDatePicker.open()"  (dateInput)="selectVisitupDate($event)">
      <mat-hint>dd/mm/yyyy</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="visitDatePicker"></mat-datepicker-toggle>
      <mat-datepicker touchUi #visitDatePicker></mat-datepicker>
    </mat-form-field>
  </div>
</div>
<!-- add track num input field DulsaraMannakkara 28/11/2023 -->
<div>
  <mat-form-field>
    <mat-label>Track Number</mat-label>
    <mat-select [(ngModel)]="selectedNumber" name="Select Track">
      <mat-option *ngFor="let number of numbers" [value]="number">{{ number }}</mat-option>
    </mat-select>
  </mat-form-field>
  
  
<!-- <div class="row">
  <div class="col-12">
    <div class="col-6 ">
      Total Distance:
    </div>
    <div class="col-6 ">
      {{this.totalDistance}} Km
    </div>
  </div>
</div> -->

<div style=" display:flex; justify-content:flex-end; width:100%; padding-top: 20px; padding-right: 20px;">
  <table bordet="0">
    <tr>
      <td style="color:green; padding-top:20px; padding-left: 20px">
        <button [disabled]="visitDate?.length < 1 || selectedNumber==null" view class="btn btn-primary float-left" (click)="loadOfficerTrackingData()">Load Route</button>
      </td>
      <!-- calculateDistance -->
      <!-- <td style="color:green; padding-top:20px; padding-left: 20px">
        <button [disabled]="visitDate?.length <1" view class="btn btn-primary float-left" (click)="calculateDistance()">Total Distance</button>
      </td> -->
      <td style="color:green; padding-top:20px; padding-left: 20px">
        <button view class="btn btn-primary float-left" (click)="openVisitSummary()">View report</button>
      </td>
      <td style="color:green; padding-top:20px; padding-left: 20px">
        <button view class="btn btn-primary float-left" (click)="closeDialog()">Close</button>
      </td>
    </tr>
  </table>
</div>
