<app-dialog-header [title]="title" (closeDialogEvent)="closeDialog()"></app-dialog-header>
<div class="container-fluid p-3">

  <div class="w-100 card-container my-2">
    <div class="card-header w-100 p-2">
      <span>Client details</span>
    </div>
    <div class="card-content p-2 w-100">
      <div class="row card-content-row w-100 px-5 px-md-4 py-2 py-md-0">
        <div class="col-12 col-md-4 py-1 py-md-2">
          <div class="col-12 card-content-leading">Name</div>
          <div class="col-12 card-content-description">{{this.selectedClientDetail.fullname | whitespace}}</div>
        </div>
        <div class="col-12 col-md-4 py-1 py-md-2">
          <div class="col-12 card-content-leading">NIC</div>
          <div class="col-12 card-content-description">{{this.selectedClientDetail.NIC | whitespace}}</div>
        </div>
      </div>
      <div class="row card-content-row w-100 px-5 px-md-4 py-2 py-md-0">
        <div class="col-12 col-md-4 py-1 py-md-2">
          <div class="col-12 card-content-leading">Contact number 1</div>
          <div class="col-12 card-content-description">
            <mat-form-field class="w-100">
              <input matInput [(ngModel)]="this.selectedClientDetail.contactNo">
            </mat-form-field>
          </div>
        </div>
        <div class="col-12 col-md-4 py-1 py-md-2">
          <div class="col-12 card-content-leading">Contact number 2</div>
          <div class="col-12 card-content-description">{{this.selectedClientDetail.contactNo2 | whitespace}}</div>
        </div>
        <div class="col-12 col-md-4 py-1 py-md-2">
          <div class="col-12 card-content-leading">e-mail</div>
          <div class="col-12 card-content-description">{{this.selectedClientDetail.email | whitespace}}</div>
        </div>
      </div>

      <div class="row card-content-row w-100 px-5 px-md-4 py-2 py-md-0">
        <div class="col-12 col-md-4 py-1 py-md-2">
          <div class="col-12 card-content-leading">Address</div>
          <div class="col-12 card-content-description">
            <mat-form-field class="w-100">
              <input matInput [(ngModel)]="this.selectedClientDetail.address">
            </mat-form-field>
          </div>
        </div>
        <div class="col-12 col-md-4 py-1 py-md-2">
          <div class="col-12 card-content-leading">Address - work</div>
          <div class="col-12 card-content-description">{{this.selectedClientDetail.adressWorkingPlace | whitespace}}</div>
        </div>
        <div class="col-12 col-md-4 py-1 py-md-2">
          <div class="col-12 card-content-leading">Location pinned</div>
          <div class="col-12 card-content-description">{{this.selectedClientDetail.locationPined | whitespace}}</div>
        </div>
      </div>

      <div class="row card-content-row w-100 px-5 px-md-4 py-2 py-md-0">
        <div class="col-12 col-md-4 py-1 py-md-2">
          <div class="col-12 card-content-leading">Occupation</div>
          <div class="col-12 card-content-description">
            <mat-form-field class="w-100">
              <input matInput [(ngModel)]="this.selectedClientDetail.occupation">
            </mat-form-field>
          </div>
        </div>
        <div class="col-12 col-md-4 py-1 py-md-2">
          <div class="col-12 card-content-leading">Sector</div>
          <div class="col-12 card-content-description">{{this.selectedClientDetail.subSector | whitespace}}</div>
        </div>
        <div class="col-12 col-md-4 py-1 py-md-2">
          <div class="col-12 card-content-leading">Sub sector</div>
          <div class="col-12 card-content-description">{{this.selectedClientDetail.subSector | whitespace}}</div>
        </div>
      </div>

      <div class="row card-content-row w-100 px-5 px-md-4 py-2 py-md-0">
        <div class="col-12 col-md-4 py-1 py-md-2">
          <div class="col-12 card-content-leading">Postal code</div>
          <div class="col-12 card-content-description">{{this.selectedClientDetail.postalCode | whitespace}}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="w-100 card-container my-2">
    <div class="card-header w-100 p-2">
      <span>3rd party details</span>
    </div>
    <div class="card-content p-2 w-100">
      <div class="row card-content-row w-100 px-5 px-md-4 py-2 py-md-0">
        <div class="col-12 col-md-4 py-1 py-md-2">
          <div class="col-12 card-content-leading">Name</div>
          <div class="col-12 card-content-description">
            <mat-form-field class="w-100">
                <input matInput [(ngModel)]="this.selectedClientDetail.thirdPartyName">
              </mat-form-field>
          </div>
        </div>
        <div class="col-12 col-md-4 py-1 py-md-2">
          <div class="col-12 card-content-leading">NIC</div>
          <div class="col-12 card-content-description">{{this.selectedClientDetail.thirdPartyNIC | whitespace}}</div>
        </div>
      </div>
      <div class="row card-content-row w-100 px-5 px-md-4 py-2 py-md-0">
        <div class="col-12 col-md-4 py-1 py-md-2">
          <div class="col-12 card-content-leading">Contact number 1</div>
          <div class="col-12 card-content-description">{{this.selectedClientDetail.thirdPartyContactNo | whitespace}}</div>
        </div>
        <div class="col-12 col-md-4 py-1 py-md-2">
          <div class="col-12 card-content-leading">e-mail</div>
          <div class="col-12 card-content-description">{{this.selectedClientDetail.thirdPartyEmail | whitespace}}</div>
        </div>
      </div>

      <div class="row card-content-row w-100 px-5 px-md-4 py-2 py-md-0">
        <div class="col-12 col-md-4 py-1 ">
          <div class="col-12 card-content-leading">Address</div>
          <div class="col-12 card-content-description">{{this.selectedClientDetail.thirdPartyAdress | whitespace}}</div>
        </div>
        <div class="col-12 col-md-4 py-1 py-md-2">
          <div class="col-12 card-content-leading">Location pinned</div>
          <div class="col-12 card-content-description">{{this.selectedClientDetail.locationPined | whitespace}}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="w-100 row">
    <div class="col-6 p-1">
      <button mat-raised-button color="primary" class="w-100 text-wrap" (click)="updateClientDetails()">UPDATE(
        Contact, Address, Occupation, 3P )</button>
    </div>
    <div class="col-6 p-1">
      <button mat-raised-button color="primary" class="w-100" (click)="closeDialog()">Close</button>
    </div>
  </div>
</div>