import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'whitespace'
})
export class WhitespacePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    if (!value) {
      return "---"
    }
    if (typeof value == "string") {
      let trimmed = value.trim()
      return trimmed.length < 1 ? "---" : value; 
    } else {
      return value
    }
  }
}
