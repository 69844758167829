import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DistencePermissionRequest } from 'src/app/models/distence-tracker/distence-permission-model';

import { RestRequestService } from '../rest-request.service';
import { Config } from "../../../config/config";

//created by DulsaraMannakkara 28.08.2023
//For access gateway to distance table.
@Injectable({
  providedIn: 'root'
})
export class DistancePermissionService {

  private readonly callDeskJobsUrl: string;
  private readonly apiUrl: string; 

  constructor(
    private restService: RestRequestService,
    @Inject("recoveryConfig") private recoveryConfig = Config.recoveryConfig,
    private http: HttpClient
  ) { 
    this.callDeskJobsUrl = this.getCallDeskJobsUrl(recoveryConfig);

    // Set apiUrl based on recoveryConfig
    if (recoveryConfig.env === 'local') {
      this.apiUrl = recoveryConfig.appServerUrl;
    } else {
      this.apiUrl = recoveryConfig.recoveryServiceUrl;
    }
  }

  private getCallDeskJobsUrl(recoveryConfig: any = {}): string {
    let callDeskUrl = '';

    if (recoveryConfig.env === 'local') {
      callDeskUrl = `${recoveryConfig.appServerUrl}`;
    } else {
      callDeskUrl = `${recoveryConfig.recoveryServiceUrl}`;
    }
    return callDeskUrl;

  }
  getDistancePermission(user: string): Observable<DistencePermissionRequest[]> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');

    //call list with filters uri
    let jobURL: string = this.callDeskJobsUrl + `/vGetDistancePermission/${user}`;
    return this.restService.get<DistencePermissionRequest[]>(
      jobURL,
      {
        headers: headers
      }
    );
  }  

  // getDistancePermission(user: string, res: string): Observable<any> {
  //   const url = `${this.apiUrl}/vGetDistancePermission/${user}`;
  //   return this.http.get<any>(url);
  // }
}
