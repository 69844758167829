<div class="container component-wrapper">
    <div class="table-wrapper pb-5">
        <div>
            <button class="mx-1 my-2" mat-raised-button color="primary" (click)="fetchData()">Fetch Data</button>
            <div>
                <table mat-table [dataSource]="tableData" class="mat-elevation-z8 w-100">
                    <ng-container matColumnDef="ID">
                        <th mat-header-cell *matHeaderCellDef> ID </th>
                        <td mat-cell *matCellDef="let element">{{element.route}}</td>
                    </ng-container>
                    <ng-container matColumnDef="Name">
                        <th mat-header-cell *matHeaderCellDef> name </th>
                        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef> actions </th>
                        <td mat-cell *matCellDef="let element; let i = index">
                            <button class="mx-1" mat-raised-button color="primary" (click)="handlePreview(i)">View</button>
                            <button class="mx-1" mat-raised-button color="accent" (click)="updateById(i)">Update</button>
                            <button class="mx-1" mat-raised-button color="warn" (click)="deleteById(i, element.route)">Delete</button>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </div>
    </div>
</div>