<!-- <h2 mat-dialog-title>Notepad Details</h2> -->
<app-dialog-header
  [title]="title"
  (closeDialogEvent)="closeDialog()"
></app-dialog-header>
<br />
<br />
<form class="example-form">
  <div class="form-row">
    <label for="conractNo">Facility No: </label>
    <input
      type="text"
      id="conractNo"
      name="conractNo"
      [(ngModel)]="contractNo"
      disabled
    />
  </div>

  <div class="form-row">
    <label for="appno">Application No:</label>
    <input type="text" id="appno" name="appno" [(ngModel)]="appno" disabled />
  </div>
</form>
<br />

<mat-dialog-content class="mat-typography">
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource">
      <!-- SerNo Column -->
      <ng-container matColumnDef="serno">
        <th mat-header-cell *matHeaderCellDef>Serial Number</th>
        <td mat-cell *matCellDef="let element">{{ element.serno }}</td>
      </ng-container>

      <!-- User ID  Column -->
      <ng-container matColumnDef="usrid">
        <th mat-header-cell *matHeaderCellDef>User ID</th>
        <td mat-cell *matCellDef="let element">{{ element.usrid }}</td>
      </ng-container>

      <!-- Note Date Column -->
      <ng-container matColumnDef="dte_of_nt">
        <th mat-header-cell *matHeaderCellDef>Note Date</th>
        <td mat-cell *matCellDef="let element">{{ element.dte_of_nt }}</td>
      </ng-container>

      <!-- Note Column -->
      <ng-container matColumnDef="DESCR">
        <th mat-header-cell *matHeaderCellDef>Note Description</th>
        <td mat-cell *matCellDef="let element">{{ element.DESCR }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      [pageSizeOptions]="[10, 20, 30, 40]"
      showFirstLastButtons
      aria-label="Select page of periodic elements"
    >
    </mat-paginator>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div class="py-3 d-flex">
    <button
      mat-raised-button
      mat-dialog-close
      color="warn"
      style="margin-right: 1rem"
    >
      Close
    </button>
  </div>
</mat-dialog-actions>
