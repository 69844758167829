<app-dialog-header [title]="title" (closeDialogEvent)="closeVisitList()"></app-dialog-header>
<!-- loadingView - kalanarathnayake-->
<div id="backColor" *ngIf="isLoading">
    <div id="loadingView">
        <img class="loadingGif" src="../../../../assets/loading.gif" alt="loadingLogo">
    </div>
</div>
<!-- loadingView -->
<div class="">
    <!-- <div class="py-3">
        <div class="row">
            <div class="col-9">
                <h6>Today route : {{visitProgress.SELECTEDROUTE}}</h6>
            </div>
        </div>
    </div> -->
    <br>
    <div class="row">
        <section>
          <mat-radio-group aria-label="Select an option" [(ngModel)]="selectOptionMode" (change)="changeType($event)">
            <div class="d-flex">
              <div class = "col-2">
                <mat-radio-button color="primary" value="CR">
                  <span style="font-size: 14px; text-overflow:ellipsis">CR</span>
                </mat-radio-button>
              </div>
              <div class = "col-2">
                <mat-radio-button color="primary" value="WO">
                  <span style="font-size: 14px; text-overflow:ellipsis">WO</span>
                </mat-radio-button>
              </div>
              <div class = "col-2">
                <mat-radio-button color="primary" value="PL">
                  <span style="font-size: 14px; text-overflow:ellipsis">PL</span>
                </mat-radio-button>
              </div>
              <div class = "col-2">
                <mat-radio-button color="primary" value="PW">
                  <span style="font-size: 14px; text-overflow:ellipsis">PLWO</span>
                </mat-radio-button>
              </div>
            </div>
          </mat-radio-group>
        </section>
      </div>
      <br>

      <div>
          <section>
              <mat-checkbox [(ngModel)]="enableSearch" (change)="handleSearchEnable($event)">Enable search</mat-checkbox>
          </section>
      </div>
    <div>
        <div class="search-container d-flex align-items-baseline">
            <div class="col w-auto">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Search</mat-label>
                    <mat-placeholder>Search reports</mat-placeholder>
                    <input matInput type="text" [(ngModel)]="search" [disabled]="!enableSearch">
                    <mat-select matSuffix [(ngModel)]="searchType">
                        <mat-option value="Vehicle">Vehicle</mat-option>
                        <mat-option value="Contract">Contract</mat-option>
                        <mat-option value="NIC">NIC</mat-option>
                        <mat-option value="Chassi">Chassi</mat-option>
                        <mat-option value="NAME">Name</mat-option>
                    </mat-select>
                    <mat-hint align="start"><strong>Select a search type from the dropdown icon. {{search}}</strong>
                    </mat-hint>
                    <mat-hint align="end">Search by: {{searchType}}</mat-hint>
                </mat-form-field>
            </div>
            <div class="ml-1 px-2">
                <button [disabled]="search.length < 1" mat-mini-fab color="primary" (click)="searchVisits()">
                    <mat-icon>search</mat-icon>
                </button>
            </div>
        </div>
        <!-- </div>
        <div class="py-3 d-flex">
        <button [disabled]="mode == 'BALANCE'" mat-raised-button (click)="balanceVisits()" color="primary" style="margin-right: 1rem;">Balance
            Visits</button>
        <button [disabled]="mode == 'ALL'" mat-raised-button (click)="allContracts()" color="primary" style="margin-right: 1rem;">All
            contracts</button> -->
        <!-- <button mat-raised-button (click)="getVistList()" color="primary">Todays Visits</button> -->
        <!-- </div> -->

        <!-- Select type of CR/WO/PL/PLWO -->


        <div class="py-3 d-flex">
            <button [disabled]="mode == 'ALL'" mat-raised-button color="primary" style="margin-right: 1rem;"
                (click)="changeMode('ALL')">All contracts</button>
            <button [disabled]="mode == 'BALANCE'" mat-raised-button color="primary" style="margin-right: 1rem;"
                (click)="changeMode('BALANCE')">Balance Visits</button>
            <button [disabled]="mode == 'COMPLETED'" mat-raised-button color="primary" style="margin-right: 1rem;"
                (click)="changeMode('COMPLETED')">Completed Visits</button>
            <!-- TODO add the -->
                <button [disabled]="mode == 'REMOVED'" mat-raised-button color="primary" style="margin-right: 1rem;"
                (click)="changeMode('REMOVED')">Removed Visits</button>
        </div>

        <br>

        <p>Now viewing : <span style="color:rgb(37, 37, 177)">{{mode.toLowerCase()}} contracts</span></p>

        <!-- <div class="py-3 d-flex align-items-center">
        <div>
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Choose a date</mat-label>
                <input matInput [matDatepicker]="picker" (dateInput)="dateChangeHandler($event)"
                    [(ngModel)]="selectedDate">
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker touchUi #picker></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="px-2">
            <button mat-raised-button color="primary" (click)="processFiltersAndReloadData()">Reload data</button>
        </div>
        </div> -->

        <div class="py-3">
            <div class="row">
                <!--d-flex align-items-center-->
                <div class="col-12 col-lg-8">
                    <div class="row py-3 d-flex align-items-center">
                        <div class="col-3">DPD Bucket</div>
                        <div class="col-9">
                            <mat-button-toggle-group [(ngModel)]="dpd" class="button-group"
                                #group="matButtonToggleGroup" multiple (change)="handleDpdChange($event)">
                                <mat-button-toggle class="button-group-btns" value="1-120">1-120 DPD</mat-button-toggle>
                                <mat-button-toggle class="button-group-btns" value="121-180">121-180 DPD
                                </mat-button-toggle>
                                <mat-button-toggle class="button-group-btns" value="181-360">181-360 DPD
                                </mat-button-toggle>
                                <mat-button-toggle class="button-group-btns" value="361-540">361-540 DPD
                                </mat-button-toggle>
                                <mat-button-toggle class="button-group-btns" value="540">above 540 DPD
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                    </div>
                    <div class="row py-3">
                        <div class="col-3">NPL</div>
                        <div class="col-9">
                            <mat-button-toggle-group [(ngModel)]="npl" class="button-group"
                                #group="matButtonToggleGroup" multiple (change)="handleNplChange($event)">
                                <mat-button-toggle class="button-group-btns" value="npl_moved">NPL moved
                                </mat-button-toggle>
                                <mat-button-toggle class="button-group-btns" value="triggering">Triggering
                                </mat-button-toggle>
                                <mat-button-toggle class="button-group-btns" value="triggering_90">Triggering 60
                                </mat-button-toggle>
                                <mat-button-toggle class="button-group-btns" value="bw_period">BW Period
                                </mat-button-toggle>
                                <mat-button-toggle class="button-group-btns" value="saved">Saved</mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                    </div>
                    <div class="row py-3">
                        <div class="col-3">Not paid for</div>
                        <div class="col-9">
                            <mat-button-toggle-group [(ngModel)]="notPayed" class="button-group"
                                #group="matButtonToggleGroup" multiple (change)="handleNotPayed($event)">
                                <mat-button-toggle class="button-group-btns" value="last_M">Last M</mat-button-toggle>
                                <mat-button-toggle class="button-group-btns" value="paid">Paid</mat-button-toggle>
                                <mat-button-toggle class="button-group-btns" value="part">Part Payment
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                    </div>
                </div>

                <!--
                Small table on right
            -->
                <div class="col-12 col-lg-4 py-3">
                    <div class="row text-center table-div py-2">
                        <div>{{selectOptionMode}} Visit progress</div>
                    </div>
                    <div class="row text-center table-div py-1">
                        <div>Name: {{visitProgress.SELECTEDROUTE}}</div>
                    </div>
                    <div class="row">
                        <div class="col-6 table-div">All triggered</div>
                        <div class="col-6 table-div">{{visitProgress.ALLTRIGGERED}}</div>
                    </div>
                    <div class="row">
                        <div class="col-6 table-div">Completed</div>
                        <div class="col-6 table-div">{{visitProgress.completed}}</div>
                    </div>
                    <div class="row">
                        <div class="col-6 table-div">Visit removed</div>
                        <div class="col-6 table-div">{{visitProgress.VISITSREMOVED}}</div>
                    </div>
                    <div class="row">
                        <div class="col-6 table-div">Balance</div>
                        <div class="col-6 table-div">{{visitProgress.BALANCE}}</div>
                    </div>
                    <!-- <div class="row text-center py-1">
                    <button  mat-raised-button color="primary" (click)="viewRoute()" class="form-control">View the route/location</button>
                </div>
                <div class="row text-center py-1">
                    <button mat-raised-button color="accent" (click)="startEnd()" class="form-control">Start/End</button>
                </div> -->
                </div>
            </div>
        </div>

        <div class="py-2">
            <div class="row d-flex justify-content-end">
                <button [disabled]="contractSelected?.length < 1" mat-raised-button color="primary" class="form-control"
                    (click)="handleDetails()">Details</button>
            </div>
        </div>

        <div *ngIf="dataset.length > 0" class="pt-4">
            Showing {{dataset.length}} Records...
        </div>

        <div class="py-3 overflow-container">
            <angular-slickgrid gridId="todaysCallList" [columnDefinitions]="columnDefinitions"
                [gridOptions]="gridOptions" [dataset]="dataset" (onAngularGridCreated)="angularGridReady($event)">
            </angular-slickgrid>
        </div>
    </div>
