import {Component , Inject , OnInit} from '@angular/core';
import {TodaysCallDeskService} from "../../services/call-desk/call-desk.service";
import {NotePadEntryViewComponent} from "../note-pad-entry-view/note-pad-entry-view.component";
import {MAT_DIALOG_DATA , MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-client-exposure',
  templateUrl: './client-exposure.component.html',
  styleUrls: ['./client-exposure.component.scss']
})
export class ClientExposureComponent implements OnInit {

  noContent: boolean = false;

  entries: any[] = [];
  nic: string = '' ;

  constructor(private service: TodaysCallDeskService, private dialogRef: MatDialogRef<ClientExposureComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {

    this.nic = data.nic;
  }

  ngOnInit(): void {
    console.log(this.data);

    // let nic=   '600783319V' ;
    this.service.getClientExposure(this.nic).subscribe({
      next: (value) => {
        this.entries = value;
        console.log(JSON.stringify(value));
      },
      error: (err) => {
        this.noContent = true;
      }
    })
  }

  closeDialog(): any {
    this.dialogRef.close();
  }


}
