<div class="container-fluid">
  <div class="row border-1" style="padding-top: 1rem">
    <div class="col-9">
      <h2>PD CHEQUE</h2>
    </div>
  </div>



  <div>
    <div class="container dialog-wrapper w-100">
      <div class="row g-12">
        <div class="col-12 col-sm-12 detail-tile-col">
          <div class="container detail-tile">
            <div class="row details-list">
              <div class="col-4 details-list-leading">
                Cheque No
              </div>
              <div class="col-8 details-list-content">
                <mat-form-field appearance="fill" style="width: 90%; margin-top: 0.5rem">
                  <mat-label>Cheque No</mat-label>
                  <input (keypress)="numberOnly($event)" maxlength="13" matInput [(ngModel)]="chequeNo">
                </mat-form-field>
              </div>
            </div>

            <div class="row details-list">
              <div class="col-4 details-list-leading">
                Cheque Date
              </div>
              <div class="col-8 details-list-content">
                <mat-form-field appearance="fill" class="w-100">
                  <mat-label>Cheque Date</mat-label>
                  <input matInput [matDatepicker]="chequeDatePicker" (click)="chequeDatePicker.open()" placeholder="disable typing" readonly (dateInput)="selectchequeDate($event)">
                  <mat-hint>dd/mm/yyyy</mat-hint>
                  <mat-datepicker-toggle matSuffix [for]="chequeDatePicker"></mat-datepicker-toggle>
                  <mat-datepicker touchUi #chequeDatePicker></mat-datepicker>
                </mat-form-field>
              </div>
            </div>

            <div class="row details-list">
              <div class="col-4 details-list-leading">
                Deposit Date
              </div>
              <div class="col-8 details-list-content">
                <mat-form-field appearance="fill" class="w-100">
                  <mat-label>Deposit Date</mat-label>
                  <input matInput [matDatepicker]="depositDatePicker" (click)="depositDatePicker.open()" placeholder="disable typing" readonly (dateInput)="selectDepositDate($event)">
                  <mat-hint>dd/mm/yyyy</mat-hint>
                  <mat-datepicker-toggle matSuffix [for]="depositDatePicker"></mat-datepicker-toggle>
                  <mat-datepicker touchUi #depositDatePicker></mat-datepicker>
                </mat-form-field>
              </div>
            </div>

            <div class="row details-list">
              <div class="col-4 details-list-leading">
                Cheque Number
              </div>
              <div class="col-8 details-list-content">
                <mat-form-field appearance="fill" style="width: 90%; margin-top: 0.5rem">
                  <mat-label>Cheque Amount</mat-label>

                  <input (keypress)="numberOnly($event)" matInput [(ngModel)]="chequeAmount">
                </mat-form-field>
              </div>
            </div>
            <div class="row details-list">
              <div class="col-4 details-list-leading">
                Bank Name
              </div>
              <div class="col-8 details-list-content">
                <mat-form-field appearance="fill" style="width: 90%; margin-top: 0.5rem">
                  <mat-label>Bank Name</mat-label>
                  <input matInput [(ngModel)]="bankName">
                </mat-form-field>
              </div>
            </div>
            <div class="row details-list">
              <div class="col-4 details-list-leading">
                Branch Name
              </div>
              <div class="col-8 details-list-content">
                <mat-form-field appearance="fill" style="width: 90%; margin-top: 0.5rem">
                  <mat-label>Branch Name</mat-label>
                  <input matInput [(ngModel)]="branchName">
                </mat-form-field>
              </div>
            </div>

            <div class="row details-list">
              <div class="col-4 details-list-leading">
                Deposit Account
              </div>
              <div class="col-8 details-list-content">
                <mat-form-field appearance="fill" style="width: 90%; margin-top: 0.5rem">
                  <mat-label>Deposit Account</mat-label>
                  <input (keypress)="numberOnly($event)" matInput [(ngModel)]="depositAccount">
                </mat-form-field>
              </div>
            </div>

            <div class="row details-list">
              <div class="col-4 details-list-leading">
                Saving Account
              </div>
              <div class="col-6 details-list-content">
                <mat-form-field appearance="fill" style="width: 90%; margin-top: 0.5rem">
                  <mat-label>Saving Account</mat-label>
                  <input (keypress)="numberOnly($event)" matInput [(ngModel)]="savingAccount">
                </mat-form-field>
              </div>
              <!--<div class="col-2 details-list-content">-->
                <!--&lt;!&ndash;<mat-form-field appearance="fill" style="width: 90%; margin-top: 0.5rem">&ndash;&gt;-->
                  <!--<mat-checkbox [checked]="isGroupChequeSelected">Group Cheque</mat-checkbox>-->
                  <!--&lt;!&ndash;<input matInput [(ngModel)]="receiptNo">&ndash;&gt;-->
                <!--&lt;!&ndash;</mat-form-field>&ndash;&gt;-->

              <!--</div>-->
            </div>

            <div class="row details-list">
              <div class="col-4 details-list-leading">
                Insurance
              </div>
              <div class="col-8 details-list-content">

                <mat-radio-group aria-label="Select an option" [(ngModel)]="selectedInsuranceOption">
                  <div class="row details-list">
                    <div class="col-3 details-list-leading">
                      <mat-radio-button color="primary"  [value]="exclude"><span style="font-weight: 100; color: rgb(81, 79, 104); font-size: 14px;">Exclude</span></mat-radio-button>
                    </div>
                  </div>

                  <div class="row details-list">
                    <div class="col-3 details-list-leading">
                      <mat-radio-button color="primary"  [value]="include"><span style="font-weight: 100; color: rgb(81, 79, 104); font-size: 14px;">Include</span></mat-radio-button>
                    </div>
                  </div>
                </mat-radio-group>

              </div>
            </div>

            <div class="row details-list">
              <div class="col-4 details-list-leading">
                Full Amount
              </div>
              <div class="col-8 details-list-content">
                <mat-form-field appearance="fill" style="width: 90%; margin-top: 0.5rem">
                  <mat-label>Full Amount</mat-label>

                  <input (keypress)="numberOnly($event)" matInput [(ngModel)]="fullAmount">
                </mat-form-field>
              </div>
            </div>

          </div>


        </div>






        <!-- <div class="row g-12">
          <div class="col-12 col-sm-12 detail-tile-col">
            <div class="container detail-tile">

              <div class="row details-list">
                <div class="col-4 details-list-leading">
                  Debt Collector
                </div>
                <div class="col-8 details-list-content">
                  <mat-checkbox>Debt Collector</mat-checkbox>
                  <mat-form-field appearance="fill" style="width: 90%; margin-top: 0.5rem">
                    <input matInput [(ngModel)]="debtCollectorCode">
                  </mat-form-field>
                </div>
              </div>

              <div class="row details-list">
                <div class="col-4 details-list-leading">
                  Book No
                </div>
                <div class="col-8 details-list-content">
                  <mat-form-field appearance="fill" style="width: 90%; margin-top: 0.5rem">
                    <mat-label>Book No</mat-label>
                    <input matInput [(ngModel)]="bookNo">
                  </mat-form-field>
                </div>
              </div>

              <div class="row details-list">
                <div class="col-4 details-list-leading">
                 Temp. Receipt No
                </div>
                <div class="col-8 details-list-content">
                  <mat-form-field appearance="fill" style="width: 90%; margin-top: 0.5rem">
                    <mat-label> Temp. Receipt No</mat-label>
                    <input matInput [(ngModel)]="tempRecNo">
                  </mat-form-field>
                </div>
              </div>


              <div class="row details-list">
                <div class="col-4 details-list-leading">
                  Receipt Date
                </div>
                <div class="col-8 details-list-content">
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Receipt Date</mat-label>
                    <input matInput [matDatepicker]="receiptDatePicker" (dateInput)="selectReceiptDate($event)">
                    <mat-hint>dd/mm/yyyy</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="receiptDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #receiptDatePicker></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>

            </div>


          </div> -->

      </div>
    </div>
  </div>

  <div class="row" style="padding-top: 2rem">
    <div class="col-4">
      <button view class="btn btn-primary float-center" style="width: 80%"  (click)="savePDCheque()">Save</button>
    </div>


    <div class="col-4">
      <button view class="btn btn-primary float-center" style="width: 80%" (click)="closeDialog()">Close</button>
    </div>
  </div>




</div>
