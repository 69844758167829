<app-dialog-header [title]="title" (closeDialogEvent)="closeCalculationDialog()"></app-dialog-header>
<div class="container-fluid dialog-wrapper">
  <div class="flex-wrap">
    <div class="" style="margin: 1rem auto 2rem auto;">
      <div style="padding-top: 1rem">
        <div class="row">
          <div class="d-flex flex-row">
            <div class="px-2"><button mat-raised-button color="primary" class="btn-sm btn-success" (click)="onCalculateHandler()">Calculate</button></div>
            <div class="px-2"><button mat-raised-button color="primary" [disabled]="!isCalculated" class="btn-sm btn-success" (click)="saveCalculation()" [disabled]="isSaved">Save</button></div>
          </div>
        </div>
        <div class="container">
          <div class="row card-view my-2">
            <div class="col-6">
              <div class="">
                <mat-form-field appearance="outline" class="m-1 w-100">
                  <mat-label>Facility No:</mat-label>
                  <input matInput [value]="dataObj.contract_id" (input)="changeHandler($event)" />
                </mat-form-field>
              </div>
            </div>
            <div class="col-6">
              <div class="">
                <mat-form-field appearance="outline" class="m-1 w-100">
                  <mat-label>Choose a date</mat-label>
                  <!-- [min]="minDate" [max]="maxDate"  -->
                  <input matInput [matDatepicker]="picker" placeholder="disable typing" readonly [(ngModel)]="till_date" (click)="picker.open()">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker touchUi #picker></mat-datepicker>
                </mat-form-field>
                <!-- (dateChange)="datePickerChangeHandler($event)" -->
                <!-- (click)="picker2.open()" -->
              </div>
            </div>
            <div class="col-6">
              <div class="">
                <mat-form-field appearance="outline" class="m-1 w-100">
                  <mat-label>TC Rate</mat-label>

                  <input matInput (keypress)="numberOnly($event)" type="number" [value]="tc_rate">
                </mat-form-field>
              </div>
            </div>
            <div class="col-6">
              <div class="">
                <mat-form-field appearance="outline" class="m-1 w-100">
                  <mat-label>Inquiry type</mat-label>
                  <mat-select aria-selected="true" [value]="inq_type">
                    <mat-option value="confirmedSettlement">Confirmed Settlement</mat-option>
                    <mat-option value="randomInquiry">Random Inquiry</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="col-6">
              <div class="">
                <mat-form-field appearance="outline" class="m-1 w-100">
                  <mat-label>Settlement reason</mat-label>
                  <mat-select aria-selected="true" [value]="settlement_reason">
                    <mat-option value="Cash Rich">Cash rich</mat-option>
                    <mat-option value="Vehicle Upgrade">Vehicle Upgrade</mat-option>
                    <mat-option value="Liability Reduction">Liability Reduction</mat-option>
                    <mat-option value="Transfer and release">Transfer and release</mat-option>
                    <mat-option value="Reason of Dissatisfaction">Reason of Dissatisfaction</mat-option>
                    <mat-option value="Third party settlement">Third party settlement</mat-option>
                    <mat-option value="Vehicle sell for Upgrade">Vehicle sell for Upgrade</mat-option>
                    <mat-option value="Obtain a Lease">Obtain a Lease</mat-option>
                    <mat-option value="Obtain a Loan">Obtain a Loan</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col card-view card-veiw-tile my-2 pb-2">
              <div class="px-1 ">
                <h5 class="mt-2">Settlement type</h5>
              </div>
              <div class="px-1 ">
                <mat-radio-group aria-label="Select an option" [(ngModel)]="settlement_type">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <mat-radio-button color="primary" value="N">
                        <span style="font-size: 14px; text-overflow:ellipsis">Settlement Balance</span>
                      </mat-radio-button>
                    </div>
                    <div class="col-12 col-md-6">
                      <mat-radio-button color="primary" value="R">
                        <span style="font-size: 14px; text-overflow:ellipsis">Refinance Balance</span>
                      </mat-radio-button>
                    </div>
                    <div class="col-12 col-md-6">
                      <mat-radio-button color="primary" value="T">
                        <span style="font-size: 14px; text-overflow:ellipsis">Transfer Balance</span>
                      </mat-radio-button>
                    </div>
                    <div class="col-12 col-md-6">
                      <mat-radio-button color="primary" value="Y">
                        <span style="font-size: 14px; text-overflow:ellipsis"> Reschedule Balance</span>
                      </mat-radio-button>
                    </div>
                  </div>
                </mat-radio-group>
              </div>
            </div>
            <div class="col card-view card-veiw-tile my-2 pb-2 d-flex align-items-center">
              <div class="px-1 col">
                <div>
                  <mat-checkbox class="row" color="primary" [(ngModel)]="waive_off">Waive Off</mat-checkbox>
                </div>
                <div>
                  <mat-checkbox class="row" color="primary" [(ngModel)]="only_to_close">Only to close (without deletion)</mat-checkbox>
                <div>
                  <mat-checkbox class="row" color="primary" [(ngModel)]="ofs">OFS balance</mat-checkbox>
                </div>
                <div>
                  <mat-checkbox class="row" color="primary" [(ngModel)]="special_contract">Special contract</mat-checkbox>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col card-view card-veiw-tile my-2 pb-2">
              <div class="px-1 ">
                <h5 class="mt-2">Differred criteria</h5>
              </div>
              <div class="px-1 ">
                <div class="row">
                  <div class="col-12">
                    <mat-radio-group aria-label="Select an option" [(ngModel)]="dif_criteria">
                      <mat-radio-button value="RATE" color="primary">
                        <span style="font-size: 14px">Rate</span>
                      </mat-radio-button>
                      <mat-radio-button value="AMOUNT" color="primary">
                        <span style="font-size: 14px">Amount</span>
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>

                <div class="row" style="padding-top: 1rem">
                  <div class="col-6">
                    <mat-form-field appearance="outline" style="width: 100%; height: 2rem ">
                      <mat-label>RNO</mat-label>
                      <input matInput type="number" (keypress)="numberOnly($event)" [disabled]="dif_criteria != 'AMOUNT'" autocomplete="off" [(ngModel)]="rno.amount">
                    </mat-form-field>
                  </div>
                  <div class="col-6">
                    <mat-form-field appearance="outline" style="width: 100%; ">
                      <mat-label>%</mat-label>
                      <input matInput type="number" (keypress)="numberOnly($event)" [disabled]="dif_criteria != 'RATE'" autocomplete="off" [(ngModel)]="rno.rate">
                    </mat-form-field>
                  </div>
                </div>

                <div class="row" style="padding-top: -1rem">
                  <div class="col-6">
                    <mat-form-field appearance="outline" style="width: 100%; height: 2rem ">
                      <mat-label>GSO</mat-label>
                      <input matInput type="number" (keypress)="numberOnly($event)" [disabled]="dif_criteria != 'AMOUNT'" autocomplete="off" [(ngModel)]="gso.amount">
                    </mat-form-field>
                  </div>
                  <div class="col-6">
                    <mat-form-field appearance="outline" style="width: 100%; ">
                      <mat-label>%</mat-label>
                      <input matInput type="number" (keypress)="numberOnly($event)" [disabled]="dif_criteria != 'RATE'" autocomplete="off" [(ngModel)]="gso.rate">
                    </mat-form-field>
                  </div>
                </div>

                <div id="defNum" class="row" style="padding-top: 0rem">
                  <div class="col-6">
                    <mat-form-field appearance="outline" style="width: 100%; padding-bottom: 0em; !important;">
                      <mat-label>INO</mat-label>
                      <input matInput type="number" (keypress)="numberOnly($event)" [disabled]="dif_criteria != 'AMOUNT'" autocomplete="off" [(ngModel)]="ino.amount">
                    </mat-form-field>
                  </div>
                  <div class="col-6">
                    <mat-form-field appearance="outline" style="width: 100%; ">
                      <mat-label>%</mat-label>
                      <input matInput type="number" (keypress)="numberOnly($event)" [disabled]="dif_criteria != 'RATE'" autocomplete="off" [(ngModel)]="ino.rate">
                    </mat-form-field>
                  </div>
                </div>

                <div class="row" style="padding-top: 0rem">
                  <div class="col-6">
                    <mat-form-field appearance="outline" style="width: 100%; ">
                      <mat-label>CHR</mat-label>
                      <input matInput type="number" (keypress)="numberOnly($event)" [disabled]="dif_criteria != 'AMOUNT'" autocomplete="off" [(ngModel)]="chr.amount">
                    </mat-form-field>
                  </div>
                  <div class="col-6">
                    <mat-form-field appearance="outline" style="width: 100%; ">
                      <mat-label>%</mat-label>
                      <input matInput type="number" (keypress)="numberOnly($event)" [disabled]="dif_criteria != 'RATE'" autocomplete="off" [(ngModel)]="chr.rate">
                    </mat-form-field>
                  </div>
                </div>

                <div class="row" style="padding-top: 0rem">
                  <div class="col">
                    <mat-form-field appearance="outline" style="width: 100%">
                      <mat-label>DCF</mat-label>
                      <input matInput type="number" (keypress)="numberOnly($event)" autocomplete="off" [(ngModel)]="dcf">
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>


            <div *ngIf="ofs" class="col card-view card-veiw-tile my-2 pb-2">
              <div class="px-1 col">
                <div class="px-1 ">
                  <h4 class="mt-2">OFS</h4>
                </div>
                <div class="row">
                  <div class="col-12 col-md-4">
                    <mat-label>Release Type</mat-label>                  
                  </div>
                  <div class="col-12 col-md-8">
                    <mat-radio-group [(ngModel)]="relType" [disabled] = "!ofs"  autocomplete="off" aria-label="Select an option" >
                      <mat-radio-button color="primary" checked="true" value="Cheque">
                        <span style="font-size: 14px">Cheque</span>
                      </mat-radio-button>
                      <span class="tab"></span>
                      <mat-radio-button color="primary" value="UponPO">
                        <span style="font-size: 14px">Upon PO</span>
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>
                  
                  <div class="">
                    <mat-form-field appearance="outline" class="m-1 w-100">
                      <mat-label>Bank / Institute</mat-label>
                      <mat-select [(ngModel)]="institute" [disabled] = "!ofs"  autocomplete="off" aria-selected="true" [value]="inq_type">
                        <mat-option *ngFor="let inst of dataset" [value]="inst.BNKCODE">{{inst.BNKNAME}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="">
                    <mat-form-field appearance="outline" class="m-1 w-100">
                      <mat-label>Address 1:</mat-label>
                      <input matInput [(ngModel)]="add1" [disabled] = "!ofs"  autocomplete="off" />
                    </mat-form-field>
                  </div>

                  <div class="">
                    <mat-form-field appearance="outline" class="m-1 w-100">
                      <mat-label>Address 2:</mat-label>
                      <input matInput [(ngModel)]="add2" [disabled] = "!ofs"  autocomplete="off" />
                    </mat-form-field>
                  </div>

                  <div class="">
                    <mat-form-field appearance="outline" class="m-1 w-100">
                      <mat-label>Address 3:</mat-label>
                      <input matInput [(ngModel)]="add3" [disabled] = "!ofs"  autocomplete="off" />
                    </mat-form-field>
                  </div>

                  <div class="">
                    <mat-form-field appearance="outline" class="m-1 w-100">
                      <mat-label>Address 4:</mat-label>
                      <input matInput [(ngModel)]="add4" [disabled] = "!ofs"  autocomplete="off"/>
                    </mat-form-field>
                  </div>

                  <div class="">
                    <mat-form-field appearance="outline" class="m-1 w-100">
                      <mat-label>CF Letter Expiry Date</mat-label>
                      <!-- [min]="minDate" [max]="maxDate"  -->

                      <input matInput [matDatepicker]="picker" placeholder="disable typing" readonly [(ngModel)]="expdate" 
                      [disabled] = "!ofs" (click)="picker.open()">
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker touchUi #picker></mat-datepicker>
                    </mat-form-field>
                    <!--  (dateChange)="datePickerChangeHandler($event)" -->
                  </div>

                  <div class = " d-flex">
                    <label for="fileRequest">Upload Request Letter</label>
                    <input type="file" id="fileRequest" class="file-upload" (input)="handleFileInputRequestLetter($event)" hidden>
                    <mat-progress-bar class="progress-bar" mode="determinate"
                    [value]="uploadProgress"></mat-progress-bar>

                    <label for="fileCF">Upload CF Letter (OFC)</label>
                    <input type="file" id="fileCF" class="file-upload" (input)="handleFileInputCFLetter($event)" hidden>
                    <mat-progress-bar class="progress-bar" mode="determinate"
                    [value]="uploadProgress"></mat-progress-bar>

                    <label (click)="onSubmitOFS()">Save Letter</label>
                  </div>
                  
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col card-view card-veiw-tile my-2 pb-2">
              <div class="px-1 ">
                <div class="px-1 ">
                  <h5 class="mt-2">Approval Send to</h5>
                </div>
              </div>
              <div class="px-1 ">
                <div id="types">
                  <mat-radio-group [value]="selectedSendTo" aria-label="Select an option">
                    <mat-radio-button value="BM">
                      BM
                    </mat-radio-button>
                    <mat-radio-button value="AM">
                      Assist. Mgr
                    </mat-radio-button>
                    <mat-radio-button value="CM">
                      Col. Manager
                    </mat-radio-button>
                    <mat-radio-button value="RM">
                      RM
                    </mat-radio-button>
                    <mat-radio-button value="SM">
                      SM
                    </mat-radio-button>
                    <mat-radio-button value="AGMR">
                      AGMR
                    </mat-radio-button>
                    <mat-radio-button value="SAGMR">
                      SAGMR
                    </mat-radio-button>
                    <mat-radio-button value="DGMR">
                      DGMR
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
            </div>
            <div class="col card-view card-veiw-tile my-2 pb-2">
              <div class="px-1  col">
                <div class="px-1 ">
                  <h5 class="mt-2">Comment</h5>
                </div>
                <div class="px-1 ">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Leave a comment</mat-label>
                    <textarea matInput placeholder="Comment.."></textarea>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col card-veiw-tile my-2">
              <button mat-raised-button color="primary" class="w-100" (click)="letterViewHandler('REQ')">View Request Letter</button>
            </div>
            <div class="col card-veiw-tile my-2">
              <button mat-raised-button color="accent" class="w-100" (click)="letterViewHandler('CF')">View CF Letter</button>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-1">
        </div>
        <div class="col-11" style="padding-top: 10px; z-index: 0">
          <hr>
          <mat-card class="example-card" class="w-100 mat-elevation-z4" style="background-color: #f0f0f0;"
            *ngIf="hasData">
            <mat-card-subtitle>Contract</mat-card-subtitle>
            <mat-card-title style="font-weight: 700;font-size: 30px;">{{title}}</mat-card-title>
            <mat-card-content>
              <table>
                <tr *ngFor="let i of tableData">
                  <td style="padding: 4px 26px;">{{i.name}}</td>
                  <td>{{i.value}}</td>
                </tr>
              </table>
            </mat-card-content>
            <mat-card-actions>
              <button mat-raised-button color="primary" class="m-3" (click)="clearHandler()">Close</button>
            </mat-card-actions>
          </mat-card>
        </div>
      </div>

      <div *ngIf="isCalculated" id="calculator-result" class="container p-3">
        <div class="row">
          <div class="col" style="font-weight: 600;">Name</div>
          <div class="col" style="font-weight: 600;">Value</div>
          <div class="col" style="font-weight: 600;">Value new</div>
        </div>

        <div class="row" *ngFor="let item of calculatedResult">
          <div class="col">{{item["NAME_TAG"]}}</div>
          <div class="col">{{item["TAG_VAL"]}}</div>
          <div class="col">{{item["TAG_VAL_NEW"]}}</div>
        </div>
      </div>

      <!-- <app-contract-request (selectToFormEvent)="selectToFormFromContract($event)">

      </app-contract-request> -->

    </div>
  </div>
</div>