import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MapsAPILoader } from "@agm/core";
import { AgmMap } from '@agm/core';
import { VisitDeskDialogComponent } from "../visit-desk-dialog/visit-desk-dialog.component";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material/dialog";
import { VisitDeskGeoLocationModel } from "../../models/visit-desk/visit-desk-geo-location-model";
import { FieldOfficerService } from "../../services/field-officer/field-officer-service";
import { GeoLocationModel } from "../../models/field-officer/geo-location-model";
import { HttpErrorResponse } from "@angular/common/http";
import { VisitSummaryViewComponent } from '../visit-summary-view/visit-summary-view.component';
import { UserAuthenticationService } from 'src/app/services/user-authentication/user-authentication.service';
import { DateUtil } from 'src/app/utils/DateUtil';
import { DistancePermissionService } from 'src/app/services/distence-tracker/distance-permission.service';

// interface distancePermission {
//   res?: string;
//   user?: string;

// }

@Component({
  selector: 'app-field-officer-tracking-dialog',
  templateUrl: './field-officer-tracking-dialog.component.html',
  styleUrls: ['./field-officer-tracking-dialog.component.scss']
})
export class FieldOfficerTrackingDialogComponent implements OnInit {
  // trackNo data - DNM - 28/11/2023
  selectedNumber: number;
  numbers: number[] = Array.from({ length: 10 }, (_, i) => i + 1);
  trackNo: number;
  numOfTracks: number;
  lastTrackModule: number;


  readonly title: string = 'Field Officer Tracking';
  lat = 6.922973;
  lng = 79.85734;

  //default values
  latitude: number = 6.922973;
  longitude: number = 79.85734;


  zoom: number = 0;
  address: string = "";
  private geoCoder: any;

  public origin: any;
  public destination: any;
  public waypoints: any[] = [];
  public wayPointArray: any[] = [];
  public numberOfSlots: number;
  public listModule: number;
  public arrayNo: number = 0;
  private listSize: number;
  //public res: string;
  public user: string = "";

  public renderOptions = {
    suppressMarkers: true,
  };
  visitDeskGeoLocationList: VisitDeskGeoLocationModel;
  geoLocationList: GeoLocationModel[] = [];

  totalDistance: number = 0;
  realDistance: number = 0;
  private directionService: any = null;
  routeURL: string = 'https://www.google.com/maps/dir';
//  routeURL: string = 'https://roads.googleapis.com/v1/snapToRoads';


  // public markerOptions = {
  //   origin: {
  //     infoWindow: 'Miss.Kamala, Union Place, 0778 558 669.',
  //     icon: 'http://i.imgur.com/7teZKif.png',
  //   },
  //   waypoints:
  //   {
  //     infoWindow: 'Mr.Sunil, Borella 071589789.',
  //     icon: 'http://i.imgur.com/7teZKif.png',
  //   }

  //   ,
  //   destination: {
  //     infoWindow: 'MR.Perera, Moratuwa',
  //     icon: 'http://i.imgur.com/7teZKif.png',
  //   },
  // };

  visitDate = '';

  constructor(private dialogRef: MatDialogRef<VisitDeskDialogComponent>,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone, @Inject(MAT_DIALOG_DATA) public data: any,
    protected fieldOfficerService: FieldOfficerService,
    public summaryDialog: MatDialog,
    private auth: UserAuthenticationService,
    private distencePermissionService: DistancePermissionService
  ) {

  }

  ngOnInit() {
    this.selectedNumber = this.trackNo;
    this.user = this.auth.currentUserValue.username;
    this.directionService = new google.maps.DirectionsService;
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
    });
    // uncomment to the live release
    //this.loadOfficerTrackingData();
  }

  async loadRoute() {

    //adding updates to load route devided route to 25 wayponts. 2023/11/27 - DulsaraMannakkara
    this.trackNo = this.selectedNumber;
    this.listSize = this.geoLocationList ? this.geoLocationList.length : 0;
    this.listSize = this.listSize;
    this.numOfTracks = Math.floor(this.listSize / 25) + 1;
    this.lastTrackModule = Math.floor(this.listSize % 25);
    console.log("listSize : " + this.listSize);
    console.log("numOfTracks : " + this.numOfTracks);
    console.log("lastTrackModule : " + this.lastTrackModule);
    console.log("trackNo : " + this.trackNo);

//middle tracks
    if (this.trackNo < this.numOfTracks) {
      //1st track in over 25 way points
      if (this.trackNo == 1) {
        console.log("1st track in over 25 way points");
        //origin
        this.origin = 0;
        this.destination = 0;
        this.waypoints = [];
        let originlat = Number(this.geoLocationList[0].lat);
        let originlng = Number(this.geoLocationList[0].lng);
        // this.origin = { lat: 6.922973, lng: 79.85734 };
        // this.routeURL = this.routeURL + '/6.922973,79.85734';
        this.origin = { lat: originlat, lng: originlng };
        console.log("origin: " + JSON.stringify(this.origin));
        this.routeURL = this.routeURL + '/' + originlat + ',' + this.ngOnInit

        //way points
        for (let i = 0; i + ((this.trackNo - 1) * 25) < ((this.trackNo - 1) * 25) + 24; i++) {
          if (this.geoLocationList[((this.trackNo - 1) * 25) + i].lat) {
            let wayPointObject = {
              location: { lat: Number(this.geoLocationList[(25 * (this.trackNo - 1)) + i].lat), lng: Number(this.geoLocationList[(25 * (this.trackNo - 1)) + i].lng) }
            };
            this.routeURL = this.routeURL + '/' + Number(this.geoLocationList[(25 * (this.trackNo - 1)) + i].lat) + ',' + Number(this.geoLocationList[(25 * (this.trackNo - 1)) + i].lng);
            this.waypoints.push(wayPointObject);
          }
        }
        console.log("way points mid: " + JSON.stringify(this.waypoints));

        //destination
        let destlat = Number(this.geoLocationList[25 * ((this.trackNo - 1)) + 24].lat);
        let destlng = Number(this.geoLocationList[25 * ((this.trackNo - 1)) + 24].lng);
        // this.origin = { lat: 6.922973, lng: 79.85734 };
        // this.routeURL = this.routeURL + '/6.922973,79.85734';
        this.destination = { lat: destlat, lng: destlng };
        console.log("destination: " + JSON.stringify(this.destination));
        this.routeURL = this.routeURL + '/' + destlat + ',' + this.ngOnInit
        console.log(this.routeURL);

      }
      //next tracks in over 25 way points
      else {
        console.log("next tracks in over 25 way points");
        //origin
        this.origin = 0;
        this.destination = 0;
        this.waypoints = [];
        let originlat = Number(this.geoLocationList[25 * ((this.trackNo - 1)) - 1].lat);
        let originlng = Number(this.geoLocationList[25 * ((this.trackNo - 1)) - 1].lng);
        // this.origin = { lat: 6.922973, lng: 79.85734 };
        // this.routeURL = this.routeURL + '/6.922973,79.85734';
        this.origin = { lat: originlat, lng: originlng };
        console.log("origin: " + JSON.stringify(this.origin));
        this.routeURL = this.routeURL + '/' + originlat + ',' + this.ngOnInit

        //way points
        for (let i = 0; i + ((this.trackNo - 1) * 25) < ((this.trackNo - 1) * 25) + 24; i++) {
          if (this.geoLocationList[((this.trackNo - 1) * 25) + i].lat) {
            let wayPointObject = {
              location: { lat: Number(this.geoLocationList[(25 * (this.trackNo - 1)) + i].lat), lng: Number(this.geoLocationList[(25 * (this.trackNo - 1)) + i].lng) }
            };
            this.routeURL = this.routeURL + '/' + Number(this.geoLocationList[(25 * (this.trackNo - 1)) + i].lat) + ',' + Number(this.geoLocationList[(25 * (this.trackNo - 1)) + i].lng);
            this.waypoints.push(wayPointObject);
          }
        }
        console.log("way points mid: " + JSON.stringify(this.waypoints));

        //destination
        let destlat = Number(this.geoLocationList[25 * ((this.trackNo - 1)) + 24].lat);
        let destlng = Number(this.geoLocationList[25 * ((this.trackNo - 1)) + 24].lng);
        // this.origin = { lat: 6.922973, lng: 79.85734 };
        // this.routeURL = this.routeURL + '/6.922973,79.85734';
        this.destination = { lat: destlat, lng: destlng };
        console.log("destination: " + JSON.stringify(this.destination));
        this.routeURL = this.routeURL + '/' + destlat + ',' + this.ngOnInit
        console.log(this.routeURL);
      }
    }
    //last track
    else if (this.trackNo == this.numOfTracks) {
      // 1st track in less 25 way points
      if (this.trackNo == 1) {
        console.log("1st track in less 25 way points");
        //origin
        this.origin = 0;
        this.destination = 0;
        this.waypoints = [];
        let originlat = Number(this.geoLocationList[0].lat);
        let originlng = Number(this.geoLocationList[0].lng);
        // this.origin = { lat: 6.922973, lng: 79.85734 };
        // this.routeURL = this.routeURL + '/6.922973,79.85734';
        this.origin = { lat: originlat, lng: originlng };
        console.log("origin: " + JSON.stringify(this.origin));
        this.routeURL = this.routeURL + '/' + originlat + ',' + this.ngOnInit

        //way points
        for (let i = 0; i + ((this.trackNo - 1) * 25) < ((this.trackNo - 1) * 25) + (this.lastTrackModule - 1); i++) {
          if (this.geoLocationList[((this.trackNo - 1) * 25) + i].lat) {
            let wayPointObject = {
              location: { lat: Number(this.geoLocationList[(25 * (this.trackNo - 1)) + i].lat), lng: Number(this.geoLocationList[(25 * (this.trackNo - 1)) + i].lng) }
            };
            this.routeURL = this.routeURL + '/' + Number(this.geoLocationList[(25 * (this.trackNo - 1)) + i].lat) + ',' + Number(this.geoLocationList[(25 * (this.trackNo - 1)) + i].lng);
            this.waypoints.push(wayPointObject);
          }
        }
        console.log("way points final: " + JSON.stringify(this.waypoints));

        //destination
        let destlat = Number(this.geoLocationList[25 * ((this.trackNo - 1)) + (this.lastTrackModule - 1)].lat);
        let destlng = Number(this.geoLocationList[25 * ((this.trackNo - 1)) + (this.lastTrackModule - 1)].lng);
        // this.origin = { lat: 6.922973, lng: 79.85734 };
        // this.routeURL = this.routeURL + '/6.922973,79.85734';
        this.destination = { lat: destlat, lng: destlng };
        console.log("destination: " + JSON.stringify(this.destination));
        this.routeURL = this.routeURL + '/' + destlat + ',' + this.ngOnInit
        console.log(this.routeURL);

      }
      // last track in over 25 way points
      else {
        console.log("last track in over 25 way points")
        //origin
        this.origin = 0;
        this.destination = 0;
        this.waypoints = [];
        let originlat = Number(this.geoLocationList[25 * ((this.trackNo - 1)) - 1].lat);
        let originlng = Number(this.geoLocationList[25 * ((this.trackNo - 1)) - 1].lng);
        // this.origin = { lat: 6.922973, lng: 79.85734 };
        // this.routeURL = this.routeURL + '/6.922973,79.85734';
        this.origin = { lat: originlat, lng: originlng };
        console.log("origin: " + JSON.stringify(this.origin));
        this.routeURL = this.routeURL + '/' + originlat + ',' + this.ngOnInit

        //way points
        for (let i = 0; i + ((this.trackNo - 1) * 25) < ((this.trackNo - 1) * 25) + (this.lastTrackModule - 1); i++) {
          if (this.geoLocationList[((this.trackNo - 1) * 25) + i].lat) {
            let wayPointObject = {
              location: { lat: Number(this.geoLocationList[(25 * (this.trackNo - 1)) + i].lat), lng: Number(this.geoLocationList[(25 * (this.trackNo - 1)) + i].lng) }
            };
            this.routeURL = this.routeURL + '/' + Number(this.geoLocationList[(25 * (this.trackNo - 1)) + i].lat) + ',' + Number(this.geoLocationList[(25 * (this.trackNo - 1)) + i].lng);
            this.waypoints.push(wayPointObject);
          }
        }
        console.log("way points final: " + JSON.stringify(this.waypoints));

        //destination
        let destlat = Number(this.geoLocationList[25 * ((this.trackNo - 1)) + (this.lastTrackModule - 1)].lat);
        let destlng = Number(this.geoLocationList[25 * ((this.trackNo - 1)) + (this.lastTrackModule - 1)].lng);
        // this.origin = { lat: 6.922973, lng: 79.85734 };
        // this.routeURL = this.routeURL + '/6.922973,79.85734';
        this.destination = { lat: destlat, lng: destlng };
        console.log("destination: " + JSON.stringify(this.destination));
        this.routeURL = this.routeURL + '/' + destlat + ',' + this.ngOnInit
        console.log(this.routeURL);

      }

    }
    else {
      alert("No Track Data");
    }
  }

  async loadOfficerTrackingData() {
    let userid = this.auth.currentUserValue.code;
    let visitDateProcess = this.visitDate ? new Date(this.visitDate) : new Date();
    this.geoLocationList = [];

    let date = DateUtil.getDateString(visitDateProcess);

    await this.fieldOfficerService.getFeildOfficerTrackingDetails(userid, date).subscribe({
      next: (responseResult: any) => {

        // console.debug("RESULT" + JSON.stringify(responseResult));

        this.geoLocationList = responseResult[0].locationlist;
      },
      complete: () => {
        this.loadRoute();
      },
      error: (error: HttpErrorResponse) => {
        console.log(error)
        this.loadRoute();
      },
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  calculateDistance() {
    let destinationToSend = this.destination;
    let waypointsToSend = this.waypoints.map(item => {
      return {
        location: item,
        stopover: true,
      }
    });
    let origin = new google.maps.LatLng({ lat: this.origin.lat, lng: this.origin.lng });
    const distance = this.getRouteDistance(origin, waypointsToSend, destinationToSend, true);
  }

  getRouteDistance = (origin: google.maps.LatLng, waypoints: any[], destination: any, optimize: boolean): number => {
    let distanceTotal = 0;
    const directions = new google.maps.DirectionsService;
    directions
      .route({
        origin: origin,
        waypoints: waypoints,
        destination: destination,
        optimizeWaypoints: optimize,
        travelMode: google.maps.TravelMode.DRIVING
      }, (result: google.maps.DirectionsResult, status: google.maps.DirectionsStatus) => {
        if (status == "OK") {
          result.routes[0].legs.map(leg => {
            distanceTotal += leg.distance.value;
          })

          this.totalDistance = distanceTotal / 1000;
          this.realDistance = this.realDistance + this.totalDistance;
          console.log("REAL DISTANCE" + this.realDistance);
          console.log("TOTAL DISTANCE" + distanceTotal);
        } else {
          distanceTotal = -1;
        }
      });
    return distanceTotal < 0 ? -1 : distanceTotal;
  }

  selectVisitupDate(e: any) {
    this.visitDate = e.value;
  }

  openVisitSummary() {
    //Add distance table access gateway
    //created by DulsaraMannakkara 28.08.2023
    const user_name = this.auth.currentUserValue.username;

    //access for view report in field officer tracking feature
    // if (user_name === "geeths" || user_name === "ruwann" || user_name === "achalamr" || user_name === "bileshs" || user_name === "damikads" || user_name === "dulsaran" || user_name === "kalanag") {
    this.distencePermissionService.getDistancePermission(this.user).subscribe({
      next: result => {
        console.log(result);
        const value = result[0].res;

        if (value === 'Y') {
          let summaryRef = this.summaryDialog.open(VisitSummaryViewComponent, {
            autoFocus: false,
            maxHeight: '100vh'
          })
          summaryRef.afterClosed().subscribe(data => {
            console.log("Visit summary closed")
          })
        }
        else {
          alert("You Haven't Access to view Report");
        }
      }
    })
    // } else {
    //   alert("You don't have access!");
    // }
  }
}
