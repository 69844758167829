import { AfterViewInit, Component, NgZone, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { VisitSelectionDeskDialogComponent } from "../visit-selection-desk-dialog/visit-selection-desk-dialog.component";
import { CallDeskDialogComponent } from "../call-desk-dialog/call-desk-dialog.component";
import { MapsAPILoader } from "@agm/core";
import { SocialAuthService, SocialUser } from "angularx-social-login";
import { Router } from "@angular/router";
import { SettlementCalculationComponent } from "../settlement-calculation/settlement-calculation.component";
import { VisitSelectionUpdateDeskDialogComponent } from "../visit-selection-update-desk-dialog/visit-selection-update-desk-dialog.component";
import { RouteManagementDialogComponent } from '../route-management/route-management-dialog/route-management-dialog.component';
import { ReceiptDialogComponent } from "../receipt-dialog/receipt-dialog.component";
import { PdChequeDialogComponent } from "../pd-cheque-dialog/pd-cheque-dialog.component";
import { FieldOfficerTrackingDialogComponent } from "../field-officer-tracking-dialog/field-officer-tracking-dialog.component";
import { User } from 'src/app/models/user/user-model';
import { Subscription } from 'rxjs';
import { UserAuthenticationService } from 'src/app/services/user-authentication/user-authentication.service';
import { TodaysCallListV2Component } from '../v2/todays-call-list-v2/todays-call-list-v2.component';
import { TodaysVisitListV2Component } from '../v2/todays-visit-list-v2/todays-visit-list-v2.component';
import { RouteAllocationV2Component } from '../v2/route-allocation-v2/route-allocation-v2.component';

declare var anime: any;

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit, AfterViewInit {
  title = 'VIRTUAL RECOVERY APP';
  lat = 6.922973;
  lng = 79.85734;

  latitude: number = 6.922973;
  longitude: number = 79.85734;
  zoom: number = 0;
  address: string = "";
  private geoCoder: any;

  // @ViewChild('search')
  // public searchElementRef: ElementRef;

  public origin: any;
  public destination: any;
  public waypoints: any;

  public isMenuOpen: boolean = false;

  user?: SocialUser;
  loggedIn?: boolean;

  public renderOptions = {
    suppressMarkers: true,
  };


  public markerOptions = {
    origin: {
      infoWindow: 'Miss.Kamala, Union Place, 0778 558 669.',
      icon: 'http://i.imgur.com/7teZKif.png',
    },
    waypoints:
    {
      infoWindow: 'Mr.Sunil, Borella 071589789.',
      icon: 'http://i.imgur.com/7teZKif.png',
    }

    ,
    destination: {
      infoWindow: 'MR.Perera, Moratuwa',
      icon: 'http://i.imgur.com/7teZKif.png',
    },
  };


  constructor(public dialog: MatDialog,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private authService: SocialAuthService,
    private router: Router,
    private auth: UserAuthenticationService
  ) { }

  // current user variables
  currentUser: User;
  currentUserSubscription: Subscription;

  ngOnInit() {
    this.getDirection();

    // USING GLOBAL USER DATA FOR ANYWHERE
    // Step 1: import UserAuthenticationService

    // method 1: As a observable
    this.currentUserSubscription = this.auth.currentUser.subscribe(
      user => {
        this.currentUser = user;
        console.log(user);
      }
    )
    
    // method 2: as a common/utility function
    // currentUserValue is a getter so this will not be called as a function
    // calling is as a public variable will be enough
    console.log(this.auth.currentUserValue)
  }

  ngOnDestroy() {
    this.currentUserSubscription.unsubscribe();
  }

  public onSidenavClick(): void {
    this.isMenuOpen = false;
  }

  checkIn() {
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;
    });
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        console.log("GEO LOCATION" + this.latitude + this.longitude);

        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  getAddress(latitude: any, longitude: any) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results: any, status: any) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  getDirection() {
    this.origin = { lat: 6.922973, lng: 79.85734 };
    this.destination = { lat: 6.931028, lng: 79.8694 };
    this.waypoints = [
      { location: { lat: 6.920779, lng: 79.857115 } },
      { location: { lat: 6.919464, lng: 79.877599 } },
      { location: { lat: 6.914881, lng: 79.876527 } },
      // {location: { lat: 6.773374, lng: 79.882934 }}, // moratuwa
    ];
  }

  navigateToGoogleApp() {
    // window.open('https://www.google.com/maps/dir/\'6.922973,79.85734\'/\'6.920779,79.857115\'/\'6.919464,79.877599\'/\'6.914881,79.876527\'/\'6.931028,79.8694\'/@6.9201125,79.8612081,15z/data=!4m32!4m31!1m5!1m1!1s0x0:0x7c5413771ce3604b!2m2!1d79.85734!2d6.922973!1m5!1m1!1s0x0:0xf3ab7965883b902f!2m2!1d79.857115!2d6.920779!1m5!1m1!1s0x0:0x56cb6b83381ae7a4!2m2!1d79.877599!2d6.919464!1m5!1m1!1s0x0:0x2ca93ecc70be4048!2m2!1d79.876527!2d6.914881!1m5!1m1!1s0x0:0x122ea0efdeac2e42!2m2!1d79.8694!2d6.931028!3e0', '_blank')
  }

  // isMenuOpen(){
  //   return true;
  // }

  openVisitSelectionDesk() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '800px';
    dialogConfig.width = '1200px';
    // dialogConfig.data = {settlementSearchOption: this.settlementSearchOption,
    //   selectedSettlementJobXid: this.selectedSettlementJobXid,
    //   selectedSettlementJobStatus: this.selectedSettlementJobStatus
    // };
    const dialogRef = this.dialog.open(VisitSelectionDeskDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        console.log(`Dialog result: WORKS`);
      }
    );
  }

  openVisitSelectionUpdateDesk() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '800px';
    dialogConfig.width = '1200px';
    // dialogConfig.data = {settlementSearchOption: this.settlementSearchOption,
    //   selectedSettlementJobXid: this.selectedSettlementJobXid,
    //   selectedSettlementJobStatus: this.selectedSettlementJobStatus
    // };
    const dialogRef = this.dialog.open(VisitSelectionUpdateDeskDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        console.log(`Dialog result: WORKS`);
      }
    );
  }

  openFieldOfficerTracking() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '800px';
    dialogConfig.width = '1200px';
    // dialogConfig.data = {settlementSearchOption: this.settlementSearchOption,
    //   selectedSettlementJobXid: this.selectedSettlementJobXid,
    //   selectedSettlementJobStatus: this.selectedSettlementJobStatus
    // };
    const dialogRef = this.dialog.open(FieldOfficerTrackingDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        console.log(`Dialog result: WORKS`);
      }
    );
  }

  openReceipt() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '800px';
    dialogConfig.width = '800px';

    const dialogRef = this.dialog.open(ReceiptDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        console.log(`Dialog result: WORKS`);
      }
    );
  }

  openCallDesk() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '800px';
    dialogConfig.width = '1200px';
    const dialogRef = this.dialog.open(TodaysCallListV2Component, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        console.log(`Call Desk opened`);
      }
    );
  }

  ngAfterViewInit(): void {
    // var anDef: any;
    // anDef = { opacityIn: [], scaleIn: [], scaleOut: 0, durationIn: 0, durationOut: 0, delay: 0 };
    // anDef.opacityIn = [0, 1];
    // anDef.scaleIn = [0.2, 1];
    // anDef.scaleOut = 3;
    // anDef.durationIn = 800;
    // anDef.durationOut = 600;
    // anDef.delay = 500;

    // anime.timeline({ loop: true })
    //   .add({
    //     targets: '.anDef .letters-1',
    //     opacity: anDef.opacityIn,
    //     scale: anDef.scaleIn,
    //     duration: anDef.durationIn
    //   }).add({
    //     targets: '.anDef .letters-1',
    //     opacity: 0,
    //     scale: anDef.scaleOut,
    //     duration: anDef.durationOut,
    //     easing: "easeInExpo",
    //     delay: anDef.delay
    //   }).add({
    //     targets: '.anDef .letters-2',
    //     opacity: anDef.opacityIn,
    //     scale: anDef.scaleIn,
    //     duration: anDef.durationIn
    //   }).add({
    //     targets: '.anDef .letters-2',
    //     opacity: 0,
    //     scale: anDef.scaleOut,
    //     duration: anDef.durationOut,
    //     easing: "easeInExpo",
    //     delay: anDef.delay
    //   }).add({
    //     targets: '.anDef .letters-3',
    //     opacity: anDef.opacityIn,
    //     scale: anDef.scaleIn,
    //     duration: anDef.durationIn
    //   }).add({
    //     targets: '.anDef .letters-3',
    //     opacity: 0,
    //     scale: anDef.scaleOut,
    //     duration: anDef.durationOut,
    //     easing: "easeInExpo",
    //     delay: anDef.delay
    //   }).add({
    //     targets: '.anDef',
    //     opacity: 0,
    //     duration: 500,
    //     delay: 500
    //   });
  }

  openPdCheque() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = false;
    dialogConfig.height = '750px';
    dialogConfig.width = '1200px';
    const dialogRef = this.dialog.open(PdChequeDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        console.log(`Call Calculation dialog opened`);
      }
    );
  }

  openCalculation() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = false;
    dialogConfig.height = '750px';
    dialogConfig.width = '1200px';
    const dialogRef = this.dialog.open(SettlementCalculationComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        console.log(`Call Calculation dialog opened`);
      }
    );
  }

  openCollectorPerformance(){

    window.open('http://www.cfmobile.lk/BranchlessB_Live/FacilityInq/FacilityInquary/?FACNO=5713749817', 'blank')


  }

  todaysVisitListReport() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = false;
    dialogConfig.height = '750px';
    dialogConfig.width = '1200px';
    const dialogRef = this.dialog.open(TodaysVisitListV2Component, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        console.log(`Todays Visit Lisit Report dialog opened`);
      }
    );
  }

  openInquiry() {
    window.open('https://www.cfmobile.lk/crmesb/ESBInfo/CRMCallCenter/CRMCallCenter?OPPORTUNITYID=300000053159026', '_blank')
  }

  signOut(): void {
    this.authService.signOut();
    this.auth.logout(this.router);
  }

  openRouteManagement(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '800px';
    dialogConfig.width = '1200px';
    const dialogRef = this.dialog.open(RouteManagementDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      console.log(data);
    })
  }

  openRouteAllocation(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '800px';
    dialogConfig.width = '1200px';
    const dialogRef = this.dialog.open(RouteAllocationV2Component, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      console.log(data);
    })
  }
}
