import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SocialAuthService } from 'angularx-social-login';
import { LocationAutomationService } from 'src/app/services/locationAutomationService/location-automation.service';
import { UserAuthenticationService } from 'src/app/services/user-authentication/user-authentication.service';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss']
})
export class NavigationBarComponent implements OnInit {

  constructor(private authService: SocialAuthService, private auth: UserAuthenticationService, private router: Router, private locationAutomationService: LocationAutomationService) { }

  loggedIn: boolean = false;

  ngOnInit(): void {
    this.loggedIn = this.auth.currentUserValue ? true : false
  }

  logout() {
    // turning off the locationAutomation service when user logging off 🤓
    console.log("Turning Off Location Automation Service")
    this.locationAutomationService.stopSendingCoordinates(this.auth.currentUserValue.username)

    this.loggedIn = false
    this.authService.signOut()
    this.auth.logout(this.router)
  }

}
