import { Component, OnInit } from '@angular/core';

interface Status {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-create-user-roles',
  templateUrl: './create-user-roles.component.html',
  styleUrls: ['./create-user-roles.component.scss']
})



export class CreateUserRolesComponent implements OnInit {

  selectedValue?: string;

  status: Status[] = [
    {value: 'select', viewValue: 'Select'},
    {value: 'active', viewValue: 'Active'},
    {value: 'inactive', viewValue: 'Inactive'},
  ];

  constructor() { }

  ngOnInit(): void {
  }







}
