import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RouteAllocationContract } from 'src/app/models/route-allocation/route-allocation-contract-visit';
import { RouteAllocationService } from 'src/app/services/route-allocation/route-allocation.service';
import { RouteServicesService } from 'src/app/services/route-management/route-services';
import { UserAuthenticationService } from 'src/app/services/user-authentication/user-authentication.service';
import { DateUtil } from 'src/app/utils/DateUtil';
import { DurationUtil } from 'src/app/utils/DurationUtil';

@Component({
  selector: 'app-allocated-route-updated',
  templateUrl: './allocated-route-updated.component.html',
  styleUrls: ['./allocated-route-updated.component.scss']
})
export class AllocatedRouteUpdatedComponent implements OnInit {

  readonly title: string = "Update allocated route"

  contractNo: string = null
  user: string = null
  routes: {
    routeId: string,
    name: string
  }[] = []
  selectedRouteId: string = ""
  suggestedRouteId: string = "default"
  suggestedRouteName: string = "Not selected"

  proposedDate: Date = null
  selectedDate: Date = null

  constructor(private dialogRef: MatDialogRef<AllocatedRouteUpdatedComponent>, private auth: UserAuthenticationService, private routeService: RouteServicesService, @Inject(MAT_DIALOG_DATA) public data: { data: RouteAllocationContract }, private snackbar: MatSnackBar, private service: RouteAllocationService) { }

  ngOnInit(): void {
    this.user = this.auth.currentUserValue.code
    let injected = this.data.data

    this.contractNo = injected.contractno
    if (injected.proposedDate == " ") {
      this.selectedDate = null
    } else {
      this.selectedDate = new Date(injected.proposedDate)
    }

    this.getRoutesForUser(() => {
      this.selectedRouteId = injected.suggestedRouteId
    });
  }

  getRoutesForUser(callback: () => void) {
    this.routeService.getRoutesByUserId(this.user).subscribe({
      next: (value: any[]) => {
        this.routes = value?.map(item => {
          let { route, name } = item
          return {
            routeId: route,
            name: name
          }
        })

        callback();
      },
      error: err => {
        console.log(err)
      }
    })
  }

  updateRouteAllocation() {
    if (!this.selectedDate) {
      this.snackbar.open("Please select a date", 'Dismiss', {
        duration: DurationUtil.TWO_SEC,
        horizontalPosition: "right",
        verticalPosition: "top",
        panelClass: ['mat-toolbar', 'mat-primary']
      });
      return;
    }

    if (this.selectedRouteId == " ") {
      this.snackbar.open("Please select a route", 'Dismiss', {
        duration: DurationUtil.TWO_SEC,
        horizontalPosition: "right",
        verticalPosition: "top",
        panelClass: ['mat-toolbar', 'mat-primary']
      });
      return;
    }

    let selectedName: string = null
    if (this.selectedRouteId == " ") {
      selectedName = " "
    } else {
      selectedName = this.routes.find(item => {
        return item.routeId == this.selectedRouteId
      }).name
    }
    let payload = {
      contractno: this.contractNo,
      suggestedRouteName: selectedName,
      suggestedRouteId: this.selectedRouteId,
      proposedDate: DateUtil.getDateString(this.selectedDate)
    }
    console.log(payload)

    this.service.saveAllocationRecord(payload).subscribe({
      next: value => {
        this.dialogRef.close(true)
      },
      error: err => {
        this.snackbar.open("Saving failed", 'Dismiss', {
          duration: DurationUtil.TWO_SEC,
          horizontalPosition: "right",
          verticalPosition: "top",
          panelClass: ['mat-toolbar', 'mat-primary']
        });
      }
    })
  }

  closeDialog() {
    this.dialogRef.close()
  }
}