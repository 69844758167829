<style>
    .cancel-area {
        position: absolute;
        top: 0px;
        right: 0px;
    }

    .cancel-button {
        box-shadow: 0px 0px 3px 1px rgb(197, 197, 197);
    }

    .main-preview-wrapper {
        position: relative;
        margin: 0px;
        width: inherit;
    }
</style>
<div mat-dialog-content class="container main-preview-wrapper">
    <h3>Preview of the route</h3>
    <div class="cancel-area">
        <button class="cancel-button" mat-icon-button>
            <mat-icon class="close-icon" appearance="fill" color="warn" (click)="closeMapDialog()">close</mat-icon>
        </button>
    </div>
    <form>
        <label for="name">Show path</label>
        <input [value]="showPath" id="show" type="checkbox" (input)="onShowPathToggle($event)" />
    </form>
    <div>
        <h5>Client count in the route: {{client_count}}</h5>
    </div>
    <agm-map style="height: 500px; width: 800px;" [latitude]="baseLat" [longitude]="baseLong" [zoom]="12">
        <agm-marker *ngFor="let marker of data" [latitude]="marker.lat" [longitude]="marker.lng">
        </agm-marker>

        <agm-direction *ngIf="showPath" [origin]="origin" [destination]="destination" [waypoints]="waypoints">
        </agm-direction>
    </agm-map>
    <div class="container p-3 d-flex justify-content-center">
        <table mat-table [dataSource]="data" class="mat-elevation-z8 w-75 m-3">
            <ng-container matColumnDef="Postal Code">
                <th mat-header-cell *matHeaderCellDef> Postal Code </th>
                <td mat-cell *matCellDef="let element"> {{element.postal_code}} </td>
            </ng-container>
            <ng-container matColumnDef="Name">
                <th mat-header-cell *matHeaderCellDef> Name </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>
            <ng-container matColumnDef="Latitude">
                <th mat-header-cell *matHeaderCellDef> Latitude </th>
                <td mat-cell *matCellDef="let element"> {{element.lat}} </td>
            </ng-container>
            <ng-container matColumnDef="Longtitude">
                <th mat-header-cell *matHeaderCellDef> Longtitude </th>
                <td mat-cell *matCellDef="let element"> {{element.lng}} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</div>