import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { RouteServicesService } from 'src/app/services/route-management/route-services';
import { UserAuthenticationService } from 'src/app/services/user-authentication/user-authentication.service';
import { DurationUtil } from 'src/app/utils/DurationUtil';
import { MapPreviewDialogComponent } from '../route-management/map-preview-dialog/map-preview-dialog.component';

@Component({
  selector: 'app-create-route-form',
  templateUrl: './create-route-form.component.html',
  styleUrls: ['./create-route-form.component.scss']
})
export class CreateRouteFormComponent implements OnInit {

  constructor(public dialog: MatDialog, private service: RouteServicesService, private _snackBar: MatSnackBar, private auth: UserAuthenticationService) { }

  displayedColumns: string[] = ['Selected', 'Postal Code', 'Name', 'Latitude', 'Longtitude'];

  selectedList: Array<string> = [];

  name: string = '';

  tableData: any = RouteServicesService.GLOBAL.branch.postalCodes;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource: MatTableDataSource<{
    code: string;
    name: string;
    lat: string;
    long: string;
    selected: boolean;
}> = null;

  data: {
    code: string;
    name: string;
    lat: string;
    long: string;
    selected: boolean;
}[] = []

  user: string = null;
  branch: string = null;

  view: boolean = false;

  ngOnInit(): void {
    this.user = this.auth.currentUserValue.code
    this.branch = this.auth.currentUserValue.branch
    this.getPostalCodes();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getPostalCodes() {
    this.service.getPostalCodes(this.user, this.branch).subscribe({
      next: (value) => {
        value.content.forEach(element => {
          this.data.push({
            code: element.POSTAL_CODE,
            name: element.TOWN,
            lat: element.LAT,
            long: element.LNG,
            selected: false
          })

          this.dataSource = new MatTableDataSource(this.data)
          this.dataSource.paginator = this.paginator;
        });
      }, 
      error: (err) => {
          console.log(err);
          this._snackBar.open("Postal codes failes to load", "Dismiss", {
            duration: DurationUtil.TWO_SEC,
            horizontalPosition: "right",
            verticalPosition: "top"
          });
      },
    })
  }

  onChangeHandler(value: any, event: any): void {
    if (event.target.checked) {
      if (!this.selectedList.includes(value)) {
        this.selectedList.push(value);
      } else {
        return;
      }
    } else {
      if (this.selectedList.includes(value)) {
        let i = this.selectedList.indexOf(value);
        this.selectedList.splice(i, 1);
      } else {
        return;
      }
    }
  }

  onPreviewHandler() {
    let temp: any[] = [];
    temp = this.dataSource.data.filter(item => {
      let data = item
      return this.selectedList.includes(data.code)
    }).map(item => {
      return {
        postal_code: item.code,
        name: item.name,
        lat: item.lat,
        lng: item.long
      }
    })

    console.log(temp);

    const dialogRef = this.dialog.open(MapPreviewDialogComponent, {
      data: temp
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  onSubmitHandler() {
    let payloadPostalCodes: any[] = this.dataSource.data.filter(item => {
      let data = item
      return this.selectedList.includes(data.code)
    }).map(item => {
      return {
        postal_code: item.code,
        name_: item.name,
        lat: item.lat || " ",
        lng: item.long || " "
      }
    })

    let payload = {
      "user_id": this.user,
      "branch_id": this.branch,
      "name": this.name,
      "postal_codes": payloadPostalCodes
    };

    this.service.createRoute(payload).subscribe({
      next: (value: any) => {
        if (value == "Successfully Added ") {
          this.name = "";
          this.selectedList = [];
          this._snackBar.open("Route created", "Dismiss", {
            duration: DurationUtil.TWO_SEC,
            horizontalPosition: "right",
            verticalPosition: "top",
          });
        } else {
          this._snackBar.open("Failed to create the route", "Dismiss", {
            duration: DurationUtil.TWO_SEC,
          });
        }
      },
      error: (err) => {
        if (err.error.text == "Successfully Added") {
          this.name = "";
          this.selectedList = [];
          this._snackBar.open("Route created", "Dismiss", {
            duration: DurationUtil.TWO_SEC,
            horizontalPosition: "right",
            verticalPosition: "top"
          });
        } else {
          console.log(err);
          alert("An error occured while connecting to the server!");
        }
      }
    })
  }
}