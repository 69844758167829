import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TodaysVisitsRequestModel } from 'src/app/models/todays-visit-list/todays-visits-request-model';
import { TodaysVisitsResponseModel } from 'src/app/models/todays-visit-list/todays-visits-response-model';
import { TodaysVisitsResponseModelInfo } from 'src/app/models/todays-visit-list/todays-visits-response-model-info';
import { VisitProgress } from 'src/app/models/todays-visit-list/visit-progress';
import { Config } from 'src/config/config';
import { RestRequestService } from '../rest-request.service';


@Injectable({
  providedIn: 'root'
})

/**
 * Todays Visit List - Service Implementation
 * @author Damika Sulaksen
 */

export class VisitListServiceService {

  private readonly todaysVisitListJobsUrl: string;

  constructor(private restService: RestRequestService, @Inject("recoveryConfig") private recoveryConfig = Config.recoveryConfig) {
    this.todaysVisitListJobsUrl = this.gettodaysVisitListJobsUrl(recoveryConfig);
  }

  /**
   * Get Route job service Url from route injectable object.
   * @param recoveryConfig
   * @returns
   */

  private gettodaysVisitListJobsUrl(recoveryConfig: any = {}): string {
    let gettodaysVisitListJobsUrl = '';

    if (recoveryConfig.env === 'local') {
      gettodaysVisitListJobsUrl = `${recoveryConfig.appServerUrl}`;
    } else {
      gettodaysVisitListJobsUrl = `${recoveryConfig.recoveryServiceUrl}`;
    }
    return gettodaysVisitListJobsUrl;
  }

  // not used
  // get visit list
  getTodaysVisitList(): Observable<TodaysVisitsResponseModelInfo[]> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');

    let jobURL: string = this.todaysVisitListJobsUrl + "/vGetTodaysVisitList/CIG00000260/All/181-360/Triggering/Last_m/31-12-2021";
    return this.restService.get<TodaysVisitsResponseModelInfo[]>(
      jobURL,
      {
        headers: headers
      }
    );
  }

  //get visit list
  getTodaysVisitListwithFilter(invg: string, type: string, dpd: string, npl: string, notPayed: string, date: string, cType: string): Observable<TodaysVisitsResponseModelInfo[]> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');

    let jobURL: string = this.todaysVisitListJobsUrl + `/vGetTodaysVisitList/${invg}/${type}/${dpd}/${npl}/${notPayed}/${date}/${cType}`;
    return this.restService.get<TodaysVisitsResponseModelInfo[]>(
      jobURL,
      {
        headers: headers,
      }
    );
  }

  //get todays search visits
  SearchVisits(invg: string, keyword: string, type: string, mode: string, npl: string, dpd: string, notPayed: string, date: string, cType: string): Observable<Array<TodaysVisitsResponseModelInfo>> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');

    let jobURL: string = this.todaysVisitListJobsUrl + `/vSearchVisitList/${invg}/${keyword}/${type}/${mode}/${dpd}/${npl}/${notPayed}/${date}/${cType}`;
    return this.restService.get<Array<TodaysVisitsResponseModelInfo>>(
      jobURL,
      {
        headers: headers,
      }
    );
  }

  //Search Visits with Filter
  // SearchVisits(keyword: string, type: string, mode: string, npl: string, dpd: string, notPayed: string): Observable<TodaysVisitsResponseModelInfo[]> {
  //   const headers = new HttpHeaders().set('Content-Type', 'text/plain');

  //   let jobURL: string = this.todaysVisitListJobsUrl + "/vSearchVisitList/CIG00000260/${keyword}/${type}/${mode}/${dpd}/${npl}/${notPayed}/31-12-2021";
  //   return this.restService.get<TodaysVisitsResponseModelInfo[]>(
  //     jobURL,
  //     {
  //       headers: headers
  //     }
  //   );
  // }

  //get all contracts

  getAllContractsTodaysVisit(invg: string): Observable<TodaysVisitsResponseModelInfo[]> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');



    let jobURL: string = this.todaysVisitListJobsUrl + `/vAllVisitList/${invg}`;
    return this.restService.get<TodaysVisitsResponseModelInfo[]>(
      jobURL,
      {
        headers: headers
      }
    );
  }

  //get balance visits
  getTodaysVisitBalanceVisitListData(invg: string): Observable<TodaysVisitsResponseModelInfo[]> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');

    let jobURL: string = this.todaysVisitListJobsUrl + `/vBalanceVisitList/${invg}`;
    return this.restService.get<TodaysVisitsResponseModelInfo[]>(
      jobURL,

      {
        headers: headers
      }
    );
  }

  getVisitProgress(invg: string, cType: string): Observable<VisitProgress[]> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');

    // call list with filters uri
    let jobURL: string = this.todaysVisitListJobsUrl + `/vGetVisitProgress/${invg}/${cType}`;
    return this.restService.get<VisitProgress[]>(
      jobURL,
      {
        headers: headers,
      }
    );
  }

  //Visit Progress
  // getVisitProgress(): Observable<VisitProgress[]> {
  //   const headers = new HttpHeaders().set('Content-Type', 'text/plain');

  //   let jobURL: string = this.todaysVisitListJobsUrl + "/vGetVisitProgress/CIG00000033";
  //   return this.restService.get<VisitProgress[]>(
  //     jobURL,
  //     {
  //       headers: headers
  //     }
  //   );
  // }


  //get route details
  getRouteDetails(requestData: TodaysVisitsRequestModel): Observable<TodaysVisitsResponseModel> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');

    let jobURL: string = this.todaysVisitListJobsUrl + "/vGetVisitRouteDetials/125/CIG00000033";
    return this.restService.get<TodaysVisitsResponseModel>(
      jobURL,
      {
        headers: headers
      }
    );
  }

  /**
   * removed services
   * (view route locations and start and end route visits)
   */

  // createStartVisit(payload: any): Observable<any> {
  //   const headers = new HttpHeaders().set('Content-Type', 'text/plain');
  //   let url = `${this.todaysVisitListJobsUrl}/vCreateRoute`;
  //   return this.restService.post<any>(url, payload,
  //     {
  //       headers: headers,
  //       responseType: "text"
  //     });
  // }

  // createEndVisit(payload: any): Observable<any> {
  //   const headers = new HttpHeaders().set('Content-Type', 'text/plain');
  //   let url = `${this.todaysVisitListJobsUrl}/vCreateRoute`;
  //   return this.restService.post<any>(url, payload,
  //     {
  //       headers: headers,
  //       responseType: "text"
  //     });
  // }

}
