<div class="w-100 data-view-container container px-2 py-3">
    <div class="row">
        <div class="col-12 col-md-6 g-2">
            <h3 class="py-2">Origin</h3>
            <mat-radio-group aria-label="Select an option" [(ngModel)]="selectedOriginType">
                <div class="row">
                    <mat-radio-button value="current">Current Location</mat-radio-button>
                </div>
                <div class="row">
                    <mat-radio-button value="address">Custom address</mat-radio-button>
                </div>
            </mat-radio-group>
            <mat-form-field appearance="outline" class="m-1 w-100 p-1">
                <mat-label>Origin</mat-label>
                <input matInput [value]="originAddress" [disabled]="selectedOriginType != 'address'" />
                <mat-hint>Ex :- Colombo, Sri Lanka</mat-hint>
            </mat-form-field>
        </div>
        <div class="col-12 col-md-6 g-2">
            <h3 class="py-2">Destination</h3>
            <mat-radio-group aria-label="Select an option" [(ngModel)]="selectedDestinationType">
                <div class="row">
                    <mat-radio-button value="current">Same as origin</mat-radio-button>
                </div>
                <div class="row">
                    <mat-radio-button value="last">Last Location</mat-radio-button>
                </div>
                <div class="row">
                    <mat-radio-button value="address">Custom</mat-radio-button>
                </div>
            </mat-radio-group>
            <mat-form-field appearance="outline" class="m-1 w-100 p-1">
                <mat-label>Destination</mat-label>
                <input matInput [value]="destinationAddress" [disabled]="selectedDestinationType != 'address'" />
                <mat-hint>Ex :- Colombo, Sri Lanka</mat-hint>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <button mat-raised-button color="primary" (click)="clickHandler()">Get data</button>
    </div>
</div>