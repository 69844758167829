import { Component, Inject, Input, OnInit } from '@angular/core';
import { TodaysCallDeskService } from "../../services/call-desk/call-desk.service";
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { ClientExposureComponent } from "../client-exposure/client-exposure.component";
import { ClientLocationViewDialogComponent } from "../client-location-view-dialog/client-location-view-dialog.component";

@Component({
  selector: 'app-client-detail-snap-dialog',
  templateUrl: './client-detail-snap-dialog.component.html',
  styleUrls: ['./client-detail-snap-dialog.component.scss']
})
export class ClientDetailSnapDialogComponent implements OnInit {

  noContent: boolean = false;

  @Input() entries: any[] = [];
  flag: string = "";
  hightlightText: string = "";

  constructor(private service: TodaysCallDeskService, public dialog: MatDialog, private dialogRef: MatDialogRef<ClientExposureComponent>, @Inject(MAT_DIALOG_DATA) public selectedData: any) {
    // this.contractNo = selectedData.contractNo;
  }

  ngOnInit(): void {
    
    // // let contractNo=   '3133977516' ;
    // this.service.getDetailViewSnapData( this.contractNo).subscribe({
    //   next: (value) => {
    //     this.entries = value;
    //     this.flag = value[0].flag;
    //     this.hightlightText = value[0].trigeringNPL;
    //     console.log(JSON.stringify(value));
    //   },
    //   error: (err) => {
    //     this.noContent = true;
    //   }
    // })
  }

  closeDialog(): any {
    this.dialogRef.close();
  }

  openLocationMap() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '800px';
    dialogConfig.width = '1200px';
    dialogConfig.data = {
      // contractNo: this.selectedCallDeskTransaction.contractNo
    }
    const dialogRef = this.dialog.open(ClientLocationViewDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        console.log(`NP entry opened`);
      }
    );
  }

}
