<h1>Location Detail</h1>

<div class="col-12 py-1">
  <agm-map [latitude]="lat" [longitude]="lng">
    <agm-direction [origin]="origin" [destination]="destination" >

    </agm-direction>
  </agm-map>
</div>

<div  style=" display:flex; justify-content:flex-end; width:100%; padding-top: 20px; padding-right: 20px;" >
  <table bordet="0">
    <tr>
      <td style="color:green; padding-top:20px; padding-left: 20px">
        <button  view class="btn btn-primary float-left" (click)="getDirection()">Load Route</button>
      </td>
      <td style="color:green; padding-top:20px; padding-left: 20px">
        <button view class="btn btn-primary float-left" (click)="closeDialog()">Close</button>
      </td>
    </tr>
  </table>
</div>
