import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CashHandoverService } from 'src/app/services/cash-handover/cash-handover.service';
import { UserAuthenticationService } from 'src/app/services/user-authentication/user-authentication.service';
import { DurationUtil } from 'src/app/utils/DurationUtil';
import { HomePageComponent } from '../../home-page/home-page.component';

@Component({
  selector: 'app-cash-handover',
  templateUrl: './cash-handover.component.html',
  styleUrls: ['./cash-handover.component.scss']
})
export class CashHandoverComponent implements OnInit {

  dataset: {
    "brncode": string,
    "descr": string,
    "loccode": string
  }[];

  readonly title: string = "Cash Handover";
  branchcode: string = "";
  user_name: string = "";

  cash_amt: Number;
  chq_amt: Number;
  btch_serial: string = "";
  massage: string = "";

  isSaved: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA)private data: any, private CashHService: CashHandoverService, private _snackBar: MatSnackBar, private auth: UserAuthenticationService, private dialogRef: MatDialogRef<HomePageComponent>) { }

  ngOnInit(): void {
    
    this.user_name = this.auth.currentUserValue.username
    this.bankList()
    
    this.cash_amt = this.data?.CASH_AMT,
    this.chq_amt = this.data?.CHQ_AMT,
    this.btch_serial = this.data?.batch_serial,
    this.massage = this.data?.msg
  }

  bankList() {
    this.CashHService.getBranchList()
      .subscribe({
        next: data => {
          this.dataset = [...data];
        },
        error: error => {
          console.log(error);
        }
      })
  }

  CashHOSignOut() {
    let payload = {
      SYSTEM: "CR",
      APP_REQ_ID: this.btch_serial,
      BRNCODE: this.branchcode,
      ACTBRNCODE: this.auth.currentUserValue.branch
    }

    this.CashHService.CashHOSOut(payload).subscribe({
      next: (value: any) => {
        if (Array.isArray(value.content) && Object.prototype.hasOwnProperty.call(value.content[0], "status")) {
          this._snackBar.open(value.content[0].msg, 'Dismiss', {
            duration: DurationUtil.TWO_SEC,
            horizontalPosition: "right",
            verticalPosition: "top",
          });
          this.isSaved = true;
          this.closeCashHandover();
          
        } else {
          console.log(value);
          this._snackBar.open(`SignOut Unsuccessful`, 'Dismiss', {
            duration: DurationUtil.TWO_SEC,
            horizontalPosition: "right",
            verticalPosition: "top",
          });
        }
      },
      error: (err: any) => {
        console.log(err)
        alert("SignOut Unsuccessful");
      }
    });
  }

  closeCashHandover() {
    this.dialogRef.close();
  }

}
