<div class="container dialog-container">
    <div class="cancel-area">
        <div>
            <button class="cancel-button" mat-icon-button (click)="closeLetterViewDialog()">
                <mat-icon class="close-icon" appearance="fill" color="warn">close</mat-icon>
            </button>
        </div>
    </div>

    <div class="container p-5 image-container">
        <img [src]="imageUrl" alt="" class="image-element" />
    </div>

    <div class="container image-error" >

    </div>
</div>