<div class="dialog-container">


  <!--<div class="row border-1" style="padding-top: 1rem">-->
    <!--<div class="col-8">-->
      <!--<h2>Snap View</h2>-->
    <!--</div>-->
  <!--</div>-->
  <div class="dialog-content w-100 p-3">
    <div >
      <a class="btn btn-primary float-none" style="background-color:#7300e6; border-color:blue; color:white; width: 100%"  (click)="openLocationMap()">Location Details ( Tab to go to the Google Map)</a>
    </div>
    <div class="container w-100 entry-tile  " *ngFor="let item of entries">
    <!--<div class="container w-75 entry-tile px-4 py-1 my-2" >-->
      <div class="row w-100 py-1" >
        <div class="col-6 tile-leading">Rental Arrears</div>
        <div class="col-6 tile-content">{{item?.RentalArrears || "0"}}</div>
      </div>
      <div class="row w-100 ">
        <div class="col-6 tile-leading">Arrears</div>
        <div class="col-6 tile-content">{{item?.arrears || "0"}}</div>
      </div>
      <div class="row w-100">
        <div class="col-6 tile-leading">DPD</div>
        <div class="col-6 tile-content">{{item?.DPD || "0"}}</div>
      </div>
      <div class="row w-100 ">
        <div class="col-6 tile-leading">Vehicle Num</div>
        <div class="col-6 tile-content">{{item?.VehicleNo || "0"}}</div>
      </div>
      <div class="row w-100">
        <div class="col-6 tile-leading">Last paid</div>
        <div class="col-6 tile-content">{{item?.lastPaidDate || "0"}}</div>
      </div>
      <div class="row w-100 ">
        <div class="col-6 tile-leading">Last paid amount</div>
        <div class="col-6 tile-content">{{item?.lastPaidAmount || "0"}}</div>
      </div>
      <div class="row w-100 ">
        <div class="col-6 tile-leading">Min to reverse NPL</div>
        <div class="col-6 tile-content">{{item?.minToReverseNPL || "0"}}</div>
      </div>
      <div class="row w-100 ">
        <div class="col-6 tile-leading">Month end DPD</div>
        <div class="col-6 tile-content"> </div>
      </div>
    </div>


    <div>
      <a class="btn btn-primary float-none" style="background-color:yellow; border-color:blue; color:black; width: 100%" >Flags ({{flag}}) </a>
    </div>
    <div>
      <a class="btn btn-primary float-none" style="background-color:yellow; border-color:blue; color:black; width: 100%" >Highlights (Trigerring: {{hightlightText}})</a>
    </div>

    <!-- <div class="cancel-area">
      <button class="cancel-button" mat-icon-button aria-label="Example icon button with a vertical three dot icon" (click)="closeDialog()">
        <mat-icon class="close-icon" appearance="fill" color="warn">close</mat-icon>
      </button>
    </div> -->
  </div>
</div>
