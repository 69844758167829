<div class="dialog-container">
  <div class="cancel-area">
    <button class="cancel-button" mat-icon-button aria-label="Example icon button with a vertical three dot icon" (click)="closeDialog()">
      <mat-icon class="close-icon" appearance="fill" color="warn">close</mat-icon>
    </button>
  </div>

  <div class="row border-1" style="padding-top: 1rem">
    <div class="col-8">
      <h2>Transaction Summary</h2>
    </div>
  </div>
  <div class="dialog-content w-100 p-3">
    <div *ngIf="noContent" class="no-content">
      No data available
    </div>
    <div class="container w-75 entry-tile px-4 py-1 my-2" *ngFor="let item of entries">
      <div class="row w-100 py-1" >
        <div class="col-6 tile-leading">Transaction Date</div>
        <div class="col-6 tile-content">{{item.date || "0"}}</div>
      </div>
      <div class="row w-100 py-1">
        <div class="col-6 tile-leading">Receipt No</div>
        <div class="col-6 tile-content">{{item.receiptno|| "---"}}</div>
      </div>
      <div class="row w-100 py-1">
        <div class="col-6 tile-leading">Transaction Code</div>
        <div class="col-6 tile-content">{{item.trxcode || "---"}}</div>
      </div>
      <div class="row w-100 py-1">
        <div class="col-6 tile-leading">Payment Mode</div>
        <div class="col-6 tile-content">{{item.paymode || "0"}}</div>
      </div>
      <div class="row w-100 py-1">
        <div class="col-6 tile-leading">Bank Code</div>
        <div class="col-6 tile-content">{{item.bankglcode || "0"}}</div>
      </div>
      <div class="row w-100 py-1">
        <div class="col-6 tile-leading">Cheque No</div>
        <div class="col-6 tile-content">{{item.cheqno || "0"}}</div>
      </div>
      <div class="row w-100 py-1">
        <div class="col-6 tile-leading">Debit</div>
        <div class="col-6 tile-content">{{item.debit || "0.00"}}</div>
      </div>
      <div class="row w-100 py-1">
        <div class="col-6 tile-leading">Credit</div>
        <div class="col-6 tile-content">{{item.credit || "0.00"}}</div>
      </div>
    </div>
  </div>
</div>