/**
 * Contract data model data representation
 * @author Malindu Panchala
 */
export class Contract {
    vistService() {
      throw new Error('Method not implemented.');
    }
    constructor(
        public contract_No: number,
        public nic: string,
        public investigatorCode: string,
        public assignDate: string,
        public customerName: string,
        public customerAddress: string,
        public totalArrears: number,
        public defaultArrears: number,
        public otherArrearsAmount: number,
        public insuranceArrears: number,
        public totalArrears1: number,
        public noOfArrears: number,
        public dueDate: string,
        public lastPayDate: number,
        public grossRental: number,
        public dueDate1: string,
        public registrationNo: string,
        public equipment: string,
        public make: string
    ) { }

    static fromJson(object: any) : Contract {
        let content = object?.content;
        return new Contract(
            content[0]["CONTRACTNO"],
            content[0]["NIC"],
            content[0]["INVESTIGATORCODE"],
            content[0]["ASSIGNDATE"],
            content[0]["CUSTOMERNAME"],
            content[0]["CUSTOMERADDRESS"],
            content[0]["TOTALARREARS"],
            content[0]["DEFAULTARREARS"],
            content[0]["OTHERARREARSAMOUNT"],
            content[0]["INSURANCEARREARS"],
            content[0]["TOTALARREARS1"],
            content[0]["NOOFARREARS"],
            content[0]["DUEDATE"],
            content[0]["LASTPAYDATE"],
            content[0]["GROSSRENTAL"],
            content[0]["DUEDATE1"],
            content[0]["REGISTRATIONNO"],
            content[0]["EQUIPMENT"],
            content[0]["MAKE"]
        );
    }

    public requestEntries(): Array<Object> {
        let temp = [
            {
                name: "Contract number",
                value: this.contract_No
            },
            {
                name: "NIC",
                value: this.nic
            },
            {
                name: "Investigator Code",
                value: this.investigatorCode
            },
            {
                name: "Assign Date",
                value: this.assignDate
            },
            {
                name: "Customer Name",
                value: this.customerName
            },
            {
                name: "Customer Address",
                value: this.customerAddress
            },
            {
                name: "Total Arrears",
                value: this.totalArrears
            },
            {
                name: "Default Arrears",
                value: this.defaultArrears
            },
            {
                name: "Other Arrears Amount",
                value: this.otherArrearsAmount
            },
            {
                name: "Insurance Arrears",
                value: this.insuranceArrears
            },
            {
                name: "Total arrears 1",
                value: this.totalArrears1
            },
            {
                name: "Number of Arrears",
                value: this.noOfArrears
            },
            {
                name: "Due Date",
                value: this.dueDate
            },
            {
                name: "Last Paydate",
                value: this.lastPayDate
            },
            {
                name: "Gross Rental",
                value: this.grossRental
            },
            {
                name: "Due Date 1",
                value: this.dueDate1
            },
            {
                name: "Registration Number",
                value: this.registrationNo
            },
            {
                name: "Equipement",
                value: this.equipment
            },
            {
                name: "Make",
                value: this.make
            },
        ];
        
        
        return temp;
    }
}