import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Config } from 'src/config/config';
import { RestRequestService } from '../rest-request.service';
import { FlaggingDetailsResponseModel } from "../../models/call-desk/flagging-details-response-model";


@Injectable({
  providedIn: 'root'
})
export class FacilityDetailsService {

  private readonly facilityDetailsJobUrl: string;

  constructor(private restService: RestRequestService, @Inject("recoveryConfig") private recoveryConfig = Config.recoveryConfig) {

    this.facilityDetailsJobUrl = this.getFacilityDetailsJobUrl(recoveryConfig);
  }

  /**
   * Returns facility details job url
   * @param recoveryConfig
   * @returns jobUrl: string
   */
  private getFacilityDetailsJobUrl(recoveryConfig: any = {}): string {
    let jobUrl = '';

    if (recoveryConfig.env === 'local') {
      jobUrl = `${recoveryConfig.appServerUrl}`;
    } else {
      jobUrl = `${recoveryConfig.recoveryServiceUrl}`;
    }
    return jobUrl;
  }

  /**
   * Retrievs a facility flag list
   * @returns Observable<{FLAG: string}>
   */
  getFlagsList(): Observable<{ FLAG: string }[]> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');

    let jobURL: string = this.facilityDetailsJobUrl + `/vGetContractFlags`;
    return this.restService.get<{ FLAG: string }[]>(
      jobURL,
      {
        headers: headers
      }
    );
  }

  // followUpNotepad(contractNo: string): Observable<FlaggingDetailsResponseModel[]> {
  //   const headers = new HttpHeaders().set('Content-Type', 'text/plain');

  //   let jobURL: string = this.facilityDetailsJobUrl + `https://cfmobile.lk/crmesbtest/ESBInfotest/CRMNotePad/CRMNotePadview?FACNO=${contractNo}`;
  //   return this.restService.get<FlaggingDetailsResponseModel[]>(
  //     jobURL,
  //     {
  //       headers: headers,
  //     }
  //   );
  // }

  updateThirdParty(paylaod: {
    contractno: string,
    name: string,
    address: string,
    contactno: string,
    user: string
  }): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');

    let jobURL: string = this.facilityDetailsJobUrl + `/vUpdate3pDet`;
    return this.restService.post<any>(
      jobURL,
      paylaod,
      {
        headers: headers
      }
    );
  }
}
