import {Inject , Injectable} from "@angular/core";
import {RestRequestService} from "./rest-request.service";
import {Config} from "../../config/config";
import {HttpHeaders , HttpParams} from "@angular/common/http";
import {Observable} from "rxjs/internal/Observable";
import {CallDeskDataResponseModel} from "../models/call-desk-data-response-model";
import {CallDeskDataRequestModel} from "../models/call-desk-data-request-model";

@Injectable({
  providedIn: 'root'
})
/**
 * Service implementation releated to call desk funtionalities
 * @author Achala M. Rathnathilaka
 */
export class CallDeskService {

  private readonly callDeskJobsUrl: string;

  constructor(private restService: RestRequestService,@Inject("recoveryConfig") private recoveryConfig = Config.recoveryConfig) {

    this.callDeskJobsUrl = this.getCallDeskJobsUrl(recoveryConfig);
  }

  /**
   * Get CallDesk job service Url from CallDesk injectable object.
   * @param recoveryConfig
   * @returns callDeskUrl:string
   */
  private getCallDeskJobsUrl(recoveryConfig: any = {}): string {
    let callDeskUrl = '';

    if (recoveryConfig.env === 'local') {
      callDeskUrl = `${recoveryConfig.appServerUrl}/vAddContractListRequest`;
    } else {
      callDeskUrl = `${recoveryConfig.recoveryServiceUrl}/vAddContractListRequest`;
    }
    return callDeskUrl;
  }


  /**
   * Returns the All the Call desk details.
   * {CallDeskDataRequestModel} requestData
   * Returns {Observable<CallDeskDataResponseModel>}
   */
  getCallDeskData ( requestData: CallDeskDataRequestModel ): Observable<CallDeskDataResponseModel> {
    const headers = new HttpHeaders().set( 'Content-Type' , 'text/plain' );

    let jobURL: string  =  this.callDeskJobsUrl + "/CIG00000679/01-Oct-2021";
    return this.restService.get<CallDeskDataResponseModel> (
      jobURL ,
      {
        headers: headers
      }
    );
  }

}
