import { Inject, Injectable } from "@angular/core";
import { Config } from "../../config/config";
import { RestRequestService } from "./rest-request.service";
import { Observable } from "rxjs/internal/Observable";
import { VisitDeskDataRequestModel } from "../models/visit-desk-data-request-model";
import { VisitDeskDataResponseModel } from "../models/visit-desk-data-response-model";
import { HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class VisitSelectionDeskService {

  private readonly optimizedVisitSeachUrl: string;
  private readonly trackMeUrl: string;


  constructor(private restService: RestRequestService, @Inject("recoveryConfig") private recoveryConfig = Config.recoveryConfig) {

    this.optimizedVisitSeachUrl = this.getOptimizeSearchJobsUrl(recoveryConfig);
    this.trackMeUrl = this.getTrackMeJobsUrl(recoveryConfig);
  }

  /**
   * Get Optimize Visit job service Url from visitDesk injectable object.
   * @param recoveryConfig
   * @returns optimizedVisitSeachUrl
   */
  private getOptimizeSearchJobsUrl(recoveryConfig: any = {}): string {
    let url = '';

    if (recoveryConfig.env === 'local') {
      url = `${recoveryConfig.appServerUrl}/vGetOptimizeList`;
    } else {
      url = `${recoveryConfig.recoveryServiceUrl}/vGetOptimizeList`;
    }
    return url;
  }

  private getTrackMeJobsUrl(recoveryConfig: any = {}): string {
    let url = '';

    if (recoveryConfig.env === 'local') {
      url = `${recoveryConfig.appServerUrl}/vAddOffTrac`;
    } else {
      url = `${recoveryConfig.recoveryServiceUrl}/vAddOffTrac`;
    }
    return url;
  }

  /**
   * Returns the All the optimize visit date details.
   * {CallDeskDataRequestModel} requestData
   * Returns {Observable<VisitDeskDataResponseModel>}
   */
  getOptimizeVisitData(user: string, requestData: VisitDeskDataRequestModel, date: string): Observable<VisitDeskDataResponseModel> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');
    let jobURL: string = this.optimizedVisitSeachUrl + `/${user}/` + `${date}`;
    // console.log("URL   " + jobURL);
    return this.restService.get<VisitDeskDataResponseModel>(
      jobURL,
      {
        headers: headers
      }
    );
  }

  trackMe(payload: any): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'text/plain' }) };
    return this.restService.post<any>(this.trackMeUrl, payload, httpOptions);

  }
}
