import { CUSTOM_ELEMENTS_SCHEMA, NgModule, Inject } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AgmCoreModule } from '@agm/core';

import { routes } from './app-recovery-routing.module';
import { AppRecoveryComponent } from './app-recovery.component';
import { AgmDirectionModule } from "agm-direction";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { VisitDeskDialogComponent } from './components/visit-desk-dialog/visit-desk-dialog.component';
import { CallDeskDialogComponent } from './components/call-desk-dialog/call-desk-dialog.component';
import { InquiryDeskDialogComponent } from './components/inquiry-desk-dialog/inquiry-desk-dialog.component';
import { AllocateClerkDialogComponent } from './components/allocate-clerk-dialog/allocate-clerk-dialog.component';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from "@angular/material/dialog";
import { VisitSelectionDeskDialogComponent } from './components/visit-selection-desk-dialog/visit-selection-desk-dialog.component';
import { AngularSlickgridModule } from "angular-slickgrid";
import { AutoCallDialogComponent } from './components/auto-call-dialog/auto-call-dialog.component';
import { ManualCallDialogComponent } from './components/manual-call-dialog/manual-call-dialog.component';
import { MatInputModule } from "@angular/material/input";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { UserLoginComponent } from './components/login/user-login/user-login.component';
import { HomePageComponent } from './components/home-page/home-page.component';
import { RouterModule } from "@angular/router";
import { environment } from "../environments/environment";
import { RegisterUserComponent } from "./components/user-registration/register-user/register-user.component";
import { CreateUserRolesComponent } from "./components/user-registration/create-user-roles/create-user-roles.component";
import { AssignUserPrivilagesComponent } from "./components/user-registration/assign-user-privilages/assign-user-privilages.component";
import { MatSelectModule } from "@angular/material/select";
import { SocialAuthServiceConfig, SocialLoginModule } from "angularx-social-login";
import { GoogleLoginProvider } from "angularx-social-login";
import { SettlementCalculationComponent } from './components/settlement-calculation/settlement-calculation.component';
import { MatRadioButton, MatRadioModule } from "@angular/material/radio";
import { MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxDefaultOptions, MatCheckboxModule } from "@angular/material/checkbox";
import { RequestDialogComponent } from "./components/request-dialog/request-dialog.component";
import { VisitUpdateDialogComponent } from './components/visit-update-dialog/visit-update-dialog.component';
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatCardModule } from '@angular/material/card';
import { ContractRequestComponent } from './components/settlement-calculation/contract-request/contract-request.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { VisitSelectionUpdateDeskDialogComponent } from './components/visit-selection-update-desk-dialog/visit-selection-update-desk-dialog.component';
import { CreateRouteFormComponent } from './components/create-route-form/create-route-form.component';
import { RouteManagementComponent } from './components/route-management/route-management.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MapPreviewDialogComponent } from './components/route-management/map-preview-dialog/map-preview-dialog.component';
import { UpdateRouteFormComponent } from './components/route-management/update-route-form/update-route-form.component';
import { ViewRoutesComponent } from './components/route-management/view-routes/view-routes.component';
import { ViewBranchRoutesComponent } from './components/route-management/view-branch-routes/view-branch-routes.component';
import { RouteManagementDialogComponent } from './components/route-management/route-management-dialog/route-management-dialog.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NotePadEntryViewComponent } from './components/note-pad-entry-view/note-pad-entry-view.component';
import { ReceiptDialogComponent } from './components/receipt-dialog/receipt-dialog.component';
import { PdChequeDialogComponent } from './components/pd-cheque-dialog/pd-cheque-dialog.component';
import { LetterViewComponent } from './components/settlement-calculation/letter-view/letter-view/letter-view.component';
import { VisitRouteDataViewComponent } from './components/visit-route-data-view/visit-route-data-view.component';
import { FieldOfficerTrackingDialogComponent } from './components/field-officer-tracking-dialog/field-officer-tracking-dialog.component';
import { TodaysCallListV2Component } from './components/v2/todays-call-list-v2/todays-call-list-v2.component'
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { TodaysVisitListV2Component } from './components/v2/todays-visit-list-v2/todays-visit-list-v2.component';
import { ClientDetailDialogComponent } from './components/client-detail-dialog/client-detail-dialog.component';
import { VisitSummaryViewComponent } from './components/visit-summary-view/visit-summary-view.component'
import { FacilityDetailDialogComponent } from './components/facility-detail-dialog/facility-detail-dialog.component';
import { SecurityDetailDialogComponent } from './components/security-detail-dialog/security-detail-dialog.component';
import { FollowupDetailDialogComponent } from './components/followup-detail-dialog/followup-detail-dialog.component'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouteAllocationV2Component } from './components/v2/route-allocation-v2/route-allocation-v2.component';
import { ClientExposureComponent } from './components/client-exposure/client-exposure.component';
import { TransactionSummaryComponent } from './components/transaction-summary/transaction-summary.component';
import { AllocatedRouteUpdatedComponent } from './components/v2/allocated-route-updated/allocated-route-updated.component';
import { ClientDetailSnapDialogComponent } from './components/client-detail-snap-dialog/client-detail-snap-dialog.component';
import { ClientLocationViewDialogComponent } from './components/client-location-view-dialog/client-location-view-dialog.component';
import { HomeComponentV2Component } from './components/home-component-v2/home-component-v2.component';
import { NavigationBarComponent } from './components/navigation-bar/navigation-bar.component';
import { ThousandSeparatorPipe } from './pipes/thousand-separator.pipe';
import { DialogHeaderComponent } from './components/common/dialog-header/dialog-header.component';
import { WhitespacePipe } from './pipes/whitespace.pipe';
import {MatExpansionModule} from '@angular/material/expansion';
import { RelatedContractsComponent } from './components/related-contracts/related-contracts.component';
import {TokenInjector} from "./shared/interceptors/token-injector.interceptor";
import { IdleService } from './idle.service';
import { PopUpContactDetailsComponent } from './pop-up-contact-details/pop-up-contact-details.component';
import { TwoDigitDecimalNumberDirective } from './directive/two-digit-decimal-number.directive';
import { CashHandoverComponent } from './components/v2/cash-handover/cash-handover.component';
import { ThousandSeparatorInputDirective } from './directive/thousand-separator-input.directive';
import { PopUpOpenNotepadComponent } from './components/pop-up-open-notepad/pop-up-open-notepad.component';
import { AdminPortelComponent } from './components/admin-portel/admin-portel.component';
import { AdminAccessCashHandoverComponent } from './components/admin-access-cash-handover/admin-access-cash-handover.component';
import { AdminAccessVisitDistanceComponent } from './components/admin-access-visit-distance/admin-access-visit-distance.component';
import { AdminAccessComponent } from './components/admin-access/admin-access.component';

const uiModules = [
  MatSidenavModule,
  MatIconModule,
  MatButtonModule,
  MatExpansionModule
];

@NgModule({
  declarations: [
    AppRecoveryComponent,
    VisitDeskDialogComponent,
    CallDeskDialogComponent,
    InquiryDeskDialogComponent,
    AllocateClerkDialogComponent,
    VisitSelectionDeskDialogComponent,
    AutoCallDialogComponent,
    ManualCallDialogComponent,
    UserLoginComponent,
    RegisterUserComponent,
    CreateUserRolesComponent,
    AssignUserPrivilagesComponent,
    HomePageComponent,
    SettlementCalculationComponent,
    RequestDialogComponent,
    VisitUpdateDialogComponent,
    ContractRequestComponent,
    VisitSelectionUpdateDeskDialogComponent,
    CreateRouteFormComponent,
    RouteManagementComponent,
    MapPreviewDialogComponent,
    UpdateRouteFormComponent,
    ViewRoutesComponent,
    ViewBranchRoutesComponent,
    RouteManagementDialogComponent,
    NotePadEntryViewComponent,
    ReceiptDialogComponent,
    PdChequeDialogComponent,
    LetterViewComponent,
    VisitRouteDataViewComponent,
    FieldOfficerTrackingDialogComponent,
    TodaysCallListV2Component,
    TodaysVisitListV2Component,
    ClientDetailDialogComponent,
    VisitSummaryViewComponent,
    RouteAllocationV2Component,
    FacilityDetailDialogComponent,
    SecurityDetailDialogComponent,
    FollowupDetailDialogComponent,
    ClientExposureComponent,
    TransactionSummaryComponent,
    AllocatedRouteUpdatedComponent,
    ClientDetailSnapDialogComponent,
    ClientLocationViewDialogComponent,
    HomeComponentV2Component,
    NavigationBarComponent,
    ThousandSeparatorPipe,
    DialogHeaderComponent,
    WhitespacePipe,
    RelatedContractsComponent,
    PopUpContactDetailsComponent,
    TwoDigitDecimalNumberDirective,
    CashHandoverComponent,
    ThousandSeparatorInputDirective,
    PopUpOpenNotepadComponent,
    AdminPortelComponent,
    AdminAccessCashHandoverComponent,
    AdminAccessVisitDistanceComponent,
    AdminAccessComponent,

  ],

  entryComponents:[PopUpContactDetailsComponent],

  imports: [
    BrowserModule,
    RouterModule.forRoot(routes),
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA3ueAbI_gX7FMdCdbPw65i-B3XVxEuGE8',
      libraries: ['places']
    }),
    AgmDirectionModule,
    MatDialogModule,
    uiModules,
    MatInputModule,
    AngularSlickgridModule.forRoot(),
    MatSelectModule,
    SocialLoginModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    SocialLoginModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCardModule,
    MatTableModule,
    MatPaginatorModule,
    MatTabsModule,
    MatSnackBarModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule
  ],
  providers: [

    {
      provide:ThousandSeparatorPipe
    },
    {
      provide:WhitespacePipe
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { hasBackdrop: false }
    },
    {
      provide: 'recoveryConfig',
      useValue: environment.recoveryConfig
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autologin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('465565472722-t7p7qk74hmtr4qm4qh54savc8gpvqd79.apps.googleusercontent.com',
              {
                scope: 'email',
                plugin_name: 'Branchless Banking'
              }
            )
          }
        ]
      } as SocialAuthServiceConfig
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    // { provide: HTTP_INTERCEPTORS, useClass: TokenInjector, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInjector, multi: true },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppRecoveryComponent],
  exports: [AppRecoveryComponent, uiModules]
})
export class AppRecoveryModule {
  constructor(@Inject('recoveryConfig') private recoveryConfig = environment.recoveryConfig) {
  }
}


