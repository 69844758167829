import { Component, OnInit } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularGridInstance, Column, FieldType, FileType, Formatters, GridOption } from 'angular-slickgrid';
import { CallProgress } from 'src/app/models/call-desk/call-progress';
import { TodaysCallDeskService } from 'src/app/services/call-desk/call-desk.service';
import { UserAuthenticationService } from 'src/app/services/user-authentication/user-authentication.service';
import { DateUtil } from 'src/app/utils/DateUtil';
import { HomePageComponent } from '../../home-page/home-page.component';
import { ManualCallDialogComponent } from '../../manual-call-dialog/manual-call-dialog.component';
import { ThousandSeparatorPipe } from "../../../pipes/thousand-separator.pipe";
import { DurationUtil } from 'src/app/utils/DurationUtil';
import { rightAllignment } from 'src/app/utils/testAlign'
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-todays-call-list-v2',
  templateUrl: './todays-call-list-v2.component.html',
  styleUrls: ['./todays-call-list-v2.component.scss']
})
export class TodaysCallListV2Component implements OnInit {

  /**
   * UI related variables
   */
  readonly headerTitle: string = "Todays call list";
  searchType: string = "Contract"; // search type default value is contract
  search: string = "";
  dpd: string[] = [];
  npl: string[] = [];
  td: string[] = [];

  notPayed: string = '_';
  notPayedOption: string = "default";
  notPaidMonths: string = null;

  // view mode for changing between all, balance and todays list
  mode: string = "ALL"
  enableSearch: boolean = false;

  // userdata
  user: string = ""

  // multiple filter selection
  filters: any = {
    dpd: [],
    npl: [],
    td: []
  }

  readonly defaults = {
    mode: "TODAYS",
    dpd: "_",
    npl: "_",
    td: "_",
    notPayed: "_",

    notPayedOption: "_",

    // search: "EPBAT-2247",
    // searchType: "Vehicle"
  }

  /**
   * call progress
   */
  callProgress: CallProgress = {
    allTriggered: 0,
    completed: 0,
    balance: 0,
    cType: ""
  }

  /**
   * Datagrid related
   */
  contractSelected: string = undefined

  columnDefinitions: Column[] = [];
  gridOptions: GridOption = {};//CallDeskDataResponseRestModelInfo
  dataset: any[] = []; //CallDeskDataResponseModelInfo

  ngGrid: AngularGridInstance;
  ngGridObj: any = null

  // util data
  cType: string = "CR";
  date: string = null;
  todayDate: Date = null;
  selectedDate: Date = null;


  selectOptionMode: string = "CR"
  CR: string
  WO: string
  PL: string
  PW: string

  isCompatible: boolean = false;
  //loadingViewBoolValue
  isLoading: boolean = false;

  constructor(private thousandSeparator: ThousandSeparatorPipe, private service: TodaysCallDeskService, private snackBar: MatSnackBar, private dialog: MatDialog, private dialogRef: MatDialogRef<HomePageComponent>, private auth: UserAuthenticationService) {
    let dateObj = new Date()
    this.selectedDate = dateObj
    this.todayDate = dateObj
    this.date = DateUtil.getDateString(this.todayDate)
  }

  ngOnInit(): void {
    this.setType();
    this.user = this.auth.currentUserValue.code
    this.columnDefinitions = [
      {
        id: "contractNo",
        name: "Contract Number",
        field: "contractNo",
        minWidth: 100,
        type: FieldType.string,
        filterable: true,
      },
      {
        id: "type",
        name: "Type",
        field: "cType",
        minWidth: 50,
        type: FieldType.string,
        filterable: true,
      },
      {
        id: "monthEndDpd",
        name: "Assigned Point DPD",
        field: "monthEndDpd",
        minWidth: 100,
        sortable: true,
        filterable: true,
        formatter: rightAllignment,
        type: FieldType.string
      },
      {
        id: "name",
        name: "Name",
        field: "name",
        minWidth: 200,
        type: FieldType.string,
        filterable: true,
      },
      {
        id: "area",
        name: "Area",
        field: "area",
        minWidth: 100,
        sortable: true,
        type: FieldType.string,
        filterable: true,
      },
      {
        id: "ria",
        name: "Assigned Point Arrears",
        field: "ria",
        minWidth: 120,
        sortable: true,
        filterable: true,

        formatter: rightAllignment,
        type: FieldType.string
      },
      {
        id: "stockAmount",
        name: "Stock Amount",
        field: "stockAmount",
        minWidth: 100,
        type: FieldType.string,
        formatter: rightAllignment,
        params: { minDecimal: 2, maxDecimal: 2 },
        filterable: true,
        sortable: true

      },
      {
        id: "arrearsAmount",
        name: "Arrears amount",
        field: "arrearsAmount",
        minWidth: 100,
        type: FieldType.string,
        formatter: rightAllignment,
        params: { minDecimal: 2, maxDecimal: 2 },
        filterable: true,
        sortable: true,
      },
      {
        id: "lastpayday",
        name: "Last Payment date",
        field: "lastpayday",
        minWidth: 100,
        type: FieldType.date,
        formatter: Formatters.dateIso,
        filterable: true,
        sortable: true
      }, {
        id: "dueDate",
        name: "Due date",
        field: "dueDate",
        minWidth: 100,
        type: FieldType.date,
        formatter: Formatters.dateIso,
        filterable: true,
        sortable: true
      },
      {
        id: "promise_amt",
        name: "Promised Amount",
        field: "promise_amt",
        minWidth: 100,
        type: FieldType.string,
        formatter: rightAllignment,
        params: { minDecimal: 2, maxDecimal: 2 },
        filterable: true,
        sortable: true,
      },
      {
        id: "promise_date",
        name: "Promised Date",
        field: "promise_date",
        minWidth: 100,
        type: FieldType.date,
        formatter: Formatters.dateIso,
        filterable: true,
        sortable: true,
      },
      {
        id: "currentDpd",
        name: "Current DPD",
        field: "currentDpd",
        minWidth: 75,
        sortable: true,
        formatter: rightAllignment,
        filterable: true,
        type: FieldType.number
      },
      {
        id: "callDirection",
        name: "Call Direction",
        field: "callDirection",
        minWidth: 150,
        type: FieldType.string,
        filterable: true,
      },
      {
        id: "BSTAT",
        name: "Blocked",
        field: "BSTAT",
        minWidth: 55,
        type: FieldType.string,
        filterable: true,
      },
      {
        id: "npl_status",
        name: "NPL Status",
        field: "npl_status",
        minWidth: 100,
        type: FieldType.string,
        filterable: true,
      },
      {
        id: "npl_triggering_date",
        name: "NPL triggering date",
        field: "npl_triggering_date",
        minWidth: 150,
        type: FieldType.string,
        filterable: true,
      },
    ];

    this.gridOptions = {
      gridHeight: "500px",
      formatterOptions: {
        minDecimal: 2,
        maxDecimal: 2,
        dateSeparator: "-",
        displayNegativeNumberWithParentheses: true
      },
      enableAutoResize: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      enableCheckboxSelector: true,
      enableFiltering: true,
      checkboxSelector: {
        hideSelectAllCheckbox: true,
      },
      multiSelect: false,
      rowSelectionOptions: {
        selectActiveRow: true,
      },
      columnPicker: {
        hideForceFitButton: true
      },
      gridMenu: {
        hideForceFitButton: true
      },
      enablePagination: false,
    };

    this.processFiltersAndReloadData()
    this.getProgress()
  }

  angularGridReady(event: any) {
    this.ngGrid = (event as CustomEvent).detail as AngularGridInstance;
    this.ngGridObj = this.ngGrid.slickGrid;
    this.ngGrid.slickGrid.onSelectedRowsChanged.subscribe((e, args) => {
      this.handleSelection(e, args);
    });
  }
  // <!-- Select type of CR/WO/PL/PLWO -->
  setType() {

    if (this.selectOptionMode == "CR") {

      this.cType = "CR";
      this.processFiltersAndReloadData(),
        this.getProgress()

    }
    else if (this.selectOptionMode == "WO") {
      this.cType = "WO";
      this.processFiltersAndReloadData()
      this.getProgress()

    }
    else if (this.selectOptionMode == "PL") {
      this.cType = "PL";
      this.processFiltersAndReloadData()
      this.getProgress()

    }
    else if (this.selectOptionMode == "PW") {
      this.cType = "PW";
      this.processFiltersAndReloadData()
      this.getProgress()

    }


  }
  changeType(event: any) {
    this.setType()
    if (this.selectOptionMode) {
      this.isCompatible = true
    } else {
      this.isCompatible = false
    }
  }
  changeOT(value: any) {

  }
  handleSelection(e: Event, args: any) {
    // this.selectedCallDeskTransaction = null;
    if (Array.isArray(args.rows) && this.ngGridObj) {

      let selected = args.rows.map((idx: number) => {
        const selectedRowData = this.ngGridObj.getDataItem(idx);
        return selectedRowData || '';
      });

      this.contractSelected = selected[0]?.contractNo
    }
  }

  handleFilterChange(event: any) {
    this.processFiltersAndReloadData()
    console.log(event)
  }

  getProgress() {
    // alert("getprogress")
    this.service.callProgress(this.user, this.cType).subscribe({
      next: (value) => {
        this.callProgress = value.pop()
      },
      error: (err) => {
        console.error(err)

        // this.snackBar.open("Call progress failed to load", 'Dismiss', {
        this.snackBar.open("Call progress loading", 'Dismiss', {
          duration: DurationUtil.TWO_SEC,
          horizontalPosition: "right",
          verticalPosition: "top",
          panelClass: ['mat-toolbar', 'mat-primary']
        });
      }
    })
  }

  handleSearchEnable(e: MatCheckboxChange) {
    if (e.checked) {

      this.changeMode("ALL")
    }
  }


  dateChangeHandler(event: MatDatepickerInputEvent<Date>) {
    this.date = DateUtil.getDateString(event.value)
  }

  searchCallList() {
    let proc_search = this.search;
    let proc_search_type = this.searchType
    let proc_type = this.mode.length < 1 ? this.defaults.mode : this.mode;
    let proc_dpd = this.dpd.length < 1 ? this.defaults.dpd : [...this.dpd].pop()
    let proc_npl = this.npl.length < 1 ? this.defaults.npl : [...this.npl].pop()
    let proc_td = this.td.length < 1 ? this.defaults.td : [...this.td].pop()
    let proc_not_payed = this.notPayed.length < 1 ? this.defaults.notPayed : [...this.notPayed].pop()

    // this.service.testAuth().subscribe({
    //   next: (value) => {
    //     console.log("AUTHEEEEE"+value);
    //   },
    //   error: (err) => {
    //     console.log ("ERROR AUTHEEEEE"+ err )
    //   }
    //     });



    this.service.searchCallListWithFilters(this.user, proc_search, proc_search_type, proc_type, proc_npl, proc_dpd, proc_not_payed, proc_td, this.date, this.cType).subscribe({
      next: (value) => {
        if (value.hasOwnProperty("msg")) {
          this.dataset = [];
          this.updateTable([])
          // this.snackBar.open("Todays Call List failed to load", 'Dismiss', {
          this.snackBar.open("Call progress loading", 'Dismiss', {
            duration: DurationUtil.TWO_SEC,
            horizontalPosition: "right",
            verticalPosition: "top",
            panelClass: ['mat-toolbar', 'mat-primary']
          });
        } else {
          this.dataset = []
          this.updateTable(value)
        }
      },
      error: (err) => {
        console.log(err)
        this.updateTable([])
        this.snackBar.open("Could not retreive search results", 'Dismiss', {
          duration: DurationUtil.TWO_SEC,
          horizontalPosition: "right",
          verticalPosition: "top",
          panelClass: ['mat-toolbar', 'mat-primary']
        });
      }
    })
  }

  changeMode(value: string) {
    this.mode = value
    if (value == "TODAYS") {
      this.selectedDate = this.todayDate
    }

    if (value == "BALANCE" || value == "COMPLETED") {
      this.enableSearch = false
    }
    this.processFiltersAndReloadData()
  }

  isDateChanged(): boolean {
    if (this.todayDate.getTime() == this.selectedDate.getTime()) {
      return false
    } else {
      return true
    }
  }

  updateTable(values: any[]) {
    console.log("got : " + values.length)
    values.forEach((item, key) => {
      this.dataset.push({
        id: key,
        contractNo: item.CONTRACTNO,
        name: item.NAME,
        ria: item.RIA,
        // stockAmount: this.thousandSeparator.transform(item.stockAmount),
        stockAmount: parseFloat(item.stockAmount),
        arrearsAmount: parseFloat(item.arrearsAmount),
        dueDate: item.dueDate,
        promise_amt: parseFloat(item.promise_amt),
        promise_date: item.promise_date,
        monthEndDpd: item.monthEndDPD,
        currentDpd: item.currentDPD,
        callDirection: item.direction_nature,
        BSTAT: item.BSTAT,
        area: item.area,
        lastpayday: item.lastpayday,
        npl_status: item.npl_status,
        npl_triggering_date: item.npl_triggering_date,

        cType: item.cType
      })
    })

    this.ngGrid.dataView.refresh();
  }

  handleDetails() {
    if (this.contractSelected == undefined || this.contractSelected == null) {
      this.snackBar.open('Please select a call record first', 'Dismiss', {
        duration: DurationUtil.TWO_SEC,
        horizontalPosition: "right",
        verticalPosition: "top",
        panelClass: ['mat-toolbar', 'mat-primary']
      });
    } else {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = false;
      dialogConfig.closeOnNavigation = true;
      dialogConfig.autoFocus = true;
      dialogConfig.height = '100vh';
      dialogConfig.width = '100vw';
      dialogConfig.maxWidth = '100vw';
      dialogConfig.data = {
        selectedCallDeskTransaction: {
          contractNo: this.contractSelected,
          cType: this.cType
        },
        dialogType: "CALL",
        mode: this.mode
      };

      const dialogRef = this.dialog.open(ManualCallDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(
        data => {
          this.processFiltersAndReloadData()
          this.getProgress()
          console.log(`Manual call dialog closed`);
        }
      );
    }
  }

  processFiltersAndReloadData() {
    //loadingView bool - KalanaRathnayake
    // alert("processFiltersAndReloadData")

    this.isLoading = true;
    this.contractSelected = null;
    let proc_type = this.mode.length < 1 ? this.defaults.mode : this.mode;
    let proc_dpd = this.dpd.length < 1 ? this.defaults.dpd : this.dpd.join(",")
    let proc_npl = this.npl.length < 1 ? this.defaults.npl : this.npl.join(",")
    let proc_td = this.td.length < 1 ? this.defaults.td : this.td.join(",")

    // alert(this.notPayed + this.notPayedOption)
    // let proc_not_payed = this.notPayed.length < 1 ? this.defaults.notPayed : this.notPayed.join(",")
    let proc_not_payed = this.notPayed.length < 1 ? this.defaults.notPayed : this.notPayed;

    // let proc_not_payed_Option = this.notPayedOption == 'default' || !this.notPayed.includes('NOT_PAID') ? this.defaults.notPayedOption : this.notPayedOption
    let proc_not_payed_Option = this.notPayedOption == 'default' ? this.defaults.notPayedOption : this.notPayedOption
    // alert(proc_not_payed_Option)

    // this.date
    this.service.getCallListWithFilters(this.user, proc_type, proc_npl, proc_dpd, proc_not_payed, proc_not_payed_Option, proc_td, this.date, this.cType).subscribe({
      next: (value) => {
        if (value.hasOwnProperty("msg")) {
          this.dataset = [];
          this.updateTable([])
          this.handleError("Todays Call List failed to load")
        } else {
          this.dataset = []
          this.updateTable(value)

        }
        //forProduction
        this.isLoading = false;
        // forDebugging
        // this.isLoading = true;
      },
      error: (err) => {
        console.warn(err)
        this.handleError("Todays Call List failed to load")
        this.isLoading = true;
      }
    })
  }


  handleError(msg: string = "An error occured. Cannot complete the task") {
    this.snackBar.open(msg, 'Dismiss', {
      duration: DurationUtil.TWO_SEC,
      horizontalPosition: "right",
      verticalPosition: "top",
      panelClass: ['mat-toolbar', 'mat-primary']
    });
  }

  hanldeClose() {
    this.dialogRef.close();
  }
}
