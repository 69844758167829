import { Component, NgZone, OnInit } from '@angular/core';
import { MapsAPILoader } from "@agm/core";
import { VisitDeskDialogComponent } from "../visit-desk-dialog/visit-desk-dialog.component";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { AngularGridInstance, Column, FieldType, Formatters, GridOption } from 'angular-slickgrid';
import { RequestDialogComponent } from "../request-dialog/request-dialog.component";
import { VisitDeskDataResponseModelInfo } from "../../models/visit-desk-data-response-model-info";
import { CallDeskDataResponseModel } from "../../models/call-desk-data-response-model";
import { VisitDeskDataResponseModel } from "../../models/visit-desk-data-response-model";
import { VisitDeskService } from "../../services/visit-desk.service";
import { VisitDeskDataRequestModel } from "../../models/visit-desk-data-request-model";
import { OptimizedVisitPostRequestObjectModel } from "../../models/visit-desk/optimized-visit-post-request-object-model";
import { HttpErrorResponse } from "@angular/common/http";
import { MatSnackBar } from '@angular/material/snack-bar';
import { RouteServicesService } from 'src/app/services/route-management/route-services';
import { UserAuthenticationService } from 'src/app/services/user-authentication/user-authentication.service';
import { DateUtil } from 'src/app/utils/DateUtil';
import { DurationUtil } from 'src/app/utils/DurationUtil';
import { TodaysVisitListV2Component } from "../v2/todays-visit-list-v2/todays-visit-list-v2.component";
import { rightAllignment } from 'src/app/utils/testAlign';
@Component({
  selector: 'app-visit-selection-desk-dialog',
  templateUrl: './visit-selection-desk-dialog.component.html',
  styleUrls: ['./visit-selection-desk-dialog.component.scss']
})
export class VisitSelectionDeskDialogComponent implements OnInit {

  readonly title = "Visit selection desk"
  panelOpenState: boolean = false

  latitude: number = 6.922973;
  longitude: number = 79.85734;
  zoom: number = 0;
  address: string = "";
  private geoCoder: any;
  private directionService: any = null;
  allVisitColumnDefinitions: Column[] = [];
  selectedVisitColumnDefinitions: Column[] = [];

  gridOptions: GridOption = {};
  allVisitDataset: any[] = [];
  gridOptionsSelectedVisit: GridOption = {};
  selectedVisitDataset: any[] = [];
  optimizedVisitPersistDataSet: OptimizedVisitPostRequestObjectModel[] = [];
  optimizedVisitPersistJSONDataSet: any;

  allVisitAngularGrid!: AngularGridInstance;
  selectedVisitangularGridObj!: any;

  angularGrid!: AngularGridInstance;
  allVisitAngularGridObj!: any;
  selectedTableRow!: any;
  selectedAllVisitDeskTransactions: VisitDeskDataResponseModelInfo[] = [];
  allVisitDeskDataResponseResult: CallDeskDataResponseModel = new CallDeskDataResponseModel();
  allVisitOriginalDataset: any[] = [];

  selectedOptimizeVisitTransaction: VisitDeskDataResponseModelInfo;

  selectedOptimizeVisitTableRow!: any;

  pathObj: any = {}

  // todays route data
  route_name: string = "N/A";

  // date selection
  selectDate: Date = null;

  // route list and selection
  routes: {
    routeId: string,
    name: string
  }[] = []

  isSaved: boolean = false;

  selectedRoute: string = null;

  vistiDeskDataRequestModel = new VisitDeskDataRequestModel();
  constructor(private dialogRef: MatDialogRef<VisitDeskDialogComponent>, public dialog: MatDialog,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone, protected visitDeskService: VisitDeskService, private _snackBar: MatSnackBar, private routesService: RouteServicesService, private auth: UserAuthenticationService) {

    this.allVisitColumnDefinitions = [
      { id: 'contractNo', name: 'Contract No', field: 'contractNo', sortable: true, minWidth: 100, filterable: true },
      { id: "type", name: "Type", field: "cType", minWidth: 50, sortable: true, filterable: true },
      { id: 'customerName', name: 'Name', field: 'customerName', sortable: true, minWidth: 200, filterable: true },
      { id: 'customerAddress', name: 'Address', field: 'customerAddress', sortable: true, minWidth: 300, filterable: true },
      { id: 'amountDue', name: 'Amount Due', field: 'amountDue', sortable: true, minWidth: 100, filterable: true, formatter: rightAllignment },
      { id: 'dueDate', name: 'Due Date', field: 'dueDate', sortable: true, minWidth: 100, type: FieldType.date, formatter: Formatters.dateIso, filterable: true },

    ];;

    this.selectedVisitColumnDefinitions = [
      { id: 'contractNo', name: 'Contract No', field: 'contractNo', sortable: true, minWidth: 100, filterable: true },
      { id: "type", name: "Type", field: "cType", minWidth: 50, sortable: true, filterable: true },
      { id: 'customerName', name: 'Name', field: 'customerName', sortable: true, minWidth: 200, filterable: true },
      { id: 'customerAddress', name: 'Address', field: 'customerAddress', sortable: true, minWidth: 300, filterable: true },
      { id: 'amountDue', name: 'Amount Due', field: 'amountDue', sortable: true, filterable: true, minWidth: 100, formatter: rightAllignment },
      { id: 'dueDate', name: 'Due Date', field: 'dueDate', sortable: true, type: FieldType.date, minWidth: 100, formatter: Formatters.dateIso, filterable: true },
    ];;

    this.gridOptions = {
      enableAutoResize: true,
      enableSorting: true,
      enableExport: true,
      enablePagination: false,
      enableRowSelection: true,
      enableExcelExport: true,
      enableFiltering: true,
      enableCheckboxSelector: true,
      enableCellNavigation: true,
      checkboxSelector: {
        hideSelectAllCheckbox: false,
      },
      multiSelect: true,
      rowSelectionOptions: {
        selectActiveRow: false,
      }
    };
    
    this.gridOptionsSelectedVisit = {

      enableAutoResize: true,
      enableSorting: true,
      enableExport: true,
      enablePagination: false,
      enableRowSelection: true,
      enableExcelExport: true,
      enableFiltering: true,
      enableCheckboxSelector: false,
      enableCellNavigation: true,
      checkboxSelector: {
        hideSelectAllCheckbox: true,
      },
      multiSelect: false,
      rowSelectionOptions: {
        selectActiveRow: true,
      }
    };
  }

  ngOnInit(): void {

    this.mapsAPILoader.load().then(() => {
      // removed because this api request is useless
      // this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;
      this.directionService = new google.maps.DirectionsService;
      this.pathObj = {
        origin: new google.maps.LatLng({ lat: 6.9, lng: 79 }),
        waypoints: [
          { location: { location: { lat: 6.9345, lng: 79.2123 }, stopover: true } },
          { location: { location: { lat: 6.9567, lng: 79.1143 }, stopover: true } },
          { location: { location: { lat: 6.9123, lng: 79.9721 }, stopover: true } },
          { location: { location: { lat: 6.9821, lng: 79.2821 }, stopover: true } }
        ],
        destination: new google.maps.LatLng({ lat: 6.9, lng: 79 })
      }
    });
    this.initiateTableData();
    this.getTodaysRoute();

    this.selectDate = new Date()

    this.getRoutesForUser(() => {
      this.selectedRoute = 'default'
    })
  }

  getRoutesForUser(callback: () => void) {
    let user = this.auth.currentUserValue.code
    this.routesService.getRoutesByUserId(user).subscribe({
      next: (value: any[]) => {
        this.routes = value?.map(item => {
          let { route, name } = item
          return {
            routeId: route,
            name: name
          }
        })

        callback();
      },
      error: err => {
        console.log(err)
        this._snackBar.open("Routes could not be loaded", "Dismiss", {
          duration: DurationUtil.TWO_SEC,
          horizontalPosition: "right",
          verticalPosition: "top",
          panelClass: ['mat-toolbar', 'mat-primary']
        })
      }
    })
  }


  initiateTableData(): any {
    // this.allVisitDataset[0] = {
    //   id: 0,
    //   contractNo: 'N/A',
    //   customerName: 'N/A',
    //   amountDue: 'N/A',
    //   dueDate: 'N/A',
    //   customerAddress: 'N/A'

    // };
    // this.selectedVisitDataset[0] = {
    //   id: 0,
    //   contractNo: 'N/A',
    //   customerName: 'N/A',
    //   amountDue: 'N/A',
    //   dueDate: 'N/A',
    //   customerAddress: 'N/A'

    // };

    this.allVisitDataset = [];
    this.selectedVisitDataset = [];

    // this.selectedVisitDataset = [];
    // this.resizeGrid2();
    // this.todayCallsAngularGrid.dataView.refresh();
  }

  initiateAllVisitAngulargridGrid(event: Event) {
    this.allVisitAngularGrid = (event as CustomEvent).detail as AngularGridInstance;
    this.allVisitAngularGridObj = this.allVisitAngularGrid.slickGrid;
    this.allVisitAngularGrid.slickGrid.onSelectedRowsChanged.subscribe((e, args) => {
      this.handleAllVisitTableRowSelection(e, args);
    });
  }
  initiateAngulargridGrid(event: Event) {
    this.angularGrid = (event as CustomEvent).detail as AngularGridInstance;
    this.selectedVisitangularGridObj = this.angularGrid.slickGrid;
    this.angularGrid.slickGrid.onSelectedRowsChanged.subscribe((e, args) => {
      this.handleOptimizeVisitTableRowSelection(e, args)
    });
  }
  resizeGrid2() {
    this.angularGrid.resizerService.resizeGrid();
  }


  /**Handle All visit desk table row selection*/
  handleAllVisitTableRowSelection(event: Event, args: any) {
    this.selectedAllVisitDeskTransactions = null;
    if (Array.isArray(args.rows) && this.allVisitAngularGridObj) {

      this.selectedTableRow = args.rows.map((idx: number) => {
        const selectedRowData = this.allVisitAngularGridObj.getDataItem(idx);
        if (selectedRowData) {
        }
        console.log('Handled Data loaded')
        return selectedRowData || '';
      });

      this.selectedAllVisitDeskTransactions = this.selectedTableRow;

    }
  }


  /**Handle Selected visit table row selection*/
  handleOptimizeVisitTableRowSelection(event: Event, args: any) {
    this.selectedOptimizeVisitTransaction = null;
    if (Array.isArray(args.rows) && this.selectedVisitangularGridObj) {

      this.selectedOptimizeVisitTableRow = args.rows.map((idx: number) => {
        const selectedRowData = this.selectedVisitangularGridObj.getDataItem(idx);
        if (selectedRowData) {
        }
        return selectedRowData || '';
      });

      this.selectedOptimizeVisitTransaction = this.selectedOptimizeVisitTableRow[0];

    }
  }

  // private setCurrentLocation() {
  //   if ('geolocation' in navigator) {
  //     navigator.geolocation.getCurrentPosition((position) => {
  //       this.latitude = position.coords.latitude;
  //       this.longitude = position.coords.longitude;
  //       console.log("GEO LOCATION" + this.latitude + this.longitude);

  //       this.zoom = 8;
  //       this.getAddress(this.latitude, this.longitude);
  //     });
  //   }
  // }

  getTodaysRoute() {
    // let user = "testuser0001";
    let user = this.auth.currentUserValue.code;
    let dateToday = DateUtil.getDateString(new Date());
    this.routesService.getTodaysRoute(user, dateToday).subscribe({
      next: (value) => {
        if (value != "NO Data Available") {
          let content = JSON.parse(value).content;
          let route_id = content[0].routeid;
          // let user = "testuser0001";
          this.routesService.getRoutesByUserIdAndRouteId(user, route_id).subscribe({
            next: (result: any) => {
              if (result != "NO Data Available") {
                // this.route_name =  result.content[0].NAME_;
                let result_proc = JSON.parse(result);
                this.route_name = result_proc.content[0].NAME_;

              } else {
                this._snackBar.open('Todays route data not available', 'Dismiss', {
                  duration: DurationUtil.TWO_SEC,
                  horizontalPosition: "right",
                  verticalPosition: "top",
                });
              }
            },
            error: (err) => {
              console.log("Todays route error :", err);
            }
          });
        } else {
          console.log("No data available");
        }
      },
      error: (err) => {
        console.log(err);
      }
    })
  }

  /**
   * Save distance, visit order(ordered list of selected contract ids) after optimize visit
   */
  saveRouteDistanceData() {
    let payload = {};
    this.visitDeskService.updateVisitDistanceData(payload)
      .subscribe({
        next: value => {
          console.log(value);
        },
        error: err => {
          console.log(err);
        }
      });
  }

  getAddress(latitude: any, longitude: any) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results: any, status: any) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }


  //loadingView bool - KalanaRathnayake
  isLoading: boolean = false;

  loadTodayVisit() {
    //loadingView bool - KalanaRathnayake
    this.isLoading = true;

    if (this.selectedRoute == null || this.selectedRoute == "default") {
      this._snackBar.open("Select the route first", "Dismiss", {
        duration: DurationUtil.TWO_SEC,
        horizontalPosition: "right",
        verticalPosition: "top",
        panelClass: ['mat-toolbar', 'mat-primary']
      })
      return;
    }
    let user = this.auth.currentUserValue.code
    let routeID = this.selectedRoute

    this.visitDeskService.getVisitDeskData(user, this.vistiDeskDataRequestModel, routeID).subscribe({
      next: (responseResult: VisitDeskDataResponseModel) => {
        this.allVisitDeskDataResponseResult = responseResult;
        this.allVisitOriginalDataset = this.allVisitDeskDataResponseResult.Table;
        Array.prototype.push.apply(this.allVisitDataset, this.allVisitOriginalDataset);

        this.allVisitDataset = this.allVisitDeskDataResponseResult.Table;

        if (this.allVisitDataset.length > 0) {
          for (let i = 0; i < this.allVisitDataset.length; i++) {
            this.allVisitDataset[i] = {
              id: i,
              assignDate: this.allVisitDataset[i].ASSIGNDATE,
              contractNo: this.allVisitDataset[i].CONTRACTNO,
              customerAddress: this.allVisitDataset[i].CUSTOMERADDRESS,
              customerName: this.allVisitDataset[i].CUSTOMERNAME,
              defaultAreass: this.allVisitDataset[i].DEFAULTARREARS,
              amountDue: this.allVisitDataset[i].TOTALARREARS,
              dueDate: this.allVisitDataset[i].DUEDATE,
              equipment: this.allVisitDataset[i].EQUIPMENT,
              grossRental: this.allVisitDataset[i].GROSSRENTAL,
              insuranArrears: this.allVisitDataset[i].INSURANCEARREARS,
              investigationCode: this.allVisitDataset[i].INVESTIGATORCODE,
              lastPayDate: this.allVisitDataset[i].LASTPAYDATE,
              make: this.allVisitDataset[i].MAKE,
              nic: this.allVisitDataset[i].NIC,
              noOfAreas: this.allVisitDataset[i].NOOFARREARS,
              otherAreassAmount: this.allVisitDataset[i].OTHERARREARSAMOUNT,
              registrationNo: this.allVisitDataset[i].REGISTRATIONNO,
              totalAreas: this.allVisitDataset[i].TOTALARREARS,
              totalAreas1: this.allVisitDataset[i].TOTALARREARS1,
              assignSerial: this.allVisitDataset[i].ASSIGNSERIAL,
              cType: this.allVisitDataset[i].cType
            };
          }
        }
        //when data is loaded the loading view will turn off by bool value 0 - KalanaRathnayake
        
        //forProduction
        this.isLoading = false;

        // forDebugging
        // this.isLoading = true;
      },
      error: (err) => {
        console.error(err)
        // loading will keep going when err trigger if you want to change make it false - KalanaRathnayake
        this.isLoading = false;
      }
    });
    this.resizeGrid2();
    this.allVisitAngularGrid.dataView.refresh();
  }

  openRequestDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '800px';
    dialogConfig.width = '900px';

    const dialogRef = this.dialog.open(RequestDialogComponent, dialogConfig);
  }

  clear() {
    this.allVisitDataset = [];
    this.selectedVisitDataset = [];
  }

  closeDialog() {
    this.dialogRef.close();
   
  }

  optimizeVisit() {
    console.log("optimizeVisit");
    // this.selectedVisitDataset = [];

    this.selectedVisitDataset = this.selectedAllVisitDeskTransactions;

    if (this.selectedVisitDataset.length > 0) {
      for (let i = 0; i < this.selectedVisitDataset.length; i++) {
        this.selectedVisitDataset[i] = {
          id: i,
          assignDate: this.selectedVisitDataset[i].assignDate,
          contractNo: this.selectedVisitDataset[i].contractNo,
          customerAddress: this.selectedVisitDataset[i].customerAddress,
          customerName: this.selectedVisitDataset[i].customerName,
          defaultAreass: this.selectedVisitDataset[i].defaultAreass,
          amountDue: this.selectedVisitDataset[i].amountDue,
          dueDate: this.selectedVisitDataset[i].dueDate,
          equipment: this.selectedVisitDataset[i].equipment,
          grossRental: this.selectedVisitDataset[i].grossRental,
          insuranArrears: this.selectedVisitDataset[i].insuranArrears,
          investigationCode: this.selectedVisitDataset[i].investigationCode,
          lastPayDate: this.selectedVisitDataset[i].lastPayDate,
          make: this.selectedVisitDataset[i].make,
          nic: this.selectedVisitDataset[i].nic,
          noOfAreas: this.selectedVisitDataset[i].noOfAreas,
          otherAreassAmount: this.selectedVisitDataset[i].otherAreassAmount,
          registrationNo: this.selectedVisitDataset[i].registrationNo,
          totalAreas: this.selectedVisitDataset[i].totalAreas,
          totalAreas1: this.selectedVisitDataset[i].totalAreas1,
          assignSerial: this.selectedVisitDataset[i].assignSerial,
          cType: this.allVisitDataset[i].cType

        };

        let date = new Date();
        let optimiZeVisitRecord = new OptimizedVisitPostRequestObjectModel();
        optimiZeVisitRecord.invgcode = this.selectedAllVisitDeskTransactions[i].investigationCode;
        optimiZeVisitRecord.contractno = this.selectedAllVisitDeskTransactions[i].contractNo;
        optimiZeVisitRecord.cType = this.selectedAllVisitDeskTransactions[i].cType;
        optimiZeVisitRecord.optdate = DateUtil.getDateString(date);
        optimiZeVisitRecord.comment = "BB APP AUTO PERSIST";
        optimiZeVisitRecord.assignserial = this.selectedAllVisitDeskTransactions[i].assignSerial;
        this.optimizedVisitPersistDataSet.push(optimiZeVisitRecord);
      }

      this.optimizedVisitPersistJSONDataSet = {
        userId: this.auth.currentUserValue.code,
        branchCode: this.auth.currentUserValue.branch,
        optimizedContract: this.optimizedVisitPersistDataSet
      };
    }

    this.resizeGrid2();
    this.angularGrid.dataView.refresh();
  }

  openVisitDesk() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '800px';
    dialogConfig.width = '1400px';

    const dialogRef = this.dialog.open(VisitDeskDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        console.log(`Dialog result: WORKS`);
      }
    );

  }

  save() {
    // console.log(JSON.stringify(this.optimizedVisitPersistJSONDataSet));
    console.log("Dataset save:success")
    this.visitDeskService.saveOptimizedVisit(this.optimizedVisitPersistJSONDataSet).subscribe({
      next: (data) => {
        console.log("Save DATA" + data);
        if (data.content[0] == "SUCCESS") {
          alert("Optimized Data saved successfully");
          this.isSaved = true;

        } else {
          alert("Data saving was unsuccessful");
        }

      },
      complete: () => {
        console.log("Save SUCCESS");
        this.isSaved = true;
      },
      error: (error: HttpErrorResponse) => {
        alert("Optimized Data saved successfully");
        this.isSaved = true;
        this.dialogRef.close();
      },

    });

    console.log(`Optimized Visit save: WORKS`);
    // this.reconciliationService.editReconciledRecord(this.selectedReconciliationTransaction).subscribe({
    //   next: data => {
    //   },
    //   complete: () => {
    //     document.getElementById('successMsg').textContent = "Successfully saved edited Reconciled result.";
    //     this.successMsgDissabled = false;
    //     this.errorMsgDissabled = true;
    //     this.isNeedToReloadData = true;
    //     this.searchReconciliationResults();
    //
    //   },
    //   error: (error: HttpErrorResponse) => {
    //     if (error.status == 401) {
    //       this.errorHandlingText = 'Your session has expired. Please log into the app again';
    //     } else {
    //       this.errorHandlingText = 'An error occurred while processing your request, Please try again. If the problem persists please contact our helpdesk for assistance.';
    //     }
    //     document.getElementById('errorHandlingMsg').textContent = this.errorHandlingText;
    //     this.errorMsgDissabled = false;
    //     this.successMsgDissabled = true;
    //   },
    // });




  }

  // ======================================================
  // Route distance and data view
  // ======================================================
  selectedOriginType: string;
  selectedDestinationType: string;
  originAddress: string = "";
  destinationLatLng: { lat: Number, lng: Number };
  destinationAddress: string = "";
  dataAvailable: boolean = false;
  waypointList: any[] = [];
  totalDistance: number;
  waypointNameList: any[] = [];

  getRouteDistance = async (origin: google.maps.LatLng, waypoints: any[], destination: any, optimize: boolean): Promise<number> => {
    let distanceTotal = 0;
    await this.directionService
      .route({
        origin: origin,
        waypoints: waypoints,
        destination: destination,
        optimizeWaypoints: optimize,
        travelMode: google.maps.TravelMode.DRIVING
      }, (result: google.maps.DirectionsResult, status: google.maps.DirectionsStatus) => {
        if (status == "OK") {
          this.waypointList = result.routes[0].legs;
          result.routes[0].legs.map(leg => {
            distanceTotal += leg.distance.value;
          })

          console.log(result.routes);
        } else {
          distanceTotal = -1;
        }

        this.waypointNameList = this.processWaypoints(result.routes[0].waypoint_order)
      });
    return distanceTotal < 0 ? -1 : distanceTotal;
  }

  async getPathDistance() {

    if (this.checkSelectedVisitDataValidity()) {
      let waypointAddressList = this.selectedVisitDataset.map(item => {
        return item.customerAddress + ", Sri Lanka";
      });

      let { destination, waypoints } = this.getDestinationAndWaypoints(waypointAddressList);
      let destinationToSend = destination;
      let waypointsToSend = waypoints.map(item => {
        return {
          location: item,
          stopover: true,
        }
      });
      // let origin = new google.maps.LatLng({ lat: 6.9271, lng: 79.8612 });
      let origin = new google.maps.LatLng({ lat: this.latitude, lng: this.longitude });
      // let waypoints = [
      //   { location: { lat: 6.9293, lng: 79.8721 }, stopover: true },
      //   { location: { lat: 6.9312, lng: 79.8843 }, stopover: true },
      //   { location: { lat: 6.9293, lng: 79.8756 }, stopover: true },
      //   { location: { lat: 6.9221, lng: 79.9121 }, stopover: true }
      // ]
      // let destination = new google.maps.LatLng({ lat: 6.9271, lng: 79.8612 });
      const distance = await this.getRouteDistance(origin, waypointsToSend, destinationToSend, true);
      this.totalDistance = distance;
      this.dataAvailable;

    } else {
      this._snackBar.open('Please select a list of visits to show data', 'Dismiss', {
        duration: DurationUtil.TWO_SEC,
        horizontalPosition: "right",
        verticalPosition: "top",
      });
    }
  }

  checkSelectedVisitDataValidity(): boolean {
    if (this.selectedVisitDataset.length == 1 && this.selectedVisitDataset[0].contractNo == "N/A") {
      return false;
    } else if (this.selectedVisitDataset.length < 1) {
      return false;
    } else {
      return true;
    }
  }

  getDestinationAndWaypoints(waypointAddressList: any[]): { destination: any, waypoints: any[] } {
    let destination: any = {};
    if (this.selectedDestinationType == "last") {
      destination = waypointAddressList.splice(-1)[0]
    } else if (this.selectedDestinationType == "address") {
      destination = this.destinationAddress;
    } else {
      destination = new google.maps.LatLng({ lat: this.latitude, lng: this.longitude })
    }

    let waypoints = waypointAddressList;

    return {
      destination: destination,
      waypoints: waypointAddressList
    };
  }

  displayDistance(num: number): string {
    const placeHolder = "Total distance: "
    if (num == -1) {
      return placeHolder + "Unavailable";
    } else if (isNaN(num)) {
      return "";
    } else {
      return placeHolder + (num / 1000.00).toFixed(2) + " km";
    }
  }

  processWaypoints(waypoints: any[]): any[] {
    let result: any[] = [];

    result.push("Origin");
    waypoints.forEach(item => {
      result.push(this.selectedVisitDataset[item].customerName);
    })

    if (this.selectedDestinationType != "last") {
      result.push(`Destination`);
    }

    console.log(result);

    return result;
  }

  todaysVisitListReport() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = false;
    dialogConfig.height = '100vh';
    dialogConfig.width = '99.5vw';
    dialogConfig.maxWidth = '99.5vw';
    const dialogRef = this.dialog.open(TodaysVisitListV2Component, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        console.log(`Todays Visit Lisit Report dialog opened`);
      }
    );
  }




}
