import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PostalCodeWithCoords, Route } from 'src/app/models/route-management/route-model';
import { RouteServicesService } from 'src/app/services/route-management/route-services';
import { UserAuthenticationService } from 'src/app/services/user-authentication/user-authentication.service';
import { DateUtil } from 'src/app/utils/DateUtil';
import { DurationUtil } from 'src/app/utils/DurationUtil';
import { MapPreviewDialogComponent } from '../map-preview-dialog/map-preview-dialog.component';
import { UpdateRouteFormComponent } from '../update-route-form/update-route-form.component';

@Component({
  selector: 'view-routes',
  templateUrl: './view-routes.component.html',
  styleUrls: ['./view-routes.component.scss']
})
export class ViewRoutesComponent implements OnInit {

  constructor(private service: RouteServicesService, public dialog: MatDialog, private _snackBar: MatSnackBar, private auth: UserAuthenticationService) { }

  routeList: Array<any> = [];

  selectedRoute:any = null;

  postalCode: PostalCodeWithCoords = {
    lat: 6.9392,
    lang: 79.8436,
    postalCode: "00100",
    name: "Colombo 01"
  }

  tableData: Array<any> = [];

  user: string = null;
  date: Date = null

  ngOnInit(): void {
    this.user = this.auth.currentUserValue.code

    this.date = new Date()
    this.fetchData();
  }

  fetchData() {
    console.log(this.user)
    let newTableData: any[] = [];

    this.service.getRoutesByUserId(this.user).subscribe(
      (value: any) => {
        value.forEach((item: any, key: any) => {
          newTableData.push({
            id: key,
            name: item.name,
            route: item.route,
            coords: [
              ...item.postal_code__
            ]
          })
        });
        this.tableData = newTableData;
      },
      (error: any) => {
        console.log(error);
      }
    );


    // ================================ this service is no longer used ======================================

    // let todaysDate = DateUtil.getDateString(this.date)
    

    // this.service.getTodaysRoute(this.user,todaysDate).subscribe({
    //   next: (value) => {
    //     if (value == "NO Data Available") {
    //       this.selectedRoute = null;
    //     } else {
    //       this._snackBar.open("Selected route found", 'Dismiss', {
    //         duration: 500,
    //         horizontalPosition: "right",
    //         verticalPosition: "top",
    //       });
    //     }
    //   },
    //   error: (err) => {
    //     this._snackBar.open("Today's route cannot be retrieved", 'Dismiss', {
    //       duration: 500,
    //       horizontalPosition: "right",
    //       verticalPosition: "top",
    //     });
    //   }
    // })
  }

  displayedColumns: string[] = ['ID', 'Name', "actions"];

  deleteById(index: number, id: string): void {
    console.log(index, id);
    let payload = {
      ROUTEID: id
    };
    this.service.deleteRoute(payload).subscribe(
      (value: any) => {
        if (value.content[0].RESULT == "SUCCESSFULLY DELETED") {
          this._snackBar.open('Route deleted', 'Dismiss', {
            duration: DurationUtil.TWO_SEC,
            horizontalPosition: "right",
            verticalPosition: "top",
            panelClass: ['mat-toolbar', 'mat-primary']
          });
          this.fetchData();
        } else {
          alert("An error occured when deleting the record!!")
        }
      },
      (error: any) => {
        console.log(error);
      }
    )
  }

  handlePreview(id: number) {
    let temp: any[] = [];
    this.tableData[id].coords.forEach((item: any) => {
      temp.push({
        postal_code: item.postal_code_, name: item.name_, lat: parseFloat(item.lat), lng: parseFloat(item.lng)
      })
    })
    const dialogRef = this.dialog.open(MapPreviewDialogComponent, {
      data: temp,
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  updateById(id: number): void {
    let temp = { ...this.tableData[id] };
    const dialogRef = this.dialog.open(UpdateRouteFormComponent, {
      disableClose: true,
      data: temp,
      height: '800px',
      width: '1200px'
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      this.fetchData();
    });
  }

  selectTodaysRoute(id: any) {
    this.selectedRoute = id;
    let payload = {
      date: DateUtil.getDateString(new Date()),
      usrid: this.auth.currentUserValue.code,
      visits: [
        {
          routid: this.selectedRoute
        }
      ]
    }
    this.service.saveTodaysRoute(payload).subscribe({
      next: value => {
        this._snackBar.open('Todays route selected', 'Dismiss', {
          duration: DurationUtil.TWO_SEC,
          horizontalPosition: "right",
          verticalPosition: "top",
        });
      },
      error:err => {
        console.log(err.error)
        this._snackBar.open('Todays route selection failed', 'Dismiss', {
          duration: DurationUtil.TWO_SEC,
          horizontalPosition: "right",
          verticalPosition: "top",
        });
      }
    })
    
  }
}