<app-dialog-header
  [title]="title"
  (closeDialogEvent)="closeDialog()"
></app-dialog-header>

<div class="container-fluid">
  <div>
    <div class="container dialog-wrapper w-100">
      <div class="row g-12">
        <div class="col-12 col-sm-12 detail-tile-col">
          <div class="container detail-tile">

            <div class="col-12">
              <mat-radio-group aria-label="Select an option" [(ngModel)]="Receipt">
                <mat-radio-button color="primary" checked="true" value="Cash">
                  <span style="font-size: 14px">Cash</span>
                </mat-radio-button>
                <span class="tab"></span>
                <mat-radio-button color="primary" value="Cheque">
                  <span style="font-size: 14px">Cheque</span>
                </mat-radio-button>
              </mat-radio-group>
            </div>

            <hr>

            <div class="row details-list" *ngIf= "Receipt != 'Cheque'">
              <div class="col-4 details-list-leading">
                Cash Amount
              </div>
              <div class="col-8 details-list-content">
                <mat-form-field appearance="fill" style="width: 90%; margin-top: 0.5rem">
                  <mat-label>Cash Amount</mat-label>

                  <input type="text" matInput ThousandSeparatorInput appTwoDigitDecimalNumber [(ngModel)]="cashAmount" [disabled]="Receipt != 'Cash'" autocomplete="off">

                  <!-- <input matInput (keypress)="numberOnly($event)" [(ngModel)]="cashAmount" [disabled]="Receipt != 'Cash'" autocomplete="off"> -->
                </mat-form-field>
              </div>
            </div>
            <div class="row details-list" *ngIf= "Receipt != 'Cash'">
              <div class="col-4 details-list-leading">
                Cheque Amount
              </div>
              <div class="col-8 details-list-content">
                <mat-form-field appearance="fill" style="width: 90%; margin-top: 0.5rem">
                  <mat-label>Cheque Amount</mat-label>

                  <input matInput ThousandSeparatorInput appTwoDigitDecimalNumber [(ngModel)]="chequeAmount" [disabled]="Receipt != 'Cheque'" autocomplete="off">
                </mat-form-field>
              </div>
            </div>
            <div class="row details-list" *ngIf= "Receipt != 'Cash'">
              <div class="col-4 details-list-leading">
                Cheque Number
              </div>
              <div class="col-8 details-list-content">
                <mat-form-field appearance="fill" style="width: 90%; margin-top: 0.5rem">
                  <mat-label>Cheque Number</mat-label>
                  <input matInput (keypress)="numberOnly($event)" maxlength="13" [(ngModel)]="chequeNo" [disabled]="Receipt != 'Cheque'" autocomplete="off">
                </mat-form-field>
              </div>
            </div>
            <div class="row details-list" *ngIf= "Receipt != 'Cash'">
              <div class="col-4 details-list-leading">
                Cheque A/C
              </div>
              <div class="col-8 details-list-content">
                <mat-form-field appearance="fill" style="width: 90%; margin-top: 0.5rem">
                  <mat-label>Cheque A/C</mat-label>
                  <input matInput (keypress)="numberOnly($event)" [(ngModel)]="chequeAC" [disabled]="Receipt != 'Cheque'" autocomplete="off">
                </mat-form-field>
              </div>
            </div>
            <div class="row details-list" *ngIf= "Receipt != 'Cash'">
              <div class="col-4 details-list-leading">
                Cheque Date
              </div>
              <div class="col-8 details-list-content">
                <mat-form-field appearance="fill" class="w-100">
                  <mat-label>Cheque Date</mat-label>
                  <!-- <input matInput placeholder="disable typing" readonly [matDatepicker]="chequeDatePicker" [disabled]="Receipt != 'Cheque'" autocomplete="off" (click)="chequeDatePicker.open()"> -->
                  <input matInput placeholder="disable typing" readonly [matDatepicker]="chequeDatePicker" [disabled]="isDisabled" autocomplete="off" (click)="chequeDatePicker.open()" [(ngModel)]="currentDate">
                  <!-- (dateInput)="selectchequeDate($event)" -->
                  <mat-hint>dd/mm/yyyy</mat-hint>
                  <mat-datepicker-toggle matSuffix [for]="chequeDatePicker"></mat-datepicker-toggle>
                  <mat-datepicker touchUi #chequeDatePicker></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <div class="row details-list">
              <div class="col-4 details-list-leading">
                Receipt No
              </div>
              <div class="col-8 details-list-content">
                  <mat-form-field appearance="fill" style="width: 90%; margin-top: 0.5rem">
                    <mat-label >Receipt No</mat-label>
                    <input matInput [disabled]="isReceiptFieldDisable" [(ngModel)]="receiptNo">
                  </mat-form-field>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="row" style="padding-top: 2rem">
        <div class="col-4">
          <button view class="btn btn-primary float-center" style="width: 80%" [disabled]="isGetButtonDisable"  (click)="getReceipt()">Save Receipt</button>
        </div>
        <!--<div class="col-4">-->
          <!--<button view class="btn btn-primary float-center" style="width: 80%" [disabled]="isConfirmButtonDisable" (click)="confirmReceipt()">Confirm</button>-->
        <!--</div>-->

        <div class="col-4">
          <button view class="btn btn-primary float-center" style="width: 80%" (click)="closeDialog()">Close</button>
        </div>
  </div>




</div>
