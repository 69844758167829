<app-dialog-header [title]="title" (closeDialogEvent)="closeDialog()"></app-dialog-header>
<!-- loadingView - kalanarathnayake-->
<div id="backColor" *ngIf="isLoading">
  <div id="loadingView">
    <img class="loadingGif" src="../../../../assets/loading.gif" alt="loadingLogo">
  </div>
</div>

<!-- <div class="py-4">
  <h4 style="color:blue">Your Current Address: </h4>
  <h5 style="color:darkred"> {{address}}</h5>
</div> -->

<div
  style=" display:flex; justify-content:flex-end; align-items: baseline; width:100%; padding-top: 1.5rem; padding-right: 1.5rem;">
  <table bordet="0">
    <tr>
      <td>
        <mat-form-field appearance="fill">
          <mat-label>Select a route</mat-label>
          <mat-select [(ngModel)]="selectedRoute" name="route">
            <mat-option value="default">Not selected</mat-option>
            <mat-option *ngFor="let itr of routes" [value]="itr.routeId">
              {{itr.name}}
            </mat-option>
          </mat-select>
          <mat-hint align="start">Select a route</mat-hint>
        </mat-form-field>
      </td>
      <td style="color:green; padding-top:1.5rem; padding-left: 1.5rem">
        <button view class="btn btn-primary float-left" (click)="loadTodayVisit()"
          [disabled]="selectedRoute == 'default'">Load
          Visit</button>
      </td>
      <td style="color:green; padding-top:1.5rem; padding-left: 1.5rem">
        <button view class="btn btn-primary float-left" (click)="todaysVisitListReport()">View Visits</button>
      </td>

      <td style="color:green; padding-top:1.5rem; padding-left: 1.5rem">
        <button view class="btn btn-primary float-left" (click)="clear()">Clear</button>
      </td>
    </tr>
  </table>
</div>

<div class="col-12 px-2 overflow-container">
  <h5 class="pb-1">Allocated visits</h5>
  <p>Showing {{allVisitDataset.length}} records</p>
  <angular-slickgrid gridId="allVisit" [columnDefinitions]="allVisitColumnDefinitions" [gridOptions]="gridOptions"
    [dataset]="allVisitDataset" (onAngularGridCreated)="initiateAllVisitAngulargridGrid($event)">
  </angular-slickgrid>
</div>
<div style="display:flex; justify-content:flex-end; width:100%; padding-top: 1.5rem; padding-right: 1.5rem;">
  <table>
    <tr>
      <td style="color:green; padding-top:1.5rem; padding-left: 1.5rem">
        <button [disabled]="selectedAllVisitDeskTransactions?.length <1" view class="btn btn-primary float-left"
          (click)="optimizeVisit()">Optimize</button>
      </td>
    </tr>
  </table>
</div>
<div class="col-12 px-2 overflow-container">
  <h5 class="pb-1">Selected Visits </h5>
  <p>Showing {{selectedVisitDataset.length}} records</p>
  <angular-slickgrid gridId="selectedVisit" style="width:95%" [columnDefinitions]="selectedVisitColumnDefinitions"
  [gridOptions]="gridOptionsSelectedVisit" [dataset]="selectedVisitDataset"
    (onAngularGridCreated)="initiateAngulargridGrid($event)"></angular-slickgrid>
</div>
<div class="w-100 py-2">
  <mat-accordion>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Calculate distance
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="w-100 data-view-container container px-2 py-3">
        <div class="row">
          <div class="col-12 col-md-6 g-2">
            <h3 class="py-2">Origin</h3>
            <mat-radio-group aria-label="Select an option" [(ngModel)]="selectedOriginType">
              <div class="row">
                <mat-radio-button value="current">Current Location</mat-radio-button>
              </div>
              <div class="row">
                <mat-radio-button value="address">Custom address</mat-radio-button>
              </div>
            </mat-radio-group>
            <mat-form-field appearance="outline" class="m-1 w-100 p-1">
              <mat-label>Origin</mat-label>
              <input matInput [value]="originAddress" [disabled]="selectedOriginType != 'address'" />
              <mat-hint>Ex :- Colombo, Sri Lanka</mat-hint>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-6 g-2">
            <h3 class="py-2">Destination</h3>
            <mat-radio-group aria-label="Select an option" [(ngModel)]="selectedDestinationType">
              <div class="row">
                <mat-radio-button value="current">Same as origin</mat-radio-button>
              </div>
              <div class="row">
                <mat-radio-button value="last">Last Location</mat-radio-button>
              </div>
              <div class="row">
                <mat-radio-button value="address">Custom</mat-radio-button>
              </div>
            </mat-radio-group>
            <mat-form-field appearance="outline" class="m-1 w-100 p-1">
              <mat-label>Destination</mat-label>
              <input matInput [value]="destinationAddress" [disabled]="selectedDestinationType != 'address'" />
              <mat-hint>Ex :- Colombo, Sri Lanka</mat-hint>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <button mat-raised-button color="primary" (click)="getPathDistance()">Get data</button>
        </div>
        <div class="p-2">
          <div class="row p-2" *ngFor="let item of waypointList; let i = index">
            <div class="col-8">
              <div>start : <span class="waypoint-list-names">{{waypointNameList[i]}}
                  <span>({{item.start_address}})</span></span>
              </div>
              <div>End: <span class="waypoint-list-names">{{waypointNameList[i+1]}}
                  <span>({{item.end_address}})</span></span>
              </div>
            </div>
            <div class="col-4">{{item.distance.text}}</div>
            <br />
          </div>
          <div class="py-2">{{displayDistance(this.totalDistance)}}</div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<!-- disable button - Bilesh Update -->
<div style=" display:flex; justify-content:flex-end; width:100%; padding-top: 1.5rem; padding-right: 1.5rem;">
  <table bordet="0">
    <tr>
      <td style="color:green; padding-top:1.5rem; padding-left: 1.5rem">
        <!-- <button [disabled]="(selectedVisitDataset?.length <1) && isSaved" view class="btn btn-primary float-left"
          (click)="save()">Save</button> -->
        <button [disabled]="selectedVisitDataset?.length < 1 || isSaved" class="btn btn-primary float-left"
          (click)="save()">Save</button>

      </td>
      <td style="color:green; padding-top:1.5rem; padding-left:1.5rem;">
        <button view class="btn btn-primary float-left" (click)="closeDialog()">Close</button>
      </td>
    </tr>
  </table>
</div>
<div>
