<app-dialog-header [title]="title" (closeDialogEvent)="closeDialog()"></app-dialog-header>
<div class="container-fluid">
  <div class="row border-1" style="padding-top: 1rem">
    <div class="col-4 ">
      <button view class="btn btn-primary float-none" style="width: 80%" (click)="openInquiry()">Inquire</button>
    </div>
    <div class="col-4 ">
      <button view class="btn btn-primary float-none" style="width: 80%" (click)="closeDialog()">Close</button>
    </div>
  </div>

  <div>
    <div class="container dialog-wrapper w-100">
      <div class="row g-5">
        <div class="col-12 col-sm-6 detail-tile-col">
          <div class="container detail-tile">
            <div class="row details-list">
              <div class="col-6 details-list-leading">
                Contract details
              </div>
              <div class="col-6 details-list-content">
                {{this.selectedOptimizeVisitTransaction.contractNo || "---"}}
              </div>
            </div>
            <div class="row details-list">
              <div class="col-6 details-list-leading">
                Client Name
              </div>
              <div class="col-6 details-list-content">
                {{this.selectedOptimizeVisitTransaction.customerName || "---"}}
              </div>
            </div>
            <div class="row details-list">
              <div class="col-6 details-list-leading">
                Address
              </div>
              <div class="col-6 details-list-content">
                {{this.selectedOptimizeVisitTransaction.customerAddress || "---"}}
              </div>
            </div>
            <div class="row details-list">
              <div class="col-6 details-list-leading">
                Vehicle details
              </div>
              <div class="col-6 details-list-content">
                {{this.selectedOptimizeVisitTransaction.registrationNo || "---"}}
              </div>
            </div>
            <div class="row details-list">
              <div class="col-6 details-list-leading">
                3P details
              </div>
              <div class="col-6 details-list-content">
                {{this.selectedOptimizeVisitTransaction.threep_name || "---"}}
              </div>
            </div>
            <div class="row details-list">
              <div class="col-6 details-list-leading">
                Grt details
              </div>
              <div class="col-6 details-list-content">
                {{this.selectedOptimizeVisitTransaction.guranter_name || "---"}}
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 detail-tile-col">
          <div class="container detail-tile">
            <mat-radio-group aria-label="Select an option">
              <div class="row details-list">
                <div class="col-6 details-list-leading">
                  <mat-radio-button color="primary" value="primary"><span
                      style="font-weight: 100; color: rgb(81, 79, 104); font-size: 14px;">Primary Contact
                      number</span></mat-radio-button>
                </div>
                <div class="col-6 details-list-content">
                  {{this.selectedOptimizeVisitTransaction.contact_no || "---"}}
                </div>
              </div>
              <div class="row details-list">
                <div class="col-6 details-list-leading">
                  <mat-radio-button color="primary" value="secondary"><span
                      style="font-weight: 100; color: rgb(81, 79, 104); font-size: 14px">Secondary Contact
                      number</span></mat-radio-button>
                </div>
                <div class="col-6 details-list-content">
                  {{this.selectedOptimizeVisitTransaction.secondary_contact_no || "---"}}
                </div>
              </div>
              <!--<div class="row details-list">-->
                <!--<div class="col-6 details-list-leading">-->
                  <!--Transaction summary-->
                <!--</div>-->
                <!--<div class="col-6 details-list-content">-->
                  <!--{{this.selectedOptimizeVisitTransaction.grossRental}}-->
                <!--</div>-->
              <!--</div>-->
              <div class="row details-list">
                <div class="col-6 details-list-leading">
                  <mat-radio-button color="primary" value="secondary"><span
                      style="font-weight: 100; color: rgb(81, 79, 104); font-size: 14px">Guranter Contact
                      number</span></mat-radio-button>
                </div>
                <div class="col-6 details-list-content">
                  {{this.selectedOptimizeVisitTransaction.guranter_contact_no || "---"}}
                </div>
              </div>
            </mat-radio-group>
            <div class="row details-list">
              <div class="col-6 details-list-leading">
                Finance amount
              </div>
              <div class="col-6 details-list-content">
                {{this.selectedOptimizeVisitTransaction.totalArrears || "---"}}
              </div>
            </div>
            <div class="row details-list">
              <div class="col-6 details-list-leading">
                Rentals debited
              </div>
              <div class="col-6 details-list-content">
                {{this.selectedOptimizeVisitTransaction.last_payment_amount || "---"}}
              </div>
            </div>
            <div class="row details-list">
              <div class="col-6 details-list-leading">
                Rentals paid
              </div>
              <div class="col-6 details-list-content">
                {{this.selectedOptimizeVisitTransaction.last_payment_amount || "---"}}
              </div>
            </div>
            <div class="row details-list">
              <div class="col-6 details-list-leading">
                Rental value
              </div>
              <div class="col-6 details-list-content">
                {{this.selectedOptimizeVisitTransaction.grossRental || "---"}}
              </div>
            </div>
            <div class="row details-list">
              <div class="col-6 details-list-leading">
                Rental due date
              </div>
              <div class="col-6 details-list-content">
                {{this.dueDate || "---"}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row g-5">
        <div class="col-12 col-sm-6 detail-tile-col">
          <div class="detail-tile">
            <div class="row details-list">
              <div class="col-6 details-list-leading">
                Last paymet amount
              </div>
              <div class="col-6 details-list-content">
                {{this.selectedOptimizeVisitTransaction.last_payment_amount || "---"}}
              </div>
            </div>
            <div class="row details-list">
              <div class="col-6 details-list-leading">
                Paid date
              </div>
              <div class="col-6 details-list-content">
                {{this.lastPayDate || "---"}}
              </div>
            </div>
            <div class="row details-list">
              <div class="col-6 details-list-leading">
                Payment mode
              </div>
              <div class="col-6 details-list-content">
                {{this.selectedOptimizeVisitTransaction.nic || "---"}}
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 detail-tile-col">
          <div class="detail-tile">
            <div class="row details-list">
              <div class="col-6 details-list-leading">
                NP entry details
              </div>
              <div class="col-6 details-list-content">
                {{this.selectedOptimizeVisitTransaction.otherArreassAmount || "---"}}
              </div>
            </div>
            <div class="row details-list">
              <div class="col-6 details-list-leading">
                Direction nature for a call
              </div>
              <div class="col-6 details-list-content">
                Self diarized
              </div>
            </div>
            <div class="row details-list">
              <div class="col-6 details-list-leading">
                Flagging details
              </div>
              <div class="col-6 details-list-content">
                3rd party flag
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <form>
        <!--<div class="row">-->
          <!--<div class="col-12">-->
            <!--<label style="padding-left: 0.5rem; color: rgba(0,0,0,.6);">Call Status :</label> <br>-->
            <!--<select #callStatus style="width: 90%" (change)="onCallStatusChange(callStatus.value)">-->
              <!--<option value="select">-Select-</option>-->
              <!--<option value="answered">Answered</option>-->
              <!--<option value="notAnswered">Ringing but not answered</option>-->
              <!--<option value="switchOff">Switched off</option>-->
              <!--<option value="engaged">Engaged</option>-->
              <!--<option value="wrongNumuber">Wrong number</option>-->
              <!--<option value="cutLine">Cut the line</option>-->
            <!--</select>-->
          <!--</div>-->
        <!--</div>-->

        <div class="row">
          <div class="col-12" style="padding-top: 1rem" [hidden]="isStatusHidden">
            <label style="color: rgba(0,0,0,.6); padding-left: 0.5rem;">Visit Status :</label><br>
            <select #status style="width: 90%" (change)="onVisitStatusChange(status.value)">
              
              <option value="select">-Select-</option>
              <option value="promisedToPay">Promised to pay</option>
              <option value="pdc">PDC</option>
              <option value="leftMsg">Left a message</option>
              <option value="visitBranch">Promised to visit branch</option>
            </select>
          </div>
        </div>

        <div [hidden]="isPromisedToPayDisabled">
          <div class="row" id="promisedToPay">
            <div class="col-12">
              <mat-form-field appearance="fill" style="width: 90%; margin-top: 0.5rem">
                <mat-label>Promised Amount</mat-label>
                <input matInput [(ngModel)]="promisedAmount">
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-8">
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>Promise Payment Date</mat-label>
                <input matInput [min]="minDate" [matDatepicker]="picker" (dateInput)="selectPaymentDate($event)">
                <mat-hint>dd/mm/yyyy</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker touchUi #picker></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>

          <br>
        <div>
          <!--<div class="row" style="">-->
            <!--<div class="col-12" style="font-weight: bold">-->
              <!--<br>Next Follow Up Date:-->
            <!--</div>-->
          <!--</div>-->
          <div class="row">
            <div class="col-8">
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>Next Follow Up Date:</mat-label>
                <input matInput [min]="minDate" [matDatepicker]="followupPicker" placeholder="disable typing" readonly 
                  (dateInput)="selectFollowupDate($event)">
                <mat-hint>dd/mm/yyyy</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="followupPicker"></mat-datepicker-toggle>
                <mat-datepicker touchUi #followupPicker></mat-datepicker>
              </mat-form-field>
            </div>
            <!--<div class="col-12">-->
              <!--<mat-form-field appearance="fill" style="width: 90%">-->
                <!--<mat-label>Date</mat-label>-->
                <!--<input matInput>-->
              <!--</mat-form-field>-->

            <!--</div>-->

          </div>
        </div>

        <br>
        <div class="row">
          <div class="col-12">
            <!-- <mat-checkbox class="example-margin" [(ngModel)]="isCompleted">Complete</mat-checkbox -->
            <!-- <mat-checkbox class="row" color="primary" [(ngModel)]="isCompleted">Completed</mat-checkbox> -->
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <mat-form-field appearance="fill" style="width: 90%; margin-top: 0.5rem">
              <mat-label>Comment</mat-label>
              <input matInput [(ngModel)]="comment">
            </mat-form-field>
          </div>

        </div>

      </form>
    </div>

    <div class="row" style="padding-top: 2rem">

      <div class="col-3">
        <button view class="btn btn-primary float-center" style="width: 80%" (click)="checking()">Track me</button>
      </div>
      <div class="col-3">
        <button view class="btn btn-primary float-center" style="width: 80%" (click)="updateVisitStatus()">Save</button>
      </div>


      <div class="col-3">
        <button view class="btn btn-primary float-right"
          style="background-color:aqua;  width: 100% ;  color: darkmagenta" (click)="openReceipt()">RECEIPT</button>
      </div>
      <div class="col-3">
        <button view class="btn btn-primary float-right"
          style="background-color: cornflowerblue ; color: red ;  width: 100%" (click)="openPdCheque()">PD
          CHEQUE</button>
      </div>
    </div>

  </div>

</div>