<div class="dialog-container">
    <div class="cancel-area" (click)="closeDialog()">
        <button class="cancel-button" mat-icon-button aria-label="Example icon button with a vertical three dot icon" (click)="closeDialog()">
            <mat-icon class="close-icon" appearance="fill" color="warn" (click)="closeDialog()">close</mat-icon>
        </button>
    </div>
    <div class="dialog-content w-100 p-3">
        <div *ngIf="noContent" class="no-content">
            No data available
        </div>
        <div class="container w-75 entry-tile px-4 py-1 my-2" *ngFor="let item of entries">
            <div class="row w-100 py-1" >
                <div class="col-6 tile-leading">Description</div>
                <div class="col-6 tile-content">{{item.DESCR || "---"}}</div>
            </div>
            <div class="row w-100 py-1">
                <div class="col-6 tile-leading">Date</div>
                <div class="col-6 tile-content">{{item.DTE_OF_NT || "---"}}</div>
            </div>
            <div class="row w-100 py-1">
                <div class="col-6 tile-leading">watch flag</div>
                <div class="col-6 tile-content">{{item.WATCH_FLAG || "---"}}</div>
            </div>
            <div class="row w-100 py-1">
                <div class="col-6 tile-leading">Promise way</div>
                <div class="col-6 tile-content">{{item.PROMISE_WAY || "---"}}</div>
            </div>
            <div class="row w-100 py-1">
                <div class="col-6 tile-leading">INVAPI code</div>
                <div class="col-6 tile-content">{{item.INVAPI_CODE || "---"}}</div>
            </div>
        </div>
    </div>
</div>