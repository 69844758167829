<app-dialog-header [title]="title" (closeDialogEvent)="closeDialog()"></app-dialog-header>
<!-- loadingView - kalanarathnayake-->
<div id="backColor" *ngIf="isLoading">
  <div id="loadingView">
    <img class="loadingGif" src="../../../../assets/loading.gif" alt="loadingLogo">
  </div>
</div>
<!-- loadingView -->
<div class="container-fluid">
  <div class="row border-1" style="padding-top: 1rem">
    <div class="col-9">
      <h2 *ngIf="dialogType != 'RELATED'">{{dialogType == 'VISIT' ? 'Visit ' : 'Call '}} status update <span
          style="font-size: 20px; color: rgb(107, 66, 255);">{{mode == "ALL" || mode == "COMPLETED" ? '(Proceed from
          balance call list to update)' : ''}}</span></h2>
      <h2 *ngIf="dialogType == 'RELATED'">Contract details</h2>
    </div>
  </div>
  <div class="row border-1" style="padding-top: 1rem">
    <div class="col-4 ">
      <button view class="btn btn-primary float-none "
        style=" background-color:#5eac0c; border-color:blue; color:white; width: 80%"
        (click)="openInquiry()">Inquire</button>
    </div>
    <div class="col-4">
      <a class="btn btn-primary float-none"
        style=" background-color:#5eac0c; border-color:blue; color:white; width: 80% "
        (click)="callTheNumber()">Dial</a>
    </div>
    <div class="col-4 ">
      <button view class="btn btn-primary float-none"
        style="background-color:#5eac0c; border-color:blue; color:white; width: 80%"
        (click)="settlementCalculation()">Calculate</button>
      <!-- [disabled]="true" -->
    </div>
  </div>
  <div class="container dialog-wrapper w-100 py-1">
    <div class="row d-flex align-items-baseline py-1">
      <div class="col-3 details-list-content">
        {{this.selectedCallDeskTransaction.contractNo | whitespace}}
      </div>
      <div class="col-3 details-list-content">
        {{customerName| whitespace}}
      </div>
      <div class="col-3 details-list-content">

        <button class="w-100 p-0" [disabled]="!ceft" mat-raised-button (click)="sendContactDetails()">{{contactNumber |
          whitespace}}</button>
      </div>
      <!--<div class="col-2 details-list-content">-->
      <!--New Client-->
      <!--</div>-->
      <div class="col-3 details-list-content ">
        <button class="w-95% p-0" [disabled]="!ceft" mat-raised-button (click)="sendCEFT()">CEFT({{ceft |
          whitespace}}) </button>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-4 px-1 py-2">
        <div class="w-100 card-container my-2">
          <div class="card-header w-100 p-2">
            <span>Snap details</span>
          </div>
          <div class="card-content p-2 w-100">

            <!-- <div class="row w-100 d-flex px-3">
              <button mat-raised-button color="primary" class="w-100" (click)="openLocationMap()">Location
                Details</button>
            </div> -->

            <div class="row card-content-row w-100 px-3 px-md-2 py-2">
              <div class="col-12">
                <div class="col-12 card-content-leading" style="font-weight: 800;">Call Direction</div>
                <div class="col-12 card-content-description" style="font-weight: 800;">{{this.snapData.direction_nature
                  || "---"}}</div>

              </div>
            </div>


            <div class="row card-content-row w-100 px-3 px-md-2 py-2">
              <div class="col-12 details-list-content">
                {{this.snapData.fullname| whitespace}}
                <!-- {{selectedClientDetail.fullname | whitespace}} -->
              </div>
            </div>



            <div class="row card-content-row w-100 px-3 px-md-2 py-2">
              <div class="col-12">
                <div class="col-12 card-content-leading" style="font-weight: 800;">Type</div>
                <div class="col-12 card-content-description" style="font-weight: 800;">{{this.snapData.cType
                  || "---"}}</div>

              </div>
            </div>

            <div class="row pb-2 pb-lg-0">
              <div class="col-6 col-lg-6 py-1">
                <div class="row card-content-row w-100 px-3 px-md-2 py-2">
                  <div class="col-12">
                    <div class="col-12 card-content-leading" style="font-weight: 800;">Rental Arrears</div>
                    <div class="col-12 card-content-description" style="font-weight: 800;">{{this.snapData.RentalArrears
                      |
                      thousandSeparator}}</div>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-6 py-1">
                <div class="row card-content-row w-100 px-3 px-md-2 py-2">
                  <div class="col-12">
                    <div class="col-12 card-content-leading" style="font-weight: 800;">Arrears</div>
                    <div class="col-12 card-content-description" style="font-weight: 800;">{{(this.snapData.arrears |
                      thousandSeparator) || "---"}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row pb-2 pb-lg-0">
              <div class="col-6 col-lg-6 py-1">
                <div class="row card-content-row w-100 px-3 px-md-2 py-2">
                  <div class="col-12">
                    <div class="col-12 card-content-leading" style="font-weight: 800;">DPD</div>
                    <div class="col-12 card-content-description" style="font-weight: 800;">{{(this.snapData.DPD |
                      thousandSeparator) || "---"}}</div>
                  </div>
                </div>

              </div>
              <div class="col-6 col-lg-6 py-1">
                <div class="row card-content-row w-100 px-3 px-md-2 py-2">
                  <div class="col-12">
                    <div class="col-12 card-content-leading" style="font-weight: 800;">Vehicle Num</div>
                    <div class="col-12 card-content-description" style="font-weight: 800;">{{this.snapData.VehicleNo ||
                      "---" }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row pb-2 pb-lg-0">
              <div class="col-6 col-lg-6 py-1">
                <div class="row card-content-row w-100 px-3 px-md-2 py-2">
                  <div class="col-12">
                    <div class="col-12 card-content-leading" style="font-weight: 800;">Last paid</div>
                    <div class="col-12 card-content-description" style="font-weight: 800;">{{(this.snapData.lastPaidDate
                      |
                      date) || "---"}}</div>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-6 py-1">
                <div class="row card-content-row w-100 px-3 px-md-2 py-2">
                  <div class="col-12">
                    <div class="col-12 card-content-leading" style="font-weight: 800;">Last paid amount</div>
                    <div class="col-12 card-content-description" style="font-weight: 800;">
                      {{(this.snapData.lastPaidAmount |
                      thousandSeparator) || "---" }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row pb-2 pb-lg-0">
              <div class="col-6 col-lg-6 py-1">
                <div class="row card-content-row w-100 px-3 px-md-2 py-2">
                  <div class="col-12">
                    <div class="col-12 card-content-leading" style="font-weight: 800;">Min to reverse NPL</div>
                    <div class="col-12 card-content-description" style="font-weight: 800;">
                      {{(this.snapData.minToReverseNPL
                      | thousandSeparator) || "---" }}</div>
                  </div>
                </div>
              </div>
              <div class="col-6 col-lg-6 py-1">
                <div class="row card-content-row w-100 px-3 px-md-2 py-2">
                  <div class="col-12">
                    <div class="col-12 card-content-leading" style="font-weight: 800;">Month end DPD</div>
                    <div class="col-12 card-content-description" style="font-weight: 800;">{{(this.snapData.eom_dpd
                      | thousandSeparator) || "---" }}</div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Location Details get in to bottom in snap view -->
            <div class="row w-100 d-flex px-3">
              <!-- TODO location view in ping the location -->
              <button mat-raised-button color="primary" class="w-100" (click)="openLocationMap()">Location Details</button>
            </div>
            <br>

            <div class="row w-100 d-flex px-3">
              <button mat-raised-button color="primary" class="w-100" (click)="openNotePadEntry_popup()">Open Notepad
                Details</button>
            </div>
            <br>

            <div class="row w-100 px-3">
              <a class="btn btn-primary float-none font-weight-bold w-100"
                style="background-color:yellow; border-color:blue; color:black; width: 100% !important">Flags
                ({{this.snapData.flag || "---"}}) </a>
            </div>
            <br>
            <div class="row w-100 px-3">
              <a class="btn btn-primary float-none font-weight-bold w-100"
              [ngStyle]="{'background-color': this.snapData.trigeringNPL === 'YES' ? 'red' : 'yellow', 'border-color': 'blue', 'color': 'black', 'width': '100%', 'margin': '0 !important'}">Highlights
                (Triggering:
                {{this.snapData.trigeringNPL | thousandSeparator}})</a>
            </div>

          </div>
        </div>
      </div>

      <div class="col-12 col-md-8">
        <div class="col-12 p-2">
          <div class="row pb-2 pb-lg-0">
            <div class="col-12 col-lg-6 py-1">
              <button mat-raised-button class="w-100" color="primary" (click)="openClientDetails()">Client
                Details</button>
            </div>
            <div class="col-12 col-lg-6 py-1">
              <button mat-raised-button class="w-100" color="primary" (click)="openFacilityDetail()">Facility
                Details</button>
            </div>
          </div>
          <div class="row pb-2 pb-lg-0">
            <div class="col-12 col-lg-6 py-1">
              <button mat-raised-button class="w-100" color="accent" (click)="openFollowUpDetail()">Follow
                Up/Flagging/NP
                History</button>
            </div>
            <div class="col-12 col-lg-6 py-1">
              <button mat-raised-button class="w-100" color="accent" (click)="openSecurityDetail()">Security
                Details</button>
            </div>
          </div>
          <div class="row pb-2 pb-lg-0">
            <div class="col-12 col-lg-6 py-1">
              <button mat-raised-button class="w-100" color="primary" (click)="openRelatedContracts()">Related
                contracts</button>
            </div>
            <div class="col-12 col-lg-6 py-1">
              <!-- TODO : Pin the location  -->
              <button mat-raised-button class="w-100" color="primary" (click)="pinTheLocation()">Pin the location</button>
            </div>
          </div>
        </div>

        <div class="col-12 p-2">
          <div class="w-100 container dialog-wrapper">
            <div class="row" *ngIf='notePadEntryVisible'>
              <section>
                <mat-checkbox [(ngModel)]="notePadEntry" (change)="changeNotePadEntry($event)">Update as Note pad
                  entry</mat-checkbox>
              </section>
            </div>
            <div class="row">
              <section>
                <mat-radio-group aria-label="Select an option" *ngIf='notePadEntry' [(ngModel)]="notePadEntryMode"
                  (change)="changeNotePadEntry($event)">
                  <div class="d-flex">
                    <div class="col-3">
                      <mat-radio-button color="primary" value="CALL">
                        <span style="font-size: 14px; text-overflow:ellipsis">Call</span>
                      </mat-radio-button>
                    </div>
                    <div class="col-3">
                      <mat-radio-button color="primary" value="VISIT">
                        <span style="font-size: 14px; text-overflow:ellipsis">Visit</span>
                      </mat-radio-button>
                    </div>
                  </div>
                </mat-radio-group>
              </section>
            </div>

            <!--
              -------------- call status ---------------
             -->
            <!--TODO add the next follow up type here -->
            <div *ngIf="this.sections.visitStats.view">
              <div class="col-3 details-list-content ">
                <button class=" py-1/2 px-1" style="background-color: rgb(10, 187, 10); font-size: medium; font-weight: bolder; border-radius: 20%;"
                  [disabled]="!ceft" mat-raised-button>{{followUpDatenew|| "---"}}
                </button>
              </div>
            </div>

            <div class="row" *ngIf="this.sections.callStatus.view">
              <div class="col-12">
                <mat-form-field appearance="fill" class="w-100">
                  <mat-label>Call Status</mat-label>
                  <mat-select [(ngModel)]="callStatus" [disabled]="this.sections.callStatus.disabled"
                    (change)="setVisibility()" (click)="clearBaseOnCallStatus($event)">
                    <mat-option value="NA">--Select--</mat-option>
                    <mat-option value="answered">Answered</mat-option>
                    <mat-option value="notAnswered">Ringing but not answered</mat-option>
                    <mat-option value="switchOff">Switched off</mat-option>
                    <mat-option value="engaged">Engaged</mat-option>
                    <mat-option value="wrongNumuber">Wrong number</mat-option>
                    <mat-option value="cutLine">Cut the line</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="row" *ngIf="this.sections.callStatus.view && callStatus == 'answered'">
              <div class="col-12" style="padding-top: 1rem">
                <mat-form-field appearance="fill" class="w-100">
                  <mat-label>Client call response</mat-label>
                  <mat-select [(ngModel)]="callResponse" [disabled]="this.sections.callStatus.disabled"
                    (change)="setVisibility()" (click)="clearBaseOnCallResponse($event)">
                    <mat-option value="NA">--Select--</mat-option>
                    <mat-option value="promisedToPay">Promised to pay</mat-option>
                    <mat-option value="refuseToPay">Refuse to pay</mat-option>
                    <mat-option value="leftMsg">Left a message</mat-option>
                    <mat-option value="visitBranch">Promised to visit branch</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <!--
              -------------- visit status ---------------
             -->

            <div class="row" *ngIf="this.sections.visitStats.view">
              <div class="col-12">
                <mat-form-field appearance="fill" class="w-100">
                  <mat-label>Visit Status</mat-label>
                  <mat-select [(ngModel)]="visitStatus" [disabled]="this.sections.visitStats.disabled"
                    (change)="setVisibility()" (click)="clearBaseOnVisitStatus($event)">
                    <mat-option value="NA">--Select--</mat-option>

                    <mat-option value="metTheClient">Met the client</mat-option>
                    <mat-option value="didNotMet">Did Not met </mat-option>

                    <mat-option value="metTheGuarantor">Met the Guarantor</mat-option>
                    <!-- <mat-option value="didNotMeetGuarantor">Did Not meet the guarantor</mat-option> -->

                    <mat-option value="metTheThirdParty">Met the ThirdParty</mat-option>
                    <!-- <mat-option value="didNotMeetThirdParty">Did Not meet the third-party</mat-option> -->

                    <mat-option value="metOther">Met the Other</mat-option>
                    <!-- <mat-option value="metOther">Met the Other</mat-option> -->
                    <mat-option value="VisitCancelled">Cancel the Visit</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <!-- <div class="row" *ngIf="showPromiseAmount"> -->
            <div class="row">

              <div class="col-12" style="padding-top: 1rem">
                <mat-form-field appearance="fill" class="w-100">
                  <mat-label>Origin Types</mat-label>
                  <mat-select [(ngModel)]="originType" [disabled]="this.sections.followUpDisabled"
                    (change)="setVisibility()">
                    <mat-option *ngFor="let origin of originTypeList"
                      [value]="origin.OTYPE">{{origin.ODESC}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="row"
              *ngIf="this.sections.visitStats.view && ((visitStatus == 'metTheClient')||(visitStatus == 'metTheGuarantor')||(visitStatus == 'metTheThirdParty')||(visitStatus == 'metOther'))">
              <div class="col-12" style="padding-top: 1rem">
                <mat-form-field appearance="fill" class="w-100">
                  <mat-label>Client visit response</mat-label>
                  <mat-select [(ngModel)]="visitResponse" [disabled]="this.sections.visitStats.disabled"
                    (change)="setVisibility()" (click)="clearBaseOnVisitResponse($event)">
                    <mat-option value="NA">-Select-</mat-option>
                    <mat-option value="promisedToPay">Promised to pay</mat-option>
                    <mat-option value="refuseToPay">Refuse to pay</mat-option>
                    <mat-option value="leftMsg">Left a message</mat-option>
                    <mat-option value="visitBranch">Promised to visit branch</mat-option>
                    <mat-option *ngIf="dialogType == 'VISIT'" value="didNotMeet">Did not meet the
                      client</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <!--
              -------------- Other data ---------------
             -->

            <!-- <div class="row" *ngIf="showPromiseAmount">
              <div class="col-12" style="padding-top: 1rem">
                <mat-form-field appearance="fill" class="w-100">
                  <mat-label>Origin Types</mat-label>
                  <mat-select [(ngModel)]="originType" [disabled]="this.sections.followUpDisabled"
                    (change)="setVisibility()">
                    <mat-option *ngFor="let origin of originTypeList"
                      [value]="origin.OTYPE">{{origin.ODESC}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div> -->

            <!--
              -------------- Promised data ---------------
             -->

            <div>
              <div class="row" *ngIf="showPromiseAmount">
                <div class="col-12">
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Promise Date</mat-label>
                    <mat-hint>dd/mm/yyyy</mat-hint>
                    <input matInput [min]="minDate" [matDatepicker]="pickerPay" placeholder="Promised Date" readonly
                      (click)="pickerPay.open()" [(ngModel)]="promisedDate"
                      [disabled]="this.sections.promisedDetailsDisabled" (change)="setVisibility()">
                    <mat-datepicker-toggle matSuffix [for]="pickerPay"></mat-datepicker-toggle>
                    <mat-datepicker touchUi #pickerPay></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>

              <div class="row" *ngIf="showPromiseAmount">
                <div class="col-12">
                  <mat-form-field appearance="fill" style="margin-top: 0.5rem" class="w-100">
                    <mat-label>Promised Amount</mat-label>
                    <input type="text" ThousandSeparatorInput appTwoDigitDecimalNumber matInput
                      [(ngModel)]="promisedAmount" [disabled]="this.sections.promisedDetailsDisabled">
                  </mat-form-field>
                </div>
              </div>

              <div class="row" id="likelihood" *ngIf="showPromiseAmount">
                <div class="col-12">
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Likelihood</mat-label>
                    <mat-select [(ngModel)]="likelyHood" [disabled]="this.sections.followUpDisabled">
                      <mat-option value="low">Low</mat-option>
                      <mat-option value="medium">Medium</mat-option>
                      <mat-option value="high">High</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div>
              <div class="row">
                <div class="col-12" style="font-weight: bold">
                  <br>Next Follow Up :
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Diarized Date</mat-label>
                    <input required #date="ngModel" matInput [min]="minDate" [matDatepicker]="picker"
                      placeholder="Follow up date" readonly (click)="picker.open()" [(ngModel)]="followUpDate"
                      [disabled]="this.sections.followUpDisabled">
                    <mat-hint>dd/mm/yyyy</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker touchUi #picker></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="col-12" style="padding-top: .5rem">
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Follow-up Type</mat-label>
                    <mat-select #followUpType [(value)]="nextFollowUpType" [disabled]="this.sections.followUpDisabled">
                      <mat-option value="NA">-Select-</mat-option>
                      <mat-option value="callTheClient">Call the Client</mat-option>
                      <mat-option value="callTheGuarantor">Call the Guarantor</mat-option>
                      <mat-option value="callThirdParty">Call ThirdParty</mat-option>
                      <mat-option value="visitTheClient">Visit the Client</mat-option>
                      <mat-option value="visitTheGuarantor">Visit the Guarantor</mat-option>
                      <mat-option value="visitThirdParty">Visit ThirdParty</mat-option>
                      <mat-option *ngIf="dialogType == 'VISIT'" value="COMPLETED">Completed</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-form-field appearance="fill" class="w-100">
                  <mat-label>Comment</mat-label>
                  <input matInput [(ngModel)]="comment" [disabled]="this.sections.followUpDisabled">
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- enable save controls save button visibility -->
    <div class="row" style="padding-top: 2rem">
      <div class="col py-2" *ngIf="enableSave && !notePadEntry">
        <button [disabled]="date.invalid" view class="btn btn-primary float-center" style="width: 80%"
          (click)="submitHandler()">Save</button>
      </div>
      <div class="col py-2" *ngIf="notePadEntry">
        <button [disabled]="date.invalid" view class="btn btn-primary float-center" style="width: 80%"
          (click)="updateNotepadEntry()">Update
          note
          pad</button>
      </div>
      <div class="col py-2">
        <button view class="btn btn-primary float-none "
          style=" background-color:#5eac0c; border-color:blue; color:white; width: 80%" (click)="openInquiry()">Center
          Communication</button>
      </div>
      <div class="col py-2" *ngIf="dialogType == 'VISIT' || dialogType == 'CALL'">
        <button view class="btn btn-primary float-none "
          style=" background-color:#5eac0c; border-color:blue; color:white; width: 80%"
          (click)="openReceipt()">Receipt</button>
        <!-- [disabled]="true" -->
      </div>
      <div class="col py-2" *ngIf="dialogType == 'VISIT' || dialogType == 'CALL'">
        <button view class="btn btn-primary float-none "
          style=" background-color:#5eac0c; border-color:blue; color:white; width: 80%" (click)="openPDCheque()"
          [disabled]="true">PD
          <!-- [disabled]="true" -->
          Cheque</button>
      </div>
      <div class="col py-2">
        <button view class="btn btn-primary float-center" style="width: 80%" (click)="closeDialog()">Close</button>
      </div>
    </div>
  </div>
</div>
