import {Component , Inject , OnInit} from '@angular/core';
import {TodaysCallDeskService} from "../../services/call-desk/call-desk.service";
import {MAT_DIALOG_DATA , MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-transaction-summary',
  templateUrl: './transaction-summary.component.html',
  styleUrls: ['./transaction-summary.component.scss']
})
export class TransactionSummaryComponent implements OnInit {

  noContent: boolean = false;

  entries: any[] = [];
  contractNo: string = "";
  constructor(private service: TodaysCallDeskService, private dialogRef: MatDialogRef<TransactionSummaryComponent>, @Inject ( MAT_DIALOG_DATA ) public data: any) {
  this.contractNo = data.contractNo;


  }



  ngOnInit(): void {
    this.service.getTranactionSummary( this.contractNo).subscribe({
      next: (value) => {
        this.entries = value.content;
        console.log(JSON.stringify(value));
      },
      error: (err) => {
        this.noContent = true;
      }
    })
  }

  closeDialog(): any {


    this.dialogRef.close();
  }


}
