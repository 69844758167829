import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TodaysCallDeskService } from 'src/app/services/call-desk/call-desk.service';

@Component({
  selector: 'app-note-pad-entry-view',
  templateUrl: './note-pad-entry-view.component.html',
  styleUrls: ['./note-pad-entry-view.component.scss']
})
export class NotePadEntryViewComponent implements OnInit {

  noContent: boolean = false;

  entries: any[] = [];

  constructor(private service: TodaysCallDeskService, private dialogRef: MatDialogRef<NotePadEntryViewComponent>, @Inject(MAT_DIALOG_DATA) public params: any) { }

  ngOnInit(): void {
    console.log(this.params);
    this.service.getNpEntry(this.params.contractNo).subscribe({
      next: (value) => {
        this.entries = value.content;
      },
      error: (err) => {
        this.noContent = true;
      }
    })
  }

  closeDialog(): void {
    console.log("click")
    this.dialogRef.close();
  }

}
