import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MapsAPILoader } from "@agm/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { VisitDeskGeoLocationModel } from "../../models/visit-desk/visit-desk-geo-location-model";
import { VisitDeskGeoLocationInfoModel } from "../../models/visit-desk/visit-desk-geo-location-info-model";
import { VisitDeskUpdateCheckinRequestModel } from "../../models/visit-desk/visit-desk-update-checkin-request-model";

@Component({
  selector: 'app-visit-desk-dialog',
  templateUrl: './visit-desk-dialog.component.html',
  styleUrls: ['./visit-desk-dialog.component.scss']
})
export class VisitDeskDialogComponent implements OnInit {


  title = 'cf-poc-app';
  lat = 6.922973;
  lng = 79.85734;

  zoom: number = 0;
  address: string = "";
  private geoCoder: any;

  // @ViewChild('search')
  // public searchElementRef: ElementRef;

  public origin: google.maps.LatLng;
  public destination: any;
  public waypoints: any[] = [];

  public renderOptions = {
    suppressMarkers: true,
  };
  visitDeskGeoLocationList: VisitDeskGeoLocationModel;
  geoLocationList: VisitDeskGeoLocationInfoModel[];

  routeURL: string = 'https://www.google.com/maps/dir';

  constructor(private dialogRef: MatDialogRef<VisitDeskDialogComponent>,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone, @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.visitDeskGeoLocationList = data.visitDeskGeoLocationList;
    this.geoLocationList = this.visitDeskGeoLocationList.geoLocationList;
  }

  ngOnInit() {
    this.getLocationAndContinue((originPosition: GeolocationPosition) => {
      this.origin = new google.maps.LatLng(originPosition.coords.latitude, originPosition.coords.longitude)

      let locations = this.data.visitDeskGeoLocationList.geoLocationList as VisitDeskGeoLocationInfoModel[]
      if (locations.length > 0) {
        let last = locations.pop()
        this.destination = {
          lat: Number(last.lat),
          lng: Number(last.lng)
        }

        this.waypoints = locations.map(item => {
          return {
            location: { lat: Number(item.lat), lng: Number(item.lng) }
          }
        })

        console.log(this.origin, this.waypoints, this.destination)
      } else {
        this.waypoints = []
        this.destination = null
      }
    })
  }

  getLocationAndContinue(callback: (origin: GeolocationPosition) => void): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (result) => {
          callback(result)
        },
        (error) => {
          alert("Failed to fetch current location!")
          this.closeDialog()
        }
      )
    } else {
      alert("Location services cannot be accessed!")
      this.closeDialog();
    }
  }

  navigateToGoogleApp() {

    // window.open('https://www.google.com/maps/dir/\'6.922973,79.85734\'/\'6.920779,79.857115\'/\'6.919464,79.877599\'/\'6.914881,79.876527\'/\'6.931028,79.8694\'/@6.9201125,79.8612081,15z/data=!4m32!4m31!1m5!1m1!1s0x0:0x7c5413771ce3604b!2m2!1d79.85734!2d6.922973!1m5!1m1!1s0x0:0xf3ab7965883b902f!2m2!1d79.857115!2d6.920779!1m5!1m1!1s0x0:0x56cb6b83381ae7a4!2m2!1d79.877599!2d6.919464!1m5!1m1!1s0x0:0x2ca93ecc70be4048!2m2!1d79.876527!2d6.914881!1m5!1m1!1s0x0:0x122ea0efdeac2e42!2m2!1d79.8694!2d6.931028!3e0', '_blank')
    this.routeURL = this.routeURL + ',15z/data=!4m32!4m31!1m5!1m1!1s0x0:0x7c5413771ce3604b!2m2!1d79.85734!2d6.922973!1m5!1m1!1s0x0:0xf3ab7965883b902f!2m2!1d79.857115!2d6.920779!1m5!1m1!1s0x0:0x56cb6b83381ae7a4!2m2!1d79.877599!2d6.919464!1m5!1m1!1s0x0:0x2ca93ecc70be4048!2m2!1d79.876527!2d6.914881!1m5!1m1!1s0x0:0x122ea0efdeac2e42!2m2!1d79.8694!2d6.931028!3e0';
    window.open(this.routeURL, '_blank');

  }

  closeDialog() {

    this.dialogRef.close();
  }

}
