<div class="container component-wrapper" >
    <div class="table-wrapper pb-5">
        <div>
            <div *ngIf="!noContent"></div>
            <table mat-table *ngIf="!noContent" [dataSource]="routeList" class="mat-elevation-z8 w-100">
                <ng-container matColumnDef="ID">
                    <th mat-header-cell *matHeaderCellDef> ID </th>
                    <td mat-cell *matCellDef="let element">{{element.route}}</td>
                </ng-container>
                <ng-container matColumnDef="Name">
                    <th mat-header-cell *matHeaderCellDef> Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>
                <ng-container matColumnDef="User">
                    <th mat-header-cell *matHeaderCellDef> User </th>
                    <td mat-cell *matCellDef="let element"> {{element.user_id}} </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef> Actions </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                        <button (click)="handlePreview(i)">View</button>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </div>
</div>
