

<div class="container-fluid">
  <h3 class="anDef">
    <span class="letters letters-1" style="color: white ;text-align: center;">Welcome to</span>
    <span class="letters letters-2" style="color:darkred ;text-align: center;"> Virtual</span>
    <span class="letters letters-3" style="color:blue ;text-align: center;"> Recovery!</span>
  </h3>
  <!--<div class="col-12">-->
  <!--<h1 style="color:darkorange ;text-align: center;">{{ title }}</h1>-->
  <!--</div>-->

  <div class="page-container">
    <header>
      <div>
        <button mat-button class="menu-button" (click)="sidenav.toggle()">
          <mat-icon>{{ isMenuOpen ? 'menu_open' : 'menu' }}</mat-icon>
        </button>
      </div>
    </header>
    <mat-sidenav-container class="sidenav-container">
      <mat-sidenav #sidenav autoFocus="false" mode="over"  (click)="onSidenavClick()" (opened)="isMenuOpen" class="sidenav">
        <a mat-button (click)="openCallDesk()">Call Desk</a>
        <a mat-button (click)="openVisitSelectionDesk()">Visit Desk Selection</a>
        <a mat-button (click)="openVisitSelectionUpdateDesk()">Navigator</a>
        <a mat-button (click)="openCalculation()">Settlement Calculation</a>
        <a mat-button (click)="openInquiry()">Inquiry</a>
        <a mat-button (click)="openCallDesk()">Allocate</a>
        <a mat-button (click)="signOut()">Log Out</a>
      </mat-sidenav>
      <mat-sidenav-content>
        <main>
          <router-outlet></router-outlet>
        </main>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
  <!--<div  class="col-12" style=" display:flex; justify-content:flex-end; width:100%; padding-bottom: 20px; padding-right: 20px; align-content: center" >-->
  <div  class="col-12"   style="  width:100%; align-content: center" >
    <!--<header>-->
    <!--<h1 style="color:darkorange ; text-align: center;">WELCOME TO VIRTUAL</h1>-->
    <!--<h1 style="color:darkorange text-align: center;"> REVOVERY WORLD</h1>-->
    <!--</header>-->
  </div>

  <div  class="col-12"   style="  width:100%; align-content: center" >

    <div class="row" style="padding-top: 1rem; padding-bottom: 1rem">
      <div class="col-6" >
        <button view class="btn btn-primary float-right" style="background-color: #4CAF50; width: 100% ;  color: darkred " (click)="openCallDesk()">CALL DESK</button>
      </div>
      <div class="col-6">
        <button view class="btn btn-primary float-right" style="background-color: blue; width: 100%" (click)="openVisitSelectionDesk()">VISIT DESK</button>
      </div>
      <!--</div>-->
    </div>
    <div class="row" style="padding-top: 1rem; padding-bottom: 1rem">
      <div class="col-6">
        <button view class="btn btn-primary float-right" style="background-color: darkorange;  width: 100% ;  color: darkmagenta" (click)="openInquiry()">INQUIRY</button>
      </div>
      <div class="col-6">
        <button view class="btn btn-primary float-right" style="background-color: yellow ; color: red ;  width: 100%" (click)="openRouteManagement()">ROUTE MANAGEMENT </button>
      </div>
    </div>
    <div class="row" style="padding-top: 1rem; padding-bottom: 1rem">
      <div class="col-6">
        <button view class="btn btn-primary float-right" style="background-color: #b5ae08;  width: 100% ;  color: darkmagenta" (click)="openVisitSelectionUpdateDesk()">NAVIGATOR</button>
      </div>
      <!-- <div class="col-6">
        <button view class="btn btn-primary float-right" style="background-color: #a2eda5 ; color: rgb(165, 6, 6) ;  width: 100%" (click)="openCalculation()">CONCESSION REQUESTS AND CALCULATIONS </button>
      </div>
    </div> -->
    <div class="col-6">
      <button view class="btn btn-primary float-right"  style="background-color: #a2eda5 ; color: rgb(165, 6, 6) ;  width: 100%" (click)="openCollectorPerformance()">Collector Performance </button>
    </div>
  </div>
    <div class="row" style="padding-top: 1rem; padding-bottom: 1rem">
      <div class="col-6">
        <button view class="btn btn-primary float-right" style="background-color: #b5ae08;  width: 100% ;  color: darkmagenta" (click)="openFieldOfficerTracking()">DISTANCE TRACKER</button>
      </div>
      <!--<div class="col-6">-->
        <!--<button view class="btn btn-primary float-right" style="background-color: #a2eda5 ; color: rgb(165, 6, 6) ;  width: 100%" (click)="openCalculation()">CALCULATOR </button>-->
      <!--</div>-->
      <div class="col-6">
        <button view class="btn btn-primary float-right"
          style="background-color: #9f1cb1 ; color: rgb(242, 245, 247) ;  width: 100%"
          (click)="todaysVisitListReport()">TODAYS VISIT LIST REPORT</button>
      </div>
    </div>
    <div class="row" style="padding-top: 1rem; padding-bottom: 1rem">
        <div class="col-6">
          <button view class="btn btn-primary float-right"
            style="background-color: #b326d3 ; color: rgb(242, 245, 247) ;  width: 100%"
            (click)="openRouteAllocation()">ROUTE ALLOCATION</button>
        </div>
    </div>
  </div>
</div>
