import {VisitDeskDataResponseModelInfo} from "./visit-desk-data-response-model-info";

/**
 * Call desk data response model
 * @author Achala M. Rathnathilaka
 */
export class VisitDeskDataResponseModel {

   Table: VisitDeskDataResponseModelInfo[] =[];
}
