<app-dialog-header [title]="title" (closeDialogEvent)="closeDialog()"></app-dialog-header>
<!-- <div class="py-4">
  <h4 style="color:blue">Your Current Address: </h4>
  <h5 style="color:darkred"> {{address}}</h5>
</div> -->

<div style=" display:flex; justify-content:flex-end; width:100%; padding-top: 1.5rem; padding-right: 1.5rem;">
  <table bordet="0">
    <tr>
      <td style="color:green; padding-top:1.5rem; padding-left: 1.5rem">
        <button view class="btn btn-primary float-left" (click)="loadVisit()">Load Visit</button>
      </td>
      <!-- <td style="color:green; padding-top:1.5rem; padding-left: 1.5rem">
        <button [disabled]="selectedOptimizeVisitTableRow?.length <1" view class="btn btn-danger float-left">Remove Visit</button>
      </td> -->
      <td style="color:green; padding-top:1.5rem; padding-left: 1.5rem">
        <button [disabled]="selectedOptimizeVisitTableRow?.length <1" view class="btn btn-primary float-left"
                (click)="openContractDetail()"> Details </button>
      </td>
      <td style="color:green; padding-top:1.5rem; padding-left: 1.5rem">
        <button view class="btn btn-primary float-left" (click)="clear()">Clear</button>
      </td>
    </tr>
  </table>
</div>

<div class="col-12 overflow-container" style="padding-top: 0.6rem; z-index: 0" >
  <p>Selected Visits </p>
  <angular-slickgrid gridId="selectedVisit" style="width:95%" [columnDefinitions]="selectedVisitColumnDefinitions"
    [gridOptions]="gridOptionsSelectedVisit" [dataset]="visitDataset"
    (onAngularGridCreated)="initiateAngulargridGrid($event)"></angular-slickgrid>
</div>

<div class="mt-5">
  <div *ngIf="watchMode" class="d-flex flex-row align-items-center">
    <div>Tracking now</div>
    <div class="px-2"></div>
    <div style="width: 50px;height: 50px;">
      <mat-spinner [diameter]="50"></mat-spinner>
    </div>
  </div>
  <div style=" display:flex; justify-content:flex-end; width:100%; padding-top: 1.5rem; padding-right: 1.5rem;">
    <table bordet="0">
      <tr>
        <td style="color:green; padding-top:1.5rem; padding-left: 1.5rem">
          <button view class="btn btn-danger  float-left" (click)="startEndTracking()">{{startEndTrackLable}}</button>
        </td>

        <td style="color:green; padding-top:1.5rem; padding-left: 1.5rem">
          <button view class="btn btn-primary float-left" (click)="trackMe()">Track Me</button>
        </td>
        <td style="color:green; padding-top:1.5rem; padding-left: 1.5rem">
          <button [disabled]="selectedOptimizeVisitTableRow?.length <1" view class="btn btn-primary float-left"
            (click)="openVisitDesk()">Visit</button>
        </td>
        <!--<td style="color:green; padding-top:1.5rem; padding-left: 1.5rem">-->
          <!--<button [disabled]="selectedOptimizeVisitTableRow?.length <1" view class="btn btn-primary float-left"-->
            <!--(click)="updateVisit()">Update</button>-->
        <!--</td>-->
        <td style="color:green; padding-top:1.5rem; padding-left: 1.5rem">
          <button [disabled]="selectedOptimizeVisitTableRow?.length <1" view class="btn btn-primary float-left"
                  (click)="openContractDetail()"> Details </button>
        </td>
        <td style="color:green; padding-top:1.5rem; padding-left:1.5rem;">
          <button view class="btn btn-primary float-left" (click)="closeDialog()">Close</button>
        </td>
      </tr>
    </table>
  </div>
</div>
