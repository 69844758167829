import { Component, Inject, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { RouteServicesService } from 'src/app/services/route-management/route-services';

@Component({
  selector: 'map-preview-dialog',
  templateUrl: './map-preview-dialog.component.html',
  styleUrls: ['./map-preview-dialog.component.scss']
})
export class MapPreviewDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, @Inject(MAT_DIALOG_DATA) public route: any, public dialogRef: MatDialogRef<MapPreviewDialogComponent>, private service: RouteServicesService) { }

  baseLat = 0.0;
  baseLong = 0.0;

  origin = { lat: 0.0, lng: 0.0 }
  destination = { lat: 0.0, lng: 0.0 }
  waypoints: any[] = []

  route_id: string = null;
  client_count: string = "";


  displayedColumns = ["Postal Code","Name", "Latitude", "Longtitude"]

  showPath = false;

  ngOnInit(): void {

    
    let len = this.data.length;

    this.baseLat = this.data[0].lat;
    this.baseLong = this.data[0].lng;

    this.origin.lat = this.data[0].lat;
    this.origin.lng = this.data[0].lng;

    this.destination.lat = this.data[len - 1].lat;
    this.destination.lng = this.data[len - 1].lng;

    let temp = this.data.slice(1, len - 1);
    this.waypoints = temp.map((item: any) => {
      return {
        location: { lat : item.lat, lng: item.lng }
      }
    })

    if (this.route == undefined || this.route == null) {
      alert("An unexpected error occured!");
      this.dialogRef.close();
    } else {
      this.service.getClientCount(this.route).subscribe({
        next: (value) => {
          if (value == "NO Data Available") {
            this.client_count = "N/A"
          } else {
            this.client_count = value;
          }
        },
        error: (err) => {
          console.log(err);
        }
      })
    }
  }

  onShowPathToggle(event: any): void {
    this.showPath = event.target.checked;
  }

  closeMapDialog(): any {
    this.dialogRef.close();
    
  }
}
