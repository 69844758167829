//for session time out - DulsaraMannakkara 2023.10.05
// session.service.ts
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SSL_OP_NETSCAPE_REUSE_CIPHER_CHANGE_BUG } from 'constants'
import { Observable } from 'rxjs';
import { Config } from 'src/config/config';
import { sessionRequest } from '../models/session-time-out-model';
import { RestRequestService } from './rest-request.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private readonly CashHandoverJobsUrl: string;
  private sessionTimeout: number = 12 * 60 * 60 * 1000; // 4 hours in milliseconds
 // private sessionTimeout: number = 100*1000; 
 // private sessionExpiration: number;
 private lastActiveTime: number;

constructor(private restService: RestRequestService, @Inject("recoveryConfig") private recoveryConfig = Config.recoveryConfig,private http: HttpClient){

  this.CashHandoverJobsUrl = this.getSessionJobsUrl(recoveryConfig);

}

private getSessionJobsUrl(recoveryConfig: any = {}): string {
  let callDeskUrl = '';

  if (recoveryConfig.env === 'local') {
    callDeskUrl = `${recoveryConfig.appServerUrl}`;
  } else {
    callDeskUrl = `${recoveryConfig.recoveryServiceUrl}`;
  }
  return callDeskUrl;

}


 GetSessionService(user_name: string): Observable<sessionRequest[]> {
  const headers = new HttpHeaders().set('Content-Type', 'text/plain');

  // call list with filters uri
  let jobURL: string = this.CashHandoverJobsUrl + `/vGetSessionTimeOut/${user_name}`;
  return this.restService.get<sessionRequest[]>(
    jobURL,
    {
      headers: headers,
    }
  );
}
  startSession() {
    this.lastActiveTime = Date.now();
    // this.sessionExpiration = Date.now();
     console.log("Current Timestamp:", this.lastActiveTime);
    // this.sessionExpiration = Date.now() + this.sessionTimeout;
    // console.log("Start session guard");
    // console.log("end Timestamp:", this.sessionExpiration);

    const dateTimeString = "2023-10-10T14:57:26.969528";
    const timestamp = Date.parse(dateTimeString);

    console.log("d&T : "+timestamp);
    
    
  }

  isSessionExpired(): boolean {
    const currentTime = Date.now();
    return currentTime - this.lastActiveTime > this.sessionTimeout;
  }

  renewSession() {
    // this.sessionExpiration = Date.now();
    console.log("Current renew Timestamp:", this.lastActiveTime)
    // this.sessionExpiration = Date.now() + this.sessionTimeout;
    // console.log("renew session guard");
    // console.log("renew Timestamp:", this.sessionExpiration);

    this.lastActiveTime = Date.now();

  }

  
}