<!-- loadingView - kalanarathnayake-->
<div id="backColor" *ngIf="isLoading">
    <div id="loadingView">
        <img class="loadingGif" src="../../../../assets/loading.gif" alt="loadingLogo">
    </div>
</div>
<!-- loadingView -->
<app-dialog-header [title]="headerTitle" (closeDialogEvent)="hanldeClose()"></app-dialog-header>
<div class="py-4" style="position: relative;">

    <!-- Select type of CR/WO/PL/PLWO -->
    <div class="row">
        <section>
            <mat-radio-group aria-label="Select an option" [(ngModel)]="selectOptionMode" (change)="changeType($event)">
                <div class="d-flex">
                    <div class="col-2">
                        <mat-radio-button color="primary" value="CR">
                            <span style="font-size: 14px; text-overflow:ellipsis">CR</span>
                        </mat-radio-button>
                    </div>
                    <div class="col-2">
                        <mat-radio-button color="primary" value="WO">
                            <span style="font-size: 14px; text-overflow:ellipsis">WO</span>
                        </mat-radio-button>
                    </div>
                    <div class="col-2">
                        <mat-radio-button color="primary" value="PL">
                            <span style="font-size: 14px; text-overflow:ellipsis">PL</span>
                        </mat-radio-button>
                    </div>
                    <div class="col-2">
                        <mat-radio-button color="primary" value="PW">
                            <span style="font-size: 14px; text-overflow:ellipsis">PLWO</span>
                        </mat-radio-button>
                    </div>
                </div>
            </mat-radio-group>
        </section>
    </div>
    <br>

    <div>
        <div>
            <section>
                <mat-checkbox [(ngModel)]="enableSearch" (change)="handleSearchEnable($event)">Enable
                    search</mat-checkbox>
            </section>
        </div>
        <div class="search-container d-flex align-items-baseline">
            <div class="col w-auto">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Search</mat-label>
                    <mat-placeholder>Search reports</mat-placeholder>
                    <input matInput type="text" [(ngModel)]="search" [disabled]="!enableSearch">
                    <mat-select matSuffix [(ngModel)]="searchType">
                        <mat-option value="Vehicle">Vehicle</mat-option>
                        <mat-option value="Contract">Contract</mat-option>
                        <mat-option value="NIC">NIC</mat-option>
                        <mat-option value="Chassi">Chassi</mat-option>
                        <mat-option value="NAME">Name</mat-option>
                    </mat-select>
                    <mat-hint align="start"><strong>Select a search type from the dropdown icon</strong></mat-hint>
                    <mat-hint align="end">Search by: {{searchType}}</mat-hint>
                </mat-form-field>
            </div>
            <div class="px-2">
                <button [disabled]="search.length < 1 || !enableSearch" mat-mini-fab color="primary"
                    (click)="searchCallList()">
                    <mat-icon>search</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <br>

    <div class="py-3 d-flex">
        <button [disabled]="mode == 'ALL'" mat-raised-button color="primary" class="mx-2"
            (click)="changeMode('ALL')">All contracts</button>
        <button [disabled]="mode == 'BALANCE'" mat-raised-button color="primary" class="mx-2"
            (click)="changeMode('BALANCE')">Balance calls</button>
        <button [disabled]="mode == 'COMPLETED'" mat-raised-button color="primary" class="mx-2"
            (click)="changeMode('COMPLETED')">Completed calls</button>
    </div>

    <br>
    <p>Now viewing : <span style="color:rgb(37, 37, 177)">{{mode.toLowerCase()}} contracts</span></p>
    <div class="overflow-container py-3">
        <div class="row">
            <div class="col-12 col-lg-9">
                <div class="row py-3 d-flex align-items-center">
                    <div class="col-3">DPD Bucket</div>
                    <div class="col-9">
                        <mat-button-toggle-group [(ngModel)]="dpd" class="button-group" #group="matButtonToggleGroup"
                            multiple (change)="handleFilterChange($event)">
                            <mat-button-toggle class="button-group-btns" value="1-120">1-120 DPD</mat-button-toggle>
                            <mat-button-toggle class="button-group-btns" value="121-180">121-180 DPD</mat-button-toggle>
                            <mat-button-toggle class="button-group-btns" value="181-360">181-360 DPD</mat-button-toggle>
                            <mat-button-toggle class="button-group-btns" value="361-540">361-540 DPD</mat-button-toggle>
                            <mat-button-toggle class="button-group-btns" value="540">above 540 DPD
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                </div>
                <div class="row py-3">
                    <div class="col-3">NPL</div>
                    <div class="col-9">
                        <mat-button-toggle-group [(ngModel)]="npl" class="button-group" #group="matButtonToggleGroup"
                            multiple (change)="handleFilterChange($event)">
                            <mat-button-toggle class="button-group-btns" value="NPL_MOVED">NPL moved</mat-button-toggle>
                            <!-- Triggering rename BT -->
                            <mat-button-toggle class="button-group-btns" value="TRIGGERING">BT</mat-button-toggle>
                            <!-- Triggering 60 rename NMT  -->
                            <mat-button-toggle class="button-group-btns" value="TRIGGERING_90">NMT
                            </mat-button-toggle>
                            <mat-button-toggle class="button-group-btns" value="BW_PERIOD">BW Period</mat-button-toggle>
                            <mat-button-toggle class="button-group-btns" value="SAVED">Saved</mat-button-toggle>
                            <!-- add new button in Already in NPL -->
                            <mat-button-toggle class="button-group-btns" value="ANPL">ANPL</mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                </div>

                <!-- Add new filter in Triggering Dates -->
                <div class="row py-3">
                  <div class="col-3">Triggering Dates</div>
                  <div class="col-9">
                      <mat-button-toggle-group [(ngModel)]="td" class="button-group" #group="matButtonToggleGroup"
                          multiple (change)="handleFilterChange($event)">
                          <mat-button-toggle class="button-group-btns" value="TD_1-5">01 - 05</mat-button-toggle>
                          <mat-button-toggle class="button-group-btns" value="TD_6-8">06 - 08</mat-button-toggle>
                          <mat-button-toggle class="button-group-btns" value="TD_9-16">09 - 16</mat-button-toggle>
                          <mat-button-toggle class="button-group-btns" value="TD_17-24">17 - 24</mat-button-toggle>
                          <mat-button-toggle class="button-group-btns" value="TD_25above">25th & above</mat-button-toggle>
                          <mat-button-toggle class="button-group-btns" value="TD_other">Other</mat-button-toggle>
                      </mat-button-toggle-group>
                  </div>
                </div>


                <div class="row py-3">
                    <div class="col-3">Filter Payment</div>
                    <div class="col-9 row d-flex align-items-center">
                        <div class="col py-1">
                            <mat-button-toggle-group [(ngModel)]="notPayed" class="button-group"
                                #group="matButtonToggleGroup" (change)="handleFilterChange($event)">
                                <mat-button-toggle class="button-group-btns" value="NOT_PAID">Not
                                    paid</mat-button-toggle>
                                <mat-button-toggle class="button-group-btns" value="PAID">Paid</mat-button-toggle>
                                <mat-button-toggle class="button-group-btns" value="PART_PAYMENT">Part
                                    Payment</mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>

                        <div *ngIf="notPayed.includes('PAID') || notPayed.includes('NOT_PAID') || notPayed.includes('PART_PAYMENT')" class="col py-1">
                            <mat-form-field appearance="fill">
                                <mat-label>Filter Month</mat-label>
                                <mat-select [(ngModel)]="notPayedOption" (selectionChange)="handleFilterChange($event)">
                                    <mat-option value="default">Not selected</mat-option>
                                    <mat-option value="CMONTH">Current month</mat-option>
                                    <mat-option value="LMONTH">Last month</mat-option>
                                    <mat-option value="L2MONTH">Last 2 months</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>

            <!--
                Small table on right
            -->
            <div class="col-12 col-lg-3">
                <div class="row text-center table-div py-2">
                    <div>Call progress</div>
                </div>
                <div class="row">
                    <div class="col-6 table-div">All triggered</div>
                    <div class="col-6 table-div">{{callProgress.allTriggered}}</div>
                </div>
                <div class="row">
                    <div class="col-6 table-div">Completed</div>
                    <div class="col-6 table-div">{{callProgress.completed}}</div>
                </div>
                <div class="row">
                    <div class="col-6 table-div">Balance</div>
                    <div class="col-6 table-div">{{callProgress.balance}}</div>
                </div>
            </div>
        </div>
    </div>

    <div class="py-2">
        <div class="row d-flex justify-content-end">
            <button [disabled]="contractSelected?.length < 1" mat-raised-button color="primary" class="form-control"
                (click)="handleDetails()">Details</button>
        </div>
    </div>
    <div *ngIf="dataset.length > 0" class="pt-4">
        Showing {{dataset.length}} records
    </div>
    <div class="py-2 overflow-container">
        <angular-slickgrid gridId="todaysCallList" [columnDefinitions]="columnDefinitions" [gridOptions]="gridOptions"
            [dataset]="dataset" (onAngularGridCreated)="angularGridReady($event)"></angular-slickgrid>
    </div>

</div>
