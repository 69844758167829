import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { VisitDeskGeoLocationInfoModel } from '../../models/visit-desk/visit-desk-geo-location-info-model';
import { VisitDeskDialogComponent } from '../visit-desk-dialog/visit-desk-dialog.component';
import { VisitDeskGeoLocationModel } from '../../models/visit-desk/visit-desk-geo-location-model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MapsAPILoader } from '@agm/core';
import { FieldOfficerService } from 'src/app/services/field-officer/field-officer-service';

interface Coordinations {
  lat: number;
  lng: number;
}

@Component({
  selector: 'app-client-location-view-dialog',
  templateUrl: './client-location-view-dialog.component.html',
  styleUrls: ['./client-location-view-dialog.component.scss'],
})
export class ClientLocationViewDialogComponent implements OnInit {
  public lat = 0;
  public lng = 0;

  selectedCallDeskContract: any;
  public latitude = 0;
  public longitude = 0;
  zoom: number = 0;
  address: string = '';
  private geoCoder: any;
  public contractNo = '';

  public origin: any;
  public destination: any;
  public waypoints: any[] = [];

  public renderOptions = {
    suppressMarkers: true,
  };
  visitDeskGeoLocationList: VisitDeskGeoLocationModel;


  // public markerOptions = {
  //   origin: {
  //     infoWindow: 'Miss.Kamala, Union Place, 0778 558 669.',
  //     icon: 'http://i.imgur.com/7teZKif.png',
  //   },
  //   waypoints:
  //     {
  //       infoWindow: 'Mr.Sunil, Borella 071589789.',
  //       icon: 'http://i.imgur.com/7teZKif.png',
  //     }
  //
  //   ,
  //   destination: {
  //     infoWindow: 'MR.Perera, Moratuwa',
  //     icon: 'http://i.imgur.com/7teZKif.png',
  //   },
  // };

  constructor(
    private dialogRef: MatDialogRef<VisitDeskDialogComponent>,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected fieldOfficerService: FieldOfficerService,
  ) {
    this.visitDeskGeoLocationList = data.visitDeskGeoLocationList;
    this.selectedCallDeskContract = data.selectedCallDeskContract;

    this.contractNo = this.selectedCallDeskContract.contractNo;
    this.origin = { lat: Number(this.data.startPosition.lat), lng: Number(this.data.startPosition.lng) };
    this.destination = { lat: Number(this.data.endPosition.lat), lng: Number(this.data.endPosition.lng) };

  }

  ngOnInit() {
   // debugger

    this.mapsAPILoader.load()
    // this.getDirection();
    console.debug('Catched the contract number : ' , this.contractNo);
    console.debug('Catched the origin : ' , this.origin);
    console.debug('Catched the destination : ' , this.destination);

    if(this.data.endPosition.lat == " " || this.data.endPosition.lng == " ")
    {
      alert("Client location data is not available to show");
    }

  }

  // checkIn(){
  //   this.mapsAPILoader.load().then(() => {
  //     this.setCurrentLocation();
  //     this.geoCoder = new google.maps.Geocoder;
  //   });
  //
  // }
  //
  // private setCurrentLocation() {
  //   if ('geolocation' in navigator) {
  //     navigator.geolocation.getCurrentPosition((position) => {
  //       this.latitude = position.coords.latitude;
  //       this.longitude = position.coords.longitude;
  //       console.log("GEO LOCATION"+this.latitude  +this.longitude  );
  //
  //       this.zoom = 8;
  //       this.getAddress(this.latitude, this.longitude);
  //     });
  //   }
  // }
  // private getCurrentLocation(callback?: (lat: number, lng: number) => void) {
  //   if ('geolocation' in navigator) {
  //     navigator.geolocation.getCurrentPosition((position) => {
  //       callback(position.coords.latitude, position.coords.longitude);
  //       console.log('geo locatio feature accessed!');
  //     });
  //   }
  // }

  getDirection() {
    this.destination = { lat: Number(this.data.endPosition.lat), lng: Number(this.data.endPosition.lng) };
    this.origin = { lat: Number(this.data.startPosition.lat), lng: Number(this.data.startPosition.lng) };

    if(this.data.endPosition.lat == " " || this.data.endPosition.lng == " ")
    {
      alert("Client location data is not available to show");
    }
    // this.getCurrentLocation((lat: number, lng: number) => {
    //   // this.destination = { lat:  };
    //   this.origin = { lat: lat, lng: lng };
    //   this.cdr.detectChanges();
    //   console.debug(lat , lng)
    // })

    // this.fieldOfficerService.getClientPingLocation(this.contractNo)
    //   .then((response) => {
    //     console.debug("client location requested");
    //     if (response && response.length > 0) {
    //       const location = response[0];
    //       if (location.lat && location.lng) {
    //         console.debug("Latitude:", location.lat);
    //         console.debug("Longitude:", location.lng);

    //         //destination  location details set here
    //         this.destination = { lat: location.lat, lng: location.lng };
    //         this.cdr.detectChanges();
    //       }
    //       else {
    //         alert("Client Location details not available to show")
    //       }
    //     } else {
    //       alert("Client Location details not available to show");
    //     }
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching client location:", error);
    //   });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
