import {environment} from "../environments/environment";


export const Config = {
  ...environment,
  appLanguage: "en",
  language: "en",
  languages: [
    {
      "code": "en",
      "name": "English"
    },
    {
      "code": "si",
      "name": "සිංහල"
    },
    {
      "code": "ta",
      "name": "தமிழ்"
    }
  ],
  materialize: {
    helpTextAnimation: true,
  }
};
