import { Injectable } from '@angular/core';
import { FieldOfficerService } from '../field-officer/field-officer-service';
import { VisitSelectionDeskService } from '../visit-selection-desk-service';
import { HttpErrorResponse } from '@angular/common/http';
import { UserAuthenticationService } from '../user-authentication/user-authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DurationUtil } from 'src/app/utils/DurationUtil';
import { environment } from 'src/environments/environment';
// import { delay } from 'rxjs/operators';
// import { Observable } from 'rxjs';

/**
   *  Location Automation Service| sending current location data to the service as a background service
   * @author kalanaRathnayake
   */

@Injectable({
  providedIn: 'root'
})

export class LocationAutomationService {
  public interval: any;
  constructor(private auth: UserAuthenticationService, private fieldOfficerService: FieldOfficerService, private visitSelectionDeskService: VisitSelectionDeskService, private _snackBar: MatSnackBar) {
  }

  // // Starting the location automation service
  // startSendingCoordinates(userId: string) {
  //   if (this.auth.currentUserValue.username) {
  //     this.fieldOfficerService.setTrackingState(userId, "ON")
  //     console.debug("ON: Automation tracker details sent to DB :)")
  //     this._snackBar.open("Location Tracker is ON", 'Dismiss', {
  //       duration: DurationUtil.TWO_SEC,
  //       horizontalPosition: "right",
  //       verticalPosition: "top",
  //       panelClass: ['mat-toolbar', 'mat-primary']
  //     });

  //     this.getGeolocation()
  //     console.debug("first location ping sent! next will be in 5 minutes")

  //     // setInterval is a global service which act as a looping feature
  //     this.interval = setInterval(() => {
  //       //current location coordinates to the backend
  //       navigator.geolocation.getCurrentPosition(
  //         (position: any) => {
  //           // TODO ingore dulplicate locations feature parts
  //           let locationLat1 = position.coords.latitude;
  //           let locationLon1 = position.coords.longitude;
  //           let locationLat2
  //           let locationLon2

  //           for (let i = 0; i <= 10; i++) {
  //             // result.push(i);
  //             console.debug(i)
  //           }

  //           if (locationLat2 == undefined || locationLon2 == undefined) {
  //             console.debug("things are undefined true!")
  //             locationLat2 = locationLat1;
  //             locationLon2 = locationLon1;
  //             console.debug("changed location " + locationLon2)
  //             console.debug("changed location " + locationLat2)
  //           }
  //           else{
  //             console.debug("gg oi")
  //           }
  //         },
  //       );

  //       this.getGeolocation()
  //     },

  //       environment.recoveryConfig.automationTrackerTime); // 300000 milliseconds (5 seconds) | set your time 😊
  //   }
  //   else {
  //     this.stopSendingCoordinates(this.auth.currentUserValue.username);
  //   }
  // }


  //gpt works
  startSendingCoordinates(userId: string) {
    if (this.auth.currentUserValue.username) {
      this.fieldOfficerService.setTrackingState(userId, "ON");
      console.debug("ON: Automation tracker details sent to DB :)");
      this._snackBar.open("Location Tracker is ON", 'Dismiss', {
        duration: DurationUtil.TWO_SEC,
        horizontalPosition: "right",
        verticalPosition: "top",
        panelClass: ['mat-toolbar', 'mat-primary']
      });

      let locationLat2: number | undefined;
      let locationLon2: number | undefined;

      this.getGeolocation(); // Initial location ping

      // setInterval is a global service which acts as a looping feature
      this.interval = setInterval(() => {
        // Current location coordinates to the backend
        navigator.geolocation.getCurrentPosition(
          (position: any) => {
            // TODO ignore duplicate locations feature parts

            let locationLat1 = 0;
            let locationLon1 = 0;

            console.debug("before",locationLat1,locationLon1)

            locationLat1 = position.coords.latitude;
            locationLon1 = position.coords.longitude;

            console.debug("After",locationLat1,locationLon1)

            if (
              locationLat2 === undefined || locationLon2 === undefined || this.distance(locationLat1, locationLon1, locationLat2, locationLon2) > 0.001 // Adjust this threshold as needed
            ) {
              // Coordinates have changed significantly, update and send location
              locationLat2 = locationLat1;
              locationLon2 = locationLon1;
              console.debug("Coordinates changed: " + locationLat2 + ", " + locationLon2);
              this.getGeolocation();
            } else {
              console.debug("Coordinates are similar, skipping update");

            }
          },
        );
      }, environment.recoveryConfig.automationTrackerTime); // 300000 milliseconds (5 minutes)
    } else {
      this.stopSendingCoordinates(this.auth.currentUserValue.username);
    }
  }

  // Function to calculate the distance between two coordinates
  distance(lat1: number, lon1: number, lat2: number, lon2: number): number {
    const radlat1 = (Math.PI * lat1) / 180;
    const radlat2 = (Math.PI * lat2) / 180;
    const theta = lon1 - lon2;
    const radtheta = (Math.PI * theta) / 180;
    let dist =
      Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    // dist = dist * 60 * 1.1515; // Miles, adjust as needed
    dist = dist * 1.609344; // Kilometers, adjust as needed

    console.debug(dist)
    return dist;
  }

  // stopping the automated service
  stopSendingCoordinates(userId: string) {
    this.fieldOfficerService.setTrackingState(userId, "OFF")
    console.debug("OFF: Automation tracker details sent to DB :)")
    this._snackBar.open("Location Tracker is OFF", 'Dismiss', {
      duration: DurationUtil.TWO_SEC,
      horizontalPosition: "right",
      verticalPosition: "top",
      panelClass: ['mat-toolbar', 'mat-primary']
    });
    // clearing interval = resetting the looping feature
    clearInterval(this.interval);
    this.interval = undefined;
  }

  // sending location details to service
  private getGeolocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: any) => {
          let date = new Date;
          let currentDate = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();

          let payload: any = {
            userid: this.auth.currentUserValue.code,
            date: currentDate,
            routeid: "1165  ",
            lat: position.coords.latitude,
            // below lat is a test data
            // lat: 7.674672,
            lng: position.coords.longitude
            // below lng is a test data
            // lng: 80.058057
          };
          // console.debug(payload)
          this.visitSelectionDeskService.trackMe(payload).subscribe({
            next: (data) => {
              console.debug("Tracking Data saved successfully");

            },
            complete: () => {
              console.debug("Save Successfully!");
              this._snackBar.open("Your Location Sending is Success!", 'Dismiss', {
                duration: DurationUtil.TWO_SEC,
                horizontalPosition: "right",
                verticalPosition: "top",
                panelClass: ['mat-toolbar', 'mat-primary']
              });
              // TODO add the null ing thing
            console.debug("before",payload.lat,payload.lng)
            payload.lat = 0
            payload.lng = 0
            console.debug("after",payload.lat,payload.lng)


            },
            error: (error: HttpErrorResponse) => {
              console.debug("Error");
            },
          });
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }



  //don't remove below code snippet it shows how to make a code part delay

  // // Define a separate observable for delayed action
  // private delayedAction() {
  //   return new Observable<void>((observer) => {
  //     // You can emit a value here if needed
  //     // For now, we are emitting when the delay is over
  //     setTimeout(() => {
  //       observer.next();
  //       observer.complete();
  //     }, 10000); // Adjust the delay time if needed
  //   });
  // }
  // // In this modified code, the delayedAction method returns an observable that emits a value after the specified delay time (10 seconds in this case). The delay operator is used to ensure that the subsequent code inside the subscribe block is executed only after the delay.

  // // Note that the delay operator does not block the execution of code; it only shifts the timing of emissions within the observable stream. Therefore, the rest of your application will continue to function as expected during the delay period.

  ///////////////////////
  //don't remove below code snippet it shows how to make a code part delay and run startSendingCoordinates() function

  // startSendingCoordinates(userId: string) {
  //   if (this.auth.currentUserValue.username) {
  //     this.fieldOfficerService.setTrackingState(userId, "ON");
  //     console.debug("ON: Automation tracker details sent to DB :)");
  //     this._snackBar.open("Location Tracker is ON", 'Dismiss', {
  //       duration: DurationUtil.TWO_SEC,
  //       horizontalPosition: "right",
  //       verticalPosition: "top",
  //       panelClass: ['mat-toolbar', 'mat-primary']
  //     });

  //     console.debug("delaying start 10 sec");

  //     // Using delay operator to delay for 10 seconds
  //     const delayTimeMs = environment.recoveryConfig.automationTrackerStartDelay;
  //     this.delayedAction().pipe(
  //       delay(delayTimeMs)
  //     ).subscribe(() => {
  //       // This code will be executed after the delay
  //       this.getGeolocation();
  //       console.debug("first location ping sent! next will be in 5 minutes");
  //     });
  //     // setInterval is a global service which acts as a looping feature
  //     this.interval = setInterval(() => {
  //       //current location coordinates to the backend
  //       console.debug("service loop started")
  //       this.getGeolocation();
  //     }, environment.recoveryConfig.automationTrackerTime); // 300000 milliseconds (5 seconds) | set your time 😊
  //   } else {
  //     this.stopSendingCoordinates(this.auth.currentUserValue.username);
  //   }
  // }
  ////////////////

}
