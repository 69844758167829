//for session time out - DulsaraMannakkara 2023.10.05
// session-guard.service.ts
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { SessionService } from './session-service';
import { UserAuthenticationService } from './user-authentication/user-authentication.service';

@Injectable({
  providedIn: 'root'
})
export class SessionGuard implements CanActivate {
  constructor(private sessionService: SessionService, private router: Router,private auth: UserAuthenticationService) {}

  canActivate(): boolean {
    if (!this.sessionService.isSessionExpired()) {
      this.sessionService.renewSession(); // Renew the session on activity
      console.log("continue session guard");
      
      return true; // Allow access to the route
    } else {
      // Redirect to the login page or another appropriate action
      //this.auth.logout(this.router);
      this.sessionService.renewSession();
      console.log("end session guard");
      return false; // Prevent access to the route
    }
  }
}
