import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularGridInstance, Column, FieldType, Formatters, GridOption } from 'angular-slickgrid';
import { ClientDetailResponseModel } from 'src/app/models/call-desk/ClientDetailResponseModel';
import { RelatedContractsService } from 'src/app/services/related-contracts.service';
import { UserAuthenticationService } from 'src/app/services/user-authentication/user-authentication.service';
import { DurationUtil } from 'src/app/utils/DurationUtil';
import { ManualCallDialogComponent } from '../manual-call-dialog/manual-call-dialog.component';

@Component({
  selector: 'app-related-contracts',
  templateUrl: './related-contracts.component.html',
  styleUrls: ['./related-contracts.component.scss']
})
export class RelatedContractsComponent implements OnInit {

  title = "Related contracts"
  user: string = null
  contractNo: string = null
  cType: string = null

  contractSelected: string = undefined

  columnDefinitions: Column[] = [];
  gridOptions: GridOption = {};//CallDeskDataResponseRestModelInfo
  dataset: any[] = []; //CallDeskDataResponseModelInfo
  ngGrid: AngularGridInstance;
  ngGridObj: any = null
 


  constructor(private dialogRef: MatDialogRef<RelatedContractsComponent>, private auth: UserAuthenticationService, @Inject(MAT_DIALOG_DATA) public data: any, private service: RelatedContractsService, private dialog: MatDialog, private snackBar: MatSnackBar ) { }

  ngOnInit(): void {
    this.contractNo = this.data.contractNo
    this.cType = this.data.cType
    this.user = this.auth.currentUserValue.code
    this.columnDefinitions = [
      {
        id: "contractNo",
        name: "Contract Number",
        field: "contractNo",
        minWidth: 100,
        type: FieldType.string,
        filterable: true,
      }, {
        id: "name",
        name: "Name",
        field: "name",
        minWidth: 200,
        type: FieldType.string,
        filterable: true,
      },{
        id: "address",
        name: "Address",
        field: "address",
        minWidth: 300,
        type: FieldType.string,
        filterable: true,
      }, {
        id: "ria",
        name: "RIA(Assigned points)",
        field: "ria",
        minWidth: 75,
        type: FieldType.string,
        filterable: true,
      }, {
        id: "stockAmount",
        name: "Stock Amount",
        field: "stockAmount",
        minWidth: 100,
        type: FieldType.string,
        formatter: Formatters.decimal,
        params: { minDecimal: 2, maxDecimal: 2 },
        filterable: true,
      }, {
        id: "arrearsAmount",
        name: "Arrears amount",
        field: "arrearsAmount",
        minWidth: 100,
        type: FieldType.string,
        formatter: Formatters.decimal,
        params: { minDecimal: 2, maxDecimal: 2 },
        filterable: true,
      }, {
        id: "dueDate",
        name: "Due date",
        field: "dueDate",
        minWidth: 100,
        type: FieldType.date,
        formatter: Formatters.dateIso,
        filterable: true,
      }, {
        id: "monthEndDpd",
        name: "Month End DPD",
        field: "monthEndDpd",
        minWidth: 75,
        type: FieldType.string,
        filterable: true,
      }, {
        id: "currentDpd",
        name: "Current DPD",
        field: "currentDpd",
        minWidth: 75,
        type: FieldType.string,
        filterable: true,
      }
    ];

    this.gridOptions = {
      gridHeight: "300px",
      formatterOptions: {
        minDecimal: 2,
        maxDecimal: 2,
        dateSeparator: "-",
        displayNegativeNumberWithParentheses: true
      },
      enableAutoResize: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      enableCheckboxSelector: true,
      enableFiltering: true,
      checkboxSelector: {
        hideSelectAllCheckbox: true,
      },
      multiSelect: false,
      rowSelectionOptions: {
        selectActiveRow: true,
      },
      columnPicker: {
        hideForceFitButton: true
      },
      gridMenu: {
        hideForceFitButton: true
      },
      enablePagination: false,
    };

    this.getReltedContractList()
  }

  angularGridReady(event: any) {
    this.ngGrid = (event as CustomEvent).detail as AngularGridInstance;
    this.ngGridObj = this.ngGrid.slickGrid;
    this.ngGrid.slickGrid.onSelectedRowsChanged.subscribe((e, args) => {
      this.handleSelection(e, args);
    });
  }

  handleSelection(e: Event, args: any) {
    // this.selectedCallDeskTransaction = null;
    if (Array.isArray(args.rows) && this.ngGridObj) {

      let selected = args.rows.map((idx: number) => {
        const selectedRowData = this.ngGridObj.getDataItem(idx);
        return selectedRowData || '';
      });

      this.contractSelected = selected[0]?.contractNo
    }
  }

  getReltedContractList() {
    this.dataset = [];
   this.service.getRelatedContracts(this.contractNo, this.cType).subscribe({
      next: value => {
        value.forEach((item, key) => {
          this.dataset.push({
            id: key,
            contractNo: item.CONTRACTNO,
            name: item.NAME,
            ria: item.RIA,
            address: item.clientAddress,
            stockAmount: item.stockAmount,
            arrearsAmount: item.arrearsAmount,
            dueDate: item.dueDate,
            monthEndDpd: item.monthEndDPD,
            currentDpd: item.currentDPD,
            cType: item.cType
          })
        })

        this.ngGrid.dataView.refresh();
      },
      error: err => {
        alert("Error occured while connecting to the server")
      }
    })
  }

  openContractDetails() {
    if (this.contractSelected == undefined || this.contractSelected == null) {
      this.snackBar.open('Please select a call record first', 'Dismiss', {
        duration: DurationUtil.TWO_SEC,
        horizontalPosition: "right",
        verticalPosition: "top",
        panelClass: ['mat-toolbar', 'mat-primary']
      });
    } else {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = false;
      dialogConfig.closeOnNavigation = true;
      dialogConfig.autoFocus = true;
      dialogConfig.height = '800px';
      dialogConfig.width = '1500px';
      dialogConfig.data = {
        selectedCallDeskTransaction: {
          contractNo: this.contractSelected
        },
        dialogType: "RELATED"
      };

      const dialogRef = this.dialog.open(ManualCallDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(
        data => {
          console.log(`Manual call dialog closed`);
        }
      );
    }
  }

  closeDialog(): any {
    this.dialogRef.close();
  }

}