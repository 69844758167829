/**
 * OPtimized visit Persisit Data model
 * @author Achala M. Rathnathilaka
 */
export class  OptimizedVisitPostRequestObjectModel {

  invgcode?: string;
  contractno?: string;
  assignserial?: string;
  optdate?: string;
  comment?: string;
  cType?: string;

}
