<style>
    table {
        width: 100%;
        /* display: flex;
        justify-content: space-between; */
        margin: 20px auto;
    }

    table {
        width: 100%;
    }

    tr.example-detail-row {
        height: 0;
    }

    tr.example-element-row:not(.example-expanded-row):hover {
        background: whitesmoke;
    }

    tr.example-element-row:not(.example-expanded-row):active {
        background: #efefef;
    }

    .expanded-element-content {
        overflow: hidden;
        display: flex;
    }

    .expandable-title {
        font-size: 30px;
        font-weight: 600;
        padding: 10px 0px 0px 0px;
        margin: 20px 0px 0px 0px;
    }

    .expandable-detail-important {
        font-size: 20px;
        font-weight: 550;
    }

    .expandable-detail-leading {
        font-weight: 200;
        opacity: 0.75;
    }
</style>
<div class="p-5">
    <h3>Contract List</h3>
    <div class="row">
        <div class="col-6">
            <mat-form-field appearance="outline" class="w-75 h-100">
                <mat-label>ID</mat-label>
                <input matInput type="text">
            </mat-form-field>
        </div>
        <div class="col-6">
            <input type="date" class="w-75 h-100">
        </div>
    </div>
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z4" *ngIf="dataLoaded">
        <ng-container matColumnDef="expand">
            <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button aria-label="expand row"
                    (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
                    <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                    <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
                </button>
            </td>
        </ng-container>
        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
                select
            </th>
            <td mat-cell *matCellDef="let row">
                {{row}}
            </td>
        </ng-container>
        <ng-container matColumnDef="{{column}}" *ngFor="let column of columns; let i = index;">
            <th mat-header-cell *matHeaderCellDef> {{column}} </th>
            <td mat-cell *matCellDef="let element"> {{element[columnLabels[i]]}} </td>
        </ng-container>

        <ng-container matColumnDef="open">
            <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button aria-label="expand row"
                    (click)="openIntoForm(element); $event.stopPropagation()">
                    <mat-icon>open_in_new</mat-icon>
                </button>
            </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsWithExpand.length" [ngClass]="expandedElement !== element ? '' : 'expanded-row'">
                <div class="expanded-element-content"
                    [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <div class="expandable" class="pb-5">
                        <div class="expandable-title"> {{element.customerName}} </div>
                        <div class="row w-100 d-flex align-items-end my-2 mx-1">
                            <div class="col-6 expandable-detail-leading">Contract number</div>
                            <div class="col-6">
                                <div class="expandable-detail"> {{element.contract_No}} </div>
                            </div>
                        </div>
                        <div class="row w-100 d-flex align-items-end my-1 mx-1">
                            <div class="col-6 expandable-detail-leading">Customer address</div>
                            <div class="col-6">
                                <div class="expandable-detail"> {{element.customerAddress}} </div>
                            </div>
                        </div>
                        <div class="row w-100 d-flex align-items-end my-1 mx-1">
                            <div class="col-6 expandable-detail-leading">NIC</div>
                            <div class="col-6">
                                <div class="expandable-detail"> {{element.nic}} </div>
                            </div>
                        </div>
                        <div class="row w-100 d-flex align-items-end my-1 mx-1">
                            <div class="col-6 expandable-detail-leading">Investigator Code</div>
                            <div class="col-6">
                                <div class="expandable-detail"> {{element.investigatorCode}} </div>
                            </div>
                        </div>
                        <div class="row w-100 d-flex align-items-end my-1 mx-1">
                            <div class="col-6 expandable-detail-leading">Assigned Date</div>
                            <div class="col-6">
                                <div class="expandable-detail"> {{element.assignDate}} </div>
                            </div>
                        </div>
                        <div class="row w-100 d-flex align-items-end my-1 mx-1">
                            <div class="col-6 expandable-detail-leading">Total Arrears</div>
                            <div class="col-6">
                                <div class="expandable-detail"> {{element.totalArrears}} </div>
                            </div>
                        </div>
                        <div class="row w-100 d-flex align-items-end my-1 mx-1">
                            <div class="col-6 expandable-detail-leading">Default Arrears</div>
                            <div class="col-6">
                                <div class="expandable-detail"> {{element.defaultArrears}} </div>
                            </div>
                        </div>
                        <div class="row w-100 d-flex align-items-end my-1 mx-1">
                            <div class="col-6 expandable-detail-leading">Other Arrears Amount</div>
                            <div class="col-6">
                                <div class="expandable-detail"> {{element.otherArrearsAmount}} </div>
                            </div>
                        </div>
                        <div class="row w-100 d-flex align-items-end my-1 mx-1">
                            <div class="col-6 expandable-detail-leading">Insurance arrears</div>
                            <div class="col-6">
                                <div class="expandable-detail"> {{element.insuranceArrears}} </div>
                            </div>
                        </div>
                        <div class="row w-100 d-flex align-items-end my-1 mx-1">
                            <div class="col-6 expandable-detail-leading">Number of Arrears</div>
                            <div class="col-6">
                                <div class="expandable-detail"> {{element.noOfArrears}} </div>
                            </div>
                        </div>
                        <div class="row w-100 d-flex align-items-end my-1 mx-1">
                            <div class="col-6 expandable-detail-leading">Due date</div>
                            <div class="col-6">
                                <div class="expandable-detail"> {{element.dueDate}} </div>
                            </div>
                        </div>
                        <div class="row w-100 d-flex align-items-end my-1 mx-1">
                            <div class="col-6 expandable-detail-leading">Last pay date</div>
                            <div class="col-6">
                                <div class="expandable-detail"> {{element.lastPayDate}} </div>
                            </div>
                        </div>
                        <div class="row w-100 d-flex align-items-end my-1 mx-1">
                            <div class="col-6 expandable-detail-leading">Gross Rental</div>
                            <div class="col-6">
                                <div class="expandable-detail"> {{element.grossRental}} </div>
                            </div>
                        </div>
                        <div class="row w-100 d-flex align-items-end my-1 mx-1">
                            <div class="col-6 expandable-detail-leading">Registration number</div>
                            <div class="col-6">
                                <div class="expandable-detail"> {{element.registrationNo}} </div>
                            </div>
                        </div>
                        <div class="row w-100 d-flex align-items-end my-1 mx-1">
                            <div class="col-6 expandable-detail-leading">Equipment</div>
                            <div class="col-6">
                                <div class="expandable-detail"> {{element.equipment}} </div>
                            </div>
                        </div>
                        <div class="row w-100 d-flex align-items-end my-1 mx-1">
                            <div class="col-6 expandable-detail-leading">Make</div>
                            <div class="col-6">
                                <div class="expandable-detail"> {{element.make}} </div>
                            </div>
                        </div>
                    </div>
                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsWithExpand"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsWithExpand;" class="example-element-row"
            [class.example-expanded-row]="expandedElement === element"
            (click)="expandedElement = expandedElement === element ? null : element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
        <!-- <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                 showFirstLastButtons 
                 aria-label="Select page size">
        </mat-paginator> -->
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>