import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SocialAuthService } from 'angularx-social-login';
import { any } from 'codelyzer/util/function';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/user/user-model';
import { CashHandoverService } from 'src/app/services/cash-handover/cash-handover.service';
import { UserAuthenticationService } from 'src/app/services/user-authentication/user-authentication.service';
import { DurationUtil } from 'src/app/utils/DurationUtil';
import { FieldOfficerTrackingDialogComponent } from '../field-officer-tracking-dialog/field-officer-tracking-dialog.component';
import { ManualCallDialogComponent } from '../manual-call-dialog/manual-call-dialog.component';
import { RouteManagementDialogComponent } from '../route-management/route-management-dialog/route-management-dialog.component';
import { SettlementCalculationComponent } from '../settlement-calculation/settlement-calculation.component';
import { CashHandoverComponent } from '../v2/cash-handover/cash-handover.component';
import { RouteAllocationV2Component } from '../v2/route-allocation-v2/route-allocation-v2.component';
import { TodaysCallListV2Component } from '../v2/todays-call-list-v2/todays-call-list-v2.component';
import { TodaysVisitListV2Component } from '../v2/todays-visit-list-v2/todays-visit-list-v2.component';
import { VisitSelectionDeskDialogComponent } from '../visit-selection-desk-dialog/visit-selection-desk-dialog.component';
import { VisitSelectionUpdateDeskDialogComponent } from '../visit-selection-update-desk-dialog/visit-selection-update-desk-dialog.component';
import { AdminPortelComponent } from '../admin-portel/admin-portel.component';
import { MatTableDataSource } from '@angular/material/table';
import { AdminPortalService } from 'src/app/services/admin-portal/admin-portal.service';

@Component({
  selector: 'app-home-component-v2',
  templateUrl: './home-component-v2.component.html',
  styleUrls: ['./home-component-v2.component.scss']
})
export class HomeComponentV2Component implements OnInit {

  constructor(private snackbar: MatSnackBar, private auth: UserAuthenticationService, private dialog: MatDialog, private authService: SocialAuthService, private router: Router, private CashHService: CashHandoverService,
    private adminPortalService: AdminPortalService) { }

  public user: string = "";
  currentUser: User = null
  currentUserSubscription: Subscription = null

  ngOnInit(): void {

    this.user = this.auth.currentUserValue.user_id

    // USING GLOBAL USER DATA FOR ANYWHERE
    // Step 1: import UserAuthenticationService

    // method 1: As a observable
    this.currentUserSubscription = this.auth.currentUser.subscribe(
      user => {
        this.currentUser = user;
      }
    )

    // method 2: as a common/utility function
    // currentUserValue is a getter so this will not be called as a function
    // calling is as a public variable will be enough
    // console.log(this.auth.currentUserValue)
  }

  ngOnDestroy() {
    this.currentUserSubscription.unsubscribe();
  };

  openTodaysCallList() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '100vh';
    dialogConfig.width = '99.5vw';
    dialogConfig.maxWidth = '99.5vw';
    const dialogRef = this.dialog.open(TodaysCallListV2Component, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {

      }
    );
  }

  openTodaysVisitList() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '100vh';
    dialogConfig.width = '99.5vw';
    dialogConfig.maxWidth = '99.5vw';

    const dialogRef = this.dialog.open(VisitSelectionDeskDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {

      }
    );
  }

  openInquiry() {

    let OPPORTUNITYID: any = "300000053159026"

    this.auth.openInquiryService(OPPORTUNITYID);

  }

  openRouteManagement() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '100vh';
    dialogConfig.width = '99.5vw';
    dialogConfig.maxWidth = '99.5vw';
    const dialogRef = this.dialog.open(RouteManagementDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {

    })
  }

  openNavigator() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '100vh';
    dialogConfig.width = '99.5vw';
    dialogConfig.maxWidth = '99.5vw';
    const dialogRef = this.dialog.open(VisitSelectionUpdateDeskDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {

      }
    );
  }

  openCalculator() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = false;
    dialogConfig.height = '100vh';
    dialogConfig.width = '99.5vw';
    dialogConfig.maxWidth = '99.5vw';
    const dialogRef = this.dialog.open(SettlementCalculationComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {

      }
    );
  }

  openDistanceTracker() {
    const user_name = this.auth.currentUserValue.username;

    //success to FieldOfficerTrackingDialogComponent(Distance tracker)
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '100vh';
    dialogConfig.width = '99.5vw';
    dialogConfig.maxWidth = '99.5vw';
    const dialogRef = this.dialog.open(FieldOfficerTrackingDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {

      }
    );
  }

  adminPortel() {
    const user_name = this.auth.currentUserValue.username;
    this.adminPortalService.getAdminPortalAccess(user_name).subscribe({
      next: (result) => {
        if (result && result.length > 0) {
          const userData = result.find((item: { USER_ID: string; }) => item.USER_ID === user_name);
          if (userData) {
            if (userData.INV_ACCESS_ROUTE_DETAILS === "1") {

              const dialogConfig = new MatDialogConfig();
              dialogConfig.disableClose = true;
              dialogConfig.closeOnNavigation = true;
              dialogConfig.autoFocus = true;
              dialogConfig.height = '100vh';
              dialogConfig.width = '99.5vw';
              dialogConfig.maxWidth = '99.5vw';
              const dialogRef = this.dialog.open(AdminPortelComponent, dialogConfig);
              dialogRef.afterClosed().subscribe(
                data => {

                }
              );

            } else if (userData.INV_ACCESS_ROUTE_DETAILS === "0") {
              alert("You don't have access");
            }
          } else {
            alert("No Data for Current User");
          }
        } else {
          alert("No Data found");
        }
      },
      error: (error) => {
        console.error("Error fetching data:", error);
        alert("Error");
      },
    });

  }

  openCollectorPerformance() {

    const invgCode = this.auth.currentUserValue.code

    window.open('https://www.cfmobile.lk/BranchlessB_Live/FacilityInq/FacilityInquary/?FACNO=' + invgCode, '_blank')

  }

  openVisitListReport() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = false;
    dialogConfig.height = '100vh';
    dialogConfig.width = '99.5vw';
    dialogConfig.maxWidth = '99.5vw';
    const dialogRef = this.dialog.open(TodaysVisitListV2Component, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {

      }
    );
  }

  openRouteAllocation() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '100vh';
    dialogConfig.width = '99.5vw';
    dialogConfig.maxWidth = '99.5vw';
    const dialogRef = this.dialog.open(RouteAllocationV2Component, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {

    })
  }

  openCashHandover() {
    const user_name = this.auth.currentUserValue.username;
    // this.adminPortalService.getAdminAccessCashHandover(user_name).subscribe({
    //   next: (result) => {
    //     if (result && result.length > 0) {
    //       const userData = result.find((item: { USER_ID: string; }) => item.USER_ID === user_name);
    //       if (userData) {
    //         if (userData.INV_ACCESS_CASH_HANDOVER === "1") {

    //success to CashHandoverComponent(Cash Handover)
    this.CashHService.GetCashHandOverDet(user_name).subscribe(result => {
      console.log(result[0].res);
      if (result[0].res !== "00") {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.closeOnNavigation = true;
        dialogConfig.autoFocus = true;
        dialogConfig.height = '100vh';
        dialogConfig.width = '99.5vw';
        dialogConfig.maxWidth = '99.5vw';
        dialogConfig.data = {
          CASH_AMT: result[0].CASH_AMT,
          CHQ_AMT: result[0].CHQ_AMT,
          batch_serial: result[0].batch_serial,
          msg: result[0].msg
        }
        const dialogRef = this.dialog.open(CashHandoverComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(data => {

        })
      } else {
        this.snackbar.open(result[0]?.msg, 'Dismiss', {
          duration: DurationUtil.TWO_SEC,
          horizontalPosition: "right",
          verticalPosition: "top",
          panelClass: ['mat-toolbar', 'mat-primary']
        });
      }
    })

    //         } else if (userData.INV_ACCESS_CASH_HANDOVER === "0") {
    //           alert("You don't have access");
    //         }
    //       } else {
    //         alert("No Data for Current User");
    //       }
    //     } else {
    //       alert("No Data found");
    //     }
    //   },
    //   error: (error) => {
    //     console.error("Error fetching data:", error);
    //     alert("Error");
    //   },
    // });
  }

  logout() {
    this.authService.signOut();
    this.auth.logout(this.router);
  }

}
