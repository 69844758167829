import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RestRequestService } from '../rest-request.service';
import { Config } from "../../../config/config";

// Admin Portal Service - Bilesh Sashin
@Injectable({
  providedIn: 'root'
})
export class AdminPortalService {
  private readonly callDeskJobsUrl: string;
  private readonly apiUrl: string;

  constructor(
    private restService: RestRequestService,
    @Inject("recoveryConfig") private recoveryConfig = Config.recoveryConfig,
    private http: HttpClient) {

    this.callDeskJobsUrl = this.getCallDeskJobsUrl(recoveryConfig);

    // Set apiUrl based on recoveryConfig
    if (recoveryConfig.env === 'local') {
      this.apiUrl = recoveryConfig.appServerUrl;
    } else {
      this.apiUrl = recoveryConfig.recoveryServiceUrl;
    }
  }
  private getCallDeskJobsUrl(recoveryConfig: any = {}): string {
    if (recoveryConfig.env === 'local') {
      return recoveryConfig.appServerUrl;
    } else {
      return recoveryConfig.recoveryServiceUrl;
    }
  }

  // GET CASH HANDOVER
  getAdminAccessCashHandover(USER_ID: string): Observable<any> {
    const url = `${this.apiUrl}/vGetadminAccessCashHandover/${USER_ID}`;
    return this.http.get<any>(url);
  }

  // POST CASH HANDOVER
  updateAdminAccessCashHandOverGrant(selectedRows: any[]): Observable<any> {
    const url = `${this.apiUrl}/vUpdateadminAccessCashHandOverGrant`;
    const requestBody = JSON.stringify(selectedRows);
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');
    return this.http.post<any>(
      url,
      requestBody,
      { headers }
    );
  }

  //GET VISIT DISTANCE
  getAdminAccessRouteDetails(USER_ID: string): Observable<any> {
    const url = `${this.apiUrl}/vGetadminAccessRouteDetails/${USER_ID}`;
    return this.http.get<any>(url);
  }

  // POST VISIT DISTANCE
  updateAdminAccessRouteGrant(selectedRows: any[]): Observable<any> {
    const url = `${this.apiUrl}/vUpdateadminAccessRouteGrant`;
    const requestBody = JSON.stringify(selectedRows);
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');
    return this.http.post<any>(
      url,
      requestBody,
      { headers }
    );
  }

  // GET ADMIN ACCESS
  getAdminPortalAccess(USER_ID: string): Observable<any> {
    const url = `${this.apiUrl}/vGetadminPortalAccess/${USER_ID}`;
    return this.http.get<any>(url);
  }

  // POST ADMIN ACCESS
  updateAdminPortalAccessGrant(selectedRows: any[]): Observable<any> {
    const url = `${this.apiUrl}/vUpdateadminPortalAccessGrant`;
    const requestBody = JSON.stringify(selectedRows);
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');
    return this.http.post<any>(
      url,
      requestBody,
      { headers }
    );
  }

}
