import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { AngularGridInstance, Column, Formatters, GridOption, GridStateChange, RxJsFacade } from 'angular-slickgrid';
import { ManualCallDialogComponent } from "../manual-call-dialog/manual-call-dialog.component";
import { AutoCallDialogComponent } from "../auto-call-dialog/auto-call-dialog.component";
import { CallDeskDataRequestModel } from "../../models/call-desk-data-request-model";
import { CallDeskService } from "../../services/call-desk.service";
import { CallDeskDataResponseModel } from "../../models/call-desk-data-response-model";
import { CallDeskDataResponseModelInfo } from "../../models/call-desk-data-response-model-info";
import { TodaysCallDeskService } from 'src/app/services/call-desk/call-desk.service';
import { TodaysCallList, TodaysCallRecord } from 'src/app/models/call-desk/todays-calls-response';
import { pipe } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DurationUtil } from 'src/app/utils/DurationUtil';

@Component({
  selector: 'app-call-desk-dialog',
  templateUrl: './call-desk-dialog.component.html',
  styleUrls: ['./call-desk-dialog.component.scss']
})
export class CallDeskDialogComponent implements OnInit {
  todayCallsColumnDefinitions: Column[] = [];
  todayCallsgridOptions: GridOption = {};//CallDeskDataResponseRestModelInfo
  todayCallsDataset: any[] = []; //CallDeskDataResponseModelInfo
  todayCallsOriginalDataset: any[] = [];

  todayCallsAngularGrid!: AngularGridInstance;
  angularGridObj!: any;
  selectedTableRow!: any;
  selectedCallDeskTransaction!: CallDeskDataResponseModelInfo;
  angularGrid!: AngularGridInstance;
  todayCallsAngularGridObj!: any;
  callDeskDataRequestModel = new CallDeskDataRequestModel();

  callDeskDataResponseResult: CallDeskDataResponseModel = new CallDeskDataResponseModel();

  constructor(protected callDeskService: CallDeskService, private service: TodaysCallDeskService, private dialogRef: MatDialogRef<CallDeskDialogComponent>, public dialog: MatDialog, private _snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.defineTodayCallsGrid();
    this.initiateTableData();
  }

  defineTodayCallsGrid(): any {

    this.todayCallsColumnDefinitions = [
      { id: 'contractNo', name: 'Contract No', field: 'contractNo', sortable: true },
      { id: 'customerName', name: 'Name', field: 'customerName', sortable: true },
      { id: 'totalArrears1', name: 'Total arrears', field: 'totalArrears1', sortable: true, formatter: Formatters.decimal, params: { minDecimal: 2, maxDecimal: 2 }, },
      { id: 'dueDate', name: 'Due Date', field: 'dueDate', sortable: true, formatter: Formatters.dateIso },
      { id: 'customerAddress', name: 'Address', field: 'customerAddress', sortable: true },
    ];
    this.todayCallsgridOptions = {
      enableAutoResize: true,
      enableSorting: true,
      enableExport: true,
      enablePagination: true,
      enableRowSelection: true,
      enableExcelExport: true,
      enableFiltering: true,
      enableCheckboxSelector: true,
      enableCellNavigation: true,
      checkboxSelector: {
        hideSelectAllCheckbox: true,
      },
      multiSelect: true,
      rowSelectionOptions: {
        selectActiveRow: true,
      }
    };
  }

  initiateTodayCallsAngulargridGrid(event: Event) {
    this.todayCallsAngularGrid = (event as CustomEvent).detail as AngularGridInstance;
    this.todayCallsAngularGridObj = this.todayCallsAngularGrid.slickGrid;
    this.todayCallsAngularGrid.slickGrid.onSelectedRowsChanged.subscribe((e, args) => {
      this.handleTodayCallsRowSelection(e, args);
    });
  }

  resizeGrid2() {
    // this..resizerService.resizeGrid();
  }

  initiateTableData(): any {
    this.todayCallsDataset[0] = {
      id: 0,
      contractNo: 'N/A',
      customerName: 'N/A',
      amountDue: 'N/A',
      dueDate: 'N/A',
      customerAddress: 'N/A'

    };
    // this.resizeGrid2();
    // this.todayCallsAngularGrid.dataView.refresh();
  }

  getTodaysCallList(): void {
    this.service.getTodaysCallList("", "").subscribe({
      next: (response: TodaysCallList) => {
        try {
          this.todayCallsDataset = [];
          response.Table.map((item: any, key) => {
            this.todayCallsDataset[key] = {
              id: key,
              contractNo: item.CONTRACTNO,
              nic: item.NIC,
              investigationCode: item.INVESTIGATORCODE,
              assignDate: item.ASSIGNDATE,
              customerName: item.CUSTOMERNAME,
              customerAddress: item.CUSTOMERADDRESS,
              totalArrears: item.TOTALARREARS,
              defaultArrears: item.DEFAULTARREARS,
              otherArrearsAmount: item.OTHERARREARSAMOUNT,
              insuranceArrears: item.INSURANCEARREARS,
              totalArrears1: item.TOTALARREARS1,
              noOfAreas: item.NOOFARREARS,
              dueDate: item.DUEDATE,
              lastPayDate: item.LASTPAYDATE,
              grossRental: item.GROSSRENTAL,
              dueDate1: item.DUEDATE1,
              registrationNo: item.REGISTRATIONNO,
              equipment: item.EQUIPMENT,
              make: item.MAKE,
              contactNo: item.CONTACT_NO,
              secondaryContactNo: item.SECONDARY_CONTACT_NO,
              guaranterContactNo: item.GURANTER_CONTACT_NO,
              threepName: item.THREEP_NAME,
              threepAddress: item.THREEP_ADDRESS,
              threepContactNo: item.THREEP_CONTACTNO,
              guranterName: item.GURANTER_NAME,
              lastPayDate1: item.LASTPAYDATE1,
              lastPaymentAmount: item.LAST_PAYMENT_AMOUNT,
              rentalsDebited: item.RENTALS_DEBITED,
              financeAmount: item.FINANCE_AMOUNT,
              defRefNo: item.DEFREFNO,
              defFalg: item.DEFFLAG,
              defStatus: item.DEFSTATUS,
              defReason: item.DEFREASON,
              assignSerial: item.ASSIGNSERIAL,
              postalCode: item.POSTALCODE,
              type: item.TYPE_,
              callStatus: item.CALLSTATUS,
              followUpDate: item.FOLLOWUPDATE,
              directionNature: item.DIRECTION_NATURE,
              flag: item.FLAG,
              callResponse: item.CL_RESPONSE,
              promisedAmount: item.PROMISEDAMT,
              promisedDate: item.PROMISED_DATE,
              comment: item.COMMENT_
            };
          });

          this._snackBar.open('Data loaded', 'Dismiss', {
            duration: DurationUtil.TWO_SEC,
            horizontalPosition: "right",
            verticalPosition: "top",
            panelClass: ['mat-toolbar', 'mat-primary']
          });

          this.resizeGrid2();
          this.todayCallsAngularGrid.dataView.refresh();
        } catch (e) {
          alert("An error occured during connecting to the service!!!");
        }
      },
      error: (err: any) => {
        alert("An error occured during connecting to the service")
        console.log(err);
      }
    })
  }

  manualCall() {
    if (this.selectedTableRow == undefined || this.selectedTableRow == null) {
      this._snackBar.open('Please select a call record first', 'Dismiss', {
        duration: DurationUtil.TWO_SEC,
        horizontalPosition: "right",
        verticalPosition: "top",
        panelClass: ['mat-toolbar', 'mat-primary']
      });
    } else {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.closeOnNavigation = true;
      dialogConfig.autoFocus = true;
      dialogConfig.height = '800px';
      dialogConfig.width = '1500px';
      dialogConfig.data = { selectedCallDeskTransaction: this.selectedCallDeskTransaction };

      console.log(this.selectedCallDeskTransaction);
      const dialogRef = this.dialog.open(ManualCallDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(
        data => {
          console.log(`Manual call dialog opened`);
          this.getTodaysCallList();
        }
      );
    }
  }

  // autoCall () {
  //   const dialogConfig = new MatDialogConfig();
  //   dialogConfig.disableClose = true;
  //   dialogConfig.closeOnNavigation = true;
  //   dialogConfig.autoFocus = true;
  //   dialogConfig.height = '600px';
  //   dialogConfig.width = '700px';
  //   const dialogRef = this.dialog.open ( AutoCallDialogComponent , dialogConfig );
  //   dialogRef.afterClosed ().subscribe (
  //     data => {
  //       console.log(`Auto call dialog opened`);
  //     }
  //   );
  //
  // }

  /** Dispatched event of a Reconciled Grid State Changed event */
  gridTodayCallsStateChanged(gridStateChanges: GridStateChange) {
  }


  /**Handle Call desk table row selection*/
  handleTodayCallsRowSelection(event: Event, args: any) {
    this.selectedCallDeskTransaction = null;
    if (Array.isArray(args.rows) && this.todayCallsAngularGridObj) {

      this.selectedTableRow = args.rows.map((idx: number) => {
        const selectedRowData = this.todayCallsAngularGridObj.getDataItem(idx);
        if (selectedRowData) {
        }
        return selectedRowData || '';
      });

      this.selectedCallDeskTransaction = this.selectedTableRow[0];
    }
  }

  closeDialog(): any {
    this.dialogRef.close();
  }

}
