import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {HomePageComponent} from "./components/home-page/home-page.component";
import { AppRecoveryComponent} from "./app-recovery.component";
import {environment} from "../environments/environment";
import {UserLoginComponent} from "./components/login/user-login/user-login.component";
import {CreateUserRolesComponent} from "./components/user-registration/create-user-roles/create-user-roles.component";
import {SettlementCalculationComponent} from "./components/settlement-calculation/settlement-calculation.component";
import { CreateRouteFormComponent } from './components/create-route-form/create-route-form.component';
import { RouteManagementComponent } from './components/route-management/route-management.component';
import { AuthGuardGuard } from './shared/AuthGuard/auth-guard.guard';
import { TodaysCallListV2Component } from './components/v2/todays-call-list-v2/todays-call-list-v2.component';
import { TodaysVisitListV2Component } from './components/v2/todays-visit-list-v2/todays-visit-list-v2.component';
import { RouteAllocationV2Component } from './components/v2/route-allocation-v2/route-allocation-v2.component';
import { HomeComponentV2Component } from './components/home-component-v2/home-component-v2.component';
import { SessionGuard } from './services/session-guard.service'; //for session time out - DulsaraMannakkara 2023.10.05

export const routes: Routes = [
  {
    path: 'home',
    // component: HomePageComponent,
    component: HomeComponentV2Component,
    canActivate:[AuthGuardGuard, SessionGuard]
  },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'call-list',
    component: TodaysCallListV2Component,
    canActivate:[AuthGuardGuard]
  },
    {
    path: 'route-allocation',
    component: RouteAllocationV2Component,
    canActivate:[AuthGuardGuard]
  },

  {
    path: 'visit-list',
    component: TodaysVisitListV2Component,
    canActivate:[AuthGuardGuard]
  },
  {
    path: 'calculation',
    component: SettlementCalculationComponent,
    canActivate:[AuthGuardGuard]
  },
  {
    path: 'create-user-role',
    component: CreateUserRolesComponent,
    canActivate:[AuthGuardGuard]
  },
  {
    path: 'route-management',
    component: RouteManagementComponent,
    canActivate:[AuthGuardGuard]
  },
  {
    path: 'login',
    component: UserLoginComponent
  },
  { path: '**', redirectTo: 'login' }
];

