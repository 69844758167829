import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularGridInstance, Column, FieldType, FileType, Formatters, GridOption, GridStateChange } from 'angular-slickgrid';
import { RouteAllocationContract } from 'src/app/models/route-allocation/route-allocation-contract-visit';
import { RouteSummaryModel } from 'src/app/models/route-allocation/route-summary-model';
import { RouteAllocationService } from 'src/app/services/route-allocation/route-allocation.service';
import { UserAuthenticationService } from 'src/app/services/user-authentication/user-authentication.service';
import { DurationUtil } from 'src/app/utils/DurationUtil';
import { AllocatedRouteUpdatedComponent } from '../allocated-route-updated/allocated-route-updated.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { VisitUpdateRequestModel } from 'src/app/models/visit-desk/VisitUpdateRequestModel';
import { VisitDeskService } from 'src/app/services/visit-desk.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TodaysCallDeskService } from 'src/app/services/call-desk/call-desk.service';
@Component({
  selector: 'app-route-allocation-v2',
  templateUrl: './route-allocation-v2.component.html',
  styleUrls: ['./route-allocation-v2.component.scss']
})
export class RouteAllocationV2Component implements OnInit {

  /**
   * UI related variables
   */
  readonly title: string = "Route Allocation"
  mode: string = "ALL"
  searchType: string = "Contract"; // search type default value is contract
  search: string = "";

  /**
   * Datagrid related
   */

  columnDefinitions: Column[] = [];
  gridOptions: GridOption = {};
  dataset: any[] = [];
  secondaryDataset: {
    contractNo: string,
    proposedDate: string,
    latitude: number,
    longtitude: number
  }[]

  ngGrid: AngularGridInstance;

  columnDefinitions1: Column[] = [];
  gridOptions1: GridOption = {};//CallDeskDataResponseRestModelInfo
  dataset1: any[] = []; //CallDeskDataResponseModelInfo

  // mode: string = "All"
  enableSearch: boolean = false;

  // util data
  cType: string = "CR";
  date: string = null;
  todayDate: Date = null;
  selectedDate: Date = null;

  selectOptionMode: string = "CR"
  CR: string
  WO: string
  PL: string
  PLWO: string

  isCompatible: boolean = false;

  ngGrid1: AngularGridInstance;
  gridObj1: any = null;

  didAutoProRun: boolean = false
  isAll: boolean = false;
  isBalance: boolean = true;
  isNonRouted: boolean = true;

  selectedTitle: string = ""
  selectedRecord: RouteAllocationContract = null

  totalContracts: number = 0;
  totalVisits: number = 0;
  totalDistance: number = 0;
  totalActualVisits: number = 0;
  totalRemovalVisits: number = 0;
  totalBalanceVisits: number = 0;


  constructor(private dialog: MatDialog, private snackbar: MatSnackBar, private dialogRef: MatDialogRef<RouteAllocationV2Component>, private service: RouteAllocationService, private auth: UserAuthenticationService, private visitDeskService: VisitDeskService, private TodaysCallDeskService: TodaysCallDeskService) { }

  ngOnInit(): void {
    this.columnDefinitions = [
      {
        id: "contractNo",
        name: "Contract Number",
        field: "contractNo",
        minWidth: 100,
        type: FieldType.string,
        filterable: true
      }, {
        id: "cType",
        name: "Type",
        field: "cType",
        minWidth: 50,
        type: FieldType.string,
        filterable: true,
      }, {
        id: "clientName",
        name: "Client / Grt Name/ 3P",
        field: "clientName",
        minWidth: 200,
        type: FieldType.string,
        filterable: true
      }, {
        id: "clientAddress",
        name: "Client address",
        field: "clientAddress",
        minWidth: 300,
        type: FieldType.string,
        filterable: true
      }, {
        id: "postalCode",
        name: "postal code & Name",
        field: "postalCode",
        minWidth: 75,
        type: FieldType.string,
        filterable: true
      }, {
        id: "suggestedRouteName",
        name: "Suggested Route Name",
        field: "suggestedRouteName",
        minWidth: 150,
        type: FieldType.string,
        filterable: true
      }, {
        id: "proposedDate",
        name: "Proposed date",
        field: "proposedDate",
        minWidth: 100,
        type: FieldType.string,
        filterable: true
        // formatter: Formatters.dateIso
      }
    ];

    this.columnDefinitions1 = [
      {
        id: "RouteName",
        name: "Route Name",
        field: "RouteName",
        minWidth: 150,
        type: FieldType.string,
        filterable: true,
        columnGroup: 'Route Summary',

        cssClass: "table-cell1Summery",
        headerCssClass: "table-cell-headerSummery"
      },
      {
        id: "ExpectedVisitDate",
        name: "Expected visit date",
        field: "ExpectedVisitDate",
        minWidth: 100,
        type: FieldType.date,
        formatter: Formatters.dateIso,
        filterable: false,
        columnGroup: 'Schedulled visits',
        cssClass: "table-cell",
        headerCssClass: "table-cell-header"
      },
      {
        id: "TotalContracts",
        name: "Total contracts",
        field: "TotalContracts",
        minWidth: 75,
        type: FieldType.string,
        filterable: false,
        columnGroup: 'Schedulled visits',
        cssClass: "table-cell",
        headerCssClass: "table-cell-header"
      }, {
        id: "TotalVisits",
        name: "Total visits",
        field: "TotalVisits",
        minWidth: 75,
        type: FieldType.string,
        filterable: false,
        columnGroup: 'Schedulled visits',
        cssClass: "table-cell",
        headerCssClass: "table-cell-header"
      }, {
        id: "RemovedVisits",
        name: "Removed Visits",
        field: "RemovedVisits",
        minWidth: 75,
        type: FieldType.string,
        filterable: false,
        columnGroup: 'Schedulled visits',
        cssClass: "table-cell",
        headerCssClass: "table-cell-header"
      }, {
        id: "ActualVisited",
        name: "Actual visited",
        field: "ActualVisited",
        minWidth: 75,
        type: FieldType.string,
        filterable: false,
        columnGroup: 'Actual visits',
        cssClass: "table-cell1",
        headerCssClass: "table-cell-header1"
      }, {
        id: "RemovedVisits",
        name: "Removed visits",
        field: "RemovedVisits",
        minWidth: 75,
        type: FieldType.string,
        filterable: false,
        columnGroup: 'Actual visits',
        cssClass: "table-cell1",
        headerCssClass: "table-cell-header1"
      }, {
        id: "Balancevisits",
        name: "Balance visits",
        field: "Balancevisits",
        minWidth: 75,
        type: FieldType.string,
        filterable: false,
        columnGroup: 'Actual visits',
        cssClass: "table-cell1",
        headerCssClass: "table-cell-header1"
      }

    ];


    this.gridOptions = {
      formatterOptions: {
        minDecimal: 2,
        maxDecimal: 2,
        dateSeparator: "-",
        displayNegativeNumberWithParentheses: true
      },
      enableAutoResize: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      enableCheckboxSelector: true,
      enableFiltering: true,
      checkboxSelector: {
        hideSelectAllCheckbox: true,
      },
      multiSelect: false,
      rowSelectionOptions: {
        selectActiveRow: true,
      },
      columnPicker: {
        hideForceFitButton: true
      },
      gridMenu: {
        hideForceFitButton: true
      },
      enablePagination: false,
    };

    this.gridOptions1 = {
      frozenColumn: 1,
      enableColumnReorder: false,
      enableSorting: true,
      createPreHeaderPanel: true,
      showPreHeaderPanel: true,
      preHeaderPanelHeight: 25,
      explicitInitialization: true,

      autoHeight: true,
      formatterOptions: {
        minDecimal: 2,
        maxDecimal: 2,
        dateSeparator: "-",
        displayNegativeNumberWithParentheses: true
      },
      enableAutoResize: false,
      enableCellNavigation: true,
      enableRowSelection: true,
      enableCheckboxSelector: true,
      enableFiltering: true,
      checkboxSelector: {
        hideSelectAllCheckbox: true,
      },
      multiSelect: false,
      rowSelectionOptions: {
        selectActiveRow: true,
      },
      columnPicker: {
        hideForceFitButton: true
      },
      gridMenu: {
        hideForceFitButton: true
      },
      enablePagination: false,
    };
    this.initData()
  }

  initData() {
    this.getAllContracts()
    this.getSummaryData();
  }

  angularGridReady(event: any) {
    this.ngGrid = (event as CustomEvent).detail as AngularGridInstance;
    this.gridObj1 = this.ngGrid.slickGrid;
    this.ngGrid.slickGrid.onSelectedRowsChanged.subscribe((e, args) => {
      this.handleSelection(e, args);
    });
  }

  angularGridReady1(angularGrid1: any) {
    this.ngGrid1 = (angularGrid1 as CustomEvent).detail as AngularGridInstance;
  }

  gridStateChanged(event: any) {
    let gridStateChanges = (event as CustomEvent).detail as GridStateChange
    console.log('Grid State changed:: ', gridStateChanges);
  }

  handleSelection(e: Event, args: any) {
    if (Array.isArray(args.rows) && this.gridObj1) {

      let selected = args.rows.map((idx: number) => {
        const selectedRowData = this.gridObj1.getDataItem(idx);
        return selectedRowData || '';
      });

      this.selectedTitle = selected[0]?.contractNo
      this.selectedRecord = {
        contractno: selected[0]?.contractNo,
        clientName: selected[0]?.clientName,
        clientAddress: selected[0]?.clientAddress,
        postalCode: selected[0]?.postalCode,
        suggestedRouteId: selected[0]?.suggestedRouteId,
        suggestedRouteName: selected[0]?.suggestedRouteName.suggestedRouteName,
        proposedDate: selected[0]?.proposedDate,
        latitude: selected[0]?.latitude,
        longtitude: selected[0]?.longtitude,
        cType: selected[0]?.cType
      }
    }
  }

  handleUpdateDialog() {
    if (!this.selectedRecord) {
      this.snackbar.open("Not selected a record", "Dismiss", {
        duration: DurationUtil.TWO_SEC,
        horizontalPosition: "right",
        verticalPosition: "top",
        panelClass: ['mat-toolbar', 'mat-primary']
      })
      return;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '800px';
    dialogConfig.width = '1500px';
    dialogConfig.data = {
      data: this.selectedRecord
    };

    let dialogRed = this.dialog.open(AllocatedRouteUpdatedComponent, dialogConfig)

    dialogRed.afterClosed().subscribe(data => {
      console.log(`Allocate update closed`);
      if (!data) {
        this.snackbar.open("Allocation not updated", "Dismiss", {
          duration: DurationUtil.TWO_SEC,
          horizontalPosition: "right",
          verticalPosition: "top",
          panelClass: ['mat-toolbar', 'mat-primary']
        })
      } else {
        this.changeMode("ALL")
        this.initData()
      }
    })
  }

  getRouteAllocationSummary() {
    this.service.getRouteAllocationSummary(this.auth.currentUserValue.code).subscribe({
      next: (value) => {
        console.log(value)
      },
      error: (err) => {
        console.log(err)
        this.snackbar.open("Route allocation summary failed to load", "Dismiss", {
          duration: DurationUtil.TWO_SEC,
          horizontalPosition: "right",
          verticalPosition: "top",
          panelClass: ['mat-toolbar', 'mat-primary']
        })
      }
    })
  }

  changeMode(mode: string) {
    this.mode = mode;

    if (mode == "ALL") {
      this.getAllContracts()
    }
    if (mode == "NONROUTED") {
      this.getNonRoutedContracts()
    }
    if (mode == "BALANCE") {
      this.getBalanceContracts()
    }
  }


  isLoading: boolean = false;   //loadingView bool - KalanaRathnayake

  getAllContracts() {
    this.isLoading = true;    //loadingView bool - KalanaRathnayake

    this.service.getAllRouteAllocations(this.auth.currentUserValue.code).subscribe({
      next: value => {
        console.log(value)
        this.isLoading = false;

        if (value.hasOwnProperty("msg")) {
          this.dataset = [];
          this.updateAllocationTable([])
          this.handleError("Route allocation list Loaded")
        } else {
          this.dataset = []
          this.updateAllocationTable(value)
          this.handleError("Route allocation list Loaded")
          console.debug("Route allocation list Loaded")
        }
        this.isAll = true;

        //when data is loaded the loading view will turn off by bool value 0 - KalanaRathnayake
        //forProduction

        // forDebugging
        // this.isLoading = true;
      },
      error: err => {
        this.handleError("Route allocation list failed to load")
        console.log(err);
        this.isLoading = false;        //loading will keep going when err trigger if you want to change make it false - KalanaRathnayake
      }
    })
  }

  getBalanceContracts() {
    this.service.getBalance(this.auth.currentUserValue.code).subscribe({
      next: value => {
        if (value.hasOwnProperty("msg")) {
          this.dataset = [];
          this.updateAllocationTable([])
          this.handleError("Balance Contract list Loaded")
          console.debug("Balance Contract list Loaded")

        } else {
          this.dataset = []
          this.updateAllocationTable(value)
        }
        this.isBalance = true;

      },
      error: err => {
        this.handleError("Route allocation list failed to load")
      }
    })
  }

  getNonRoutedContracts() {
    this.service.getNonRouted(this.auth.currentUserValue.code).subscribe({
      next: value => {
        if (value.hasOwnProperty("msg")) {
          this.dataset = [];
          this.updateAllocationTable(value)
          this.handleError("Non-Routed Contract list Loaded")
          console.debug("Route allocation list Loaded")
        } else {
          this.dataset = []
          this.updateAllocationTable(value)
          this.handleError("Non-Routed Contract list Loaded");
          console.debug("Route allocation list Loaded")
        }
        this.isNonRouted = true;

      },
      error: err => {
        this.handleError("Failed to load")
      }
    })
  }

  autoProximityAdjust() {
    this.service.autoProximityAdjust(this.auth.currentUserValue.code).subscribe({
      next: value => {
        this.didAutoProRun = true
        this.dataset = [];
        this.selectedTitle = null
        if (value.hasOwnProperty("msg")) {
          this.updateAllocationTable([])
          this.handleError("Route allocation list Loaded")
        } else {
          this.initData()
        }
      },
      error: err => {
        this.handleError("Route allocation list failed to load")
      }
    })
  }

  getSummaryData() {
    this.service.getRouteAllocationSummary(this.auth.currentUserValue.code).subscribe({
      next: (value) => {
        console.debug("Summary data loaded")
        console.log(value)
        if (value.hasOwnProperty("msg")) {
          this.dataset1 = [];
          this.updateSummaryTable([])
          this.handleError("Route allocation list Loaded")
        } else {
          this.dataset1 = []
          this.updateSummaryTable(value)
          this.handleError("Route allocation list Loaded")
        }
      },
      error: (err) => {
        this.handleError("Summary details failed to load")
      }
    })
  }

  updateSummaryTable(values: RouteSummaryModel[]) {
    this.dataset1 = []
    var total = {
      id: 0,
      RouteName: "Total",
      ExpectedVisitDate: "",
      TotalContracts: 0,
      TotalVisits: 0,
      DistanceA: 0,
      ActualVisited: 0,
      DistanceB: 0,
      RemovedVisits: 0,
      Balancevisits: 0
    }

    values.forEach((item, key) => {
      let { NAME_, DATE_, TOTALCONTRACTS, TOTALVISITS, DISTANCE, ACTUALVISITED, ACTUALVISITEDDISTANCE, REMOVEDVISITS, BALANCEVISITS } = item

      let totalVisits = parseInt(TOTALVISITS)
      let actualVisited = parseInt(ACTUALVISITED)
      let balanceVisits = totalVisits - actualVisited

      this.dataset1.push({
        id: key,
        RouteName: NAME_,
        ExpectedVisitDate: DATE_,
        TotalContracts: TOTALCONTRACTS,
        TotalVisits: totalVisits,
        DistanceA: DISTANCE,
        ActualVisited: ACTUALVISITED,
        DistanceB: ACTUALVISITEDDISTANCE,
        RemovedVisits: parseInt(REMOVEDVISITS) || 0,
        Balancevisits: balanceVisits
      })

      total.TotalContracts += TOTALCONTRACTS
      total.TotalVisits += totalVisits
      total.ActualVisited += actualVisited
      total.Balancevisits += balanceVisits
      total.RemovedVisits += parseInt(REMOVEDVISITS) || 0

    })


    this.dataset1.push({
      ...total,
      id: this.dataset1.length
    })
    //TODO add removed visit count
    this.totalContracts = total.TotalContracts
    this.totalVisits = total.TotalVisits
    this.totalDistance = 0
    this.totalActualVisits = total.ActualVisited
    this.totalRemovalVisits = total.RemovedVisits
    this.totalBalanceVisits = total.Balancevisits

    this.ngGrid1.dataView.refresh()
  }


  updateAllocationTable(values: RouteAllocationContract[]) {
    this.dataset = []
    values.forEach((item, key) => {
      this.dataset.push({
        id: key,
        contractNo: item.contractno,
        clientName: item.clientName,
        clientAddress: item.clientAddress,
        postalCode: item.postalCode,
        suggestedRouteName: item.suggestedRouteName,
        suggestedRouteId: item.suggestedRouteId,
        proposedDate: item.proposedDate,
        latitude: item.latitude,
        longtitude: item.longtitude,
        cType: item.cType
      })
    })

    this.ngGrid.dataView.refresh();
  }

  searchAllocations() {
    this.service.searchRouteAllocation(this.search, this.searchType, this.auth.currentUserValue.code, this.cType).subscribe({
      next: value => {
        if (value.hasOwnProperty("msg")) {
          this.dataset = [];
          this.updateAllocationTable([])
          this.handleError("Route allocation list Loaded")
        } else {
          this.dataset = []
          this.updateAllocationTable(value)
        }
      },
      error: err => {
        this.handleError("Route allocation list failed to load")
      }
    })
  }

  handleError(msg: string = "An error occured. Cannot complete the task") {
    this.snackbar.open(msg, 'Dismiss', {
      duration: DurationUtil.TWO_SEC,
      horizontalPosition: "right",
      verticalPosition: "top",
      panelClass: ['mat-toolbar', 'mat-primary']
    });
  }

  showLocation() {
    const lat = this.selectedRecord.latitude || null, lng = this.selectedRecord.longtitude || null
    if (lat && lng) {
      const location = `https://maps.google.com/?q=${lat},${lng}`
      window.open(location)
    } else {
      this.handleError("Location is not available")
    }
  }

  getSingleContractData() {
    this.TodaysCallDeskService.getContractDetailsById(this.selectedRecord.contractno, this.selectedRecord.cType).subscribe({

      next: value => {

      },
      error: err => {
        this.handleError("Data failed to load")
      }
    })
  }

  // TODO visit removal feature

  removeVisit() {
    let contractno = this.selectedRecord.contractno;
    let system = this.selectedRecord.cType;
    let user = this.auth.currentUserValue.username;
    let selectedRouteToGo = this.selectedRecord.suggestedRouteId;
    if (selectedRouteToGo == null || selectedRouteToGo == " " || !selectedRouteToGo) {
      alert("You cannot remove visit Without routing!")
    }
    else {
      let response = this.service.RemoveVisitService(contractno, system, user);
      if (response) {
        alert("Remove Success")
      }

    }
    // console.debug(contractno + system + user)
    this.getAllContracts();
    this.getSummaryData();
  }

  formatDate(inputDate: string): string {
    const date = new Date(inputDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  closeDialog() {
    this.dialogRef.close()
  }

  handleSearchEnable(e: MatCheckboxChange) {
    if (e.checked) {

      // this.changeMode("ALL")
    }
  }

  changeType(event: any) {
    this.setType()
    if (this.selectOptionMode) {
      this.isCompatible = true
    } else {
      this.isCompatible = false
    }
  }
  setType() {
    if (this.selectOptionMode == "CR") {

      this.cType = "CR";


    }
    else if (this.selectOptionMode == "WO") {
      this.cType = "WO";


    }
    else if (this.selectOptionMode == "PL") {
      this.cType = "PL";

    }
    else if (this.selectOptionMode == "PW") {
      this.cType = "PW";


    }
  }


}
