import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PostalCodeResponse } from 'src/app/models/route-management/postal-codes-model';
import { Config } from 'src/config/config';
import { RestRequestService } from '../rest-request.service';


@Injectable({
  providedIn: 'root'
})

/**
 * Service implementation releated to route management
 * @author Malindu Panchala
 */

export class RouteServicesService {

  private readonly routeJobsUrl: string;

  constructor(private rest: RestRequestService, @Inject("recoveryConfig") private recoveryConfig = Config.recoveryConfig) {
    this.routeJobsUrl = this.getRouteJobsUrl(recoveryConfig);
  }

  /**
     * Get Route job service Url from route injectable object.
     * @param recoveryConfig
     * @returns routeJobsUrl:string
     */
  private getRouteJobsUrl(recoveryConfig: any = {}): string {
    let routeJobsUrl = '';

    if (recoveryConfig.env === 'local') {
      routeJobsUrl = `${recoveryConfig.appServerUrl}`;
    } else {
      routeJobsUrl = `${recoveryConfig.recoveryServiceUrl}`;
    }
    return routeJobsUrl;
  }

  createRoute(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');
    let url = `${this.routeJobsUrl}/vCreateRoute`;
    return this.rest.post<any>(url, payload,
      {
        headers: headers,
        responseType: "text"
      });
  }

  getPostalCodes(id: string, branch: string): Observable<PostalCodeResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');
    let url = `${this.routeJobsUrl}/vGetPostalCodesBranch/${id}/${branch}`;
    return this.rest.get<PostalCodeResponse>(url,
      {
        headers: headers,
      });
  }

  getRoutesByUserId(id: string): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');
    let url = `${this.routeJobsUrl}/vGetRoutestoUser/${id}`;
    return this.rest.get<any>(url,
      {
        headers: headers,
      });
  }

  getRoutesByUserIdAndRouteId(user_id: string, route_id: string): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');
    let url = `${this.routeJobsUrl}/vGetSingleRoute/${user_id}/${route_id}`;
    return this.rest.get<any>(url,
      {
        headers: headers,
        responseType: 'text' as const
      });
  }

  updateRoute(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');
    let url = `${this.routeJobsUrl}/vUpdateRoute`;
    return this.rest.post<any>(url, payload,
      {
        headers: headers,
        responseText: "text"
      });
  }

  deleteRoute(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');
    let url = `${this.routeJobsUrl}/vDeleteRoute `;
    return this.rest.post<any>(url, payload,
      {
        headers: headers,
      });
  }

  getRoutesByBranchId(branch_id: string): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');
    let url = `${this.routeJobsUrl}/vGetRouteforBranch/${branch_id}`;
    return this.rest.get<any>(url,
      {
        headers: headers,
      });
  }

  saveTodaysRoute(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');
    let url = `${this.routeJobsUrl}/vSaveRoute`;
    return this.rest.post<any>(url, payload,
      {
        headers: headers,
      });
  }

  getTodaysRoute(user: string, date: string): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');
    let url = `${this.routeJobsUrl}/vGetTdselroute/${user}/${date}`;
    return this.rest.get<any>(url,
      {
        headers: headers,
        responseType: 'text' as const
      });
  }

  getClientCount(route: string): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');
    let url = `${this.routeJobsUrl}/vGetClientCount/${route}`;
    return this.rest.get<any>(url,
      {
        headers: headers,
        responseType: 'text' as const
      });
  }

  /**
   * global hardcoded route data
   */
  static readonly GLOBAL: GlobalData = {
    user: "USER001",
    branch: {
      id: "Colombo Branch",
      postalCodes: [
        {
          code: "00100",
          name: "Colombo 01",
          lat: 6.9392,
          long: 79.8436,
          selected: false
        },
        {
          code: "00200",
          name: "COLOMBO 05",
          lat: 6.8908,
          long: 79.8717,
          selected: false
        },
        {
          code: "00800",
          name: "COLOMBO 08",
          lat: 6.9177,
          long: 79.8742,
          selected: false
        },
        {
          code: "00900",
          name: "DEMATAGODA",
          lat: 6.9353,
          long: 79.8808,
          selected: false
        }, {
          code: "01000",
          name: "Colombo 10",
          lat: 6.9270,
          long: 79.8640,
          selected: false
        },
        {
          code: "01300",
          name: "COLOMBO-13",
          lat: 6.9459,
          long: 79.8617,
          selected: false
        },
        {
          code: "01500",
          name: "COLOMBO 15",
          lat: 6.95,
          long: 79.8667,
          selected: false
        },
        {
          code: "10107",
          name: "RAJAGIRIYA",
          lat: 6.9094,
          long: 79.8943,
          selected: false
        }
      ]
    }
  }
}

interface GlobalData {
  branch: {
    id: string,
    postalCodes: Array<{
      code: string,
      name: string,
      lat: number,
      long: number,
      selected: boolean
    }>
  };
  user: string;
}

