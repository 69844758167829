<div class="container-fluid">
  <div class="row border-1" style="padding-top: 1rem">
    <div class="col-8">
      <h2>Auto Calls</h2>
    </div>

    <div class="col-4 ">
      <button view class="btn btn-sm btn-dark float-end" (click)="closeDialog()">Close</button>
    </div>
  </div>

  <div>
    <div class="col-12 " style="padding-top: 5rem">
      <h4>Dialing 0712338996 </h4>
    </div>
  </div>


  <!--<div style="padding-top: 1.5rem">
    <form>
      <div class="row">
        &lt;!&ndash;<div class="col-3">Vehicle No :</div>&ndash;&gt;
        <div class="col-6">
          <mat-form-field appearance="fill" style="width: 90%">
            <mat-label>Vehicle No</mat-label>
            <input matInput>
          </mat-form-field>
        </div>

        <div class="col-6" >
          <mat-form-field appearance="fill" style="width: 90%">
            <mat-label>Contact No </mat-label>
            <input matInput>
          </mat-form-field>
        </div>
      </div>


      &lt;!&ndash;<div class="row" >
        <div class="col-6">Contact No :</div>
        <div class="col-6">
          <mat-form-field appearance="fill" >
            <input matInput placeholder="Contact No">
          </mat-form-field>
        </div>
      </div>&ndash;&gt;

      <div class="row" >
        <div class="col-6" >
          <mat-form-field appearance="fill" style="width: 90%">
            <mat-label>Due Amount</mat-label>
            <input matInput>
          </mat-form-field>
        </div>

        <div class="col-6" >
          <mat-form-field appearance="fill" style="width: 90%">
            <mat-label>Total Due</mat-label>
            <input matInput>
          </mat-form-field>
        </div>

      </div>
      &lt;!&ndash;<div class="row" style="">
        <div class="col-6">Total Due :</div>
        <div class="col-6">
          <mat-form-field appearance="fill">
            <input matInput placeholder="Total Due">
          </mat-form-field>
        </div>
      </div>&ndash;&gt;
      <div class="row" style="">
        <div class="col-6">
          Call Status :
          <select style="width: 80%">
            <option>-Select-</option>
            <option>Answered</option>
            <option>Ringing but not answered</option>
            <option>Switched off</option>
            <option>Engaged</option>
            <option>Wrong number</option>
            <option>Cut the line</option>
          </select>
        </div>

        <div class="col-6">
          Visit Status :
          <select #visitStatus style="width: 80%" (change)="onVisitStatusChange(visitStatus.value)">
            <option value="select">-Select-</option>
            <option value="promisedToPay">Promised to pay</option>
            <option value="pdc">PDC</option>
            <option value="leftMsg">Left a message</option>
            <option value="visitBranch">Promised to visit branch</option>
          </select>
        </div>
      </div>

      <div class="row" id="promisedToPay" [hidden]="isPromisedToPayDisabled">
        <div class="col-6" >
          <mat-form-field appearance="fill" style="width: 90%">
            <mat-label>Promised Amount</mat-label>
            <input matInput>
          </mat-form-field>
        </div>

        <div class="col-6" >
          <mat-form-field appearance="fill" style="width: 90%">
            <mat-label>Payment Date</mat-label>
            <input matInput>
          </mat-form-field>
        </div>
      </div>


    </form>

    <div class="row" style="padding-top: 5rem">
      <div class="col-6">
        <button view class="btn btn-primary float-end" style="width: 80%" >Clear</button>
      </div>
      <div class="col-6">
        <button view class="btn btn-primary float-none" style="width: 80%" >Save</button>
      </div>
    </div>

  </div>-->

</div>
