import { Inject, Injectable } from "@angular/core";
import { Config } from "../../../config/config";
import { RestRequestService } from "../rest-request.service";
import { Observable } from "rxjs/internal/Observable";
import { HttpHeaders } from "@angular/common/http";
import { VisitDeskService } from "../visit-desk.service";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Injectable({
  providedIn: 'root'
})
/**
 * Service implementation releated to Field Officer funtionalities
 * @author Achala M. Rathnathilaka
 */
export class FieldOfficerService {

  private readonly fieldOfficerTrackingURL: string;
  private readonly getTrackingStatusURL: string;
  private readonly setTrackingStatusURL: string;
  private readonly getClientLocationPing: string;

  constructor(private restService: RestRequestService, @Inject("recoveryConfig") private recoveryConfig = Config.recoveryConfig, protected visitDeskService: VisitDeskService) {

    this.fieldOfficerTrackingURL = this.getFieldOfficerTrackingUrl(recoveryConfig);
    this.getTrackingStatusURL = this.getTrackingStatUrl(recoveryConfig);
    this.setTrackingStatusURL = this.setTrackingStatUrl(recoveryConfig);
    this.getClientLocationPing = this.getClientLocationPingUrl(recoveryConfig);


  }


  /**
   * Get Filed officer get Url from  injectable object.
   * @param recoveryConfig
   * @returns url:string
   */
  private getFieldOfficerTrackingUrl(recoveryConfig: any = {}): string {
    let url = '';

    if (recoveryConfig.env === 'local') {
      url = `${recoveryConfig.appServerUrl}/vGetOffTrac`;
    } else {
      url = `${recoveryConfig.recoveryServiceUrl}/vGetOffTrac`;
    }
    return url;
  }

  /**
   *
   * @param payload
   * @returns {Observable<any>}
   */
  getFeildOfficerTrackingDetails(userid: string, date: string): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');

    let jobURL: string = this.fieldOfficerTrackingURL + '/' + userid + '/' + date;
    return this.restService.get<any>(
      jobURL,
      {
        headers: headers
      }
    );
  }

  /**
     *  Location Automation Service | managing DB data
     * @author kalanaRathnayake
     */
  // tracking status URL (get) 🤔
  private getTrackingStatUrl(recoveryConfig: any = {}): string {
    let url = '';

    if (recoveryConfig.env === 'local') {
      url = `${recoveryConfig.appServerUrl}/vGetTrackStat`;
    } else {
      url = `${recoveryConfig.recoveryServiceUrl}/vGetTrackStat`;
    }
    return url;
  }

  // tracking status URL (set) 🤔
  private setTrackingStatUrl(recoveryConfig: any = {}): string {
    let url = '';

    if (recoveryConfig.env === 'local') {
      url = `${recoveryConfig.appServerUrl}/vSetTrackStat`;
    } else {
      url = `${recoveryConfig.recoveryServiceUrl}/vSetTrackStat`;
    }
    return url;
  }

  // tracking status URL (set) 🤔
  private getClientLocationPingUrl(recoveryConfig: any = {}): string {
    let url = '';

    if (recoveryConfig.env === 'local') {
      url = `${recoveryConfig.appServerUrl}/vGetClientLocation`;
    } else {
      url = `${recoveryConfig.recoveryServiceUrl}/vGetClientLocation`;
    }
    return url;
  }

  // set tracking status 😌
  async setTrackingState(userId: string, trackingState: string): Promise<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');
    try {
      const requestBody: any = {
        userId: userId,
        trackingState: trackingState
      };

      let trackURL: string = this.setTrackingStatusURL + '';
      const response = await this.restService.post<any>(trackURL, requestBody, { headers: headers })
        .toPromise();
      console.debug('Tracking Data set: ', { response });
      return response;
    } catch (error) {
      console.error('Tracking Data error: ', error);
      throw error;
    }
  }

  //get Tracking status 😊
  async getTrackingState(userId: string): Promise<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');
    console.debug("Get Tracking Status");
    let trackURL: string = this.getTrackingStatusURL + '/' + userId;

    const response = await this.restService.get<any>(
      trackURL,
      {
        headers: headers
      }
    ).toPromise();
    return response;
  }
  //trackingAutomationEnd

  // TODO getClientPingLocation Service
  async getClientPingLocation(contractNo: string): Promise<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');
    console.debug("Get Location details from the service" + contractNo );

    let jobURL: string = this.getClientLocationPing + '/' + contractNo;

    const response = await this.restService.get<any>(
      jobURL,
      {
        headers: headers
      }
    ).toPromise();
    return response;
  }

}


