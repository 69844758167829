
/**
 * VisitDeskGeoLocationInfoModel data representation
 * @author Achala M. Rathnathilaka
 */
export class VisitDeskGeoLocationInfoModel{

  contractId?: string;
  lat?: string;
  lng?: string;
  customerName?: string;
  customerAdress?: string;
  contactNumber?: string;
  cType?: string;


}
