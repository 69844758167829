import {VisitDeskGeoLocationInfoModel} from "./visit-desk-geo-location-info-model";

/**
 * Visit Desk GEO location data representation
 * @author Achala M. Rathnathilaka
 */

export class VisitDeskGeoLocationModel {

  geoLocationList: VisitDeskGeoLocationInfoModel[] =[];

}
