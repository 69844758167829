import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularGridInstance, Column, FieldType, Filters, Formatter, Formatters, GridOption } from 'angular-slickgrid';
import { VisitProgress } from 'src/app/models/todays-visit-list/visit-progress';
import { TodaysVisitsResponseModelInfo } from 'src/app/models/todays-visit-list/todays-visits-response-model-info';
import { VisitListServiceService } from 'src/app/services/todays-visit-list/visit-list-service.service';
import { UserAuthenticationService } from 'src/app/services/user-authentication/user-authentication.service';
import { DateUtil } from 'src/app/utils/DateUtil';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ThousandSeparatorPipe } from "../../../pipes/thousand-separator.pipe";
import { DurationUtil } from 'src/app/utils/DurationUtil';
import { rightAllignment } from 'src/app/utils/testAlign'
import { ManualCallDialogComponent } from '../../manual-call-dialog/manual-call-dialog.component';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-todays-visit-list-v2',
  templateUrl: './todays-visit-list-v2.component.html',
  styleUrls: ['./todays-visit-list-v2.component.scss']
})



export class TodaysVisitListV2Component implements OnInit {

  /**
   * UI related variables
   */
  readonly title: string = "Todays Visit List Report" 

  searchType: string = "Contract"; // search type default value is contract
  search: string = "";
  dpd: string[] = [];
  npl: string[] = [];
  notPayed: string[] = [];

  dataObj: {
    requestdate: string,
    requestuser: string
  } = {
      requestdate: "",
      requestuser: ""
 
    };
 
  // view mode for changing between all, balance and todays list
  mode: string = "All"
  enableSearch: boolean = false;

  // userdata
  user: string = ""

  // multiple filter selection
  filters: any = {
    dpd: [],
    npl: []
  }
 

  readonly defaults = {
    dpd: "_",
    npl: "_",
    notPayed: "_",
    mode: "_",
    search: "_",
    searchType: "Contract"
  }
 

  /**
   * Datagrid related
   */


  columnDefinitions: Column[] = [];
  gridOptions: GridOption = {};//todaysVisitLisitDataResponseRestModelInfo
  dataset: any[] = []; //todaysVisitLisitDataResponseModelInfo

  ngGrid: AngularGridInstance;
  ngGridObj: any = null



  visitProgress: VisitProgress = {
    SELECTEDROUTE: 0,
    ALLTRIGGERED: 0,
    completed: 0,
    VISITSREMOVED: 0,
    BALANCE: 0,
    cType: "CR"
  }
  getVisitProgress: VisitProgress;

  contractSelected: string = undefined

  // util data
  cType: string = "CR";
  date: string = null;
  todayDate: Date = null;
  selectedDate: Date = null;

  selectOptionMode: string = "CR"
  CR: string
  WO: string
  PL: string
  PLWO: string

  isCompatible: boolean = false;

  constructor(private thousandSeparator: ThousandSeparatorPipe, private vistService: VisitListServiceService, private dialogRef: MatDialogRef<TodaysVisitListV2Component>, private snackBar: MatSnackBar, private auth: UserAuthenticationService, private dialog: MatDialog) {
    let dateObj = new Date()
    this.selectedDate = dateObj
    this.todayDate = dateObj
    this.date = DateUtil.getDateString(this.todayDate)
  }

  ngOnInit(): void {
    this.user = this.auth.currentUserValue.code
    this.cType = this.visitProgress.cType
    console.log(this.cType);
    this.columnDefinitions = [
      {
        id: "contractNo",
        name: "Contract Number",
        field: "contractNo",
        minWidth: 100,
        type: FieldType.string,
        filterable: true,
      }, {
        id: "type",
        name: "Type",
        field: "cType",
        minWidth: 50,
        type: FieldType.string,
        filterable: true,
      }, {
        id: "name",
        name: "Name",
        field: "name",
        minWidth: 200,
        type: FieldType.string,
        filterable: true,
      }, {
        id: "ria",
        name: "RIA(Assigned points)",
        field: "ria",
        minWidth: 75,

        filterable: true,

        formatter: rightAllignment,
        type: FieldType.string
      }, {
        id: "stockAmount",
        name: "Stock Amount",
        field: "stockAmount",
        minWidth: 100,
        type: FieldType.string,
        params: { minDecimal: 2, maxDecimal: 2 },

        filterable: true,
        sortable: true,

        formatter: rightAllignment
      }, {
        id: "arrearsAmount",
        name: "Arrears amount",
        field: "arrearsAmount",
        minWidth: 100,
        type: FieldType.string,
        params: { minDecimal: 2, maxDecimal: 2 },

        filterable: true,
        sortable: true,

        formatter: rightAllignment
      }, {
        id: "dueDate",
        name: "Due date",
        field: "dueDate",
        minWidth: 100,
        type: FieldType.date,
        formatter: Formatters.dateIso,
        filterable: true,
      }, {
        id: "clientAddress",
        name: "Client Address",
        field: "clientAddress",
        minWidth: 300,
        type: FieldType.string,
        filterable: true,
      }, {
        id: "monthEndDpd",
        name: "Month End DPD",
        field: "monthEndDpd",
        minWidth: 75,

        filterable: true,

        formatter: rightAllignment,
        type: FieldType.string
      }, {
        id: "currentDpd",
        name: "Current DPD",
        field: "currentDpd",
        minWidth: 75,

        filterable: true,

        formatter: rightAllignment,
        type: FieldType.string
      }, {
        id: "callDirection",
        name: "Visit Direction",
        field: "callDirection",
        minWidth: 150,
        type: FieldType.string,
        filterable: true,
      },
    ];
 
    this.gridOptions = {
      gridHeight: "500px",
      formatterOptions: {
        minDecimal: 2,
        maxDecimal: 2,
        dateSeparator: "-",
        displayNegativeNumberWithParentheses: true
      },
      asyncEditorLoading: false,
      autoEdit: true,
      autoCommitEdit: true,
      editable: true,
      enableCellNavigation: true,
      enableColumnPicker: false,
      autoTooltipOptions: {
        enableForCells: true,
        enableForHeaderCells: false,
        maxToolTipLength: 250
      },
 
      enableAutoTooltip: true,
      enableExcelCopyBuffer: true,
      enableFiltering: true,
      gridMenu: {
        hideForceFitButton: true,
        columnTitle: "",
        hideRefreshDatasetCommand: true
      },
      rowSelectionOptions: {
        // True (Single Selection), False (Multiple Selections)
        selectActiveRow: false
      },
      headerMenu: {
        hideSortCommands: false,
        hideColumnHideCommand: true,
        hideClearFilterCommand: true
      },
      enableCellMenu: false,
      enablePagination: false
    };
 
    this.getVistList();
    this.getProgress();
 
    // visitProgress(){
 
    // }

    

    

    // this.vistService.getVisitProgress().subscribe({
    //   next: (value) => {
    //     console.log(value)

    //     this.visitProgress = value.pop()
    //   },
    //   error: (err: any) => {
    //     console.log(err)

    //     this.snackBar.open("Call progress failed to load", 'Dismiss', {
    //       duration: 500,
    //       horizontalPosition: "right",
    //       verticalPosition: "top",
    //       panelClass: ['mat-toolbar', 'mat-primary']
    //     });
    //   }
    // })
  }

  angularGridReady(event: any) {
    this.ngGrid = (event as CustomEvent).detail as AngularGridInstance;
    this.ngGridObj = this.ngGrid.slickGrid;
    this.ngGrid.slickGrid.onSelectedRowsChanged.subscribe((e, args) => {
      this.handleSelection(e, args);
    });
  }

  getProgress() {
    this.vistService.getVisitProgress(this.user, this.cType).subscribe({
      next: (value) => {
        this.visitProgress = value.pop()
      },
      error: (err) => {
        console.log(err)

        // this.snackBar.open("Call progress failed to load", 'Dismiss', {
        this.snackBar.open("Call progress loading", 'Dismiss', {
          duration: DurationUtil.TWO_SEC,
          horizontalPosition: "right",
          verticalPosition: "top",
          panelClass: ['mat-toolbar', 'mat-primary']
        });
      }
    })
  }

  // <!-- Select type of CR/WO/PL/PLWO -->
  setType() {
      
    if (this.selectOptionMode == "CR") {
      
      this.cType = "CR";
      this.processFiltersAndReloadData(),
        this.getProgress()

    }
    else if (this.selectOptionMode == "WO") {
      this.cType = "WO";
      this.processFiltersAndReloadData()
      this.getProgress()

    }
    else if (this.selectOptionMode == "PL") {
      this.cType = "PL";
      this.processFiltersAndReloadData()
      this.getProgress()

    }
    else if (this.selectOptionMode == "PW") {
      this.cType = "PW";
      this.processFiltersAndReloadData()
      this.getProgress()

    }
    
  
  }

  handleSearchEnable(e: MatCheckboxChange) {
    if (e.checked) {
    
      // this.changeMode("ALL")
    }
  }
    
  changeType(event: any) {
    this.setType()
    if (this.selectOptionMode) {
      this.isCompatible = true
    } else {
      this.isCompatible = false
    }
  }

  handleSelection(e: Event, args: any) {
    // this.selectedCallDeskTransaction = null;
    if (Array.isArray(args.rows) && this.ngGridObj) {

      let selected = args.rows.map((idx: number) => {
        const selectedRowData = this.ngGridObj.getDataItem(idx);
        return selectedRowData || '';
      });

      this.contractSelected = selected[0].contractNo
    }
  }



  handleDpdChange(event: any) {
    console.log(event)
    // let toggle = event.source;
    // if (toggle) {
    //   let group = toggle.buttonToggleGroup;
    //   if (event.value.some((item: any) => item == toggle.value)) {
    //     group.value = [toggle.value];
    //     this.dpd = group.value
    //   }
    // }

    this.processFiltersAndReloadData()

  }

  handleNplChange(event: any) {
    // let toggle = event.source;
    // if (toggle) {
    //   let group = toggle.buttonToggleGroup;
    //   if (event.value.some((item: any) => item == toggle.value)) {
    //     group.value = [toggle.value];
    //     this.npl = group.value
    //   }
    // }

    this.processFiltersAndReloadData()

  }

  handleNotPayed(event: any) {
    // let toggle = event.source;
    // if (toggle) {
    //   let group = toggle.buttonToggleGroup;
    //   if (event.value.some((item: any) => item == toggle.value)) {
    //     group.value = [toggle.value];
    //     this.notPayed = group.value
    //   }
    // }

    this.processFiltersAndReloadData()

  }

  changeMode(value: string) {
    this.mode = value
    if (value == "ALL") {
      this.selectedDate = this.todayDate
    }

    if (value == "BALANCE" || value == "COMPLETED") {
      this.enableSearch = false
    }
    this.processFiltersAndReloadData()
  }

  dateChangeHandler(event: MatDatepickerInputEvent<Date>) {
    this.date = DateUtil.getDateString(event.value)
  }

  handleDetails() {
    if (this.contractSelected == undefined || this.contractSelected == null) {
      this.snackBar.open('Please select a call record first', 'Dismiss', {
        duration: DurationUtil.TWO_SEC,
        horizontalPosition: "right",
        verticalPosition: "top",
        panelClass: ['mat-toolbar', 'mat-primary']
      });
    } else {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = false;
      dialogConfig.closeOnNavigation = true;
      dialogConfig.autoFocus = true;
      dialogConfig.height = '100vh';
      dialogConfig.width = '100vw';
      dialogConfig.maxWidth = '100vw';
      dialogConfig.data = {
        selectedCallDeskTransaction: {
          contractNo: this.contractSelected,
          cType: this.cType
        },
        dialogType: "RELATED",

        // dialogType: "CALL",
        mode: this.mode
      };

      const dialogRef = this.dialog.open(ManualCallDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(
        data => {
          this.processFiltersAndReloadData()
          // this.getProgress()
          console.log(`Manual call dialog closed`);
        }
      );
    }
  }

  balanceVisits() {
    this.vistService.getTodaysVisitBalanceVisitListData(this.user)
      .subscribe({
        next: data => {
          this.dataset = [];
          try {
            data.map((item: any, key: Number) => {
              this.dataset.push({
                id: key,
                contractNo: item.CONTRACTNO, //contractno
                name: item.NAME, //name
                cType: item.cType, 

                clientAddress: item.clientAddress,
                ria: item.RIA,
                stockAmount: parseFloat(item.stockAmount),
                arrearsAmount: parseFloat(item.arrearsAmount),
                dueDate: item.dueDate,
                monthEndDpd: item.monthEndDPD,
                currentDpd: item.currentDPD,
                callDirection: item.direction_nature,
              })
            })
            this.ngGrid.dataView.refresh();

            //this.dataset = []

            window.alert("Operation successfull");
          } catch (e) {
            console.log(e);
            alert("Error occured during the operation");
          }
        },
        error: error => {
          console.log(error);
        }
      })
  }

  allContracts() {
    
    let process_type = this.mode.length < 1 ? this.defaults.mode : this.mode;
    let process_dpd = this.dpd.length < 1 ? this.defaults.dpd : [...this.dpd].pop()
    let process_npl = this.npl.length < 1 ? this.defaults.npl : [...this.npl].pop()
    let process_not_payed = this.notPayed.length < 1 ? this.defaults.notPayed : [...this.notPayed].pop()

    this.vistService.getAllContractsTodaysVisit(this.user)
      .subscribe({
        next: data => {
          this.dataset = [];
          try {
            data.map((item: any, key: Number) => {
              this.dataset.push({
                id: key,
                contractNo: item.CONTRACTNO, //contractno
                name: item.NAME, //name
                cType: item.cType,

                clientAddress: item.clientAddress,
                ria: item.RIA,
                stockAmount: this.thousandSeparator.transform(item.stockAmount),
                arrearsAmount: this.thousandSeparator.transform(item.arrearsAmount),
                dueDate: item.dueDate,
                monthEndDpd: item.monthEndDPD,
                currentDpd: item.currentDPD,
                callDirection: item.direction_nature,
              })
            })
            this.ngGrid.dataView.refresh();

            window.alert("Operation Successfull");
          } catch (e) {
            console.log(e);
            alert("Error occured during the operation");
          }
        },
        error: error => {
          console.log(error);
        }
      })
  }

  /**
   * removed services
   * (view route locations, start end route visit)
   */

  // viewRoute() {
  //   this.vistService.getRouteDetails(this.dataObj)
  //     .subscribe({
  //       next: (value) => {
  //         this
  //       },
  //       error: (err) => {
  //         console.log(err)
  //       }
  //     })
  // }

  // startEnd() {
  //   this.vistService.createStartVisit(this.dataObj);
  //   this.vistService.createEndVisit(this.dataObj)
  //     .subscribe({
  //       next: (value) => {
  //         this.visitProgress(value)
  //       },
  //       error: (err) => {
  //         console.log(err)
  //       }
  //     })
  // }

  //old version
  // searchVisits() {
  // this.vistService.getTodaysVisitList(/**this.search, this.searchType**/).subscribe({
  //   next: (value) => {
  //     this.updateTable(value)
  //   },
  //   error: (err) => {
  //     console.log(err)
  //     this.updateTable([])
  //     this.snackBar.open("Could not retreive search results", 'Dismiss', {
  //       duration: 1000,
  //       horizontalPosition: "right",
  //       verticalPosition: "top",
  //       panelClass: ['mat-toolbar', 'mat-primary']
  //     });
  //   }
  // })
  // this.vistService.getTodaysVisitList(/**this.search, this.searchType**/).subscribe({
  //   next: (value) => {
  //     this.updateTable(value)
  //   },
  //   error: (err) => {
  //     console.log(err)
  //     this.updateTable([])
  //     this.snackBar.open("Could not retreive search results", 'Dismiss', {
  //       duration: 1000,
  //       horizontalPosition: "right",
  //       verticalPosition: "top",
  //       panelClass: ['mat-toolbar', 'mat-primary']
  //     });
  //   }
  // })
  // }

  searchVisits() {

    let process_search = this.search.length < 1 ? this.defaults.search : this.search;
    let process_search_type = this.searchType.length < 1 ? this.defaults.searchType : this.searchType
    let process_type = this.mode.length < 1 ? this.defaults.mode : this.mode;
    let process_dpd = this.dpd.length < 1 ? this.defaults.dpd : [...this.dpd].pop()
    let process_npl = this.npl.length < 1 ? this.defaults.npl : [...this.npl].pop()
    let process_not_payed = this.notPayed.length < 1 ? this.defaults.notPayed : [...this.notPayed].pop()

    this.vistService.SearchVisits(this.user, this.search, this.searchType, process_type, process_npl, process_dpd, process_not_payed, this.date, this.cType).subscribe({
      next: (value) => {
        if (value.hasOwnProperty("msg")) {
          this.dataset = [];
          this.updateTable(null)

          this.snackBar.open("Todays Visit List failed to load", 'Dismiss', {
            duration: DurationUtil.TWO_SEC,
            horizontalPosition: "right",
            verticalPosition: "top",
            panelClass: ['mat-toolbar', 'mat-primary']
          });
        } else {
          this.dataset = []
          this.updateTable(value)
        }
      },
      error: (err) => {
        console.log(err)
        this.updateTable(null)
        this.snackBar.open("Could not retreive search results", 'Dismiss', {
          duration: DurationUtil.TWO_SEC,
          horizontalPosition: "right",
          verticalPosition: "top",
          panelClass: ['mat-toolbar', 'mat-primary']
        });
      }
    })
  }

  updateTable(values: TodaysVisitsResponseModelInfo[]) {

    this.dataset = [];
    values?.forEach((item, key) => {
      this.dataset.push({
        id: key,
        contractNo: item.CONTRACTNO, //contractno

        name: item.NAME, //name
        clientAddress: item.clientAddress,
        ria: item.RIA,
        stockAmount: this.thousandSeparator.transform(item.stockAmount),
        arrearsAmount: this.thousandSeparator.transform(item.arrearsAmount),
        dueDate: item.dueDate,
        monthEndDpd: item.monthEndDPD,
        currentDpd: item.currentDPD,
        callDirection: item.direction_nature,
        cType: item.cType
      })
    })

    this.ngGrid.dataView.refresh();
  }

  /**
   * Process filters and reload data
   */

  // processFilters() {
  //   let process_dpd = this.dpd.length < 1 ? this.defaults.dpd : [...this.dpd].pop()
  //   let process_npl = this.npl.length < 1 ? this.defaults.npl : [...this.npl].pop()
  //   let process_not_payed = this.notPayed.length < 1 ? this.defaults.notPayed : [...this.notPayed].pop()

  // }

  //loadingView bool - KalanaRathnayake
  isLoading: boolean = true;

  processFiltersAndReloadData() {
    this.isLoading = true;
    this.contractSelected = null;
    let process_type = this.mode.length < 1 ? this.defaults.mode : this.mode;
    let process_dpd = this.dpd.length < 1 ? this.defaults.dpd : [...this.dpd].join(",")
    let process_npl = this.npl.length < 1 ? this.defaults.npl : [...this.npl].join(",")
    let process_not_payed = this.notPayed.length < 1 ? this.defaults.notPayed : [...this.notPayed].join(",")

    console.log(process_type)

    this.vistService.getTodaysVisitListwithFilter(this.user, process_type, process_dpd, process_npl, process_not_payed, this.date, this.cType).subscribe({
      next: (value) => {
        if (value.hasOwnProperty("msg")) {
          this.dataset = [];
          this.updateTable(null)
          this.snackBar.open("Todays Visit List failed to load", 'Dismiss', {
            duration: DurationUtil.TWO_SEC,
            horizontalPosition: "right",
            verticalPosition: "top",
            panelClass: ['mat-toolbar', 'mat-primary']
          });
        } else {
          this.dataset = []
          this.updateTable(value)
        }
        //when data is loaded the loading view will turn off by bool value 0 - KalanaRathnayake
        //forProduction
        this.isLoading = false;

        // forDebugging
        // this.isLoading = true;
      },
      error: (err) => {
        console.log(err)

        this.snackBar.open("Todays Visit List failed to load", 'Dismiss', {
          duration: DurationUtil.TWO_SEC,
          horizontalPosition: "right",
          verticalPosition: "top",
          panelClass: ['mat-toolbar', 'mat-primary']
        });
        //loading will keep going when err trigger if you want to change make it false - KalanaRathnayake
        this.isLoading = true;
      }
    })
  }

  getVistList() {
    let process_type = this.mode.length < 1 ? this.defaults.mode : this.mode;
    let process_dpd = this.dpd.length < 1 ? this.defaults.dpd : [...this.dpd].pop()
    let process_npl = this.npl.length < 1 ? this.defaults.npl : [...this.npl].pop()
    let process_not_payed = this.notPayed.length < 1 ? this.defaults.notPayed : [...this.notPayed].pop()

    this.vistService.getTodaysVisitListwithFilter(this.user, process_type, process_dpd, process_npl, process_not_payed, this.date, this.cType).subscribe({
      next: response => {
        this.dataset = [];
        response.map((item: any, key: Number) => {
          this.dataset.push({
            id: key,
            contractNo: item.CONTRACTNO, //contractno
            name: item.NAME, //name
            cType: item.cType,

            clientAddress: item.clientAddress,
            ria: item.RIA,
            stockAmount: this.thousandSeparator.transform(item.stockAmount),
            arrearsAmount: this.thousandSeparator.transform(item.arrearsAmount),
            dueDate: item.dueDate,
            monthEndDpd: item.monthEndDPD,
            currentDpd: item.currentDPD,
            callDirection: item.direction_nature,
          })
        })
        this.ngGrid.dataView.refresh();
        this.isLoading = false;
        this.snackBar.open("Todays Visits Loaded", 'Dismiss', {
          duration: DurationUtil.TWO_SEC,
          horizontalPosition: "right",
          verticalPosition: "top",
          panelClass: ['mat-toolbar', 'mat-primary']
        });
        
      },
      error: err => { console.log(err) }
    })
  }

  closeVisitList() {
    this.dialogRef.close();
  }
}

