import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RouteAllocationContract } from 'src/app/models/route-allocation/route-allocation-contract-visit';
import { RouteSummaryModel } from 'src/app/models/route-allocation/route-summary-model';
import { Config } from 'src/config/config';
import { RestRequestService } from '../rest-request.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DurationUtil } from 'src/app/utils/DurationUtil';

@Injectable({
  providedIn: 'root'
})
/**
 * Services implementation releated to route allocation
 * @author Malindu Panchala
 */
export class RouteAllocationService {

  private routeAllocationJobsUrl: string;

  constructor(private restService: RestRequestService, @Inject("recoveryConfig") private recoveryConfig = Config.recoveryConfig, private _snackBar: MatSnackBar) {
    this.routeAllocationJobsUrl = this.getRouteAllocationJobsUrl(recoveryConfig);
  }

  getRouteAllocationJobsUrl(recoveryConfig: any = {}): string {
    let routeAllocUrl = '';

    if (recoveryConfig.env === 'local') {
      routeAllocUrl = `${recoveryConfig.appServerUrl}`;
    } else {
      routeAllocUrl = `${recoveryConfig.recoveryServiceUrl}`;
    }
    return routeAllocUrl;
  }

  getAllRouteAllocations(invg: string): Observable<RouteAllocationContract[]> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');

    let jobURL: string = this.routeAllocationJobsUrl + `/vGetRouteAllocation/${invg}`;
    return this.restService.get<RouteAllocationContract[]>(
      jobURL,
      {
        headers: headers
      }
    );
  }

  getNonRouted(invg: string): Observable<RouteAllocationContract[]> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');

    let jobURL: string = this.routeAllocationJobsUrl + `/vGetRouteAllocationNR/${invg}`;
    return this.restService.get<RouteAllocationContract[]>(
      jobURL,
      {
        headers: headers
      }
    );
  }

  getBalance(invg: string): Observable<RouteAllocationContract[]> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');

    let jobURL: string = this.routeAllocationJobsUrl + `/vGetRouteAllocationBL/${invg}`;
    return this.restService.get<RouteAllocationContract[]>(
      jobURL,
      {
        headers: headers
      }
    );
  }

  searchRouteAllocation(search: string, type: string, invg: string, cType: string): Observable<RouteAllocationContract[]> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');

    let jobURL: string = this.routeAllocationJobsUrl + `/vGetSearchRouteAllocation/${search}/${type}/${invg}/${cType}`;
    return this.restService.get<RouteAllocationContract[]>(
      jobURL,
      {
        headers: headers
      }
    );
  }

  autoProximityAdjust(invg: string): Observable<RouteAllocationContract[]> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');

    let jobURL: string = this.routeAllocationJobsUrl + `/vGetAutoproximityadjust/${invg}`;
    return this.restService.get<RouteAllocationContract[]>(
      jobURL,
      {
        headers: headers
      }
    );
  }

  getRouteAllocationSummary(invg: string): Observable<RouteSummaryModel[]> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');

    let jobURL: string = this.routeAllocationJobsUrl + `/vGetRouteAlcSummory/${invg}`;
    return this.restService.get<RouteSummaryModel[]>(
      jobURL,
      {
        headers: headers
      }
    );
  }

  //https://cfmobile.lk/CFBBService.svc/vRouteAllcSave
  saveAllocationRecord(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');

    let jobURL: string = this.routeAllocationJobsUrl + `/vRouteAllcSave`;
    return this.restService.post<any>(
      jobURL,
      payload,
      {
        headers: headers
      }
    );
  }

  // remove visit service #KGR
  async RemoveVisitService(contractno: string, system: string, user: string): Promise<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');
    try {
      const requestBody: any = {
        contractno: contractno,
        system: system,
        user: user
      };

      let jobURL: string = this.routeAllocationJobsUrl + '/vRemoveVisit';
      const response = await this.restService.post<any>(jobURL, requestBody, { headers: headers })
        .toPromise();
      console.debug('Success', { response });
      //TODO add notification
      this._snackBar.open("Visit Remove Success", 'Dismiss', {
        duration: DurationUtil.TWO_SEC,
        horizontalPosition: "right",
        verticalPosition: "top",
        panelClass: ['mat-toolbar', 'mat-primary']
      });

      return response;
    } catch (error) {
      console.error('Unsuccess: ', error);
      this._snackBar.open("Visit Remove Unsuccess", 'Dismiss', {
        duration: DurationUtil.TWO_SEC,
        horizontalPosition: "right",
        verticalPosition: "top",
        panelClass: ['mat-toolbar', 'mat-primary']
      });
      throw error;
    }
  }

}
