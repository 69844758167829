/**
 * Visit Desk Model data representation
 * @author Achala M. Rathnathilaka
 */
export class VisitDeskDataRequestModel {

  requestuser?: string;
  requestdate?: string;


}
