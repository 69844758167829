export class DateUtil {
    static getDateString(date: Date): string {

        if(isNaN(date.valueOf())){
            return null;
        }
        let year = date.getFullYear()
        let monthIdx = date.getMonth()
        let _date = date.getDate()
        
        let month: string = null
        let dateReturns: string = null

        if (monthIdx < 9) {
            month = `0${++monthIdx}`
        } else {
            month = `${++monthIdx}`
        }

        if (_date < 10) {
            dateReturns = `0${_date}`
        } else {
            dateReturns = `${_date}`
        }

        return `${dateReturns}-${month}-${year}`
    }
}